import { logError } from '@vendor';
import { XMLParser } from 'fast-xml-parser';
import { IODataContentType } from '@microsoft/sp-odata-types';
import _ from 'lodash';
import { GraphClient } from '../../../../services/src/networkAndCache';
import { SharePointClient } from '../sharePointAPI';
import { SPDocument } from '../sharePointTypes';
import { formatDateTime } from '../actions/ActionsUtils';
import { SharePointField, SharePointFieldType } from '../metadata/SharePointFieldType';
import { OfficeUserSettingManager } from '../stateManager';

export interface ViewCreationParameters {
  title: string;
  rowLimit: number;
  viewFields: string[];
  query?: string;
  viewType?: string;
}

// Define interfaces for the properties
export interface EmailItemProperties {
  contentType: IODataContentType[];
  to?: string;
  cc?: string;
  from?: string;
  subject?: string;
  date?: string;
  received?: string;
  categories?: string[];
  messageId?: string;
  conversationIndex?: string;
  conversationTopic?: string;
  hasAttachments?: boolean;
}

export interface EmailHeaderMapping {
  shouldMap: boolean;
  fieldMappings?: LibraryEHMMapping;
  contentTypeId?: string; // Include ContentTypeId in the interface
}

export interface UpdatePropertiesPayload {
  formValues: Array<{
    FieldName: string;
    FieldValue: string | boolean;
    HasException?: boolean;
  }>;
  bNewDocumentUpdate?: boolean;
  checkInComment?: string | null;
  datesInUTC: boolean;
}

export const SupportedEHMFieldsRecord = {
  Attachments: '',
  From: '',
  Date: '',
  Received: '',
  Subject: '',
  To: '',
  Cc: '',
  Categories: '',
  MessageId: '',
  ConversationIndex: '',
  ConversationTopic: '',
  ContentTypes: '',
  ContentTypeId: '',
};

export type SupportedEHMFields = keyof typeof SupportedEHMFieldsRecord;

interface MappedFieldInfo {
  mappedNames: string[];
  isAttachment?: boolean;
  isNameList?: boolean;
  fieldName?: string;
  ind?: number;
}

export type LibraryEHMMapping = Partial<Record<SupportedEHMFields, MappedFieldInfo>>;

interface InverseMappedEntry extends MappedFieldInfo {
  key: SupportedEHMFields;
}
export class EmailHeaderFieldsMapper {
  private readonly mappings: Record<string, InverseMappedEntry>;
  private readonly mappedFields: LibraryEHMMapping = {
    Attachments: {
      isAttachment: true,
      mappedNames: [
        'hrmV2HasAttachments',
        'hrmHasAttachments',
        'hrmAttachments',
        'HasAttachments',
        'Attachments',
        'Attachment',
        'MailAttachments',
        'WithAttachments',
      ],
    },
    From: { mappedNames: ['hrmV2From', 'hrmFrom', 'EmailFrom', 'EMailSender', 'From', 'From1', 'MailFrom', 'Sender'] },
    Received: {
      mappedNames: [
        'hrmV2Received',
        'hrmV2Recieved', // typo in our code!
        'hrmReceived',
        'EmailReceived',
        'Received',
      ],
    },
    Date: {
      mappedNames: ['hrmV2Date', 'hrmDate', 'EmailDate', 'Date', 'MailDate'],
    },
    Subject: { mappedNames: ['hrmV2Subject', 'hrmSubject', 'EmailSubject', 'Subject', 'MailSubject'] },
    To: {
      isNameList: true,
      mappedNames: ['hrmV2To', 'hrmTo', 'EmailTo', 'To', 'MailTo'],
    },
    Cc: { isNameList: true, mappedNames: ['hrmV2CC', 'hrmV2Cc', 'hrmCc', 'hrmCcOWSMTXT', 'EmailCc', 'Cc', 'MailCc'] },
    Categories: {
      mappedNames: ['hrmV2Categories', 'hrmCategories', 'Categories'],
    },
    MessageId: {
      mappedNames: ['hrmV2MessageID', 'hrmMessageID', 'MessageID'],
    },
    ConversationIndex: {
      mappedNames: ['hrmV2ConversationIndex', 'hrmConversationIndex', 'Conversation-Index', 'ConversationIndex'],
    },
    ConversationTopic: {
      mappedNames: ['hrmV2ConversationTopic', 'hrmConversationTopic', 'Conversation-Topic', 'ConversationTopic'],
    },
    ContentTypes: {
      mappedNames: ['EMail', 'E-Mail'],
    },
    ContentTypeId: { mappedNames: ['ContentTypeId'] },
  };

  constructor(parsed: any) {
    this.mappings = Object.entries(this.mappedFields).reduce(
      (acc, [key, value]) => {
        let ind = 0;
        parsed[key]?.forEach(
          name => (acc[name.toLowerCase()] = { ...value, key: key as SupportedEHMFields, ind: ind++ })
        );
        value.mappedNames.forEach(
          name => (acc[name.toLowerCase()] = { ...value, key: key as SupportedEHMFields, ind: ind++ })
        );
        return acc;
      },
      {} as Record<string, InverseMappedEntry>
    );
  }

  getEmailContentType = (contentTypes: IODataContentType[]) => {
    return _.minBy(
      contentTypes.filter(contentType => this.getFieldInfo(contentType.Name)?.key === 'ContentTypes'),
      contentType => this.getFieldInfo(contentType.Name)?.ind // Return the best (lowest value) match.
    );
  };

  getFieldInfo = (fieldName: string) => {
    const fieldMapping = this.mappings[fieldName.toLowerCase()];
    return fieldMapping ? { ...fieldMapping, fieldName } : undefined;
  };

  isTypeField = (field?: SharePointField): boolean => {
    return field?.TypeAsString === SharePointFieldType.Computed && field.InternalName === 'DocIcon';
  };

  isAttachmentField = (field?: SharePointField) => {
    return field && this.getFieldInfo(field.StaticName)?.isAttachment;
  };
}

class EmailHeaderMappingSettings extends OfficeUserSettingManager<EmailHeaderFieldsMapper> {
  constructor() {
    super('EmailHeaderMapping', () => new EmailHeaderFieldsMapper({}), 'EHMState');
  }

  protected override transformValue(val: any): EmailHeaderFieldsMapper {
    return new EmailHeaderFieldsMapper(val);
  }
}
export const EmailHeaderMappingState = new EmailHeaderMappingSettings();

export const getMappedFieldInfoIfMatch = (
  ehmField: SupportedEHMFields,
  fieldName: string,
  mapping: LibraryEHMMapping
): MappedFieldInfo | undefined => {
  const fieldMapping = mapping[ehmField];
  return fieldMapping?.fieldName === fieldName ? fieldMapping : undefined;
};

function extractFieldNames(xml: string | undefined, libFields: SharePointField[]): LibraryEHMMapping | undefined {
  try {
    const parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: '', // Ensure attributes are parsed without a prefix
    });
    const result = xml && parser.parse(xml);

    const fields = result?.ContentType?.Fields?.Field || libFields;
    if (xml && !fields) {
      throw new Error('Fields not found in the parsed XML.');
    }

    const ehmSettings = EmailHeaderMappingState.value;
    const extractedFields: LibraryEHMMapping = xml ? { ContentTypeId: ehmSettings.getFieldInfo('ContentTypeId') } : {};
    const fieldsArray = Array.isArray(fields) ? fields : [fields];
    for (const field of fieldsArray) {
      const { key, ...curMapping } = EmailHeaderMappingState.value.getFieldInfo(field.StaticName) || {};
      if (key && !extractedFields[key])
        extractedFields[key] = { ...curMapping!, fieldName: field.StaticName } as MappedFieldInfo;
    }
    return Object.keys(extractedFields).length ? extractedFields : undefined;
  } catch (error: any) {
    logError('Error parsing XML:', error);
    throw error;
  }
}

function getEmailHeaderMapping(contentTypes: IODataContentType[], libFields: SharePointField[]): EmailHeaderMapping {
  const emailContentType = EmailHeaderMappingState.value.getEmailContentType(contentTypes);
  const xml = emailContentType?.SchemaXml;
  const fieldMappings = extractFieldNames(xml, libFields);
  const contentTypeId = emailContentType?.Id?.StringValue;
  return {
    shouldMap: fieldMappings !== undefined,
    fieldMappings,
    contentTypeId, // Include ContentTypeId if found
  };
}

// Function to prepare email header fields for updating using dynamic field names from XML
function prepareEmailHeaderFields(
  emailHeaders: EmailItemProperties,
  fieldMappings?: LibraryEHMMapping,
  contentTypeId?: string
): UpdatePropertiesPayload {
  // Define a mapping of email header properties to their corresponding values in emailHeaders
  const possibleMappings: Partial<Record<SupportedEHMFields, string | undefined>> = {
    To: emailHeaders.to,
    Cc: emailHeaders.cc,
    From: emailHeaders.from,
    Subject: emailHeaders.subject,
    Attachments: emailHeaders.hasAttachments ? 'True' : 'False',
    Received: formatDateTime(emailHeaders.received, true), // Use the custom format for date mapping
    Date: formatDateTime(emailHeaders.date, true), // Use the custom format for date mapping
    Categories: emailHeaders.categories?.join(';'),
    MessageId: emailHeaders.messageId,
    ConversationIndex: emailHeaders.conversationIndex,
    ConversationTopic: emailHeaders.conversationTopic,
    ContentTypeId: contentTypeId, // Include ContentTypeId if it exists
  };

  // Iterate over the possibleMappings to create the formValues array dynamically
  const formValues: UpdatePropertiesPayload['formValues'] = Object.entries(possibleMappings)
    .filter(([key, value]) => fieldMappings?.[key] && value) // Only mapped fields with values
    .map(([key, value]) => ({
      FieldName: fieldMappings![key].fieldName!,
      FieldValue: value!,
      HasException: false,
    }));

  return {
    bNewDocumentUpdate: false,
    checkInComment: null,
    datesInUTC: true,
    formValues,
  };
}

// Function to update properties in SharePoint
async function updateProperties(
  item: SPDocument,
  spc: SharePointClient,
  payload: UpdatePropertiesPayload
): Promise<void> {
  await spc.api(`${item.parent.list.apiId}/items(${item.metadata.ID})/ValidateUpdateListItem()`).post(payload);
}

// Main function to manage properties for a given document
export const manageProperties = async (
  item: SPDocument,
  gcl: GraphClient,
  content: EmailItemProperties
): Promise<void> => {
  const spc = new SharePointClient(gcl, item.rootSite);
  // Extract email header mapping and prepare the payload for update
  const emailHeaderMapping = getEmailHeaderMapping(content.contentType, await item.parent.getSchema(spc));
  if (emailHeaderMapping.shouldMap) {
    const payload = prepareEmailHeaderFields(
      content,
      emailHeaderMapping?.fieldMappings,
      emailHeaderMapping?.contentTypeId
    );
    await updateProperties(item, spc, payload);
  }
};
