import { EternalWindow, GraphClient } from '@services';
import { SharePointClient, SPCList, SPCListPropsKeys, SPList, SPSite, MicrosoftApps } from '../utilities';
import { HarmonieFilesTitle, HarmonieInternals } from './ProvisioningReader';

// Utility function to create a document library under the root site.
const findHarmonieFilesLibrary = async (
  gcl: GraphClient,
  siteUrl: string,
  siteName: string
): Promise<SPList | undefined> => {
  const spc = new SharePointClient(gcl, new URL(siteUrl).origin);
  try {
    // Check if library already exists
    const hlib: SPCList = await spc
      .api(`${siteUrl}/_api/web/lists/getByTitle('${HarmonieFilesTitle}')`)
      .cache(EternalWindow)
      .select(SPCListPropsKeys)
      .get();
    return new SPList({
      id: hlib['odata.id'],
      name: hlib.Title,
      sharepointListId: hlib.Id,
      serverRelativeUrl: SPSite.extractLibPathFromView(hlib.DefaultViewUrl),
      OfficeAppLocated: MicrosoftApps.SharePoint,
      site: new SPSite({
        id: '',
        site: siteUrl,
        name: siteName,
        OfficeAppLocated: MicrosoftApps.SharePoint,
        _isUserManaged: false,
      }),
    });
  } catch (error) {
    return undefined;
  }
};

export const getHarmonieFilesLibrary = async (gcl: GraphClient, rootSite: string): Promise<SPList | undefined> => {
  return (
    (await findHarmonieFilesLibrary(gcl, rootSite, 'root')) ||
    (await findHarmonieFilesLibrary(gcl, `${rootSite}/${HarmonieInternals}`, HarmonieInternals))
  );
};

export enum UserRole {
  Admin = 'Admin',
  Member = 'Member',
  None = 'None',
}

export enum UserPermissions {
  None = 0, // No permissions
  ViewListItems = 0x00000001, // Binary: 00000001
  AddListItems = 0x00000004, // Binary: 00000100
  EditListItems = 0x00000008, // Binary: 00001000
  DeleteListItems = 0x00000010, // Binary: 00010000
  ManagePermissions = 0x40000000, // Binary: 01000000 (High bit)
  // Compound permissions
  View = ViewListItems, // Read-only access
  Edit = AddListItems | EditListItems | DeleteListItems | ManagePermissions, // Full write access
}

export const getSharePointListUserRole = async (gcl: GraphClient, list?: SPList): Promise<UserRole> => {
  const userPermissions = await list?.getListPermissions(gcl);
  return userPermissions === UserPermissions.Edit ? UserRole.Admin : UserRole.Member;
};
