import React, { forwardRef } from 'react';
import { StackProps } from '@mui/material';
import { WhatsNewStepsControls } from './WhatsNewStepsControls';

export interface WhatsNewSingleStepControlProps extends Omit<StackProps, 'height'> {
  skipText?: string;
  onSkipClick?: () => void;
  customButtonText?: string;
  onCustomButtonClick?: () => void;
}

export const WhatsNewSingleStepControl = forwardRef<any, WhatsNewSingleStepControlProps>(
  ({ skipText, onSkipClick, customButtonText, onCustomButtonClick, ...rest }: WhatsNewSingleStepControlProps, ref) => {
    return (
      <WhatsNewStepsControls
        ref={ref}
        customButtonText={customButtonText}
        onCustomButtonClick={onCustomButtonClick}
        finishText={skipText}
        onFinish={onSkipClick}
        {...rest}
      />
    );
  }
);

WhatsNewSingleStepControl.displayName = 'WhatsNewSingleStepControl';
