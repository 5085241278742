import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme): Components['MuiTabs'] & Components['MuiTab'] => {
  return {
    styleOverrides: {
      root: {
        color: theme.palette.texts.secondary,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: theme.spacing(1, 2),
        textTransform: 'none',
        flex: 1,
        minWidth: 0,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        minHeight: 30,
        borderBottom: `2px solid ${theme.palette.outlinesAndStrokes.primary}`,
        '&.Mui-selected': {
          color: theme.palette.texts.accentPrimary,
          borderBottom: `2px solid ${theme.palette.outlinesAndStrokes.accent}`,
        },
        '&.Mui-selected:hover': {
          color: theme.palette.texts.accentPrimary,
          backgroundColor: theme.palette.backgrounds.secondary,
          borderBottom: `2px solid ${theme.palette.outlinesAndStrokes.accent}`,
        },
        '&:hover': {
          backgroundColor: theme.palette.backgrounds.secondary,
          color: theme.palette.texts.primary,
        },
      },
    },
  };
};
