import React from 'react';
import { Stack, StackProps, Typography } from '@mui/material';

export interface WhatsNewBulletItemProps extends StackProps {
  icon?: React.ReactNode;
  text: string | React.ReactNode;
}

export const WhatsNewBulletItem = ({ icon, text, ...rest }: WhatsNewBulletItemProps) => {
  return (
    <Stack direction="row" spacing={1} {...rest}>
      {icon ? <Stack sx={{ minWidth: 20, maxWidth: 20 }}>{icon}</Stack> : null}
      {typeof text === 'string' ? (
        <Typography variant="H2Regular" color="text.primary">
          {text}
        </Typography>
      ) : (
        text
      )}
    </Stack>
  );
};
