import React, { SyntheticEvent } from 'react';
import { Dialog, DialogProps } from '@mui/material';
import { CloseButton } from './common';

export type WhatsNewDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose?: (e: SyntheticEvent) => void;
  borderRadius?: number | undefined;
};

export const WhatsNewDialog = ({ children, onClose, borderRadius, ...rest }: WhatsNewDialogProps) => {
  return (
    <Dialog
      {...rest}
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius },
      }}
    >
      <CloseButton onClick={onClose} />
      {children}
    </Dialog>
  );
};
