import { AppConfig } from '@services';
import LogRocket from 'logrocket';
import { EnableLogsRecording } from '~/modules/Settings/SettingContext';

const LOG_EXPIRY_TIME = 60 * 60 * 1000; // 1 hour in milliseconds

export const getCurrentTimeString = (): string => {
  const now = new Date();
  return (
    `${now.getHours().toString().padStart(2, '0')}:` +
    `${now.getMinutes().toString().padStart(2, '0')}:` +
    `${now.getSeconds().toString().padStart(2, '0')}`
  );
};

export const hasOneHourPassed = (savedTime?: string): boolean => {
  if (!savedTime) return true;

  const [savedHours, savedMinutes, savedSeconds] = savedTime.split(':').map(Number);
  const savedDate = new Date();
  savedDate.setHours(savedHours, savedMinutes, savedSeconds ?? 0, 0);

  return Date.now() - savedDate.getTime() > LOG_EXPIRY_TIME;
};

export const logrocket = (profile: Office.UserProfile, isEnabled?: boolean) => {
  const lastEnabledTime = EnableLogsRecording.value;

  // If more than 1 hour has passed, disable logging
  if (lastEnabledTime && hasOneHourPassed(lastEnabledTime)) {
    EnableLogsRecording.value = '';
    return;
  }

  if (isEnabled) {
    const currentTime = getCurrentTimeString();
    EnableLogsRecording.value = currentTime; // Store time as HH:mm
    LogRocket.init(AppConfig.getInstance().logRocketAppId);
    LogRocket.identify(profile.emailAddress, {
      name: profile.displayName,
      email: profile.emailAddress,
    });

    // Auto-disable logs after 1 hour
  } else {
    EnableLogsRecording.value = '';
  }
};
