import React, { useCallback, useEffect, useState } from 'react';
import { ContextMenu2, Scrollbar, useTheme, useYesNoDialog } from '@storybook';
import { getUserImageOrInitialName, getUserInfo, useGraphClient } from '@services';
import { Stack, Tab, Tabs } from '@mui/material';
import { strings } from '@vendor';
import { getProvisionedLibrary, getSharePointListUserRole, UserRole } from '~/provisioning';
import { LocalStateManager, SPList } from '~/utilities';
import { useSettingsState } from '~/hooks';
import { TopBar } from '../../components';
import { useSettingsController } from './useSettingsController';
import { useSettings } from './SettingContext/SettingContext';
import GeneralSettings from './GeneralSettings';
import AdvancedSettings from './AdvancedSettings';
import AdminCenter from './Admin/AdminCenter';
import SettingsSkeleton from './SettingsSkeleton';

const IsKnownAdminUser = new LocalStateManager<boolean | undefined>('IsKnownAdminUser', () => undefined, false);

const Settings = () => {
  const uiTheme = useTheme();
  const gcl = useGraphClient();
  const {
    theme,
    setTheme,
    setGeneralNumber,
    setOpenFilesSettings,
    setEditPropertiesMode,
    generaFilesCount,
    openFilesSettings,
    showTreeHistory,
    editPropertiesMode,
    setShowTreeHistory,
    resetSetting,
    setEmailNameSettings,
    emailNameSettings,
    setEmailHeaderMapping,
    emailHeaderMapping,
    setUserPhoto,
    userPhoto,
    setUserDetails,
    userDetails,
    enableTeams,
    changeLanguage,
    selectedLanguage,
    enableLogsRecording,
    setEnableLogsRecording,
  } = useSettings();
  const [activeTab, setActiveTab] = useState(0); // Manage active tab state
  const [loading, setLoading] = useState(false); // Manage loading state
  const [pendingTab, setPendingTab] = useState<number | null>(null); // Store the tab user clicked
  const [stateChange, setStateChange] = useState(false);
  const [organizationLibrary, setOrganizationLibrary] = useState<SPList | undefined>(undefined);
  const { showDialog, YesNoDialog } = useSettingsController(resetSetting);
  const [isAdminUser, setIsAdminUser] = useSettingsState(IsKnownAdminUser);
  const { YesNoDialog: TabChangeConfirmed, setIsDialogVisible } = useYesNoDialog({
    texts: {
      dialogTitle: strings.lang.adminSetting.unsavedChangesDialogTitle,
      dialogSubTitle: strings.lang.adminSetting.unsavedChangesDialogDescription,
      primaryActionText: strings.lang.adminSetting.unsavedChangesDialogPrimaryAction,
      secondaryActionText: strings.lang.adminSetting.unsavedChangesDialogSecondaryAction,
    },
    blockOutsideClick: false,
    secondryActionCallback: () => {
      setActiveTab(pendingTab || 0);
      setStateChange(false);
      PubSub.publish('AdminSettingsChanged', false);
    },
    useTextVariant: true,
  });

  const getUserInfoAndRole = useCallback(async () => {
    try {
      const list = getProvisionedLibrary();
      setOrganizationLibrary(list);
      setLoading(list !== undefined && IsKnownAdminUser.value === undefined);
      const userRole = await getSharePointListUserRole(gcl, list);
      const userInfo = await getUserInfo(gcl);
      setUserDetails?.({ userInfo: userInfo, userRole: userRole });
      setIsAdminUser(userRole === UserRole.Admin);
      const account = gcl.msal.getActiveAccount() || gcl.msal.getAllAccounts()[0];
      const userAvatar = await getUserImageOrInitialName(
        gcl,
        userInfo?.id || account?.localAccountId,
        userInfo?.displayName || Office.context.mailbox.userProfile.displayName
      );
      setUserPhoto?.(userAvatar);
      // Ensure skeleton is displayed for at least 500 ms
      setLoading(false);
    } catch (e) {
      setUserPhoto && setUserPhoto(undefined);
    }
  }, [gcl, setIsAdminUser, setUserDetails, setUserPhoto]);

  useEffect(() => {
    if (userPhoto === 'isLoading') getUserInfoAndRole();
  }, [getUserInfoAndRole, userPhoto]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (activeTab === 2 && stateChange) {
      setPendingTab(newValue);
      setIsDialogVisible(true);
    } else {
      setActiveTab(newValue);
    }
  };

  return (
    <ContextMenu2>
      <Stack sx={{ background: uiTheme.palette.backgrounds.primary }} minHeight="100%">
        {/* Tab Wrapper */}
        {loading ? (
          <SettingsSkeleton />
        ) : (
          <Stack minHeight="100%">
            <Stack
              alignItems="center"
              justifyContent="flex-start"
              px={3}
              py={2}
              sx={{
                zIndex: 1000, // Ensure it's above other content (if necessary)
                width: '100%', // Full-width to align with its container
              }}
            >
              <TopBar isSettingsPage={true} />
            </Stack>
            <Stack px={3} width={'100%'}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                TabIndicatorProps={{ style: { display: 'none' } }}
                sx={{ minHeight: 30 }}
              >
                <Tab label={strings.lang.userSetting.generalSettingTitle} />
                <Tab label={strings.lang.userSetting.advancesSettingTitle} />
                {isAdminUser && <Tab label={strings.lang.adminSetting.adminCenterTitle} />}
              </Tabs>
            </Stack>
            <Scrollbar>
              <Stack py={2} px={3} minHeight="100%">
                {activeTab === 0 && (
                  <GeneralSettings
                    settings={{
                      theme,
                      setTheme,
                      setGeneralNumber,
                      setOpenFilesSettings,
                      generaFilesCount,
                      openFilesSettings,
                      showTreeHistory,
                      setShowTreeHistory,
                      changeLanguage,
                      selectedLanguage,
                    }}
                    userPhoto={userPhoto}
                    userDetails={userDetails}
                    showDialog={showDialog}
                  />
                )}
                {activeTab === 1 && (
                  <AdvancedSettings
                    settings={{
                      editPropertiesMode,
                      setEditPropertiesMode,
                      emailHeaderMapping,
                      setEmailHeaderMapping,
                      emailNameSettings,
                      setEmailNameSettings,
                      enableTeams,
                      enableLogsRecording,
                      setEnableLogsRecording,
                    }}
                  />
                )}
                {activeTab === 2 && (
                  <AdminCenter
                    setAdminStateChange={setStateChange}
                    adminStateChange={stateChange}
                    list={organizationLibrary}
                  />
                )}
              </Stack>
            </Scrollbar>
            {TabChangeConfirmed}
            {YesNoDialog}
          </Stack>
        )}
      </Stack>
    </ContextMenu2>
  );
};

export default Settings;
