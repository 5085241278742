import React, { useCallback, useMemo } from 'react';
import { multiSelectionsEventPublish, TreeHandlers, TreeView, TreeViewProps, WrapperComponentProps } from '@storybook';
import { treeHandlers } from 'react-hyper-tree';
import { useGraphClient } from '@services';
import { useGetChildren, useSettingsState, useSubscribe } from '~/hooks';
import {
  ItemData,
  FavoritesRoot,
  OneDriveRoot,
  SharedWithMeRoot,
  RecentRoot,
  TeamsRoot,
  SharePointSitesRoot,
  ItemListChangesType,
  ItemsListChangedEvent,
  isItemContainer,
  resolveAndPublishItem,
  EnableOneDriveState,
  EnableTeamsState,
  getItemContainer,
  getRootSectionItem,
  EmptyStateItem,
} from '~/utilities';
import KeyPressSubscribe from '~/modules/KeyPress/KeyPressSubscribe';
import { RenderNodeProps, getTreeNodeRenderer } from './Renderers/GetNodeRenderer';

export const getHarmonieTree = () => {
  return treeHandlers.trees['HarmonieTree'];
};

export const getHarmonieTreeHandlers = (): TreeHandlers => {
  return getHarmonieTree().handlers as any as TreeHandlers;
};

const WrapperComponent: React.FC<WrapperComponentProps> = ({ children, handlers }) => {
  const gcl = useGraphClient();
  useSubscribe(
    ItemsListChangedEvent,
    useCallback(
      ({ toResolve, updated, added, deleted, location }: ItemListChangesType) => {
        const node = location && handlers.getNode(location.id);
        const data = getItemContainer(node?.data as ItemData);
        if (!data || !node) return;

        updated = { ...updated, ...toResolve };

        let updatedChildren = node.getChildren().map(n => n.data as ItemData);

        if (toResolve) {
          // Resolving can only be done when the current location has folders.
          if (location.hasFolder) Object.values(toResolve).forEach(item => resolveAndPublishItem(gcl, location, item));
        }

        if (added) {
          const addedItems = Object.values(added);
          data.newItemsCount += addedItems.length;
          // If node is opened than we'll show the added items immediately. Mark the root section as uptodate
          if (node.isOpened()) getRootSectionItem(data)?.markAsUpdated();

          // We insert every item in the beginning, reverse ensures items are inserted in correct order
          addedItems.reverse().forEach(newItem => {
            newItem.rootPrefix = location.rootPrefix;
            if (isItemContainer(newItem.type)) newItem.getChildren = location.getChildren;

            const existingIndex = updatedChildren.findIndex(v => v.apiIdKey === newItem.apiIdKey);
            if (existingIndex !== -1) {
              // Replace existing item and move it to the top
              updatedChildren.splice(existingIndex, 1); // Remove existing item
              updatedChildren.unshift(newItem); // Add new item to the top
            } else {
              // Add new item
              const where = location.getAddedItemsInsertionPoint(newItem, updatedChildren);
              updatedChildren.splice(where !== -1 ? where : updatedChildren.length, 0, newItem);
            }
          });
        }

        if (updated) {
          updatedChildren = updatedChildren.map(n => {
            const updatedItem = updated[n.apiIdKey];
            if (updatedItem) {
              updatedItem.rootPrefix = location.rootPrefix;
              updatedItem.getChildren = n.getChildren;
              return updatedItem;
            }
            return n;
          });
        }

        if (deleted) {
          updatedChildren = updatedChildren.filter(n => !deleted[n.apiIdKey]);
          multiSelectionsEventPublish({ handlers: handlers as any, selectedItem: [] });
        }

        handlers.setRawChildren(
          node,
          location.applyEmptyState(updatedChildren.filter(item => !(item instanceof EmptyStateItem))),
          undefined,
          true
        );
      },
      [gcl, handlers]
    )
  );

  return (
    <div>
      <KeyPressSubscribe handlers={handlers as any} />
      {children}
    </div>
  );
};

const Tree = () => {
  const getChildren = useGetChildren();
  const [enableTeams] = useSettingsState(EnableTeamsState);
  const [enableOneDrive] = useSettingsState(EnableOneDriveState);
  const data = useMemo(() => {
    const arrayData = [FavoritesRoot.instance, RecentRoot.instance, new SharedWithMeRoot()] as ItemData[];

    // Add the Apps root items by order
    if (enableTeams) arrayData.push(TeamsRoot.instance);
    arrayData.push(SharePointSitesRoot.instance);
    if (enableOneDrive) arrayData.push(new OneDriveRoot());
    return arrayData.map((n: ItemData) => {
      n.getChildren = getChildren;
      return n;
    });
  }, [enableOneDrive, enableTeams, getChildren]);
  const renderNode = (props: RenderNodeProps) =>
    // eslint-disable-next-line react/prop-types
    getTreeNodeRenderer((props.node.data as ItemData).type)?.(props);

  const treeViewProps: TreeViewProps = useMemo(
    () => ({
      useTreeStateProps: {
        data,
        id: 'HarmonieTree',
        multipleSelect: true,
      },
      treeProps: {
        renderNode,
        disableLines: true,
      },
      wrapperComponent: WrapperComponent,
      rootIds: data.map(d => d.id as string),
      canBeSelected: n => (n.data as ItemData).supportsSelection,
    }),
    [data]
  );

  return (
    <TreeView key={`tree-${enableOneDrive}-${enableTeams}`} {...treeViewProps} wrapperComponent={WrapperComponent} />
  );
};

export default Tree;
