import { StackOwnProps, TypographyOwnProps } from '@mui/material';
import { Stack, Typography, useTheme } from '@storybook';
import React from 'react';

export const SettingsKeyControl: React.FC<
  {
    title?: string;
    titleVariant?: TypographyOwnProps['variant'];
    description?: string;
    descriptionColor?: string;
    descriptionVariant?: TypographyOwnProps['variant'];
  } & StackOwnProps
> = ({ title, description, titleVariant, descriptionVariant, descriptionColor, ...props }) => {
  const theme = useTheme();
  return (
    <Stack {...props}>
      {title && (
        <Typography variant={titleVariant || 'H1SemiBold'} color={theme.palette.texts.primary}>
          {title}
        </Typography>
      )}
      {description && (
        <Typography
          variant={descriptionVariant || 'H3Regular'}
          color={descriptionColor || theme.palette.texts.secondary}
        >
          {description}
        </Typography>
      )}
    </Stack>
  );
};
