/* eslint-disable no-var */
import { RecentEmptyStateDark, RecentEmptyStateLight, RecentMedium, IconProps } from '@storybook';
import { SharedInsight, UsedInsight } from '@microsoft/microsoft-graph-types';
import { RecentExpirationWindow, GraphBatchRequest, GValue, GraphClient } from '@services';
import { strings } from '@vendor';
import _ from 'lodash';
import { RecentFolders } from '~/modules/Settings/SettingContext';
import {
  BaseContainer,
  FetchChildrenProps,
  ItemContainer,
  EmptyStateItem,
  ItemDataWithPaging,
  ItemData,
} from '../itemTypes';
import { createItemDataFromJson } from '../misc';
import { OfficeUserSettingManager } from '../stateManager';
import { FilterDriveItem, getSortByDate, Insight, InsightItem, InsightTypedFilter } from '../graphTypes';
import { encodeSpecialCharacters } from '../sharePointAPI';
import { ItemListChangesType, ItemsListChangedEvent } from '../actions';
import { BaseRootSectionItem } from './RootSectionItem';
import { ViewFilesItem } from '../itemTypes/ViewFilesItem';

interface RecentFolder extends ItemContainer {
  savedDate: string;
}

export class RecentRoot extends BaseRootSectionItem {
  static RecentFolders = RecentFolders;
  static readonly RecentCountState = new OfficeUserSettingManager('RecentCountState', () => 5);
  private static _instance?: RecentRoot;

  static get instance() {
    if (!this._instance) this._instance = new RecentRoot();
    return this._instance;
  }

  private constructor() {
    super({ type: 'recentroot', name: `${strings.lang.nodeNames.recent}`, id: 'root' });
    this.rootPrefix = 'recent:';
    PubSub.subscribe(ItemsListChangedEvent, (_nde, { location, added, updated, deleted }: ItemListChangesType) => {
      if (location?.isRootSection || !location?.hasFolder) return;

      let list = RecentRoot.RecentFolders.value;
      if (added && Object.values(added).filter(n => n.isDocument).length > 0) {
        list = list.filter(r => r.id.toLowerCase() !== location.apiIdKey);
        list.unshift({ ...location.toJson(), savedDate: new Date().toISOString() });
      }
      if (deleted) list = list.filter(loc => !Object.keys(deleted).includes(loc.id.toLowerCase()));
      if (updated)
        list = list.map(loc => {
          const key = Object.keys(updated).find(key => key === loc.id.toLowerCase());
          const updatedItem = key && updated[key]?.toJson();
          if (updatedItem) updatedItem.savedDate = loc.savedDate;
          return updatedItem || loc;
        });
      RecentRoot.RecentFolders.value = list.slice(0, 25).map(({ isNew: _0, ...data }) => data);
    });
  }

  override get name() {
    return strings.lang.nodeNames.recent;
  }
  get stateCount(): OfficeUserSettingManager<number> {
    return RecentRoot.RecentCountState;
  }
  override get supportSearch(): boolean {
    return false;
  }
  get quickSearchPlaceholder(): string {
    return strings.lang.quickSearch.recent;
  }

  override getNumberOfQuickActions(): number {
    return 1;
  }

  static getAccountAndName(gcl: GraphClient) {
    const account = gcl.msal.getActiveAccount() || gcl.msal.getAllAccounts()[0];
    return { account, encodedUsername: encodeSpecialCharacters(account.username?.replace(/'/g, "''") || '') };
  }

  override applyEmptyState(items: ItemData[], isDrilldown?: boolean): ItemData[] {
    return new EmptyStateItem({
      name: 'recent',
      inTree: true,
      images: { light: RecentEmptyStateLight, dark: RecentEmptyStateDark },
      isDrilldown,
    }).apply(items, items.length === 0);
  }

  override async fetchChildren({ gcl, refreshStamp, isDrilldown }: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const { account, encodedUsername } = RecentRoot.getAccountAndName(gcl);
    const gb = new GraphBatchRequest(gcl.client);
    const fetchCount = isDrilldown ? 100 : 25;
    const resultCount = isDrilldown ? fetchCount : RecentRoot.RecentCountState.value;
    const sharedByMeFuture = await gb
      .api('/me/insights/shared')
      .cache(RecentExpirationWindow, refreshStamp)
      .filter(
        `${InsightTypedFilter + FilterDriveItem} AND (lastShared/sharedBy/address eq '${encodeSpecialCharacters(
          encodedUsername
        )}')`
      )
      .top(fetchCount!)
      .get();

    const accessedFuture = await gb
      .api('/me/insights/used')
      .cache(RecentExpirationWindow, refreshStamp)
      .orderby('lastUsed/lastAccessedDateTime desc')
      .filter(InsightTypedFilter + FilterDriveItem)
      .top(fetchCount)
      .get();
    const modifiedFuture = await gb
      .api('/me/insights/used')
      .cache(RecentExpirationWindow, refreshStamp)
      .filter(InsightTypedFilter + FilterDriveItem)
      .top(fetchCount)
      .get();
    // TODO: try and catch for this requests so if one of them failed dont throw error  -  o'hara@harmon.ie user failed on shared reuest
    await gb.runAll();
    const { value: accessed }: GValue<UsedInsight> = await accessedFuture();
    const { value: modified }: GValue<UsedInsight> = await modifiedFuture();
    const { value: sharedByMe }: GValue<SharedInsight> = await sharedByMeFuture();
    const sharedItems = sharedByMe.filter(item => item.resourceReference?.type !== 'microsoft.graph.fileAttachment');
    // Same item can be both in shared & used. Merge them.
    const allItems = _.keyBy(
      ([...accessed, ...modified, ...sharedItems] as Insight[]).filter(v => v.resourceReference?.webUrl),
      'resourceReference.webUrl'
    );
    accessed.forEach(u => {
      const webUrl = u?.resourceReference?.webUrl || '';
      if (!allItems[webUrl]) {
        allItems[webUrl] = {}; // Initialize the object if it doesn't exist
      }
      allItems[webUrl].lastUsed = u?.lastUsed;
    });
    modified.forEach(u => {
      const webUrl = u?.resourceReference?.webUrl || '';
      if (!allItems[webUrl]) {
        allItems[webUrl] = {}; // Initialize the object if it doesn't exist
      }
      allItems[webUrl].lastUsed = u?.lastUsed;
    });
    const collator = new Intl.Collator();
    const sortedItems = _.values(allItems).sort((a, b) => {
      const ad = _.max([a.lastUsed?.lastAccessedDateTime, a.lastShared?.sharedDateTime]) || 'none';
      const bd = _.max([b.lastUsed?.lastAccessedDateTime, b.lastShared?.sharedDateTime]) || 'none';
      return collator.compare(bd, ad);
    });

    const foldersMaxCount = _.min([
      hrmProvisioning.maxRecentFoldersCount,
      hrmProvisioning.recentFoldersCount + resultCount / 10,
    ]);
    const folders = RecentRoot.RecentFolders.value.slice(0, foldersMaxCount).map(v => {
      const res = createItemDataFromJson(v) as BaseContainer;
      res['savedDate'] = v.savedDate; // So res is actually a RecentFolder
      return res;
    });
    folders.forEach(f => f.setOwnPrefix());

    // Split the sharedItems array based on the condition
    const items = (
      await this.mixFoldersAndRecent(
        gcl,
        folders as any[],
        sortedItems.map(item => InsightItem.fromInsight(item, account)),
        resultCount
      )
    ).map(v => {
      v.isTransientLocation = true;
      return v;
    });

    return {
      items: this.applyEmptyState(
        new ViewFilesItem({ name: strings.lang.nodeNames.viewAllFiles, parent: this }).apply(
          items,
          resultCount,
          !isDrilldown
        ),
        isDrilldown
      ),
    };
  }

  // Goal - Show most recent recentCount items with at least recentFoldersCount folders from last week
  // Last week folders may not be within the recentCount recent items.
  private async mixFoldersAndRecent(gcl: GraphClient, folders: RecentFolder[], recent: InsightItem[], count: number) {
    // Sort everything according to date. Then separate folders & documents
    const lastQuarter = new Date(Date.now() - hrmProvisioning.recentTimeSpan).toISOString();
    const res = ([...folders, ...recent] as (InsightItem & RecentFolder)[])
      .sort(getSortByDate())
      .filter(v => lastQuarter <= (v.date || v.savedDate || ''))
      .slice(0, count);

    let slicedFolders: RecentFolder[] = res.filter(r => r.hasFolder);
    const lastWeek = new Date(Date.now() - hrmProvisioning.recentFoldersTimeSpan).toISOString();
    const lastWeekFolders = folders.filter(f => lastWeek <= f.savedDate);
    // slicedFolders may have less items than last week if user used/shared many documents since last upload
    if (slicedFolders.length < lastWeekFolders.length) slicedFolders = lastWeekFolders;

    const insightItems = res.filter(v => v instanceof InsightItem);
    // Resolve SharePoint IDs for the insight items in BG
    InsightItem.resolveSharepointIds(gcl, insightItems);

    // Folders first by date. Note that because of lastWeekFolders we res may have increased in size
    return [...slicedFolders, ...insightItems].slice(0, count);
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: RecentMedium, isColorable: true };
  }
}
