import React, { useCallback, useState } from 'react';
import { Stack, StackProps } from '@mui/material';
import { WhatsNewSteps, WhatsNewStepsProps } from './WhatsNewSteps';
import { WhatsNewStepperControlsProps, WhatsNewStepsControls } from './WhatsNewStepsControls';
import { useComponentHeight } from '../../hooks';

const CONTROLS_HEIGHT = 60;

export type WhatsNewContentProps = StackProps & {
  stepsProps: WhatsNewStepsProps;
  stepsControlsProps: WhatsNewStepperControlsProps;
};

export const WhatsNewContent = ({ stepsProps, stepsControlsProps, ...rest }: WhatsNewContentProps) => {
  const containerRef = React.useRef<any>(null);
  const contentRef = React.useRef<any>(null);
  const [slideRef, setSlideRef] = useState<any>(null);
  const { state } = useComponentHeight({ ref: containerRef });

  const hasScroll = state.height - CONTROLS_HEIGHT <= (slideRef?.clientHeight || 0);
  const stepsHeight = `calc(100% - ${CONTROLS_HEIGHT}px)`;

  const setCurrentSlideRef = useCallback(() => {
    setSlideRef(contentRef?.current);
  }, []);

  return (
    <Stack alignItems="center" ref={containerRef} height="100%" {...rest}>
      <WhatsNewSteps ref={contentRef} onSprintChange={setCurrentSlideRef} height={stepsHeight} {...stepsProps} />
      <WhatsNewStepsControls dropShadow={hasScroll} height={CONTROLS_HEIGHT} {...stepsControlsProps} />
    </Stack>
  );
};
