import { ViewItem, EditDetailsMedium, DetailsViewMedium } from '@storybook';
import { getLocationUrl, useGraphClient } from '@services';
import { strings } from '@vendor';
import { oDirname } from '@vendor';
import { ItemContainer, ItemData, ShareableItem } from '../itemTypes';
import {
  ActionRendererHookType,
  BaseAction,
  BaseReadActionClass,
  SelectedItemHandler,
  TrackedEventType,
} from './BaseAction';
import { LibraryItem } from '../itemTypes/LibraryItem';
import { OfficeUserSettingManager } from '../stateManager';
import { createActionEvent, trackEvent } from '../analytics/tracking';
import { publishItemListChanged } from './UploadAction';
import { RefreshAction } from './RefreshAction';
import { openRedirectedOfficeDialog } from './ActionsUtils';

export const EditPropertiesMode = new OfficeUserSettingManager<boolean>('EditPropertiesMode', () => true);

// Fake event to easily trigger a Mixpanel event.
class CloseProperties extends BaseReadActionClass {
  readonly trackedName = 'CloseProperties';
  isHandled = () => true;

  transformEvent({ data, eventName }: TrackedEventType, ctx?: boolean) {
    return { eventName, data: { ...data, autoClose: Boolean(ctx) } };
  }
}

const useOpenOnSharepoint: ActionRendererHookType = ({ action, nodes, useSafeCallback, handlers }) => {
  const gcl = useGraphClient();
  const detailsPage = (action as OpenPageOnSharepointAction).isReadOnly ? 'view' : 'edit';
  const onClick = useSafeCallback(async () => {
    const useModal = EditPropertiesMode.value;
    const parent = nodes[0].data.parent;
    const location = (handlers.getParent(nodes[0])?.data as ItemContainer) || parent;
    let url: string | undefined;
    if (parent) url = (await parent.getAccessUrls(gcl))?.webUrl;
    else url = oDirname((await nodes[0].data.getAccessUrls(gcl))?.address);
    url = (url && `${url}?env=WebView`) || '/_layouts/15/AccessRequests.aspx';
    let secondSource = useModal ? getLocationUrl('/closeChild') : undefined; // This will automatically close the fialog window
    for (let i = 0; i < nodes.length; i++) {
      url = `${await (nodes[i].data as unknown as ShareableItem).getDetailsUrlByType(detailsPage, gcl)}&env=Embedded&Source=${encodeURIComponent(url)}`;
      if (secondSource) url = `${url}&Source=${encodeURIComponent(secondSource)}`;
      secondSource = undefined;
    }
    const onClose = autoClose => {
      const tracked = createActionEvent(new CloseProperties(), 'Other', nodes, handlers, autoClose);
      trackEvent(tracked.eventName, tracked.data);
      const toResolve = {};
      nodes.forEach(n => (toResolve[n.data.apiId] = n.data));
      RefreshAction.markRefreshStamp(location, Date.now());
      publishItemListChanged({ toResolve, location });
    };
    if (useModal)
      openRedirectedOfficeDialog(url, undefined, result => {
        if (!result.value) return;
        result.value.addEventHandler(Office.EventType.DialogMessageReceived, async arg => {
          // Close dialog if any error occurred or a message to close the dialog received.
          if ('error' in arg || arg.message === 'closeChild') {
            onClose(!('error' in arg));
            result.value.close();
          }
        });
        result.value.addEventHandler(Office.EventType.DialogEventReceived, async () => onClose(false));
      });
    else url && window.open(url);
  }, [nodes, handlers, gcl, detailsPage]);

  if (detailsPage == 'view')
    return {
      title: strings.lang.actionToolTip.viewOnSharepoint,
      icon: DetailsViewMedium,
      onClick,
    };

  return {
    title: strings.lang.actionToolTip.editOnSharepoint,
    icon: EditDetailsMedium,
    onClick,
  };
};

const useOpenDetailsOnSharepoint = useOpenOnSharepoint;

abstract class OpenPageOnSharepointAction extends BaseReadActionClass implements SelectedItemHandler {
  constructor(readonly isReadOnly: boolean) {
    super();
  }
  readonly useRenderer = useOpenDetailsOnSharepoint;

  // Limit action to 3 items.
  isHandled = BaseAction.isSingleHandled(this);
  // If we want multi selection...
  isHandled2 = (nodes: ViewItem<ItemData>[]) => {
    if (BaseAction.isMultiHandled(this)(nodes) && nodes.length <= 5) {
      const list = (nodes[0].data as LibraryItem).list; // Ensure all items are from same list
      return (list && nodes.every(n => (n.data as LibraryItem).list === list)) || nodes.length == 1;
    }
    return false;
  };
  isSingleHandled(data: ItemData) {
    return data.isShareable && (data.isAdvancedSearchItem || !data.isSearchItem);
  }
}

export class EditOnSharepointAction extends OpenPageOnSharepointAction {
  constructor() {
    super(false);
  }
  readonly trackedName = 'EditProperties';
  isSingleHandled(data: ItemData) {
    return super.isSingleHandled(data) && (data as any as ShareableItem).allowEdit;
  }
}

export class ViewOnSharepointAction extends OpenPageOnSharepointAction {
  constructor() {
    super(true);
  }
  readonly trackedName = 'ViewProperties';
  isSingleHandled(_data: ItemData) {
    return false; // super.isSingleHandled(data) && !(data as any as ShareableItem).allowEdit;
  }
}
