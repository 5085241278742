import React, { useCallback, forwardRef } from 'react';
import { MobileStepper, Stack, StackProps, useTheme } from '@mui/material';
import { Button } from '../../../Button';

const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
};

export interface WhatsNewStepperControlsProps extends Omit<StackProps, 'height'> {
  activeStep?: number;
  maxSteps?: number;
  finishText?: string;
  onFinish?: () => void;
  skipAllText?: string;
  onSkipAll?: () => void;
  customButtonText?: string;
  onCustomButtonClick?: () => void;
  nextText?: string;
  backText?: string;
  onStepChange?: (step: number) => void;
  dropShadow?: boolean;
  height?: number;
}

export const WhatsNewStepsControls = forwardRef<any, WhatsNewStepperControlsProps>(
  (
    {
      activeStep = 0,
      maxSteps = 0,
      onStepChange,
      onFinish,
      onSkipAll,
      nextText = 'Next',
      backText = 'Back',
      finishText = 'Close',
      skipAllText = 'Skip all',
      customButtonText,
      onCustomButtonClick,
      dropShadow = false,
      sx,
      height = 70,
      ...rest
    }: WhatsNewStepperControlsProps,
    ref
  ) => {
    const theme = useTheme();

    const handleNext = useCallback(() => {
      onStepChange?.(activeStep + 1);
    }, [activeStep, onStepChange]);

    const handleBack = useCallback(() => {
      onStepChange?.(activeStep - 1);
    }, [activeStep, onStepChange]);

    const lastStep = activeStep === Number(maxSteps || 0) - 1;
    const finishStep = (activeStep === 0 && maxSteps === 0) || lastStep;

    return (
      <Stack
        ref={ref}
        direction="row"
        width="100%"
        height={height}
        justifyContent={onSkipAll ? 'space-between' : 'space-between'}
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.backgrounds.primary,
          position: 'absolute',
          bottom: 0,
          left: 0,
          zIndex: 1000,
          boxShadow: dropShadow ? theme.palette.shadow.bottomSheet : 'none',
          px: 3,
          ...sx,
        }}
        {...rest}
      >
        {maxSteps ? (
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            backButton={null}
            nextButton={null}
            sx={{ backgroundColor: theme.palette.backgrounds.primary, px: 0 }}
          />
        ) : null}
        <Stack
          direction="row"
          spacing={2}
          justifyContent={maxSteps === 0 ? 'space-between' : 'flex-end'}
          height={30}
          width="100%"
        >
          <Button
            variant="text"
            onClick={finishStep ? onFinish : onSkipAll}
            sx={{ ...ellipsis, display: activeStep > 0 ? 'none' : 'inherit' }}
          >
            {finishStep ? finishText : skipAllText}
          </Button>
          <Button
            variant="text"
            onClick={handleBack}
            sx={{ ...ellipsis, display: activeStep <= 0 ? 'none' : 'inherit' }}
          >
            {backText}
          </Button>
          <Button
            variant="primary"
            onClick={finishStep ? onCustomButtonClick : handleNext}
            sx={{ minWidth: 50, ...ellipsis }}
          >
            {finishStep ? customButtonText : nextText}
          </Button>
        </Stack>
      </Stack>
    );
  }
);

WhatsNewStepsControls.displayName = 'WhatsNewStepsControls';
