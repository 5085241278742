import React, { MutableRefObject, useCallback } from 'react';
import { BAVariant, ContainerHandlers, ViewItem } from '@storybook';
import { GraphClient } from '@services';
import { ShowNotificationFunction } from '@harmon.ie-ce/storybook/src/contexts/NotificationsContext';
import { useSafeCallbackType } from '~/hooks';
import { ItemData, MicrosoftApps } from '../itemTypes/ItemData';
import { BaseActionUIWrapProps } from './BaseActionUIWrap';

export type TrackedEventName =
  | 'Group'
  | 'Menu'
  | 'SidebarInit'
  | 'SidebarError'
  | 'Feedback'
  | 'TrainingVideos'
  | 'ManageSites'
  | 'ManageTeams'
  | 'ManageItemCount'
  | 'GotoSettings'
  | 'SignIn'
  | 'Navigate'
  | 'NavigationHistory'
  | 'DeleteItems'
  | 'AddItems'
  | 'DeleteItems'
  | 'AddItems'
  | 'SaveEmailButton'
  | 'UploadTransaction'
  | 'CopyLink'
  | 'EmailWithLink'
  | 'EmailWithAttachment'
  | 'ShareToTeams'
  | 'RefreshItem'
  | 'Download'
  | 'ViewProperties'
  | 'EditProperties'
  | 'CloseProperties'
  | 'OpenInApp'
  | 'SearchAllLocations'
  | 'Rename'
  | 'OpenDocument'
  | 'OpenContainingLocation'
  | 'CopyAddress'
  | 'Sort'
  | 'GetSupport'
  | 'Auto Email Header Mapping'
  | 'ChangeView'
  | 'DetailsView'
  | 'SettingsChange'
  | 'ProvisionedSettings!'
  | 'WhatsNew'
  | 'SimpleView'
  | 'ReleaseNotes';

export type ActionItemType =
  | 'None'
  | 'Site'
  | 'Library'
  | 'OneDrive'
  | 'Custom List'
  | 'Document Set'
  | 'Folder'
  | 'Document'
  | 'Custom List Item'
  | 'Shared Channel'
  | 'Private Channel'
  | 'Channel'
  | 'Team'
  | 'Group'
  | 'Location Url'
  | 'Favorite'
  | 'Followed Site'
  | 'Registered Site'
  | 'Group By Item'
  | 'Virtual Item'
  | 'Home Location'
  | 'Outlook'
  | 'Setting'
  | 'View'
  | 'Recent'
  | 'Shared With Me'
  | 'Teams Chat Files'
  | 'Favorite'
  | 'Favorites List'
  | 'Teams List'
  | 'Sites List'
  | 'Recent Items'
  | 'User Setting'
  | 'History Item'
  | 'People Tab'
  | 'Favorites Folder'
  | 'License File';

export type ActionTrigger =
  | 'Quick Action'
  | 'Context Menu'
  | '3Dots'
  | 'Double Click'
  | 'Shortcut'
  | 'Empty State'
  | 'Breadcrumbs'
  | 'Footer Quick Action'
  | 'Indicator'
  | 'Admin Center'
  | 'View All Files'
  | 'Other';

export interface TrackedEventType {
  data: {
    trigger: ActionTrigger;
    itemsCount?: number;
    targetApp?: MicrosoftApps;
    targetView?: string;
    itemType: ActionItemType;
    actionType: BaseActionType;
    rootType?: ActionItemType;
  };
  eventName: TrackedEventName;
}

export interface SelectedItemHandler {
  isSingleHandled(data: ItemData);
}
type BaseActionType = 'Read' | 'Update' | 'Create' | 'Delete';

// Defined this externally so that type inference will work better. Otherwise, too many things become 'any'.
interface UseRendererProps {
  useSafeCallback: useSafeCallbackType;
  useCallback: typeof useCallback;
  nodes: ViewItem<ItemData>[];
  actionVariant: BAVariant;
  handlers: ContainerHandlers<ItemData>;
  trigger: ActionTrigger;
  action: BaseAction;
  actionRef: MutableRefObject<any>;
}

interface ExtraRendererHookProps {
  SideUI?: React.JSX.Element;
  actionVariant?: BAVariant;
}

export abstract class BaseAction {
  protected constructor(readonly type: BaseActionType) {}

  supportsTopLocation = false;
  abstract get trackedName(): TrackedEventName;

  isQuickAction?(data: ItemData, isTopBarItem: boolean): string | undefined;
  abstract isHandled(nodes: ViewItem<ItemData>[]): boolean;
  transformEvent?({ data, eventName }: TrackedEventType, _ctx?: any): TrackedEventType;
  isDirty(): boolean {
    return false;
  }
  readonly useRenderer: (
    props: UseRendererProps
  ) => (Omit<BaseActionUIWrapProps, 'BaseActionVariant' | 'node'> & ExtraRendererHookProps) | null;

  static isMultiHandled(a: SelectedItemHandler) {
    return (nodes: ViewItem<ItemData>[]) => !!nodes.length && nodes.every(n => a.isSingleHandled(n.data));
  }

  static isAnyHandled(a: SelectedItemHandler) {
    return (nodes: ViewItem<ItemData>[]) => !!nodes.length && nodes.some(n => a.isSingleHandled(n.data));
  }

  static isSingleHandled(a: SelectedItemHandler) {
    return (nodes: ViewItem<ItemData>[]) => nodes.length == 1 && a.isSingleHandled(nodes[0].data);
  }
}

export type ActionRendererHookType = BaseAction['useRenderer'];
type ActionHookRT = ReturnType<ActionRendererHookType>;

export enum KeyModifiers {
  None = 0,
  Alt = 1,
  Ctrl = 1 << 1,
  Meta = 1 << 2,
}

export type ShortcutAction = BaseAction & {
  useRenderer: (props: UseRendererProps) => NonNullable<ActionHookRT> & {
    onClick: () => any;
  };
  directAction?: (
    gcl: GraphClient,
    nodes: ViewItem<ItemData>[]
  ) => Promise<Parameters<ShowNotificationFunction> | void>; // Use when we know that the call isn't async after all...
  shortcut: [KeyModifiers, string];
};
export type ShortcutActionRendererHookType = (props: UseRendererProps) => ReturnType<ShortcutAction['useRenderer']>;

export abstract class BaseReadActionClass extends BaseAction {
  constructor() {
    super('Read');
  }
}

export abstract class BaseUpdateActionClass extends BaseAction {
  constructor() {
    super('Update');
  }
}

export abstract class BaseAddActionClass extends BaseAction {
  readonly trackedName: TrackedEventName = 'AddItems';

  constructor() {
    super('Create');
  }
}

export abstract class BaseDeletActionClass extends BaseAction {
  readonly trackedName = 'DeleteItems';
  constructor() {
    super('Delete');
  }
}

export interface ActionComponentProps<nodeType = ViewItem<ItemData>> {
  title: string;
  node: nodeType;
  isDrilldown?: boolean;
  BaseActionVariant: BAVariant;
}

export interface ActionComponentWithHandlersProps<nodeType = ViewItem<ItemData>>
  extends ActionComponentProps<nodeType> {
  handlers?: ContainerHandlers<ItemData>;
}
