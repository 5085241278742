import { Components, Theme } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiMenu'] = {
    defaultProps: {},
    styleOverrides: {
      root: {
        pointerEvents: 'all',
        '& .MuiList-root': {
          width: '100%',
          flex: 1,
          padding: theme.spacing(1),
          borderRadius: theme.spacing(2),
          backgroundColor: theme.palette.backgrounds.overlaidComponentsPrimary,
          position: 'relative',
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
        '& .MuiDivider-root': {
          margin: theme.spacing(1, 0),
          background: theme.palette.outlinesAndStrokes.primary,
          border: 'none',
          height: '1px',
        },
      },
      list: {
        padding: 0,
        borderRadius: theme.spacing(2),
        overflowY: 'hidden',
        overflowX: 'hidden',
        boxShadow: 'none',
      },
      paper: {
        background: 'rgba(0,0,0,0)',
        borderRadius: theme.spacing(2),
        overflow: 'visible',
        boxShadow: 'none',
        minWidth: '220px',
        maxWidth: '220px',

        ['.onBottom &']: {
          display: 'flex',
          alignItems: 'flex-end',
        },
      },
    },
  };
  return styleOverrides;
};
