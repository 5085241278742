import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { strings } from '@vendor';
import {
  OnboardingSitesDark,
  OnboardingSitesLight,
  OnboardingTeamsDark,
  OnboardingTeamsLight,
} from '../../assets/images';
import { OutlookLarge, SharePointLarge, TeamsLarge, OneDriveLarge } from '../../assets/icons';
import { SlideFooter } from './SlideFooter';
import {
  allSetupAnimationData,
  searchAnimationDataDark,
  searchAnimationDataLight,
  shareAnimationDataDark,
  shareAnimationDataLight,
  uploadAnimationDataDark,
  uploadAnimationDataLight,
  welcomeAnimationDataDark,
  welcomeAnimationDataLight,
  welcomeAnimationDataLoopDark,
  welcomeAnimationDataLoopLight,
} from '../Lottie';
import { Stack } from '../Stack';
import { OnboardingManage } from './OnboardingManage';
import { SliderHeader } from './SlideHeader';
import { OperatedList } from '../ManageLists/ManageItem';
import { OnboardingListItemType } from '../OnboardingListItem/OnboardingListItem';

interface SlideDataProps {
  header?: React.ReactNode;
  title: string;
  message?: string;
  animationData?: any;
  animationDataTwo?: any;
  visualSlide?: React.ReactNode;
  officeImages?: React.ReactNode;
  footer?: React.ReactNode;
  manageList?: React.ReactNode;
}

interface SlideDataHookProps {
  isLoading: boolean;
  currentSlide: number;
  items?: { sites: OperatedList[]; teams: OperatedList[] };
  loginCallback?: () => Promise<void>;
  setCurrentSlide?: (currentSlide: number) => void;
}

const useSlideData = ({ isLoading, items, currentSlide, setCurrentSlide, loginCallback }: SlideDataHookProps) => {
  const theme = useTheme();
  const [selectedSites, setSelectedSites] = useState<OperatedList[] | undefined>();
  const [selectedTeams, setSelectedTeams] = useState<OperatedList[] | undefined>();
  const nextSlide = () => {
    setCurrentSlide && setCurrentSlide(currentSlide + 1);
  };

  const skipSites = () => {
    setSelectedSites(undefined);
    nextSlide();
  };

  const skipTeams = () => {
    setSelectedTeams(undefined);
    nextSlide();
  };

  const prevSlide = () => {
    setCurrentSlide && setCurrentSlide(currentSlide - 1);
  };

  const slideData: SlideDataProps[] = [
    {
      title: strings.lang.onBoarding.welcomePageTitle,
      message: strings.lang.onBoarding.welcomePageMessage,
      animationData: {
        loop: 2,
        autoplay: true,
        animationData: theme.palette.mode === 'light' ? welcomeAnimationDataLight : welcomeAnimationDataDark,
      },
      animationDataTwo: {
        loop: true,
        autoplay: true,
        animationData: theme.palette.mode === 'light' ? welcomeAnimationDataLoopLight : welcomeAnimationDataLoopDark,
      },
      officeImages: (
        <Stack direction={'row'} spacing={1}>
          <OutlookLarge />
          <SharePointLarge />
          <TeamsLarge />
          <OneDriveLarge />
        </Stack>
      ),
      footer: <SlideFooter slideType="welcome" loginOnClick={loginCallback} />,
    },
    {
      title: strings.lang.onBoarding.uploadTitle,
      message: strings.lang.onBoarding.uploadMessage,
      animationData: {
        loop: 2,
        autoplay: true,
        animationData: theme.palette.mode === 'light' ? uploadAnimationDataLight : uploadAnimationDataDark,
      },
      footer: <SlideFooter slideType="upload" skipOrBackSlide={prevSlide} nextSlide={nextSlide} />,
    },
    {
      title: strings.lang.onBoarding.shareTitle,
      message: strings.lang.onBoarding.shareMessage,
      animationData: {
        loop: 2,
        autoplay: true,
        animationData: theme.palette.mode === 'light' ? shareAnimationDataLight : shareAnimationDataDark,
      },
      footer: <SlideFooter slideType="share" skipOrBackSlide={prevSlide} nextSlide={nextSlide} />,
    },
    {
      title: strings.lang.onBoarding.searchTitle,
      message: strings.lang.onBoarding.searchMessage,
      animationData: {
        loop: 2,
        autoplay: true,
        animationData: theme.palette.mode === 'light' ? searchAnimationDataLight : searchAnimationDataDark,
      },
      footer: (
        <SlideFooter
          slideType="search"
          skipOrBackSlide={prevSlide}
          nextSlide={nextSlide}
          isLastSlide={items?.sites.length === 0 && items.teams.length === 0}
        />
      ),
    },
    // Conditionally include the sites slide only if items.sites is defined
    ...(items && items.sites && items.sites.length > 0
      ? [
          {
            header: <SliderHeader isSharePoint={true} />,
            title: strings.lang.onBoarding.sharePointPageTitle,
            message: strings.lang.onBoarding.sharePointPageMessage,
            visualSlide: theme.palette.mode === 'light' ? <OnboardingSitesLight /> : <OnboardingSitesDark />,
            footer: (
              <SlideFooter
                slideType="sites"
                skipOrBackSlide={skipSites}
                nextSlide={nextSlide}
                selectedItems={selectedSites?.length || 0}
                setSelectedItems={setSelectedSites}
                items={items.sites}
                isLastSlide={items.teams.length === 0}
              />
            ),
            manageList: (
              <OnboardingManage
                selectedItems={selectedSites}
                setSelectedItems={setSelectedSites}
                items={items.sites}
                listType={OnboardingListItemType.sharepoint}
              />
            ),
          },
        ]
      : []),
    // Conditionally include the teams slide only if items and items.teams are defined
    ...(items && items.teams && items.teams.length > 0
      ? [
          {
            header: <SliderHeader isSharePoint={false} />,
            title: strings.lang.onBoarding.teamsPageTitle,
            message: strings.lang.onBoarding.teamsPageMessage,
            visualSlide: theme.palette.mode === 'light' ? <OnboardingTeamsLight /> : <OnboardingTeamsDark />,
            footer: (
              <SlideFooter
                slideType="teams"
                skipOrBackSlide={skipTeams}
                nextSlide={nextSlide}
                selectedItems={selectedTeams?.length || 0}
                setSelectedItems={setSelectedTeams}
                items={items.teams}
                isLastSlide={true}
              />
            ),
            manageList: (
              <OnboardingManage
                selectedItems={selectedTeams}
                setSelectedItems={setSelectedTeams}
                items={items.teams}
                listType={OnboardingListItemType.teams}
              />
            ),
          },
        ]
      : []),
    {
      title: isLoading ? strings.lang.onBoarding.onBoardingLoadingTitle : strings.lang.onBoarding.onBoardingDoneTitle,
      animationData: {
        loop: false,
        autoplay: true,
        animationData: allSetupAnimationData,
      },
    },
  ];

  return { slideData, selectedSites, selectedTeams };
};

export default useSlideData;
