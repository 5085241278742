import React, { MouseEventHandler, useCallback, useContext, useMemo } from 'react';
import {
  Stack,
  Toolbar,
  Typography,
  useTheme,
  ViewItem,
  ContextMenuContext,
  MouseEventsInterface,
  MouseEventsContext,
  toolbarQuickActions,
  ContainerHandlers,
} from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor';
import { ItemContainer, ItemData, RenderActionByHook, generateActionMenu, getQuickActionsList } from '~/utilities';
import { TransientLocationLabelTooltip } from '~/components/Tree/Renderers/TransientNodeRenderer';
import { Search } from '~/utilities/search';
import { DrilldownActionsManager, getDrilldownActions } from './Actions/DrilldownActionsManager';
import { GoBackAction } from './Actions/GoBack';

const DrilldownToolbar: React.FC<{
  location: ItemContainer;
  handlers: ContainerHandlers<ItemData>;
  enableActions: boolean;
}> = ({ location, enableActions, handlers }) => {
  const { handleContextMenu } = useContext(ContextMenuContext);
  const { waitForAction, actionDone } = useContext<MouseEventsInterface>(MouseEventsContext);
  const gcl = useGraphClient();
  const theme = useTheme();

  const item: ViewItem<ItemData> = { data: location, id: location.id };
  const handleSelection = useCallback<MouseEventHandler<HTMLDivElement>>(
    event => {
      const item: ViewItem<ItemData> = { data: location, id: location.id };
      const isContextMenu = event.type === 'contextmenu';
      if (isContextMenu) {
        event.preventDefault();
        const { actionsStructure } = getQuickActionsList(
          [item],
          getDrilldownActions(gcl, location, handlers),
          false,
          true
        );
        const actions = generateActionMenu(actionsStructure, [item], 'Context Menu', handlers);

        if (actions.length) {
          waitForAction?.();
          handleContextMenu?.(event, actions, actionDone);
        }
      }
    },
    [actionDone, handleContextMenu, location, waitForAction, handlers, gcl]
  );

  const text = useMemo(
    () =>
      location.isAdvancedSearchContainer &&
      (location as Search).location !== undefined &&
      !(location as Search).location?.isAdvancedSearchContainer
        ? `${`${strings.lang.nodeNames.search} "${location?.name}"`}`
        : location?.name,
    [location]
  );
  return (
    <Toolbar onContextMenu={handleSelection} className={'all-locations-toolbar'}>
      <Stack direction="row" sx={{ width: '100%' }} pl={2} spacing={1} pr={2}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flexGrow: 1,
          }}
        >
          <Stack>
            <RenderActionByHook
              nodes={[{ data: location, id: location.id }]}
              handlers={handlers}
              action={new GoBackAction()}
              actionVariant={toolbarQuickActions}
              trigger="Quick Action"
            />
          </Stack>
          <TransientLocationLabelTooltip
            data={location}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            hideTooltip={(location as Search).location === undefined && location.isAdvancedSearchContainer}
          >
            <Stack
              sx={{
                color: theme.palette.texts.quaternary,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'default',
              }}
            >
              <Typography
                variant="H3Medium"
                sx={{
                  color: theme.palette.texts.quaternary,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {text.toUpperCase()}
              </Typography>
            </Stack>
          </TransientLocationLabelTooltip>
        </Stack>
        {enableActions && (
          <Stack direction="row" spacing={2} flexGrow={0}>
            <DrilldownActionsManager location={location} node={item} handlers={handlers} />
          </Stack>
        )}
      </Stack>
    </Toolbar>
  );
};

export default DrilldownToolbar;
