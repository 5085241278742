import {
  DeleteMedium,
  FooterWarningAction,
  MenuActionsWarningColor,
  NotificationsToastsSuccessIcon,
  RemoveMedium,
  UndoAction,
  useYesNoDialog,
} from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor';
import { useCallback } from 'react';
import { template } from 'lodash';
import React from 'react';
import { getNodesType, ItemContainer, ItemData } from '../itemTypes';
import {
  BaseAction,
  BaseDeletActionClass,
  KeyModifiers,
  SelectedItemHandler,
  ShortcutAction,
  ShortcutActionRendererHookType,
} from './BaseAction';
import { RefreshAction } from './RefreshAction';
import { ItemListChangesType, publishItemListChanged } from './UploadAction';
import { RootSectionManager, SharePointSitesRoot, TeamsRoot } from '../virtualRoots';

const useRemoveItemAction: ShortcutActionRendererHookType = ({
  action,
  nodes,
  handlers,
  useSafeCallback,
  actionVariant,
}) => {
  const Settings = (action as RemoveItemAction).Settings;
  const gcl = useGraphClient();
  const isUserManaged = nodes[0].data.isUserManaged;
  const texts = strings.lang.removeItem[getNodesType(nodes)];
  const countArg = { count: nodes.length };
  const formattedTexts: typeof texts = {
    ...texts,
    ...(nodes.length > 1
      ? {
          onStartTitle: template(texts?.onStartTitle)(countArg),
          onSuccessTitle: template(texts?.onSuccessTitle)(countArg),
          errorTitle: template(texts?.errorTitle)(countArg),
        }
      : {}),
  };

  const deleteItems = useSafeCallback(
    async () => {
      let res: undefined | UndoAction;
      if (Settings) {
        res = Settings.remove(nodes.map(n => n.data.apiIdKey));
      } else {
        for (let i = 0; i < nodes.length; i++) {
          const nData = nodes[i].data;
          await nData.removeItem(gcl);
          nData.parent && (await RefreshAction.markRefreshStamp(nData.parent, Date.now()));
        }
      }
      const toPublishById: Record<string, ItemListChangesType> = {};
      nodes.forEach(curNode => {
        const nData = curNode.data;
        const location = handlers?.getParent(curNode)?.data as ItemContainer;
        if (!location) return;
        if (!toPublishById[location.id]) toPublishById[location.id] = { location, deleted: {} };
        toPublishById[location.id]!.deleted![nData.apiIdKey] = nData;
      });
      Object.keys(toPublishById).forEach(key => publishItemListChanged(toPublishById[key]!));
      return res;
    },
    [Settings, nodes, gcl, handlers],
    false,
    formattedTexts,
    undefined,
    undefined,
    nodes.length > 1 ? 'footer-indications' : '',
    <NotificationsToastsSuccessIcon />
  );
  const { YesNoDialog, setIsDialogVisible } = useYesNoDialog({
    blockOutsideClick: true,
    primaryActionCallback: deleteItems,
    texts: {
      ...strings.lang.deleteDialog,
      ...(nodes.length > 1
        ? {
            dialogTitle: strings.lang.deleteMultipleDialog.dialogTitle({ count: nodes.length }),
          }
        : {}),
    },
  });

  const title = texts?.actionTitle || '';
  const icon = isUserManaged ? RemoveMedium : DeleteMedium;
  const onClick = useCallback(async () => {
    if (isUserManaged) {
      await deleteItems();
    } else {
      setIsDialogVisible(true);
    }
  }, [deleteItems, isUserManaged, setIsDialogVisible]);
  return {
    title,
    icon,
    onClick,
    actionVariant: actionVariant === FooterWarningAction ? actionVariant : MenuActionsWarningColor,
    chipLabel: strings.lang.shortcuts.delete,
    SideUI: YesNoDialog,
  };
};

export class RemoveItemAction extends BaseDeletActionClass implements SelectedItemHandler, ShortcutAction {
  constructor(readonly Settings?: RootSectionManager<any>) {
    super();
  }
  readonly useRenderer = useRemoveItemAction;
  isHandled = BaseAction.isMultiHandled(this);
  isSingleHandled = (d: ItemData) =>
    !d.isSearchItem && !d.isAdvancedSearchItem && (this.Settings ? this.Settings.canRemove(d) : d.canRemove);
  shortcut: [KeyModifiers, string] = [KeyModifiers.None, 'Delete'];

  static create = () => [
    new RemoveItemAction(SharePointSitesRoot.Settings),
    new RemoveItemAction(TeamsRoot.Settings),
    new RemoveItemAction(),
  ];
}
