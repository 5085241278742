import { HomeMedium, ViewItem } from '@storybook';
import { useNavigate } from 'react-router-dom';
import { strings } from '@vendor';
import { ActionRendererHookType, BaseReadActionClass, ItemData, TrackedEventType, isItemContainer } from '~/utilities';

export const useGoHome: ActionRendererHookType = ({ useCallback }) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => navigate('/home'), [navigate]);
  return {
    title: strings.lang.toolbarActions.home,
    icon: HomeMedium,
    onClick,
  };
};

export class GoHomeAction extends BaseReadActionClass {
  readonly trackedName = 'Navigate';
  useRenderer = useGoHome;
  transformEvent({ data, eventName }: TrackedEventType, _ctx?: any): TrackedEventType {
    return {
      eventName,
      data: {
        ...data,
        itemType: 'Home Location',
      },
    };
  }

  isQuickAction() {
    return 'only';
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return isItemContainer(nodes[0]?.data.type) && !nodes[0].data.isRootSection;
  }
}
