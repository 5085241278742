import { RadioGroup, FormControlLabel, Radio, Switch } from '@mui/material';
import {
  useTheme,
  Stack,
  Typography,
  ShareAvatar,
  Divider,
  BuildingsLarge,
  Skeleton,
  Button,
  DeleteMedium,
  Box,
  ResethMedium,
  LogoutMedium,
  ThemeSelector,
  Slider,
  AvatarPersonMedium,
  InformationStrokeSmall,
} from '@storybook';
import { strings } from '@vendor';
import React from 'react';
import { AppConfig } from '@services';
import { TruncatedTextWithTooltip } from '@harmon.ie-ce/storybook/src/components/Tooltip/Tooltip';
import { OpenAction } from '~/utilities';
import { UserRole } from '~/provisioning';
import { SettingsContextInterface } from './SettingContext';
import { UserRoleAndInfo } from './SettingContext';
import { SettingsKeyControl } from './SettingsControls';
import LanguageSelector from './LanguageSelector';
const isValidAvatar = (userPhoto: string | Blob | undefined) => {
  if (userPhoto === undefined) return false;
  if (userPhoto instanceof Blob) return true;
  if (typeof userPhoto === 'string' && userPhoto.length === 2) return true;
  return false;
};

const GeneralSettings = ({
  settings,
  userDetails,
  userPhoto,
  showDialog,
}: {
  settings: SettingsContextInterface;
  userPhoto: string | Blob | undefined;
  userDetails?: UserRoleAndInfo | undefined;
  showDialog: (type: 'resetDialog' | 'clearDialog' | 'signoutDialog') => void;
}) => {
  const {
    theme,
    setTheme,
    setGeneralNumber,
    setOpenFilesSettings,
    generaFilesCount,
    openFilesSettings,
    showTreeHistory,
    setShowTreeHistory,
    changeLanguage,
    selectedLanguage,
  } = settings;
  const uiTheme = useTheme();
  const userAccountInfo = userDetails?.userInfo;
  const userRole = userDetails?.userRole;
  return (
    <Stack height="100%" spacing={6} mb={7} mt={2}>
      <Stack>
        <SettingsKeyControl mb={3} title={strings.lang.userSetting.accountSettingTitle} />
        <Stack spacing={3} mb={5}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            {/* Left Side - Avatar and User Information */}
            <Stack sx={{ minWidth: 0, flexShrink: 1 }} spacing={0.3} width={'50%'} maxWidth={'50%'}>
              {isValidAvatar(userPhoto) ? (
                <ShareAvatar type="MyAvatar" avatar={userPhoto} size={40} />
              ) : (
                <AvatarPersonMedium />
              )}
              <TruncatedTextWithTooltip
                text={Office.context.mailbox.userProfile.displayName || userAccountInfo?.displayName}
                color={uiTheme.palette.texts.primary}
                variant="H3SemiBold"
                maxWidth="135px"
                charLimit={20}
              />

              {/* Email Address */}
              <TruncatedTextWithTooltip
                text={Office.context.mailbox.userProfile.emailAddress || userAccountInfo?.userPrincipalName}
                color={uiTheme.palette.texts.accentPrimary}
                variant="H3Regular"
                maxWidth="115px"
                charLimit={20}
              />
            </Stack>
            {/* Fixed Divider */}
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: '76px',
                color: uiTheme.palette.texts.secondary,
              }}
            />
            {/* Right Side - Company Information */}
            <Stack paddingLeft={1} sx={{ minWidth: 0, flexGrow: 1 }}>
              <Box paddingTop={'3px'} paddingBottom={'3px'}>
                <BuildingsLarge />
              </Box>
              {userAccountInfo ? (
                <>
                  {/* Company Name */}
                  <Typography
                    color={uiTheme.palette.texts.primary}
                    variant="H3SemiBold"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '300px', // Set a fixed width for truncation
                    }}
                  >
                    {userAccountInfo.companyName}
                  </Typography>

                  {/* Role */}
                  <Typography color={uiTheme.palette.texts.secondary} variant="H3Regular">
                    {userRole === UserRole.Admin
                      ? strings.lang.userSetting.adminRoleSettingTitle
                      : strings.lang.userSetting.userRoleSettingTitle}
                  </Typography>
                </>
              ) : (
                <>
                  <Skeleton width={100} height={20} />
                  <Skeleton width={100} height={20} />
                </>
              )}
            </Stack>
          </Stack>
          {userDetails?.userRole === UserRole.Member && (
            <Stack
              sx={{
                backgroundColor: uiTheme.palette.backgrounds.tertiary,
                borderRadius: 2,
                padding: 1,
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <InformationStrokeSmall />
                <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', alignItems: 'center', minWidth: 0 }}>
                  <Typography
                    variant="H3Regular"
                    color={uiTheme.palette.texts.secondary}
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {strings.lang.userSetting.needAdminAccess}
                  </Typography>
                  <Typography
                    onClick={() => window.open('https://harmon.ie/kb/new-harmonie-admin-guide/')}
                    variant="H3Regular"
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: uiTheme.palette.texts.secondary,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {strings.lang.userSetting.findAdminInfo}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack alignItems="flex-start" ml={-1}>
          <Button onClick={() => showDialog('clearDialog')} variant="text">
            <DeleteMedium />
            <Box width="5px" />
            {strings.lang.userSetting.clearTitle}
          </Button>
          <Button onClick={() => showDialog('resetDialog')} variant="text">
            <ResethMedium />
            <Box width="5px" />
            {strings.lang.userSetting.resetTitle}
          </Button>
          <Button onClick={() => showDialog('signoutDialog')} variant="text">
            <LogoutMedium />
            <Box width="5px" />
            {strings.lang.signOut}
          </Button>
        </Stack>
      </Stack>
      <Divider sx={{ width: '100%', color: uiTheme.palette.texts.secondary }} />
      <Stack spacing={3}>
        <ThemeSelector theme={theme} setTheme={setTheme} />
      </Stack>
      <Stack>
        <SettingsKeyControl
          mb={2}
          title={strings.lang.userSetting.documentOpenTitle}
          description={strings.lang.userSetting.documentOpenDescription}
        />
        <RadioGroup
          aria-labelledby="open-document"
          name="open-document"
          value={openFilesSettings}
          onChange={e => setOpenFilesSettings?.(e.target.value as OpenAction)}
        >
          <FormControlLabel
            value={OpenAction.IN_APP}
            sx={{ flexDirection: 'row-reverse', margin: 0 }}
            slotProps={{
              typography: {
                sx: { flex: 1 },
              },
            }}
            control={<Radio />}
            label={
              <Stack direction={'column'}>
                <Typography sx={{ flex: 1 }} variant="H2Regular">
                  {strings.lang.doubleClickAction.openInApp}
                </Typography>
              </Stack>
            }
          />
          <FormControlLabel
            sx={{ flexDirection: 'row-reverse', margin: 0 }}
            slotProps={{
              typography: {
                sx: { flex: 1 },
              },
            }}
            value={OpenAction.OPEN}
            control={<Radio />}
            label={<Typography variant="H2Regular">{strings.lang.doubleClickAction.openInBrowserApp}</Typography>}
          />
          <FormControlLabel
            sx={{ flexDirection: 'row-reverse', margin: 0 }}
            slotProps={{
              typography: {
                sx: { flex: 1 },
              },
            }}
            value={OpenAction.CHOOSE}
            control={<Radio />}
            label={<Typography variant="H2Regular">{strings.lang.doubleClickAction.askEveryTime}</Typography>}
          />
        </RadioGroup>
      </Stack>
      <Stack>
        <Slider
          titleVariant="H1SemiBold"
          messageVariant="H2Regular"
          withoutWrap={true}
          value={generaFilesCount}
          onChange={n => setGeneralNumber?.(n)}
          min={5}
          max={25}
          message={strings.lang.userSetting.treeSettingFileDescription}
          title={strings.lang.userSetting.treeSettingFileTitle}
        />
      </Stack>
      <Stack>
        <SettingsKeyControl mb={2} title={strings.lang.userSetting.treeSettingHistoryTitle} />
        <FormControlLabel
          value={true}
          sx={{ flexDirection: 'row-reverse', margin: 0 }}
          slotProps={{
            typography: {
              sx: { flex: 1 },
            },
          }}
          control={<Switch checked={showTreeHistory} onClick={() => setShowTreeHistory?.(!showTreeHistory)} />}
          label={
            <Typography sx={{ flex: 1 }} variant="H2Regular">
              {strings.lang.userSetting.treeSettingHistoryDescription}
            </Typography>
          }
        />
      </Stack>
      <Divider sx={{ width: '100%', color: uiTheme.palette.texts.secondary }} />
      <LanguageSelector changeLanguage={changeLanguage} selectedLanguage={selectedLanguage} />
      <Divider sx={{ width: '100%', color: uiTheme.palette.texts.secondary }} />
      <Stack spacing={3}>
        <SettingsKeyControl title={strings.lang.userSetting.aboutHarmonietitle} />

        <Stack direction={'row'} spacing={1}>
          <Typography variant="H3Medium" sx={{ color: uiTheme.palette.texts.quaternary }}>
            {`Version ${AppConfig.getInstance().version}`}
          </Typography>
          {process.env.REACT_APP_GIT_COMMIT_HASH && (
            <>
              <Divider sx={{ height: '13px', color: uiTheme.palette.texts.secondary }} orientation="vertical" />
              <Typography variant="H3Medium" sx={{ color: uiTheme.palette.texts.quaternary }}>
                {`Build ${process.env.REACT_APP_GIT_COMMIT_HASH}`}
              </Typography>
            </>
          )}
          <Divider sx={{ height: '13px', color: uiTheme.palette.texts.secondary }} orientation="vertical" />
          <Typography variant="H3Medium" sx={{ color: uiTheme.palette.texts.quaternary }}>
            {'©2025'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default GeneralSettings;
