import { OnSignInKey, ResetSettingKey } from '@vendor';
import PubSub from 'pubsub-js';

export interface StateManager<T> {
  get value(): T;
  get sKey(): string;
}

const OnSignIn2Key = `${OnSignInKey}2`;

interface LocalUserSettings {
  username?: string;
  timestamp?: number;
}

// Base class for local-storage & office-roaming settings. Expects implementation to publish an event if value is changed
export abstract class BaseStateManager<T> implements StateManager<T> {
  private static _userLocalSettings: LocalUserSettings | undefined;
  static get userLocalSettings() {
    if (this._userLocalSettings === undefined)
      this._userLocalSettings = JSON.parse(localStorage.getItem('userSettings') || '{}') as LocalUserSettings;
    return this._userLocalSettings;
  }

  protected _value: T;
  protected initialGetter: () => T;
  private needsReset = true;

  get sKey() {
    return `stateOf.${this.key}`;
  }
  get value() {
    if (this.needsReset) {
      this.reset();
      this.needsReset = false;
    }
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.needsReset = false;
    this.save();
  }

  abstract save();

  protected stringify(val: T) {
    return JSON.stringify(val);
  }

  protected parse(val: string): T {
    return JSON.parse(val);
  }
  protected transformValue(val: any): T {
    return val;
  }

  protected abstract reset(resetSetting?: boolean);

  constructor(
    readonly key: string,
    initVal: () => T,
    handleUserReset = true
  ) {
    this.initialGetter = initVal;
    PubSub.subscribe(OnSignIn2Key, () => this.reset());
    handleUserReset && PubSub.subscribe(ResetSettingKey, () => this.reset(true));
  }

  static resetAll() {
    PubSub.publishSync(ResetSettingKey);
    PubSub.publishSync('onAfterResetSetting');
  }

  static getSettingValue(key: string) {
    const roamingStamp = JSON.parse(Office.context.roamingSettings?.get('userSettingsStamp') || 0);
    if (this.userLocalSettings.username && (this.userLocalSettings.timestamp || 0) >= roamingStamp)
      return this.userLocalSettings[key];
  }

  static saveLocalUserSettings(updateStamp = true, roamingSettings?: Office.RoamingSettings) {
    roamingSettings?.set('userSettingsStamp', Date.now());
    if (updateStamp) this.userLocalSettings.timestamp = Date.now();
    localStorage.setItem('userSettings', JSON.stringify(this.userLocalSettings));
  }

  static resetLocalUserSettings(username: string) {
    const roamingStamp = JSON.parse(Office.context.roamingSettings?.get('userSettingsStamp') || 0);
    if (this.userLocalSettings.username !== username || roamingStamp > (this.userLocalSettings.timestamp || 0)) {
      this._userLocalSettings = { username };
      this.saveLocalUserSettings();
    }
  }
}

export class LocalStateManager<T> extends BaseStateManager<T> {
  public save() {
    if (this._value !== undefined) localStorage.setItem(this.sKey, this.stringify(this._value));
    else localStorage.removeItem(this.sKey);
    PubSub.publish(this.sKey, this._value);
  }

  reset(resetSetting = false) {
    if (resetSetting) localStorage.removeItem(this.sKey);
    const lVal = localStorage.getItem(this.sKey);
    const prevValue = this._value;
    this._value = (lVal && this.transformValue(this.parse(lVal))) || this.initialGetter();
    if (prevValue !== this._value) PubSub.publish(this.sKey, this._value);
  }
}

PubSub.subscribe(OnSignInKey, (_key: string, username: string) => {
  BaseStateManager.resetLocalUserSettings(username);
  PubSub.publish(OnSignIn2Key, username);
});
