import { ComponentHeightState, useTheme, WhatsNew, WhatsNewContent, WhatsNewDrawer } from '@storybook';
import React, { useCallback, useState } from 'react';
import { AppConfig } from '@services';
import { strings } from '@vendor';
import { useWhatsNewNotifications } from '~/hooks';
import { trackEvent } from '~/utilities/analytics/tracking';
import getSteps from './steps';
import getStepMedia from './media';
import Celebration from './Celebration';

enum CloseMethod {
  Default = 'Close',
  Discover = 'Discover',
  Skip = 'Skip',
}

const WhatsNewNotification = () => {
  const { isSeen, markAsSeen } = useWhatsNewNotifications();
  const version = AppConfig.getInstance().version;
  const [open, setOpen] = useState<boolean>(!isSeen(version));
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const steps = getSteps(theme);

  const handleClose = useCallback(
    (method?: CloseMethod) => {
      trackEvent('ReleaseNotes', {
        actionType: 'Close',
        activeStep: activeStep + 1,
        steps: steps.length,
        itemType: 'ReleaseNotes',
        closeMethod: method ?? CloseMethod.Default,
      });
      setOpen(false);
      markAsSeen(version);
    },
    [activeStep, markAsSeen, steps.length, version]
  );

  const onDiscoverAll = useCallback(() => {
    handleClose(CloseMethod.Discover);
    window.open('https://harmon.ie/documentation/sharepoint/new/getstarted', '_blank');
  }, [handleClose]);

  return (
    <WhatsNewDrawer open={open} onClose={() => handleClose()}>
      <div style={{ height: '480px', width: '100vw' }}>
        <WhatsNew>
          {(state: ComponentHeightState) => {
            const media = getStepMedia(activeStep, state);
            return (
              <>
                {media}
                <WhatsNewContent
                  stepsProps={{ activeStep, steps }}
                  stepsControlsProps={{
                    nextText: strings.lang.onBoarding.next,
                    backText: strings.lang.onBoarding.back,
                    skipAllText: strings.lang.whatsNew.skipLanguageSelection,
                    finishText: strings.lang.actions.close,
                    activeStep,
                    maxSteps: steps.length,
                    onSkipAll: () => handleClose(CloseMethod.Skip),
                    onFinish: handleClose,
                    onStepChange: setActiveStep,
                    customButtonText: strings.lang.whatsNew.discoverAllButton,
                    onCustomButtonClick: onDiscoverAll,
                  }}
                />
              </>
            );
          }}
        </WhatsNew>
      </div>
      <Celebration />
    </WhatsNewDrawer>
  );
};

export default WhatsNewNotification;
