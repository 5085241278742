import { OnOfficeReady } from '@vendor';
import { asyncSubscribeOnce } from '~/hooks';
import { setDefaultLanguage } from '~/modules/Settings/LanguageSelector';
import { LanguageSetting } from '~/modules/Settings/SettingContext';

const onOfficeReady = async () => {
  const { initOfficeMode, setupOfficeItemSelectionChangedListeners } = await import('~/misc');
  setDefaultLanguage(LanguageSetting.value);
  initOfficeMode();
  setupOfficeItemSelectionChangedListeners();
};

export const OfficeInit = () => {
  asyncSubscribeOnce(OnOfficeReady, onOfficeReady);
};
