import { ViewItem, VideoMedium } from '@storybook';
import { strings } from '@vendor';
import { useCallback } from 'react';
import { ActionSeenStateList } from '~/modules/Settings/SettingContext';
import { ActionRendererHookType, BaseReadActionClass, ItemData } from '~/utilities';

const useTrainingVideosComponent: ActionRendererHookType = () => {
  const onClick = useCallback(() => {
    ActionSeenStateList.value = [...ActionSeenStateList.value, 'TrainingVideos'];
    window.open('https://harmon.ie/training-new/', '_blank');
  }, []);
  return {
    title: strings.lang.allLocatiosToolbar.trainingVideos,
    icon: VideoMedium,
    onClick: onClick, // Attach the Hotjar loader to the click handler
    isNew: !ActionSeenStateList?.value?.includes?.('TrainingVideos'),
  };
};
export class TrainingVideos extends BaseReadActionClass {
  readonly trackedName = 'TrainingVideos';
  readonly useRenderer = useTrainingVideosComponent;

  isHandled(_nodes: ViewItem<ItemData>[]): boolean {
    return true;
  }
}
