import { toTitleCase } from '@vendor';
import mixpanel from 'mixpanel-browser';
import { ContainerHandlers, ViewItem } from '@storybook';
import _ from 'lodash';
import { AppConfig } from '@services';
import { LanguageSetting } from '~/modules/Settings/SettingContext';
import { ActionItemType, ActionTrigger, BaseAction, TrackedEventName, TrackedEventType } from '../actions';
import { Folder, ItemData } from '../itemTypes';
import { Channel } from '../graphTypes';
import { Search } from '../search';
import { sidebarInit } from './sidebarInit';
import { OfficeUserSettingManager } from '../stateManager';
import { ViewFilesItem } from '../itemTypes/ViewFilesItem';

export const SidebarInitKey = 'SidebarInit';

const getDomain = (email: string) => {
  return (email && email.split('@')[1]) || email;
};

export const EnableDataCollection = new OfficeUserSettingManager('EnableDataCollection', () => true);

export const initUser = (profile: Office.UserProfile) => {
  if (profile && EnableDataCollection.value) {
    const identifier = profile.emailAddress;
    mixpanel.init(AppConfig.getInstance().mixpanelToken, { api_host: AppConfig.getInstance().mixpanelApiHost }); //TODO -> Move to .env!!
    mixpanel.identify(identifier);
    mixpanel.people.set(
      toTitleCase({
        $name: profile.displayName,
        $email: identifier,
        Domain: getDomain(identifier), // Not a built-in proerty & aligns with desktop.
        cloudVersion: AppConfig.getInstance().version,
        cloudEdition: 'Microsoft 365',
        newHarmonieLanguage: LanguageSetting.value,
        officeLanguage: Office.context.displayLanguage.split('-')[0],
      })
    );
    mixpanel.people.unset(['$cloud_Version', '$cloud_edition', '$office_Language']);
    PubSub.subscribeOnce(SidebarInitKey, sidebarInit);
  }
};

export const trackEvent = (eventName: TrackedEventName, data = {}) => {
  const constantData = toTitleCase({
    cloudVersion: AppConfig.getInstance().version,
    outlookType: Office.context?.platform,
    officeHost: Office.context?.host,
  });

  try {
    if (EnableDataCollection.value && mixpanel?.get_distinct_id()) {
      const titledEventName = eventName.endsWith('!') ? eventName.replace(/!$/, '') : _.startCase(eventName);
      data = { ...toTitleCase(data), ...constantData };
      if (globalThis.hrmProvisioning.logMixpanel) console.warn(titledEventName, data);
      mixpanel.track(titledEventName, data);
    }
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error({ errorMessage: error?.message });
  }
};

interface SimpleGraphError {
  message?: {
    code: string;
    message: {
      value?: string;
      lang?: string;
    };
  };
  name?: string;
}

interface InternalGraphError {
  body?: string;
  errorCode?: string;
  code?: string;
  statusCode?: number;
  errorMessage?: string;
  message?: string;
  name?: string;
}

export const trackErrorEvent = ({ data, eventName }: TrackedEventType, error: Error) => {
  const simpleGraphError = error as any as SimpleGraphError;
  const internalGraphError = error as any as InternalGraphError;
  const errorProps = simpleGraphError?.message?.code
    ? {
        code: simpleGraphError.message.code,
        message: simpleGraphError.message.message?.value,
      }
    : internalGraphError.body
      ? {
          code: internalGraphError.errorCode || internalGraphError.code,
          message: internalGraphError.errorMessage || internalGraphError.message,
          statusCode: internalGraphError.statusCode,
        }
      : {
          name: error.name,
          message: error.message,
        };
  return trackEvent('SidebarError', {
    ...errorProps,
    ...data,
    eventName,
  });
};

const allowedItemTypeToActionItemType = (data: ItemData): ActionItemType => {
  const type = data?.type || 'home';
  switch (type) {
    case 'site':
      return 'Site';
    case 'channel': {
      const chType = (data as Channel).membershipType;
      return chType == 'private' ? 'Private Channel' : chType == 'shared' ? 'Shared Channel' : 'Channel';
    }
    case 'insight':
    case 'document':
      return 'Document';
    case 'folder':
      return (data as Folder).isDocumentSet ? 'Document Set' : 'Folder';
    case 'onedriveroot':
      return 'OneDrive';
    case 'list':
      return 'Library';
    case 'teamschatfiles':
      return 'Teams Chat Files';
    case 'recentroot':
      return 'Recent';
    case 'sharedwithmeroot':
      return 'Shared With Me';
    case 'sharepointroot':
      return 'Sites List';
    case 'favoritesroot':
      return 'Favorites List';
    case 'teamsroot':
      return 'Teams List';
    case 'team':
      return 'Team';
    case 'home':
      return 'Home Location';
    case 'viewfiles':
      return allowedItemTypeToActionItemType((data as ViewFilesItem).data);
    case 'SearchResults': {
      const searchLocation = (data as Search).location;
      if (searchLocation) return allowedItemTypeToActionItemType(searchLocation);
      return 'Home Location';
    }
  }
  return 'None';
};

export const createActionEvent = (
  action: BaseAction,
  trigger: ActionTrigger,
  nodes: ViewItem<ItemData>[],
  handlers: ContainerHandlers<ItemData>,
  ctx?: any
): TrackedEventType => {
  const data = nodes[0]?.data;
  const targetView = nodes[0]?.['options']
    ? 'All Locations'
    : data?.isAdvancedSearchItem
      ? 'Search Results'
      : data?.isSearchItem
        ? 'Quick Search'
        : 'Sidebar View';
  let rootType = allowedItemTypeToActionItemType(data);
  for (let parent: ViewItem<ItemData> | undefined = nodes[0]; parent; parent = handlers.getParent(parent))
    rootType = allowedItemTypeToActionItemType(parent.data);

  const res: TrackedEventType = {
    eventName: action.trackedName,
    data: {
      actionType: action.type,
      trigger,
      itemsCount: nodes.length || undefined,
      targetApp: nodes[0]?.data?.OfficeAppLocated,
      targetView,
      itemType: data && allowedItemTypeToActionItemType(data),
      rootType,
    },
  };
  return action?.transformEvent?.(res, ctx) || res;
};
