import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export interface ComponentHeightState {
  height: number;
}

export const useComponentHeight = ({ ref }: { ref: React.RefObject<HTMLDivElement> }) => {
  const [state, dispatch] = useState<ComponentHeightState>({
    height: 0,
  });

  useEffect(() => {
    if (!ref?.current?.clientHeight) {
      return;
    }

    const handleResize = debounce(() => {
      const containerHeight = Number(ref.current?.clientHeight || 0);

      dispatch({
        height: containerHeight,
      });
    }, 100);

    const observer = new ResizeObserver(handleResize);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [ref]);

  return { state };
};
