import { BaseContainer, ExBaseItemProps, ItemContainer, ItemData } from '../itemTypes';
import { BaseRootSectionManager } from './RootSectionManager';

export interface RootSectionItem extends ItemContainer {
  get showNewIndicator(): boolean;
  markAsUpdated(): void;
  get Settings(): BaseRootSectionManager<any> | undefined;
}

export abstract class BaseRootSectionItem extends BaseContainer implements RootSectionItem {
  constructor(props: ExBaseItemProps) {
    super(props);
  }

  get isRootSection(): boolean {
    return true;
  }
  get showNewIndicator(): boolean {
    return false;
  }
  get Settings(): BaseRootSectionManager<any> | undefined {
    return undefined;
  }

  markAsUpdated(): void {}
}

export const getRootSectionItem = (item: ItemData) => {
  return item instanceof BaseRootSectionItem ? (item as RootSectionItem) : undefined;
};
