import { isArray } from 'lodash';
import { EmailHeaderFieldsMapper } from '~/utilities/manageProperties/ManageProperties';
import { SharePointField, SharePointFieldType } from '~/utilities/metadata/SharePointFieldType';

export const cellIsEmpty = (type: SharePointFieldType, headerName: string, value: any): boolean => {
  if (
    (value === '' || (isArray(value) && value.length === 0)) &&
    type !== SharePointFieldType.Computed &&
    headerName !== 'DocIcon' &&
    type !== SharePointFieldType.AverageRating &&
    type !== SharePointFieldType.Boolean
  ) {
    return true;
  }
  if (
    (type == SharePointFieldType.User && value?.[0]?.value === '') ||
    (type == SharePointFieldType.Lookup && value?.[0]?.lookupValue === '')
  ) {
    return true;
  } else return false;
};

export const calculateQuickActionIndex = (
  ehmSettings: EmailHeaderFieldsMapper,
  schemaFieldsMap: (SharePointField | undefined)[]
) => {
  for (let i = 0; i < schemaFieldsMap?.length; i++) {
    const field = schemaFieldsMap[i];
    if (field)
      if (ehmSettings.getFieldInfo(field.StaticName)?.isAttachment) continue;
      else if (field.TypeAsString === SharePointFieldType.Computed && field.InternalName === 'DocIcon') continue;
    return i;
  }

  return 0;
};

export const calculateColumnWidths = (isIconColumn?: boolean) => {
  const width = isIconColumn ? 35 : 150;
  return width;
};
