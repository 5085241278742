import { Components, Theme } from '@mui/material/styles';
export default (theme: Theme) => {
  const styleOverrides: Components['MuiSelect'] = {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.outlinesAndStrokes.secondary,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.outlinesAndStrokes.secondary,
          boxShadow: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.outlinesAndStrokes.secondary,
        },
        backgroundColor: 'transparent',
      },
    },
  };
  return styleOverrides;
};
