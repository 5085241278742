import React from 'react';
import { OpenInBrowserSmall, OpenInBrowserMedium, MediaKeysType, SVGComponent } from '@storybook';
import { asType, useGraphClient } from '@services';
import { SafeCallbackStrings, strings } from '@vendor';
import { DocumentItem, isOfficeAppDocument, ItemData } from '../itemTypes';
import {
  ActionRendererHookType,
  BaseAction,
  BaseReadActionClass,
  SelectedItemHandler,
  TrackedEventType,
} from './BaseAction';
import { OpenAction } from '../misc/utils';
import { openRedirectedOfficeDialog } from './ActionsUtils';

const useOpenInBrowserAction: ActionRendererHookType = ({
  nodes,
  useSafeCallback,
  actionVariant,
  action,
  actionRef,
}): ReturnType<ActionRendererHookType> => {
  const gcl = useGraphClient();
  const that = action as BaseOpenInBrowserAction;
  const node = nodes[0];
  const onClick = useSafeCallback(
    async (e: React.MouseEvent<Element, MouseEvent>) => {
      if (!actionVariant.withText) e.stopPropagation();
      const nData = node.data as ItemData;
      actionRef.current = asType<OpenDocumentContext>({
        mode: OpenAction.OPEN,
        mediaType: nData.isDocument ? (nData as DocumentItem).mediaType : undefined,
      });
      const urls = await nData.getAccessUrls(gcl);
      urls && (that.useRedirect ? openRedirectedOfficeDialog(urls.webUrl) : window.open(urls.webUrl));
    },
    [actionVariant.withText, node, actionRef, gcl, that],
    false,
    that.notification
  );

  return {
    title: that.title,
    icon: that.icons,
    onClick,
  };
};

abstract class BaseOpenInBrowserAction extends BaseReadActionClass implements SelectedItemHandler {
  abstract get title(): string;
  abstract get notification(): Partial<SafeCallbackStrings>;
  readonly useRedirect: boolean = false;

  readonly useRenderer = useOpenInBrowserAction;

  isHandled = BaseAction.isSingleHandled(this);
  isSingleHandled(data: ItemData) {
    return data.canOpenInBrowser;
  }
  get icons(): SVGComponent[] | SVGComponent {
    return [OpenInBrowserSmall, OpenInBrowserMedium];
  }
}

export class OpenInBrowserAction extends BaseOpenInBrowserAction {
  readonly trackedName = 'OpenInApp';
  readonly title = strings.lang.actionToolTip.openInBrowser;
  readonly notification: Partial<SafeCallbackStrings> = strings.lang.notifications.openInBrowser;

  transformEvent({ data, eventName }: TrackedEventType) {
    return {
      eventName,
      data: { ...data, attribute: 'Browser' },
    } as TrackedEventType;
  }

  override isSingleHandled(data: ItemData) {
    return super.isSingleHandled(data) && !data.isDocument;
  }
}

export interface OpenDocumentContext {
  mode: OpenAction;
  mediaType?: MediaKeysType;
}

export class OpenDocumentInBrowserAction extends BaseOpenInBrowserAction {
  readonly trackedName = 'OpenDocument';
  get title() {
    return strings.lang.actionToolTip.openInBrowserApp;
  }
  get notification() {
    return strings.lang.notifications.openInBrowser;
  }
  constructor(readonly forOfficeDocs: boolean | undefined) {
    super();
  }

  override transformEvent({ data }: TrackedEventType, ctx: OpenDocumentContext = { mode: OpenAction.OPEN }) {
    return {
      eventName: this.trackedName,
      data: { ...data, mode: ctx.mode == OpenAction.OPEN ? 'Browser' : 'Desktop', mediaType: ctx.mediaType },
    } as TrackedEventType;
  }

  override isSingleHandled(data: ItemData) {
    return (
      super.isSingleHandled(data) &&
      data.isDocument &&
      (this.forOfficeDocs == undefined || this.forOfficeDocs == isOfficeAppDocument(data))
    );
  }
}
