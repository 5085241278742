import React, { useState } from 'react';
import { SelectChangeEvent, MenuItem, Stack } from '@mui/material';
import { Select as MuiSelect } from '@mui/material';
import { ArrowDownMedium, ArrowUpMedium, VMedium } from '../../assets/icons';
import { CustomIcon } from '../Icons';

export interface SelectOption {
  id?: string;
  value: string;
  label: string;
}

export interface SelectProps {
  options: SelectOption[];
  value?: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  currentSelected: SelectOption;
  placeholder?: string;
}

export const Select: React.FC<SelectProps> = ({ options, value, onChange, currentSelected, placeholder }) => {
  const [open, setOpen] = useState(false);

  const ArrowDown = CustomIcon(ArrowDownMedium);
  const ArrowUp = CustomIcon(ArrowUpMedium);
  return (
    <>
      <MuiSelect
        value={value}
        onChange={onChange}
        displayEmpty
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        IconComponent={() => (
          <Stack
            sx={{ cursor: 'pointer', mr: 2 }}
            onClick={e => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {open ? <ArrowUp /> : <ArrowDown />}
          </Stack>
        )}
        renderValue={selected => options.find(option => option.value === selected)?.label || placeholder}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
              {option.label}
              {currentSelected.value === option.value && <VMedium />}
            </Stack>
          </MenuItem>
        ))}
      </MuiSelect>
    </>
  );
};
