import { asType } from '@services';
import { ProvisionedSettingKeys, strings } from '@vendor';
import { EmailHeaderMappingChoices, OpenAction } from '~/utilities';
import { SupportedEHMFieldsRecord } from '~/utilities/manageProperties/ManageProperties';

export interface ProvisionedValue<T> {
  defaultValue?: T;
  value?: T;
  isReadOnly: boolean;
  isHidden: boolean;
  validator?: (key: any, val: any) => void;
}

export class ValidationError extends Error {}

const throwsValidationError = (key: string, failure: string) => {
  throw new ValidationError(strings.lang.adminSetting.validation.keyFailed({ key }) + failure);
};

const boolValidator = (key, val) => {
  if (!(typeof val === 'boolean')) throwsValidationError(key, strings.lang.adminSetting.validation.mustBeBoolean);
};
const numValidator = (key, val) => {
  if (!(typeof val === 'number')) throwsValidationError(key, strings.lang.adminSetting.validation.mustBeNumber);
};
const ehmValidator = (key, val) => {
  if (!(typeof val === 'object')) throwsValidationError(key, strings.lang.adminSetting.validation.mustBeObject);

  Object.entries(val).forEach(([subKey, subVal]) => {
    if (typeof subKey !== 'string' || SupportedEHMFieldsRecord[subKey] === undefined)
      throwsValidationError(key, strings.lang.adminSetting.validation.emailFieldUnsupported({ subKey }));

    if (!Array.isArray(subVal) || !subVal.every(item => typeof item === 'string'))
      throwsValidationError(key, strings.lang.adminSetting.validation.emailFieldMustBeStringArray({ subKey }));
  });
};

let ProvisionedSettings: Record<ProvisionedSettingKeys, ProvisionedValue<any>>;

const getProvisionedSettingsInitialValue = (): typeof ProvisionedSettings => {
  return {
    // Root sections
    SharedFilterState: {
      validator: boolValidator,
      isReadOnly: false,
      isHidden: true,
    },
    AllFavorites: { isReadOnly: false, isHidden: true },
    SharePointSettings: { isReadOnly: false, isHidden: true },
    TeamsSettings: { isReadOnly: false, isHidden: true },
    RecentFolders: { isReadOnly: false, isHidden: true },

    // Shown item counts
    SharedCountState: {
      validator: numValidator,
      defaultValue: 5,
      isReadOnly: false,
      isHidden: true,
    },
    ChatsCountState: {
      validator: numValidator,
      defaultValue: 5,
      isReadOnly: false,
      isHidden: true,
    },
    RecentCountState: {
      validator: numValidator,
      defaultValue: 5,
      isReadOnly: false,
      isHidden: true,
    },
    SPFilesCountState: {
      validator: numValidator,
      defaultValue: 5,
      isReadOnly: false,
      isHidden: true,
    },

    // Key features on/off
    EnableTeams: {
      validator: boolValidator,
      isReadOnly: true,
      defaultValue: true,
      isHidden: false,
    },
    EnableOneDrive: {
      validator: boolValidator,
      defaultValue: true,
      isReadOnly: true,
      isHidden: false,
    },
    EnableDataCollection: {
      validator: boolValidator,
      defaultValue: true,
      isReadOnly: true,
      isHidden: true,
    },
    onBoardingDone: {
      validator: boolValidator,
      isReadOnly: false,
      isHidden: true,
    },

    // Misc settings
    OpenInDesktopAppOrBrowserState: {
      defaultValue: OpenAction.CHOOSE,
      isReadOnly: false,
      isHidden: true,
    },
    DownloadOrBrowserState: {
      defaultValue: OpenAction.CHOOSE,
      isReadOnly: false,
      isHidden: true,
    },
    LanguageSetting: { defaultValue: 'en-US', isReadOnly: false, isHidden: true },
    EnableLogsRecordingSetting: {
      defaultValue: false,
      isReadOnly: false,
      isHidden: true,
    },

    EditPropertiesMode: {
      validator: boolValidator,
      defaultValue: true,
      isReadOnly: false,
      isHidden: true,
    },
    SelectedTheme: { defaultValue: 'system', isReadOnly: false, isHidden: true },
    ShowTreeHistorySetting: {
      validator: boolValidator,
      defaultValue: true,
      isReadOnly: false,
      isHidden: true,
    },
    ODOpenInfoDialogShown: {
      validator: boolValidator,
      isReadOnly: false,
      isHidden: true,
    },
    AddCategory: {
      validator: boolValidator,
      defaultValue: false,
      isReadOnly: false,
      isHidden: true,
    },
    UseUniqueNames: {
      validator: boolValidator,
      defaultValue: false,
      isReadOnly: false,
      isHidden: true,
    },
    EmailHeaderMapping: {
      validator: ehmValidator,
      defaultValue: {},
      isReadOnly: true,
      isHidden: true,
    },
    AutoEmailHeaderMapping: {
      defaultValue: EmailHeaderMappingChoices.TeamsOnly,
      isReadOnly: false,
      isHidden: true,
    },
    WhatsNewNotificationsSettings: asType<ProvisionedValue<Record<string, any>>>({
      defaultValue: {},
      isReadOnly: false,
      isHidden: true,
    }),
  };
};

export const getProvisionedSettings = (reset?: boolean) => {
  if (!reset && ProvisionedSettings) return ProvisionedSettings;
  return (ProvisionedSettings = getProvisionedSettingsInitialValue());
};

export type InternalStateKeys =
  | 'EmailHeaderMappingBlackList'
  | 'newPermissions'
  | 'onBoardingState'
  | 'SearchHistoryState'
  | 'WhatsNewNotificationSeen'
  | 'ActionSeenState';

export const getInitialSettings = () => {
  const initialSettings = {} as Record<ProvisionedSettingKeys, Omit<ProvisionedValue<any>, 'default'>>;
  Object.entries(getProvisionedSettings()).forEach(([key, { defaultValue, ...provisionedValue }]) => {
    if (!provisionedValue.isHidden || provisionedValue.value !== undefined) {
      initialSettings[key] = asType<Omit<ProvisionedValue<any>, 'default'>>({
        ...provisionedValue,
        value: provisionedValue.value ?? defaultValue,
      });
    }
  });

  return initialSettings;
};
