import { useState } from 'react';
import { OfficeUserSettingManager } from '~/utilities';

// Define the hook with TypeScript
const useOpenOnce = (key: OfficeUserSettingManager<boolean>): [boolean, () => void, () => void] => {
  const [triggeredOnce, setTriggeredOnce] = useState(!!key.value);
  // Handler to permanently close the popup
  const handleClosePermanently = () => {
    setTriggeredOnce(true);
    key.value = true;
  };

  // Handler to dismiss the popup temporarily
  const handleDismiss = () => {
    setTriggeredOnce(false);
    key.value = false;
  };

  return [triggeredOnce, handleClosePermanently, handleDismiss];
};

export default useOpenOnce;
