import React, { MouseEventHandler, ReactElement, ReactNode, useCallback } from 'react';
import { ViewItem, Stack, BaseActionUI, BAVariant, SVGComponent } from '@storybook';
import { styled } from '@mui/system';
import { ItemData } from '../itemTypes';

export const ActionStack = styled(Stack)`
  width: 100%;
`;

export interface BaseActionUIWrapProps {
  children?: ReactNode;
  title: string | ReactElement;
  onClick: MouseEventHandler;
  BaseActionVariant: BAVariant;
  icon?: SVGComponent[] | SVGComponent | ReactElement;
  node?: ViewItem<ItemData>;
  disabled?: boolean;
  hideAction?: boolean;
  opened?: boolean;
  tooltipClassName?: string;
  tooltipTitle?: string | ReactElement;
  EndIcon?: ReactElement;
  chipLabel?: string;
  isNew?: boolean;
}
export const BaseActionUIWrap = ({
  children,
  icon,
  title,
  onClick,
  node,
  tooltipTitle,
  disabled,
  BaseActionVariant,
  opened,
  tooltipClassName,
  EndIcon,
  chipLabel,
  isNew,
}: BaseActionUIWrapProps) => {
  const noPropogationOnClick: MouseEventHandler | undefined = useCallback(
    e => {
      if (!BaseActionVariant.withText && node?.data.isVirtual) {
        e['no-expand'] = true;
      }
      onClick(e);
      node?.data?.additionalActionHandler?.(e);
    },
    [BaseActionVariant.withText, node?.data, onClick]
  );

  const renderIconElement = Array.isArray(icon) ? (BaseActionVariant.useMediumIcon ? icon[1] : icon[0]) : icon;
  return (
    <ActionStack onClick={noPropogationOnClick}>
      {children}
      <BaseActionUI
        title={title}
        Icon={renderIconElement}
        EndIcon={EndIcon}
        BaseActionVariant={BaseActionVariant}
        tooltipClassName={tooltipClassName}
        tooltipTitle={tooltipTitle || title}
        opened={opened}
        disabled={disabled}
        chipLabel={chipLabel}
        isNew={isNew}
      />
    </ActionStack>
  );
};
