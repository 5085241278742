import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import {
  CaretClosedSmall,
  CaretOpenedSmall,
  LottieAnimation,
  Stack,
  TreeExpandIcon,
  TreeRootExpandIcon,
  TreeViewLoader,
  dropCloseAnimationData,
  dropOpenAnimationData,
  unselectChildren,
} from '@storybook';
import React from 'react';
import { TreeNode } from 'react-hyper-tree/dist/helpers/node';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash';
import {
  AllowedItemType,
  ItemContainer,
  ItemData,
  LocalStateManager,
  getItemContainer,
  getRootSectionItem,
  isItemContainer,
  useCombinedHandlers,
} from '~/utilities';
import { ShowTreeHistory } from '~/modules/Settings/SettingContext';
import { RenderNodeProps, RenderNodeWithNextProps, TreeNodeRenderer } from './GetNodeRenderer';
import { getHarmonieTree } from '../Tree';

interface NodeState {
  isOpened: boolean;
}
export interface RootNodeStates {
  [key: string]: NodeState;
}

const treeExpansionState = new LocalStateManager<RootNodeStates>('main-state', () => ({
  'recent:root': { isOpened: true },
}));

export const saveNodeState = (n: TreeNode, isOpened: boolean, save = true) => {
  if (!isOpened) delete treeExpansionState.value[n.id];
  else treeExpansionState.value[n.id] = { isOpened };
  save && treeExpansionState.save();
};

export const resetTreeNodeState = () => {
  treeExpansionState.value = {};
};

// Handle correctly the tree state, new dots etc. related to closing an open tree node
export const handleNodeClosing = (node: TreeNode, close?: boolean) => {
  const data = getItemContainer(node.data as ItemData);
  if (!data || (!node.isOpened() && !node.isLoading())) return;

  saveNodeState(node, false, false);
  getRootSectionItem(node.data as ItemData)?.markAsUpdated();
  if (node.isLoading()) node.setAsyncDataLoaded(false);
  else if (data.newItemsCount) {
    data.newItemsCount = 0;
    node.setAsyncDataLoaded(false);
  }
  if (close) node.setOpened(false);
};

const ExpandHandlerComponent = (props: RenderNodeWithNextProps) => {
  const { onToggle, onSelect, node, handlers, ItemPrefix, nextRenderer } = props;
  const nData = node.data as ItemContainer;
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    if (playAnimation) {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 50); // Adjust the timeout duration to match the duration of your animation
    }
  }, [playAnimation]);

  useEffect(() => {
    const isNodeOpened = handlers.isExpanded(node);
    const state = treeExpansionState.value[node.id];
    if (state?.isOpened && !isNodeOpened && ShowTreeHistory.value) handlers.setOpen(node, true);
  }, [handlers, node]);

  const onExpand = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const isNodeOpened = handlers.isExpanded(node);
      event.stopPropagation();
      if (nData.isRootSection) {
        setPlayAnimation(true);
        getHarmonieTree()
          .instance.getOpenedNodes()
          .filter(n => n.data.isRootSection)
          .forEach(n => handleNodeClosing(n, n !== node));
      }

      unselectChildren(node, handlers);

      // Since the node is already loaded, mark it as updated since fecthChildren won't be called
      if (!node.isOpened() && node.isAsyncDataLoaded()) getRootSectionItem(nData)?.markAsUpdated();
      handleNodeClosing(node);
      onToggle(event);
      saveNodeState(node, !isNodeOpened);
    },
    [handlers, node, nData, onToggle]
  );

  const onClickExpand: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (nData.isRootSection && event.type !== 'contextmenu' && !event['no-expand']) onExpand(event);
    },
    [nData.isRootSection, onExpand]
  );
  const options = node.options;

  const treeExpander = useCallback(
    () => (
      <>
        {(options.loading && <TreeViewLoader />) ||
          (node.data.isRootSection ? (
            <TreeRootExpandIcon>
              {options.opened || playAnimation ? (
                <Stack className="lottie-animation">
                  <LottieAnimation
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: playAnimation ? dropCloseAnimationData : dropOpenAnimationData,
                      rendererSettings: {
                        preserveAspectRatio: 'none',
                      },
                    }}
                    height={64}
                    width={16}
                  />
                </Stack>
              ) : (
                <TreeExpandIcon>
                  <CaretClosedSmall />
                </TreeExpandIcon>
              )}
            </TreeRootExpandIcon>
          ) : (
            <TreeExpandIcon onClick={onExpand}>
              {options.opened ? <CaretOpenedSmall /> : <CaretClosedSmall />}
            </TreeExpandIcon>
          ))}
        {ItemPrefix && <ItemPrefix />}
      </>
    ),
    [ItemPrefix, node.data.isRootSection, onExpand, options.loading, options.opened, playAnimation]
  );

  const combinedSelectionHandler = useCombinedHandlers(onClickExpand, onSelect);
  return nextRenderer({
    ...props,
    onSelect: combinedSelectionHandler,
    ItemPrefix: treeExpander,
  });
};

export const treeExpandHandler = (type: AllowedItemType, nextRenderer: TreeNodeRenderer): TreeNodeRenderer => {
  if (!isItemContainer(type)) return nextRenderer;
  const res = (props: RenderNodeProps) => <ExpandHandlerComponent {...{ ...props, nextRenderer }} />;
  return res;
};
