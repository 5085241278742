import { IDBPDatabase, openDB } from 'idb';
import { max as _max } from 'lodash';
import { logError } from '@vendor';
import { asType } from '../misc';

declare global {
  // eslint-disable-next-line no-var
  var hrmNetworkCacheDB: IndexedDBStore;
}
export let NetworkCacheDB: IndexedDBStore;
export const STAMP_NOCACHE = -1; // Used to disable request cache.
export const sleep = (n: number): Promise<void> => new Promise(f => setTimeout(f, n));

interface Statistics {
  logicalSize: number;
  entriesNumber: number;
  perfCounter: number;
}

const MainTableName = 'MainTable';
const KeyExpirationTableName = 'KeyExpirationTable';

export class IndexedDBStore {
  private static readonly Version = 2;

  private isEnabled = true;
  private runningJobs = 0;
  private jobStartTime = 0;
  private cleanupCounter = 0;
  private readonly forAccessUpdate = new Map<string, CacheExpirationEntry>();

  constructor(readonly db: IDBPDatabase) {}

  static async openDB(dbName: string): Promise<IndexedDBStore> {
    const db = await openDB(dbName, this.Version, {
      upgrade(db: IDBPDatabase) {
        if (db.objectStoreNames.contains(MainTableName)) db.deleteObjectStore(MainTableName);
        if (db.objectStoreNames.contains(KeyExpirationTableName)) db.deleteObjectStore(KeyExpirationTableName);

        db.createObjectStore(MainTableName, { keyPath: 'key' });
        db.createObjectStore(KeyExpirationTableName, { keyPath: 'key' });
      },
    });
    return new IndexedDBStore(db);
  }

  async clear() {
    const ts = this.db.transaction(this.getTableNames(), 'readwrite');
    await ts.objectStore(MainTableName).clear();
    await ts.objectStore(KeyExpirationTableName).clear();
    this.cleanupCounter++;
  }

  async get(key: string, refreshStamp?: number): Promise<any> {
    if (!this.db) throw new Error('Database must be open');
    if (!this.isEnabled) return;
    return await this.runPerf(async () => {
      const ts = this.db.transaction(this.getTableNames(), 'readonly');
      const xs = ts.objectStore(KeyExpirationTableName);
      const ms = ts.objectStore(MainTableName);
      const expData: CacheExpirationEntry | undefined = await xs.get(key);
      if (!expData || this.isExpired(expData, refreshStamp)) return;
      const res = await ms.get(key);
      if (res && expData)
        this.forAccessUpdate.set(key, {
          key,
          created: expData.created,
          accessed: Date.now(),
          sliding: expData?.sliding,
          expires: expData?.expires,
        });
      return res;
    });
  }

  async updateStamp(key: string, cw: CacheWindow, stamp?: number) {
    return await this.runPerf(async () => {
      if (stamp === STAMP_NOCACHE) return stamp;
      const res: CacheExpirationEntry = await this.db.get(KeyExpirationTableName, key);
      const newStamp = _max([res?.created, stamp]);
      if (newStamp) {
        const cacheVal: CacheExpirationEntry = {
          key,
          created: newStamp,
          accessed: Date.now(),
          sliding: cw.sliding,
          expires: cw.expires,
        };
        // Delay access update for access update. For new stamp update immediately.
        if (newStamp === res?.created) this.forAccessUpdate.set(key, cacheVal);
        else await this.db.put(KeyExpirationTableName, cacheVal);
      }
      return newStamp;
    });
  }

  async put(value: any, { expires, sliding }: CacheWindow): Promise<void> {
    if (!this.db) throw new Error('Database must be open');
    if (!this.isEnabled) return;
    await this.runPerf(async () => {
      const ts = this.db.transaction(this.getTableNames(), 'readwrite');
      await ts.objectStore(MainTableName).put(value);
      await ts.objectStore(KeyExpirationTableName).put(
        asType<CacheExpirationEntry>({
          key: value.key,
          created: Date.now(),
          accessed: Date.now(),
          sliding,
          expires,
        })
      );
    });
  }

  async getAllKeys(): Promise<IDBValidKey[]> {
    return await this.db.getAllKeys(KeyExpirationTableName);
  }

  async getStatistics(): Promise<Statistics> {
    const items = await this.db.getAll(MainTableName);
    const logicalSize = items.reduce((size, item) => size + JSON.stringify(item).length * 2, 0);
    return { entriesNumber: items.length, logicalSize, perfCounter: this.getPerfCounter() };
  }

  setDelayScale(scale: number) {
    const prevVal = hrmProvisioning.delayScale;
    hrmProvisioning.delayScale = scale;
    return prevVal;
  }

  setIsEnabled(isEnabled: boolean) {
    this.isEnabled = isEnabled;
  }

  private isExpired(expData: CacheExpirationEntry, stamp?: number) {
    if (stamp && expData.created < stamp) return true;
    const curTime = Date.now();
    return (
      (expData.sliding && curTime > expData.accessed + expData.sliding * hrmProvisioning.delayScale) ||
      (expData.expires && curTime > expData.created + expData.expires * hrmProvisioning.delayScale)
    );
  }

  private getPerfCounter() {
    const perfCounter = localStorage[`db.perf.counter:${this.db.name}`];
    return perfCounter && JSON.parse(perfCounter);
  }

  private updatePerfCounter(worked: number) {
    const curCounter = this.getPerfCounter() || { accumulatedTime: 0, actionsCount: 0 };
    localStorage[`db.perf.counter:${this.db.name}`] = JSON.stringify({
      accumulatedTime: worked + curCounter.accumulatedTime,
      actionsCount: curCounter.actionsCount + 1,
    });
  }

  private async runPerf<T>(fn: () => Promise<T>): Promise<T> {
    if (this.jobStartTime === 0) this.jobStartTime = performance.now();
    this.runningJobs++;
    try {
      return await fn();
    } finally {
      this.runningJobs--;
      if (this.runningJobs === 0) {
        this.updatePerfCounter(performance.now() - this.jobStartTime);
        this.jobStartTime = 0;
      } else this.updatePerfCounter(0); // Increase count but not time spent
    }
  }

  private getTableNames(): string[] {
    return [KeyExpirationTableName, MainTableName];
  }

  async updateExpireAccess(curCounter: number) {
    try {
      const accessKeys = [...this.forAccessUpdate.keys()];
      for (let i = 0; i < accessKeys.length; i++) {
        if (curCounter !== this.cleanupCounter) return;
        const key = accessKeys[i];
        const val = this.forAccessUpdate.get(key);
        if (val && this.forAccessUpdate.delete(key)) {
          this.db.put(KeyExpirationTableName, val);
          await sleep(50);
        }
      }
    } catch (error: any) {
      logError(error, 'Failed updating access keys');
    }
  }

  async startExpiredKeysCleanup() {
    const curCounter = this.cleanupCounter; // Mark counter on start to prevent two updates running in the same time

    for (let i = 0; i < 10; i++) {
      await sleep(1000);
      await this.updateExpireAccess(curCounter);
    }

    const allKeys = await this.getAllKeys();
    let updateExpireAccessSkipCount = 0;
    for (const key of allKeys) {
      if (curCounter !== this.cleanupCounter) return;
      try {
        if (await this.expireKeyIfNeeded(key)) await sleep(300);
        else {
          await sleep(50); // Short sleep
          if (updateExpireAccessSkipCount++ & 0x1f) continue; // Usually skip updateExpireAccess
        }
      } catch (error: any) {
        logError(error, `Error testing expiration for '${key}'`);
        await sleep(500); // Longer sleep
      }
      await this.updateExpireAccess(curCounter);
    }

    while (curCounter === this.cleanupCounter) {
      await this.updateExpireAccess(curCounter);
      await sleep(2000);
    }
  }

  private async expireKeyIfNeeded(key: IDBValidKey): Promise<boolean> {
    const ts = this.db.transaction(this.getTableNames(), 'readwrite');
    const expData = await ts.objectStore(KeyExpirationTableName).get(key);
    if (expData && this.isExpired(expData)) {
      await ts.objectStore(KeyExpirationTableName).delete(key);
      await ts.objectStore(MainTableName).delete(key);
      return true;
    }
    return false;
  }
}

interface CacheExpirationEntry {
  key: string;
  created: number;
  accessed: number;
  expires?: number;
  sliding?: number;
}

export interface CacheWindow {
  expires?: number;
  sliding?: number;
}

export const initializeDB = async (): Promise<void> => {
  if (NetworkCacheDB != null) return;

  globalThis.hrmNetworkCacheDB = NetworkCacheDB = await IndexedDBStore.openDB('NetworkCacheDB');
  NetworkCacheDB.startExpiredKeysCleanup(); // Spawn promise, don't wait for it...
};
