import React from 'react';
import { Stack } from '@mui/system';
import { Button } from '../Button';
import { MenuAction } from '../ContextMenu';
import { IndicatorStatus } from './Indicator';

export interface IndicatorFooterProps {
  isCanceling: boolean | undefined;
  onCancel: (() => void) | undefined;
  cancel: string;
  hasCancel: boolean;
  status: IndicatorStatus;
  indicatorActions?: MenuAction[];
}

export const IndicatorFooter = ({
  cancel,
  isCanceling,
  onCancel,
  hasCancel,
  status,
  indicatorActions,
}: IndicatorFooterProps) => {
  const hasFooterActions =
    (hasCancel && status === 'progress') ||
    ((status === 'success' || status === 'error') && indicatorActions && indicatorActions?.length > 0);
  return (
    <Stack width={'100%'} justifyContent="flex-end" direction="row" minHeight={hasFooterActions ? 32 : 0}>
      {hasFooterActions && indicatorActions && (
        <Stack direction={'row'} spacing={2} maxWidth={'100%'} width={'100%'} justifyContent="flex-end">
          {indicatorActions.map(action => {
            return (
              <Stack key={action.title} maxWidth={indicatorActions.length > 1 ? '50%' : '100%'}>
                {action.render}
              </Stack>
            );
          })}
        </Stack>
      )}
      {hasCancel && status === 'progress' && (
        <Button disabled={isCanceling} variant="text" onClick={onCancel} color="secondary">
          {cancel}
        </Button>
      )}
    </Stack>
  );
};
