import { ContainerHandlers, ViewItem, toolbarQuickActions } from '@storybook';
import React from 'react';
import { strings } from '@vendor';
import { GraphClient, useGraphClient } from '@services';
import {
  BaseAction,
  DocumentTemplateType,
  FavoriteAction,
  ItemContainer,
  ItemData,
  ManageSitesAction,
  NewFolderAction,
  NewItemAction,
  NewLinkDocumentAction,
  OpenInBrowserAction,
  RenderActionByHook,
  ShareAction,
  UploadAction,
  getQuickActionsList,
} from '~/utilities';
import { SaveEmailAction } from '~/utilities/actions/SaveEmailAction';
import { OpenInTeamsAction } from '~/utilities/actions/OpenInTeamsAction';
import { CopyLink } from '~/utilities/actions/CopyLink';
import { EmailWithLink } from '~/utilities/actions/EmailWithLink';
import { ShareToTeams } from '~/utilities/actions/ShareToTeams';
import { NewOfficeDocumentAction } from '~/utilities/actions/NewOfficeDocument';
import { EmailWithAttachment } from '~/utilities/actions/EmailWithAttachment';
import { GroupAction } from '~/utilities/actions/GroupeAction';
import { AdvancedFilterAction } from '~/utilities/actions/AdvancedFilterAction';
import { ViewsAction } from '~/utilities/actions/ViewsAction';
import { ManageTeamsAction } from '~/utilities/actions/ManageTeamsAction';
import { ReOrderAToZAction } from '~/utilities/actions/ReOrderAToZ';
import { LocationRefreshAction } from './LocationRefreshAction';
import { GoHomeAction } from './GoHome';

export const getDrilldownActions = (
  gcl: GraphClient,
  location: ItemContainer,
  handlers: ContainerHandlers<ItemData>
): BaseAction[] => [
  new NewItemAction([
    new NewFolderAction(),
    new NewOfficeDocumentAction(DocumentTemplateType.Word, strings.lang.actionToolTip.newWordDocument),
    new NewOfficeDocumentAction(DocumentTemplateType.Excel, strings.lang.actionToolTip.newExcelDocument),
    new NewOfficeDocumentAction(DocumentTemplateType.PowerPoint, strings.lang.actionToolTip.newPowerPointDocument),
    new NewLinkDocumentAction(),
    new GroupAction([new SaveEmailAction(), new UploadAction()]),
  ]),
  new AdvancedFilterAction([new ViewsAction(gcl, location)], handlers),
  new ShareAction([new CopyLink(), new EmailWithLink(), new EmailWithAttachment(), new ShareToTeams()]),
  ...FavoriteAction.create(),
  new GroupAction([new OpenInBrowserAction(), new OpenInTeamsAction()]),
  new ReOrderAToZAction(),
  new LocationRefreshAction(),
  new ManageSitesAction(),
  new ManageTeamsAction(),
  new GoHomeAction(),
];

interface DrilldownActionsManagerProps {
  node: ViewItem<ItemData>;
  handlers: ContainerHandlers<ItemData>;
  location: ItemContainer;
}

export const DrilldownActionsManager: React.FunctionComponent<DrilldownActionsManagerProps> = ({
  location,
  node,
  handlers,
}) => {
  const gcl = useGraphClient();
  const { quickActions, contextAction } = getQuickActionsList(
    [node],
    getDrilldownActions(gcl, location, handlers),
    false,
    true
  );
  return (
    <>
      {[...quickActions, ...contextAction].map((action, index) => (
        <React.Fragment key={`action-${index}`}>
          <RenderActionByHook
            trigger={index < quickActions.length ? 'Quick Action' : '3Dots'}
            action={action}
            nodes={[node]}
            actionVariant={toolbarQuickActions}
            handlers={handlers}
          />
        </React.Fragment>
      ))}
    </>
  );
};
