import React, { ReactElement } from 'react';
import { Tooltip, Button, Typography, ViewItem, Stack, BAVariant, SVGComponent } from '@harmon.ie-ce/storybook';
import { styled } from '@mui/system';
import { Chip, useTheme } from '@mui/material';
import { MenuActionButton } from './MenuActionButton';

export const ActionStack = styled(Stack)`
  width: 100%;
`;

interface BaseActionUIProps {
  tooltipTitle: string | ReactElement;
  title: string | ReactElement;
  BaseActionVariant: BAVariant;
  Icon?: SVGComponent | ReactElement;
  node?: ViewItem<any>;
  disabled?: boolean;
  hideAction?: boolean;
  opened?: boolean;
  tooltipClassName?: string;
  EndIcon?: ReactElement;
  chipLabel?: string;
  isNew?: boolean;
}
export const BaseActionUI = ({
  Icon,
  tooltipTitle,
  title,
  disabled,
  opened,
  tooltipClassName,
  BaseActionVariant,
  EndIcon,
  chipLabel,
  isNew,
}: BaseActionUIProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const theme = useTheme();
  const renderIconElement = typeof Icon === 'function' ? <Icon /> : Icon;
  const tooltipWithChip = typeof tooltipTitle === 'string' && chipLabel;
  const chipLabelComponent = (
    <Chip
      label={chipLabel}
      className="shortcuts"
      sx={{
        backgroundColor: tooltipWithChip ? theme.palette.chip.onAccent : theme.palette.backgrounds.quaternary,
        borderRadius: theme.spacing(1),
        height: theme.spacing(4),
        padding: theme.spacing(0, 1),
        justifyContent: 'flex-end',
        '& .MuiChip-label': {
          color: theme.palette.texts.secondary,
        },
        '&:hover': {
          backgroundColor: tooltipWithChip ? theme.palette.chip.onAccent : theme.palette.backgrounds.quaternary,
        },
      }}
    />
  );
  const renderTooltipContent = (): React.ReactNode => {
    if (tooltipWithChip) {
      // Tooltip with string title and chip label
      return (
        <Stack direction={'row'} alignItems={'center'} spacing={2} overflow={'hidden'} maxWidth={'100%'}>
          <Typography
            variant="H3Regular"
            sx={{
              color: theme.palette.texts.onAccentPrimary,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {tooltipTitle}
          </Typography>
          {chipLabelComponent}
        </Stack>
      );
    }
    return tooltipTitle;
  };
  return (
    <Tooltip
      className={tooltipClassName}
      title={renderTooltipContent()}
      open={(isTooltipOpen && !BaseActionVariant.withText) || (isTooltipOpen && React.isValidElement(tooltipTitle))}
      onClick={() => setIsTooltipOpen(false)}
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
    >
      <Stack>
        {BaseActionVariant.withText ? (
          <MenuActionButton
            Icon={Icon}
            title={title}
            opened={opened}
            BaseActionVariant={BaseActionVariant}
            disabled={disabled}
            EndIcon={EndIcon}
            chipLabel={chipLabel}
            isNew={isNew}
          />
        ) : (
          <Button
            size="medium"
            variant={BaseActionVariant.variant}
            onDoubleClick={e => e.stopPropagation()}
            opened={opened}
            disabled={disabled}
          >
            {renderIconElement}
          </Button>
        )}
      </Stack>
    </Tooltip>
  );
};
