import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  ContextMenuContext,
  FilePreviewPopover,
  RenderTooltipProps,
  noPreviewDark,
  noPreviewLight,
  useTheme,
} from '@storybook';
import { NativeTooltip } from '@storybook';
import { useGraphClient } from '@services';
import { formatDateByTime } from '@vendor';
import { strings } from '@vendor';
import { ItemData, DocumentItem } from '~/utilities';
import { LanguageSetting } from '~/modules/Settings/SettingContext';

const RenderTooltip = ({ data, children }: RenderTooltipProps<ItemData>) => {
  const { open } = useContext(ContextMenuContext);
  const nData = data as DocumentItem;
  const [hovered, setHovered] = useState(false);
  const [filename, setFileName] = useState(data.name);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [thumb, setThumb] = useState<any>();
  const tooltipChildrens = useRef<HTMLHeadingElement>(null);
  const gcl = useGraphClient();
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const theme = useTheme();
  const getThumb = useCallback(async () => {
    const thumbData = await nData.getThumbnail(gcl);
    if (thumbData) {
      setThumb(thumbData.thumbNail);
      setFileName(thumbData.name);
    }
    if (thumbData?.thumbNail === undefined) {
      setThumb(theme.palette.mode === 'light' ? noPreviewLight : noPreviewDark);
    }
  }, [gcl, nData, theme.palette.mode]);

  const userName = nData.user?.displayName || '';
  const title = (
    <FilePreviewPopover
      fileName={filename}
      atDate={formatDateByTime(nData.date, strings.lang.dateFormat, LanguageSetting.value)}
      hasPreview={nData.hasPreview}
      previewImage={thumb}
      type={nData.usageType}
      byUser={userName}
      subject={nData.subject}
    />
  );

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
    setIsOpen(false);
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
  }, []);

  useEffect(() => {
    if (hovered && !timer.current) {
      timer.current = setTimeout(async () => {
        setIsOpen(true);
        getThumb();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  useEffect(() => {
    // Handle click on children to close tooltip.
    const child = tooltipChildrens.current?.children || [];
    child[0]?.addEventListener('click', handleMouseLeave); // We have only one child
  }, [handleMouseLeave]);

  return (
    <NativeTooltip
      enterDelay={1000}
      enterNextDelay={1000}
      placement="bottom-start"
      title={title}
      disableHoverListener
      open={isOpen && !open}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div ref={tooltipChildrens}>{children}</div>
    </NativeTooltip>
  );
};

export default RenderTooltip;
