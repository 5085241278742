export interface ConfigurationState {
  auth: { clientId: string | undefined };
}
export class ConfigurationBase implements ConfigurationState {
  auth: { clientId: string | undefined };
  version: string;
  logRocketAppId: string;
  mixpanelToken: string;
  mixpanelApiHost: string;
  constructor() {
    this.auth = {
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    };
    this.version = '1.1.0';
    this.logRocketAppId = '9aeofm/new-harmonie';
    this.mixpanelToken = '54960d6214916469afe2f7bc120fb603';
    this.mixpanelApiHost = 'https://analytics.harmon.ie';
  }
}
export class Configuration extends ConfigurationBase {
  constructor() {
    super();
    this.auth = {
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    };
  }
}

export class AppConfig {
  private static instance: Configuration;

  static getInstance(): Configuration {
    if (!this.instance) {
      this.instance = new Configuration();
    }
    return this.instance;
  }
}

export default AppConfig.getInstance();
