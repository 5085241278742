import { CopyLinkMedium, Secondary, ViewItem } from '@storybook';
import { GraphClient, useGraphClient } from '@services';
import { strings } from '@vendor';
import { ItemData } from '../itemTypes';
import { copyLink } from './ActionsUtils';
import {
  BaseAction,
  BaseReadActionClass,
  KeyModifiers,
  SelectedItemHandler,
  ShortcutAction,
  ShortcutActionRendererHookType,
} from './BaseAction';
import { InsightItem } from '../graphTypes';
import { OneDriveRoot } from '../virtualRoots';
import { isMacOsNative, isMacOsSafari } from '../misc';

const useCopyLink: ShortcutActionRendererHookType = ({ useSafeCallback, nodes, actionVariant }) => {
  const gcl = useGraphClient();
  const onClick = useSafeCallback(
    async () => {
      await copyLink(gcl, nodes);
    },
    [gcl, nodes],
    false,
    nodes.length > 1 ? strings.lang.notifications.copyLinks : strings.lang.notifications.copyLink,
    undefined,
    undefined,
    nodes.length > 1 ? 'footer-indications' : ''
  );
  return {
    title: nodes.length == 1 ? strings.lang.actionToolTip.copyLink : strings.lang.actionToolTip.copyLinks,
    icon: CopyLinkMedium,
    onClick,
    chipLabel: actionVariant !== Secondary ? strings.lang.shortcuts.copyLink : undefined,
    nodes,
  };
};

export class CopyLink extends BaseReadActionClass implements SelectedItemHandler, ShortcutAction {
  readonly trackedName = 'CopyLink';
  readonly useRenderer = useCopyLink;
  directAction: ShortcutAction['directAction'] = async (gcl: GraphClient, nodes: ViewItem<ItemData>[]) => {
    const notif = strings.lang.notifications;
    await copyLink(gcl, nodes);
    return [
      nodes.length == 1 ? notif.copyLink.onSuccessTitle! : notif.copyLinks.onSuccessTitle!,
      undefined,
      undefined,
      nodes.length > 1 ? 'footer-indications' : '',
    ];
  };
  supportsTopLocation = true;
  isHandled = BaseAction.isMultiHandled(this);

  isSingleHandled = (d: ItemData) =>
    d.canOpenInBrowser &&
    !(d instanceof OneDriveRoot) &&
    !(d instanceof InsightItem && d.isEmailAttachment) &&
    // Disable CopyLink on Team & channel for Mac
    ((!isMacOsNative() && !isMacOsSafari()) || (d.type != 'channel' && d.type != 'team'));

  shortcut: [KeyModifiers, string] = [KeyModifiers.Ctrl, 'l'];
}
