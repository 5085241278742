import { AccountInfo } from '@azure/msal-browser';
import { GraphClient } from '@services';
import { logError, OnSignInKey } from '@vendor';
import {
  createItemDataFromJson,
  DEFAULT_FIELDS,
  DocumentItem,
  Folder,
  LocalStateManager,
  OfficeUserSettingManager,
  OneDriveRoot,
  SharePointSitesRoot,
  SPList,
} from '~/utilities';
import { LibraryItem } from '~/utilities/itemTypes/LibraryItem';
import { EmptyListView } from '~/utilities/sharePointTypes/SPListViewFactory';
import { getHarmonieFilesLibrary } from './Setup';
import { getProvisionedSettings } from './Provisioned';

type DocLibItem = LibraryItem & DocumentItem;

// Constants
export const ProvisioningFile = 'Provisioning.json';
export const HarmonieFilesTitle = 'harmon.ie Files';
export const HarmonieInternals = 'harmon.ie Admin';

export const SiteLocations = ['/', `/${HarmonieInternals}`, `/sites/${HarmonieInternals}`];

export const ProvisionedDetails = new LocalStateManager<{ fileDetails?: any; libraryDetails?: any } | undefined>(
  'ProvisioningDetails',
  () => undefined,
  false // Do not reset when user resets settings.
);
const ProvisionedFileContents = new LocalStateManager<any | undefined>(
  'ProvisioningFileContents',
  () => undefined,
  false // Do not reset when user resets settings.
);

let currentUser: string | undefined = undefined;

const getRootSite = async (gcl: GraphClient) => {
  let rootSite: string;
  try {
    const rootSPSite = await SharePointSitesRoot.getRootSPSite(gcl);
    rootSite = rootSPSite.rootSite;
  } catch (error) {
    const rootSPSite = await OneDriveRoot.getRootLibrary(gcl);
    rootSite = rootSPSite.rootSite.replace('-my.', '.');
  }
  return rootSite;
};

export const getProvisionedLibrary = (): SPList | undefined => {
  const libDetails = ProvisionedDetails.value?.libraryDetails;
  return libDetails && (createItemDataFromJson(libDetails) as SPList);
};

const initProvisioningDetails = async (gcl: GraphClient) => {
  ProvisionedDetails.value = ProvisionedDetails.value || {};
  try {
    const rootSite = await getRootSite(gcl);
    const library = getProvisionedLibrary() || (await getHarmonieFilesLibrary(gcl, rootSite));
    const view = new EmptyListView().combineWithFields(DEFAULT_FIELDS);
    const doc = (await library?.resolveByPath(gcl, view, ProvisioningFile)) as DocLibItem;
    ProvisionedDetails.value = { ...ProvisionedDetails.value, libraryDetails: library?.toJson() };
    if (!doc) {
      ProvisionedFileContents.value = undefined; // No provisioning file, reset contents.
      ProvisionedDetails.value = { ...ProvisionedDetails.value, fileDetails: undefined }; // No provisioning file, reset details.
    }
    return doc;
  } catch (error) {
    logError(error, 'Failed to get provisioning file');
  }
};

const applyProvisioningContent = (pv: any) => {
  if (pv == undefined) {
    getProvisionedSettings(true); // Reset the provisioning.
    return;
  }
  // Read from siplified provisioning format
  const ps = getProvisionedSettings();
  Object.keys(pv).forEach(key => {
    ps[key] && (ps[key].value = pv[key]);
    if (key.endsWith('.ReadOnly')) ps[key.substring(9)] && (ps[key.substring(9)].isReadOnly = pv[key]);
  });

  // To avoid initialization issues, we pass the settings to the manager instead of the manager referncing them diretly.
  OfficeUserSettingManager.resetFromProvisioning(ps);
};

const readProvisioingContent = async (gcl: GraphClient, provDoc: DocLibItem) => {
  try {
    const content = await provDoc.download(gcl);
    const txt = await content.blob.text();
    ProvisionedFileContents.value = JSON.parse(txt);
    ProvisionedDetails.value = { ...ProvisionedDetails.value, fileDetails: provDoc.toJson() };
  } catch (error) {
    logError(error, 'Failed to get provisioning file contents');
  }
};

const refreshProvisioingContent = async (gcl: GraphClient) => {
  const savedFileDetails = ProvisionedDetails.value?.fileDetails;
  const savedDoc = savedFileDetails && (createItemDataFromJson(savedFileDetails) as DocLibItem);
  let curDoc: DocLibItem | undefined;
  try {
    curDoc = await initProvisioningDetails(gcl);
    if (curDoc?.metadata.Modified === savedDoc?.metadata.Modified) return; // No provisioning change
  } catch (error) {
    // Unexpected error, see if we can (or should) verify that the library still exists...
    logError(error, 'Failed to resolve provisioning file');
  }

  curDoc && (await readProvisioingContent(gcl, curDoc));
  applyProvisioningContent(ProvisionedFileContents.value);
};

const initProvisioingContent = async (gcl: GraphClient) => {
  if (ProvisionedDetails.value == undefined) {
    // First time after reset, wait until we read the provisioning file
    const provDoc = await initProvisioningDetails(gcl);
    provDoc && (await readProvisioingContent(gcl, provDoc));
  }
  applyProvisioningContent(ProvisionedFileContents.value);

  // Run the following in the BG so startup won't be impacted.
  refreshProvisioingContent(gcl);
};

export const initProvisioing = async (gcl: GraphClient, account: AccountInfo) => {
  if (!account || currentUser === account.username) return;
  currentUser = account.username;

  await initProvisioingContent(gcl);
};

const resetProvisioing = () => {
  currentUser = undefined;
  getProvisionedSettings(true); // Reset the provisioning.
};

PubSub.subscribe(OnSignInKey, () => resetProvisioing());

const simplifyProvisioning = (provisioning: ReturnType<typeof getProvisionedSettings>) => {
  const res = {};
  Object.keys(provisioning).forEach(key => {
    const val = provisioning[key];
    if (val && val.value !== undefined) {
      res[key] = val.value;
      if (val.isReadOnly) res[`${key}.ReadOnly`] = true;
    }
  });
  return res;
};

export const updateProvisioning = async (gcl: GraphClient, folder: Folder) => {
  ProvisionedFileContents.value = simplifyProvisioning(getProvisionedSettings());
  const provisioningData = new Blob([JSON.stringify(ProvisionedFileContents.value, undefined, 2)], {
    type: 'application/json',
  });
  await folder.upload({
    gcl,
    fileName: ProvisioningFile,
    data: provisioningData,
    override: true,
  });
  if (ProvisionedDetails.value?.fileDetails === undefined) {
    const provDoc = await initProvisioningDetails(gcl); // Refresh details from file.
    ProvisionedDetails.value = { ...ProvisionedDetails.value, fileDetails: provDoc?.toJson() };
  }

  // Reset settings based on the current provisioning
  OfficeUserSettingManager.resetFromProvisioning(getProvisionedSettings());
};
