import React, { useContext, useEffect, useMemo, useRef } from 'react';
import Tree, {
  DefaultNodeProps,
  DragZoneProps,
  HyperTreeNodeProps,
  useTreeState,
  HyperTreeViewProps,
  treeHandlers,
  DefaultNode,
} from 'react-hyper-tree';
import { IUseTreeState } from 'react-hyper-tree/dist/helpers/hooks';
import { IData, TreeNode, TreeView as HTreeView } from 'react-hyper-tree/dist/helpers/node';
import { ContainerHandlers, ViewItem, ViewItemRenderProps } from '../ContainerTypes';
import TreeRoot from './TreeRoot';
import { ScrollbarContext } from '../Scollbar';
import TreeRootTopLevel from './TreeRootTopLevel';

export type UseTreeState = IUseTreeState;
export const treeViewHandlers = treeHandlers;
export const TreeViewDefaultNode = DefaultNode;
export type TreeHandlers = ReturnType<typeof useTreeState>['handlers'];
export type TreeViewNodeProps = HyperTreeNodeProps;
export type TreeViewDragZoneProps = DragZoneProps;
export type TreeViewNode = TreeNode;
export type TreeState = ReturnType<typeof useTreeState>;
export type TreeNodeHandlers = TreeState['handlers'];
export const MultiSelectionEvent = 'MultiSelectionEvent';
export interface SubscribeMultiSelection {
  handlers: ContainerHandlers<any>;
  selectedItem?: ViewItem<any>[];
}
export const multiSelectionsEventPublish = ({ handlers, selectedItem }: SubscribeMultiSelection) =>
  PubSub.publish(MultiSelectionEvent, { handlers, selectedItem });
export interface TreeViewRenderNodeProps extends Omit<ViewItemRenderProps<IData>, 'handlers' | 'node'> {
  handlers: TreeHandlers & Pick<ContainerHandlers, 'isSelected' | 'isExpanded'>;
  node: TreeNode;
}

export type TreeProps = Omit<
  HyperTreeViewProps,
  'renderNode' | 'renderDragZone' | 'required' | 'handlers' | 'depth' | 'nodeHeight' | 'draggableHandlers' | 'data'
> & {
  renderNode?: (props: TreeViewRenderNodeProps) => React.ReactNode | string | null;
  renderDragZone?: (props: TreeViewDragZoneProps) => React.ReactNode | string | null;
};

export const unselectChildren = (node: TreeNode, handlers: TreeHandlers) => {
  if (node.children) node.children.forEach(n => unselectChildren(n, handlers));
};

export interface TreeViewProps {
  useTreeStateProps: UseTreeState;
  treeProps: TreeProps;
  wrapperComponent: React.ComponentType<any>;
  rootIds: string[];
  canBeSelected: (data: TreeNode) => boolean;
}

export interface WrapperComponentProps {
  children: any;
  handlers: TreeHandlers;
}

const traverseTreeNode = (node: TreeNode, callback: (n: TreeNode) => boolean) => {
  callback(node) && node.getChildren().forEach(n => traverseTreeNode(n, callback));
};

const traverseTree = (instance: HTreeView, rootIds: string[], callback: (n: TreeNode) => boolean) => {
  rootIds.forEach(id => {
    const node = instance.getNodeById(id);
    node && traverseTreeNode(node, callback);
  });
};

export type TreeViewHandlers<T extends IData = IData> = ContainerHandlers<T> & TreeHandlers;

const TreeView = ({
  treeProps,
  useTreeStateProps,
  wrapperComponent: WrapperComponent,
  rootIds,
  canBeSelected,
}: TreeViewProps) => {
  const treeRef = useRef<HTMLDivElement | null>(null);
  const { resizeObserver } = useContext(ScrollbarContext);
  const { required, handlers: hyperTreeHandlers, instance } = useTreeState(useTreeStateProps);
  const handlers = useMemo<ContainerHandlers & TreeHandlers>(
    () => ({
      ...hyperTreeHandlers,
      //Not implemented yet
      hasNewItems: () => false,
      isExpanded: node => (node as TreeNode).isOpened(),
      isSelected: node => (node as TreeNode).isSelected(),
      unselectAll: () => {
        multiSelectionsEventPublish({ handlers: handlers });
        hyperTreeHandlers.unselectAll();
      },
      setSelected: (node: any, selected?: boolean | undefined, shift?: boolean) => {
        multiSelectionsEventPublish({ handlers: handlers });
        if (shift) {
          const firstSel = handlers.getSelectedNodes()[0];
          let doSelection = false;
          const curNodeId = node.id;
          traverseTree(instance, rootIds, n => {
            if (n.id === firstSel?.id) doSelection = !doSelection;
            else if (n.id === curNodeId) {
              doSelection = !doSelection && Boolean(firstSel);
              n.setSelected(canBeSelected(n));
            } else n.setSelected(doSelection && canBeSelected(n));
            return n.isOpened();
          });
        }
        hyperTreeHandlers.setSelected(node, selected);
      },
      getSelectedNodes: () => {
        const nodes: any = [];
        traverseTree(instance, rootIds, node => {
          if (node.isSelected()) nodes.push(node);
          return node.isOpened();
        });
        return nodes;
      },
      getParent: node => (node?.['options'] ? (node as TreeNode).getParent() : undefined),
    }),
    [hyperTreeHandlers, instance, rootIds, canBeSelected]
  );
  const renderNode = treeProps?.renderNode
    ? (props: DefaultNodeProps) => treeProps.renderNode?.({ ...props, handlers })
    : undefined;

  useEffect(() => {
    treeRef.current && resizeObserver?.observe(treeRef.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      treeRef.current && resizeObserver?.unobserve(treeRef.current);
    };
  }, [resizeObserver]);

  return (
    <TreeRootTopLevel onClick={() => handlers.unselectAll()}>
      <WrapperComponent handlers={handlers}>
        <TreeRoot ref={treeRef}>
          <Tree
            {...treeProps}
            renderNode={renderNode}
            {...required}
            {...hyperTreeHandlers}
            depthGap={20}
            disableTransitions
          />
        </TreeRoot>
      </WrapperComponent>
    </TreeRootTopLevel>
  );
};

export default TreeView;
