import { useCallback } from 'react';
import { useGraphClient } from '@services';
import { logError } from '@vendor';
import { strings } from '@vendor';
import { ErrorItem, ItemContainer, ItemData, isItemContainer, RefreshAction } from '~/utilities';

export interface DataNode {
  node: {
    data: ItemData;
  };
}

export type extGetChildrenType = (
  { node }: DataNode,
  refreshStamp?: number,
  itemCount?: number,
  isDrilldown?: boolean,
  throwError?: boolean,
  searchTrem?: string
) => any[] | Promise<any[]>;

export const useGetChildren = (): extGetChildrenType => {
  const gcl = useGraphClient();

  const getChildren = useCallback(
    (
      { node: { data } }: DataNode,
      refreshStamp?: number,
      itemCount?: number,
      isDrilldown?: boolean,
      throwError?: boolean
    ): any => {
      const wrapChildren = async (nodesPromise: Promise<ItemData[]>, parent: ItemContainer, throwError?: boolean) => {
        try {
          const nodes = await nodesPromise;
          return nodes.map((n: ItemData) => {
            n.rootPrefix = parent.rootPrefix;
            if (isItemContainer(n.type)) n.getChildren = getChildren;
            return n;
          });
        } catch (error: any) {
          logError(error, 'An error occurred during getChildren');
          if (throwError) throw error;
          return [
            new ErrorItem({
              id: `${data.apiId}-error`,
              name: error.code !== undefined ? error.message : strings.lang.errorMessages.cannotGetItems,
            }),
          ];
        }
      };

      const nData = data.objectify(data) as ItemContainer;
      const setStampAndFetch = async (): Promise<ItemData[]> => {
        refreshStamp = await RefreshAction.markRefreshStamp(nData, refreshStamp);
        const res = await nData.fetchChildren({
          gcl,
          refreshStamp,
          itemCount,
          isDrilldown,
          sortDir: true,
        });
        return res.items;
      };
      return wrapChildren(setStampAndFetch(), nData, throwError);
    },
    [gcl]
  );

  return getChildren;
};
