import React from 'react';
import { strings } from '@vendor';
import {
  CheckMarkMedium,
  FranceFlag,
  GermanyFlag,
  Typography,
  UsaFlag,
  WhatsNewBulletItem,
  WhatsNewBulletItemList,
  WhatsNewHeader,
} from '@storybook';
import { styled, Theme } from '@mui/material';

const StyledCheckMarkMedium = styled(CheckMarkMedium)`
  rect {
    fill: ${({ theme }) => theme.palette.backgrounds.secondary};
  }
`;

export default (theme: Theme) => [
  <WhatsNewBulletItemList key="improvedSettings">
    <WhatsNewHeader
      title={strings.lang.whatsNew.improvedSettingsTitle}
      subTitle={strings.lang.whatsNew.settingsOptionsDescription}
    />
  </WhatsNewBulletItemList>,
  <WhatsNewBulletItemList key="newLanguageSupport">
    <WhatsNewHeader
      title={strings.lang.whatsNew.newLanguageSupportTitle}
      subTitle={strings.lang.whatsNew.selectLanguageInstruction}
    />
    <WhatsNewBulletItem
      icon={<StyledCheckMarkMedium />}
      text={
        <>
          <Typography variant="H2Regular" sx={{ color: theme.palette.texts.primary }}>
            {strings.lang.whatsNew.english}
          </Typography>
          <UsaFlag />
        </>
      }
    />
    <WhatsNewBulletItem
      icon={<StyledCheckMarkMedium />}
      text={
        <>
          <Typography variant="H2Regular" sx={{ color: theme.palette.texts.primary }}>
            {strings.lang.whatsNew.franch}
          </Typography>
          <FranceFlag />
        </>
      }
    />
    <WhatsNewBulletItem
      icon={<StyledCheckMarkMedium />}
      text={
        <>
          <Typography variant="H2Regular" sx={{ color: theme.palette.texts.primary }}>
            {strings.lang.whatsNew.german}
          </Typography>
          <GermanyFlag />
        </>
      }
    />
  </WhatsNewBulletItemList>,
  <WhatsNewBulletItemList key="adminProvisioning">
    <WhatsNewHeader
      title={strings.lang.whatsNew.title_adminProvisioning}
      subTitle={strings.lang.whatsNew.title_adminProvisioningDescription}
    />
    <WhatsNewBulletItem icon={<StyledCheckMarkMedium />} text={strings.lang.whatsNew.customEmailHeaderMapping} />
    <WhatsNewBulletItem icon={<StyledCheckMarkMedium />} text={strings.lang.whatsNew.teamsOneDriveConfiguration} />
  </WhatsNewBulletItemList>,
];
