export interface HarmonieProvisioning {
  logMixpanel: boolean;
  delayScale: number;
  autoRefreshDelay: number;
  recentFoldersCount: number;
  maxRecentFoldersCount: number;
  recentTimeSpan: number;
  recentFoldersTimeSpan: number;
  teamChatCount: number;
  documentsCount: number;
  foldersCount: number;
  frequentsSitesCount: number;
  followedSitesCount: number;
  virtualFoldersCount: number;
  sitesSearchCount: number;
  appDoubleClickAction?: any;
  doubleClickAction?: any;
  searchTerm: string;
  searchLocation: string;
  scrollDebugger?: boolean;
  showStorybookAction: boolean;
  showOnboardingFlowkAction: boolean;
  search: (x: string) => void;
  showProfiler: any;
}

declare global {
  // eslint-disable-next-line no-var
  var hrmProvisioning: HarmonieProvisioning;
}

export const initHarmonieProvisionig = (val?: HarmonieProvisioning) => {
  const oneHour = 3600 * 1000;
  const oneDay = 24 * oneHour;
  globalThis.hrmProvisioning = {
    ...{
      logMixpanel: false,
      delayScale: oneHour,
      autoRefreshDelay: oneHour,
      recentFoldersCount: 2,
      maxRecentFoldersCount: 8,
      recentTimeSpan: 90 * oneDay,
      recentFoldersTimeSpan: 7 * oneDay,
      teamChatCount: 5,
      documentsCount: 25,
      foldersCount: 30,
      frequentsSitesCount: 15,
      followedSitesCount: 10,
      virtualFoldersCount: 0,
      sitesSearchCount: 50,
      searchTerm: '',
      searchLocation: '',
      showStorybookAction: false,
      showOnboardingFlowkAction: false,
      search: () => undefined,
      showProfiler: () => false,
    },
    ...(val || {}),
  };
};

export interface ShareToTeamsProvisioning {
  showErrorPage: boolean;
}

declare global {
  // eslint-disable-next-line no-var
  var ShareProvisioning: ShareToTeamsProvisioning;
}

export const initShareToTeamsProvisionig = (val?: ShareToTeamsProvisioning) => {
  globalThis.ShareProvisioning = {
    ...{
      showErrorPage: false,
    },
    ...(val || {}),
  };
};

// TODO: Please review what Danyl did in Debgguer part!!
