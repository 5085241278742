import { ViewConfigurationMedium } from '@storybook';
import { SafeCallbackStrings, strings } from '@vendor';
import { ItemData, OpenDocumentInBrowserAction } from '~/utilities';

export class ViewConfiguration extends OpenDocumentInBrowserAction {
  constructor(readonly stateChanged: boolean) {
    super(false);
  }
  readonly useRedirect = true;

  get icons() {
    return ViewConfigurationMedium;
  }
  override get title() {
    return strings.lang.actionToolTip.viewConfiguration;
  }

  override get notification(): Partial<SafeCallbackStrings> {
    return strings.lang.notifications.viewConfiguration;
  }

  override isSingleHandled(data: ItemData): boolean {
    return super.isSingleHandled(data) && !this.stateChanged;
  }
}
