import { GraphClient } from '@services';
import { IconProps, UndoAction, ViewItem } from '@storybook';
import { ItemContainer } from './ItemContainer';
import { SharePointFieldType } from '../metadata/SharePointFieldType';

export enum MicrosoftApps {
  OneDrive = 'OneDrive',
  SharePoint = 'SP',
  Teams = 'Teams',
  Default = 'Microsoft 365',
}

export type AllowedItemType =
  | 'list'
  | 'site'
  | 'folder'
  | 'team'
  | 'channel'
  | 'document'
  | 'error'
  | 'empty'
  | 'favoritesroot'
  | 'insight'
  | 'sharepointroot'
  | 'teamsroot'
  | 'onedriveroot'
  | 'recentroot'
  | 'sharedwithmeroot'
  | 'home'
  | 'separator'
  | 'teamschatfiles'
  | 'SearchResults'
  | 'viewfiles';

export type ByItemType<T> = Record<AllowedItemType, T>;
export type getChildrenType = ({ node }: any, refreshStamp?: number) => any[] | Promise<any[]>;

export interface AccessUrls {
  webUrl: string;
  appUrl?: string;
  address?: string;
  odOpenUrl?: string;
}

export enum treeitemFirstParent {
  OneDrive = 'OneDrive',
  SharePoint = 'SharePoint',
  Teams = 'Teams',
  Recent = 'Recent',
  Favorite = 'Favorite',
  SharedWithMe = 'SharedWithMe',
}

export interface ItemData {
  readonly type: AllowedItemType;
  readonly name: string;
  id: string;
  size?: number;

  rootPrefix: string;
  isTransientLocation?: boolean;
  isVirtualItem?: boolean;
  isSearchItem?: boolean;
  isAdvancedSearchItem?: boolean;
  isNew?: boolean;
  itemFirstParent?: treeitemFirstParent;
  searchedLocationId?: string;
  timeStamp?: number;
  itemColorable: boolean;
  additionalActionHandler?: (e: React.MouseEvent) => void;
  summary?: string;
  getChildren?: getChildrenType;
  objectify: (props: any) => ItemData;

  // Computed criteria for logical computations on items.
  OfficeAppLocated?: MicrosoftApps;
  get parent(): ItemContainer | undefined;
  get isRootSection(): boolean;
  get isVirtual(): boolean;
  get isAppContainer(): boolean;
  get isUserManaged(): boolean;
  get isDocument(): boolean;
  get isLibraryItem(): boolean;
  get isShareable(): boolean;
  get hasFolder(): boolean;
  get rootSite(): string;
  get apiId(): string;
  get apiIdKey(): string;
  get canRefresh(): boolean;
  get supportsSelection(): boolean;
  get canOpenInBrowser(): boolean;
  get canDownload();
  get isTeamsEntity(): boolean;
  get isAdvancedSearchContainer(): boolean;
  get canRemove(): boolean;
  get pathOrDescription(): string | null;
  get secondLineContent(): string | null;
  get canReorder(): boolean;
  get sortDir(): boolean;

  getNumberOfQuickActions(isDrilldown: boolean): number;
  getIcon(expanded: boolean): IconProps | undefined;
  getAccessUrls(gcl: GraphClient): Promise<AccessUrls | undefined>;
  toJson(): any;
  removeItem(gcl: GraphClient): Promise<UndoAction | void>;
  getProperty(name: string, type: SharePointFieldType): any;
}

export const NoRootSite = 'none';

export const getNodesType = (nodes: ViewItem<ItemData>[]) => {
  const firstType = nodes[0].data.type;
  if (nodes.length === 1) return firstType;
  switch (firstType) {
    case 'insight':
    case 'document':
    case 'folder':
      return 'items'; // Multiple documents & folders are items
  }
  return `${firstType}s`; // Pluralize the type
};
