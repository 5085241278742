import { setLanguage, ThemeType } from '@vendor';
import { createContext, useCallback, useMemo, useState } from 'react';
import { UserInfo } from '@services';
import {
  EmailHeaderMappingChoices,
  OfficeUserSettingManager,
  OpenAction,
  EditPropertiesMode,
  BaseStateManager,
  BooleanFixOfficeUserSettingManager,
  EnableTeamsState,
  SharedWithMeRoot,
  TeamChatFiles,
  RecentRoot,
  LocalStateManager,
} from '~/utilities';
import { UserRole } from '~/provisioning';
import { useSettingsState, useWhatsNewNotifications } from '~/hooks';

export interface UserRoleAndInfo {
  userInfo?: UserInfo;
  userRole?: UserRole;
}
export interface SettingsContextInterface {
  theme?: ThemeType;
  setTheme?: (theme: ThemeType) => void;
  setGeneralNumber?: (number: number) => void;
  generaFilesCount?: number;
  openFilesSettings?: OpenAction;
  editPropertiesMode?: boolean;
  setEditPropertiesMode?: (mode: boolean) => void;
  showTreeHistory?: boolean;
  enableTeams?: boolean;
  addCategory?: boolean;
  setAddCategory?: (c: boolean) => void;
  emailNameSettings?: boolean;
  setEmailNameSettings?: (saveEmailwithUniqueNames: boolean) => void;
  setShowTreeHistory?: (chose: boolean) => void;
  resetSetting?: () => void;
  setOpenFilesSettings?: (action: OpenAction) => void;
  setEmailHeaderMapping?: (ehmChoice: EmailHeaderMappingChoices) => void;
  emailHeaderMapping?: EmailHeaderMappingChoices;
  userPhoto?: string | Blob | undefined; // New user photo state
  setUserPhoto?: (photo: string | Blob | undefined) => void; // New setter for user photo
  userDetails?: UserRoleAndInfo | undefined; // New user account information state
  setUserDetails?: React.Dispatch<React.SetStateAction<UserRoleAndInfo | undefined>>;
  changeLanguage?: (lang: string) => void;
  selectedLanguage?: string;
  setEnableLogsRecording?: (enable: string) => void;
  enableLogsRecording?: string;
}

export const SearchHistoryState = new OfficeUserSettingManager<string[]>('SearchHistoryState', () => []);
export const DownloadOrBrowserState = new OfficeUserSettingManager<OpenAction>(
  'DownloadOrBrowserState',
  () => OpenAction.CHOOSE
);
export const OpenInDesktopAppOrBrowserState = new OfficeUserSettingManager<OpenAction>(
  'OpenInDesktopAppOrBrowserState',
  () => OpenAction.CHOOSE
);
export const SelectedTheme = new OfficeUserSettingManager<ThemeType>('SelectedTheme', () => 'system');
export const ShowTreeHistory = new BooleanFixOfficeUserSettingManager('ShowTreeHistorySetting', () => true);
export const EnableLogsRecording = new LocalStateManager<string>('EnableLogsRecording', () => '');
export const ODOpenInfoDialogShown = new OfficeUserSettingManager('ODOpenInfoDialogShown', () => false);
export const LanguageSetting = new OfficeUserSettingManager<string>(
  'LanguageSetting',
  () => (Office.context?.displayLanguage || 'en-US').split('-')[0]
);

// Count states
export const SPFilesCountState = new OfficeUserSettingManager('SPFilesCountState', () => 5);
export const RecentFolders = new OfficeUserSettingManager('RecentFolders', () => [] as any[]);
export const WhatsNewNotificationSeen = new OfficeUserSettingManager('WhatsNewNotificationSeen', () => [] as string[]);
export const AddCategory = new BooleanFixOfficeUserSettingManager('AddCategory', () => false);
export const UseUniqueNames = new BooleanFixOfficeUserSettingManager('UseUniqueNames', () => false, undefined);
export const AutoEmailHeaderMapping = new OfficeUserSettingManager(
  'AutoEmailHeaderMapping',
  () => EmailHeaderMappingChoices.TeamsOnly,
  'EmailHeaderMapping'
);
export const ActionSeenStateList = new OfficeUserSettingManager<string[]>('ActionSeenState', () => []);

export const SettingsContext = createContext<SettingsContextInterface>({});

export const useSettings = (): SettingsContextInterface => {
  const [showTreeHistory, setShowTreeHistory] = useSettingsState(ShowTreeHistory, true);
  const [enableLogsRecording, setEnableLogsRecording] = useSettingsState(EnableLogsRecording, false);
  const [generaFilesCount, setGeneralCount] = useState<number>(
    Math.min(
      SharedWithMeRoot.SharedCountState.value,
      TeamChatFiles.ChatsCountState.value,
      RecentRoot.RecentCountState.value,
      SPFilesCountState.value
    )
  );

  const [selectedLanguage, setSelectedLanguage] = useSettingsState(LanguageSetting, true);
  const [addCategory, setAddCategory] = useSettingsState(AddCategory, true);
  const [theme, setTheme] = useSettingsState(SelectedTheme, true);
  const [editPropertiesMode, setEditPropertiesMode] = useSettingsState(EditPropertiesMode, true);
  const [openFilesSettings, setOpenFilesSettings] = useSettingsState(OpenInDesktopAppOrBrowserState, true);
  const [inpEmailHeaderMapping, setEmailHeaderMapping] = useSettingsState(AutoEmailHeaderMapping, true);
  const [enableTeams] = useSettingsState(EnableTeamsState);
  const emailHeaderMapping = useMemo(
    () =>
      inpEmailHeaderMapping == EmailHeaderMappingChoices.TeamsOnly && !enableTeams
        ? EmailHeaderMappingChoices.None
        : inpEmailHeaderMapping,
    [enableTeams, inpEmailHeaderMapping]
  );
  const [emailNameSettings, setEmailNameSettings] = useSettingsState(UseUniqueNames, true);
  const [userPhoto, setUserPhoto] = useState<string | Blob | undefined>('isLoading');
  const [userDetails, setUserDetails] = useState<UserRoleAndInfo | undefined>();
  const changeLanguage = useCallback(
    (lang: string) => {
      setSelectedLanguage(lang);
      setLanguage(lang); // Assuming this updates i18n context
    },
    [setSelectedLanguage]
  );
  const { clearAll: clearAllWhatsNew } = useWhatsNewNotifications();
  // As this is a combined setting, we need to update all the states when this changes
  const changeGeneralNumber = useCallback((number: number) => {
    TeamChatFiles.ChatsCountState.value =
      SharedWithMeRoot.SharedCountState.value =
      RecentRoot.RecentCountState.value =
      SPFilesCountState.value =
        number;
    setGeneralCount(number);
  }, []);

  const resetSetting = useCallback(() => {
    setGeneralCount(5);
    setLanguage(Office.context.displayLanguage.split('-')[0]);
    BaseStateManager.resetAll();
    clearAllWhatsNew();
  }, [clearAllWhatsNew]);

  return {
    theme,
    setTheme,
    setGeneralNumber: changeGeneralNumber,
    setOpenFilesSettings,
    generaFilesCount,
    openFilesSettings,
    showTreeHistory,
    enableTeams,
    addCategory,
    editPropertiesMode,
    setEditPropertiesMode,
    setShowTreeHistory,
    resetSetting,
    setAddCategory,
    emailNameSettings,
    setEmailNameSettings,
    setEmailHeaderMapping,
    emailHeaderMapping,
    userPhoto,
    setUserPhoto,
    userDetails,
    setUserDetails,
    changeLanguage,
    selectedLanguage,
    setEnableLogsRecording,
    enableLogsRecording,
  };
};
