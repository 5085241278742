import React, { Suspense, useEffect, useState, startTransition } from 'react';
import { Routes, Route, MemoryRouter } from 'react-router-dom';
import { initHarmonieProvisionig } from '@services';
import { NotificationsAndErrorProvider } from '@storybook';
import DrilldownShell from '~/modules/Drilldown/DrilldownShell';
import LoadingScreen from '~/modules/Login/LoadingScreen';
import { ProtectedRoute } from '../components';

initHarmonieProvisionig();

const CloseChild = () => {
  Office.context.ui.messageParent('closeChild'); // Tell parent to close this window
  return <div>You can close this window now</div>;
};

const Redirect = () => {
  const target = new URLSearchParams(window.location.search).get('targetUrl');
  const isValidUrl = (url: string | null) => {
    if (!url) return false;
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.origin === window.location.origin;
    } catch (e) {
      return false;
    }
  };
  const redirectUrl = isValidUrl(target) ? target! : '/'; // Ensure `target` is not null after validation
  window.location.href = redirectUrl;
  return <LoadingScreen />;
};

const LazyFolderDrilldown = React.lazy(() => import('~/modules/Drilldown/FolderDrilldown'));
const LazySimpleDrilldown = React.lazy(() => import('~/modules/Drilldown/SimpleDrilldown'));
const LazyHome = React.lazy(() => import('~/modules/Home/Home'));
const LazyShell = React.lazy(() => import('~/modules/Shell/Shell'));
const LazySettings = React.lazy(() => import('~/modules/Settings/Settings'));
const LazyAuthDialog = React.lazy(() => import('~/modules/AuthDialog/AuthDialog'));
const LazyLogin = React.lazy(() => import('~/modules/Login/Login'));
const LazySignOutAuthDialog = React.lazy(() => import('~/modules/SignOutAuthDialog/SignOutAuthDialog'));
const LazyOnBoarding = React.lazy(() => import('~/modules/Onboarding/OnboardingFlow'));

export const App = () => {
  const [, setShowProfiler] = useState<boolean>(false);

  const handleContextMenu = event => {
    event.preventDefault(); // Prevent the default context menu
  };

  // Attach the context menu event handler to the entire document
  useEffect(() => {
    hrmProvisioning.showProfiler = show => {
      startTransition(() => {
        setShowProfiler(show);
      });
    };
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <MemoryRouter>
      <Routes>
        <Route
          path="login"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <LazyLogin />
            </Suspense>
          }
        />
        <Route path="closeChild" element={<CloseChild />} />
        <Route path="redirect" element={<Redirect />} />
        <Route
          path="authDialog"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <LazyAuthDialog />
            </Suspense>
          }
        />
        <Route
          path="onboarding"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <LazyOnBoarding />
            </Suspense>
          }
        />
        <Route
          path="signOutAuthDialog"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <LazySignOutAuthDialog />
            </Suspense>
          }
        />
        <Route path="drilldown" element={<DrilldownShell />}>
          <Route
            path="folderDrilldown"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <LazyFolderDrilldown />
              </Suspense>
            }
          />
          <Route
            path="simpleDrilldown"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <LazySimpleDrilldown />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="settings"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <NotificationsAndErrorProvider>
                <LazySettings />
              </NotificationsAndErrorProvider>
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Suspense fallback={<LoadingScreen />}>
                <LazyShell />
              </Suspense>
            </ProtectedRoute>
          }
        >
          <Route
            path="home"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <LazyHome />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </MemoryRouter>
  );
};

export default App;
