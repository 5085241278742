import { ViewItem } from '@storybook';
import { strings } from '@vendor';
import React from 'react';
import { useDrilldownNavigation } from '~/components/Tree/Renderers/DrilldownHandler';
import { ActionSeenStateList } from '~/modules/Settings/SettingContext';
import { isItemContainer, ItemContainer, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';

const useOpenLocation: ActionRendererHookType = ({ nodes, useCallback }) => {
  const data = nodes[0].data as ItemContainer;
  const onClick = useDrilldownNavigation({ data, useCallback });
  const IconProps = data.getIcon(false);
  const Icon = IconProps?.icon;
  const fill = IconProps?.isColorable ? {} : { 'data-nofill': true };
  const newIcon = Icon && <Icon {...fill} />;
  const open = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (data.isRootSection) {
      ActionSeenStateList.value = [...ActionSeenStateList.value, 'OpenRootSection'];
    }
    onClick(e);
  };
  return {
    title: strings.lang.actionToolTip.open,
    icon: newIcon,
    onClick: open,
    isNew: data.isRootSection ? !ActionSeenStateList?.value?.includes?.('OpenRootSection') : false,
  };
};

export class OpenLocation extends BaseReadActionClass {
  readonly trackedName = 'Navigate';
  readonly useRenderer = useOpenLocation;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.length == 1 && isItemContainer(nodes[0].data.type);
  }
}
