import {
  AdminProvisioning,
  ComponentHeightState,
  NewLanguageSupport,
  WhatsNewMedia,
  ImprovedSettings,
} from '@storybook';
import React from 'react';

const hideBreakpoint = 400;
const sx = { maxWidth: 400, minWidth: 220 };

export default (activeStep: number, state: ComponentHeightState) =>
  [
    <WhatsNewMedia
      key="improvedSettingsMedia"
      hide={state.height <= hideBreakpoint}
      sx={{ borderRadius: 4 }}
      video={{
        controls: false,
        videoPoster: <ImprovedSettings style={{ width: '100%' }} />,
        videoSrc: 'https://harmoniedesktop.blob.core.windows.net/newharmonie/whats-new-settings.mp4',
      }}
      {...sx}
    />,
    <WhatsNewMedia
      key="newLanguageSupportMedia"
      hide={state.height <= hideBreakpoint}
      svgComponent={<NewLanguageSupport style={{ width: '100%' }} />}
      {...sx}
    />,
    <WhatsNewMedia
      key="adminProvisioningMedia"
      hide={state.height <= hideBreakpoint}
      svgComponent={<AdminProvisioning style={{ width: '100%' }} />}
      {...sx}
    />,
  ][activeStep];
