import { LanguageSetting } from '~/modules/Settings/SettingContext';
import { lightOrDark } from '../misc';
import { trackEvent } from './tracking';

export const sidebarInit = async () => {
  const { SharePointSitesRoot, TeamsRoot } = await import('../virtualRoots');
  trackEvent('SidebarInit', {
    newHarmonieLanguage: LanguageSetting.value,
    registeredSites: SharePointSitesRoot.Settings.value?.registeredItems?.length,
    registeredTeams: TeamsRoot.Settings.value?.registeredItems?.length,
    officeTheme: lightOrDark(Office.context.officeTheme?.bodyBackgroundColor) ? 'Dark' : 'Light',
    officeVersion: Office.context.diagnostics.version,
    officeLanguage: Office.context.displayLanguage.split('-')[0],
  });
};
