import { ContainerHandlers, EmptyState, ViewItem, emptyStateButton, emptyStateDrilldownButton } from '@storybook';
import React from 'react';
import { strings } from '@vendor';
import { EmptyStateItem, ItemData, RenderActionByHook } from '~/utilities';

interface EmptyStateProps {
  node: ViewItem<ItemData>;
  handlers: ContainerHandlers<ItemData>;
}

export const RenderEmptyState: React.FunctionComponent<EmptyStateProps> = ({ node, handlers }) => {
  const item = node.data as EmptyStateItem;
  const variant = item.isDrilldown ? emptyStateDrilldownButton : emptyStateButton;
  const renderedAction =
    item.location && item.action ? (
      <>
        <RenderActionByHook
          trigger={'Empty State'}
          action={item.action}
          nodes={[item.location]}
          actionVariant={variant}
          handlers={handlers}
        />
      </>
    ) : (
      ''
    );
  return (
    <EmptyState
      inTree={item.inTree}
      centredText={true}
      nestingLevel={item.nestingLevel}
      title={strings.lang.emptyStateTitle[item.name]}
      message={strings.lang.emptyStateMessage[item.name]}
      button={renderedAction}
      images={item.images}
      isSearchItem={item.isSearchItem}
      nobackground={item.noBackground}
      {...item.componentProps}
      isDrilldown={item.isDrilldown}
      isSaveableLocation={item.isSaveableLocation}
    />
  );
};
