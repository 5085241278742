import React, { useCallback } from 'react';
import { strings } from '@vendor';
import { Divider, Stack, Typography, useTheme } from '@storybook';
import { FormControlLabel, Radio, RadioGroup, Switch } from '@mui/material';
import { EmailHeaderMappingChoices } from '~/utilities';
import { getCurrentTimeString, hasOneHourPassed, logrocket } from '~/utilities/analytics/logrocket';
import { SettingsKeyControl } from './SettingsControls';
import { EnableLogsRecording, SettingsContextInterface } from './SettingContext';

const AdvancedSettings = ({ settings }: { settings: SettingsContextInterface }) => {
  const {
    editPropertiesMode,
    setEditPropertiesMode,
    emailHeaderMapping,
    setEmailHeaderMapping,
    emailNameSettings,
    setEmailNameSettings,
    enableTeams,
    setEnableLogsRecording,
    enableLogsRecording,
  } = settings;

  const uiTheme = useTheme();
  return (
    <Stack minHeight="100%" spacing={6} mb={7} mt={2}>
      <Stack>
        <SettingsKeyControl
          mb={2}
          title={strings.lang.userSetting.editPropertiesTitle}
          description={strings.lang.userSetting.editPropertiesDescription}
        />
        <RadioGroup
          aria-labelledby="open-document"
          name="open-document"
          value={editPropertiesMode}
          onChange={e => setEditPropertiesMode?.(e.target.value === 'true')}
        >
          <FormControlLabel
            value={true}
            sx={{ flexDirection: 'row-reverse', margin: 0 }}
            slotProps={{
              typography: {
                sx: { flex: 1 },
              },
            }}
            control={<Radio />}
            label={
              <Stack direction={'column'}>
                <Typography sx={{ flex: 1 }} variant="H2Regular">
                  {strings.lang.userSetting.editPropertiesInModal}
                </Typography>
              </Stack>
            }
          />
          <FormControlLabel
            sx={{ flexDirection: 'row-reverse', margin: 0 }}
            slotProps={{
              typography: {
                sx: { flex: 1 },
              },
            }}
            value={false}
            control={<Radio />}
            label={<Typography variant="H2Regular">{strings.lang.userSetting.editPropertiesInBrowser}</Typography>}
          />
        </RadioGroup>
      </Stack>
      <Stack>
        <SettingsKeyControl
          mb={2}
          title={strings.lang.userSetting.ehmSettingTitle}
          description={strings.lang.userSetting.ehmSettingDescription}
        />
        <RadioGroup
          aria-labelledby="open-document"
          name="ehm"
          value={emailHeaderMapping}
          onChange={e => setEmailHeaderMapping?.(e.target.value as EmailHeaderMappingChoices)}
        >
          <FormControlLabel
            value={EmailHeaderMappingChoices.TeamsAbsSharePoint}
            sx={{ flexDirection: 'row-reverse', margin: 0 }}
            slotProps={{
              typography: {
                sx: { flex: 1 },
              },
            }}
            control={<Radio />}
            label={
              <Typography sx={{ flex: 1 }} variant="H2Regular">
                {enableTeams
                  ? strings.lang.userSetting.ehmSettingCheckboxAllLocations
                  : strings.lang.userSetting.ehmSettingCheckboxAllLocationsNoTeams}
              </Typography>
            }
          />
          {enableTeams && (
            <FormControlLabel
              sx={{ flexDirection: 'row-reverse', margin: 0 }}
              slotProps={{
                typography: {
                  sx: { flex: 1 },
                },
              }}
              value={EmailHeaderMappingChoices.TeamsOnly}
              control={<Radio />}
              label={<Typography variant="H2Regular">{strings.lang.userSetting.ehmSettingCheckboxTeams}</Typography>}
            />
          )}
          <FormControlLabel
            sx={{ flexDirection: 'row-reverse', margin: 0 }}
            slotProps={{
              typography: {
                sx: { flex: 1 },
              },
            }}
            value={EmailHeaderMappingChoices.None}
            control={<Radio />}
            label={<Typography variant="H2Regular">{strings.lang.userSetting.ehmSettingCheckboxNone}</Typography>}
          />
        </RadioGroup>
      </Stack>
      <Divider sx={{ width: '100%', color: uiTheme.palette.texts.secondary }} />

      {/* Remove Category for nowe <Stack>
          <SettingsKeyControl mb={2} title={strings.lang.userSetting.addCategoryTitle} />
          <Typography mb={2} color={uiTheme.palette.texts.primary} variant="H1SemiBold">
          {strings.lang.userSetting.addCategoryTitle}
          </Typography>
          <FormControlLabel
          value={true}
          sx={{ flexDirection: 'row-reverse', margin: 0 }}
          slotProps={{
            typography: {
              sx: { flex: 1 },
              },
              }}
              control={<Switch checked={addCategory} onClick={() => changeAddCategory?.(!addCategory)} />}
              label={
                <Typography sx={{ flex: 1 }} variant="H2Regular" color={uiTheme.palette.texts.primary}>
                {strings.lang.userSetting.addCategoryDescription}
                </Typography>
                }
                />
                </Stack> */}
      <Stack>
        <SettingsKeyControl
          mb={2}
          title={strings.lang.userSetting.emailNameTitle}
          description={strings.lang.userSetting.emailNamesDescription}
        />
        <RadioGroup
          aria-labelledby="open-document"
          name="open-document"
          value={emailNameSettings}
          onChange={e => setEmailNameSettings?.(e.target.value === 'true')}
        >
          <FormControlLabel
            value={'false'}
            sx={{ flexDirection: 'row-reverse', margin: 0 }}
            slotProps={{
              typography: {
                sx: { flex: 1 },
              },
            }}
            control={<Radio />}
            label={
              <Typography sx={{ flex: 1 }} variant="H2Regular">
                {strings.lang.userSetting.emailNamesDefault}
              </Typography>
            }
          />
          <FormControlLabel
            sx={{ flexDirection: 'row-reverse', margin: 0 }}
            slotProps={{
              typography: {
                sx: { flex: 1 },
              },
            }}
            value={'true'}
            control={<Radio />}
            label={<Typography variant="H2Regular">{strings.lang.userSetting.emailNamesAdvanced}</Typography>}
          />
        </RadioGroup>
      </Stack>
      <Divider sx={{ width: '100%', color: uiTheme.palette.texts.secondary }} />
      <Stack>
        <Typography mb={2} color={uiTheme.palette.texts.primary} variant="H1SemiBold">
          {strings.lang.userSetting.supportTitle}
        </Typography>
        <FormControlLabel
          value={true}
          sx={{ flexDirection: 'row-reverse', margin: 0 }}
          slotProps={{
            typography: {
              sx: { flex: 1 },
            },
          }}
          control={
            <Switch
              checked={!hasOneHourPassed(enableLogsRecording)}
              onClick={useCallback(() => {
                const newState = !EnableLogsRecording.value || hasOneHourPassed(enableLogsRecording);
                setEnableLogsRecording?.(newState ? getCurrentTimeString() : '');
                logrocket(Office.context?.mailbox?.userProfile, newState);
              }, [enableLogsRecording, setEnableLogsRecording])}
            />
          }
          label={
            <Typography sx={{ flex: 1 }} variant="H2Regular">
              {strings.lang.userSetting.supportDescription}
            </Typography>
          }
        />
      </Stack>
    </Stack>
  );
};

export default AdvancedSettings;
