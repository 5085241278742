import React from 'react';
import { ReorderAToZMedium, ReorderZToAMedium, ViewItem } from '@storybook';
import { strings } from '@vendor';
import { ItemContainer, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';

const useReOrderAToZ: ActionRendererHookType = ({ nodes, useCallback }) => {
  const data = nodes[0].data as ItemContainer;
  const onClick = useCallback(async () => data.reorderItems(), [data]);
  const title = data.sortDir ? strings.lang.actionToolTip.reorderZ2A : strings.lang.actionToolTip.reorderA2Z;
  return {
    title: title,
    icon: data.sortDir ? <ReorderZToAMedium key="XMedium" /> : <ReorderAToZMedium />,
    onClick,
  };
};

export class ReOrderAToZAction extends BaseReadActionClass {
  readonly trackedName = 'Sort';
  readonly useRenderer = useReOrderAToZ;
  isQuickAction(data: ItemData, isTopBarItem: boolean): string | undefined {
    return isTopBarItem && data.canReorder ? 'only' : undefined;
  }
  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.length == 1 && nodes[0].data.canReorder;
  }
}
