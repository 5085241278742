import React, { useState, useMemo } from 'react';
import { Box, Stack } from '@mui/system';
import { Chip } from '@mui/material';
import { strings } from '@vendor';
import { NavigationItem } from '../NavigationItem';
import { ArrowSmall, RecentMedium, ArrowDownSmall, ArrowUpSmall } from '../../assets/icons';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { VirtualListRender } from '../VirtualList/VirtualList';
import { LottieAnimation } from '../Lottie';
import miniLoader from '../Lottie/Animations/mini-loader/lottie/Loader_loop.json';
import ChipBox from '../Chip/ChipBox';

export type Filter = {
  label: string;
  Icon: React.ReactElement;
  filterString?: string;
  entityTypes?: any[];
  type?: 'location' | 'document';
  enabled: boolean;
  newLine?: boolean;
};
export interface SearchProps {
  searchTerm?: string;
  filters?: string;
  entityTypes?: any[];
}

interface QuickSearchBodyProps {
  isSearching: boolean;
  searchTerm?: string;
  renderItems: any[];
  render: VirtualListRender;
  showResultOnClick: () => void;
  isSearchInit: boolean;
  activeFilter?: Filter;
  setFilters: (data: Partial<SearchProps>) => void;
  historyTitle: string;
  searchHistory: string[];
  filtersUI: Filter[];
  historyItemClick: (e: React.MouseEvent<Element, MouseEvent>, searchTerm: string) => void;
}

const LoadingState = () => (
  <Stack pb={5}>
    <LottieAnimation options={{ loop: true, autoplay: true, animationData: miniLoader }} height={50} width={100} />
  </Stack>
);

const RenderedItems: React.FC<QuickSearchBodyProps> = ({ renderItems, render, showResultOnClick }) => (
  <Stack p={2} alignItems="stretch">
    {renderItems.slice(0, 4).map((item, index) => (
      <span key={index}>{render({ id: item.id + index, data: item })}</span>
    ))}
    {renderItems.length > 4 && (
      <Stack direction="row" alignItems="center" sx={{ cursor: 'pointer' }}>
        <Button variant="text" onClick={showResultOnClick}>
          <Typography variant="CapsLockSemiBold">{strings.lang.actiosNames.showAllResults}</Typography>
          <ArrowSmall />
        </Button>
      </Stack>
    )}
  </Stack>
);

const FiltersWithHistory: React.FC<
  QuickSearchBodyProps & {
    appTypeFilters: Filter[];
    fileTypeFilters: Filter[];
  }
> = ({ appTypeFilters, fileTypeFilters, searchHistory, activeFilter, historyTitle, historyItemClick, setFilters }) => {
  const [collapsed, setCollapsed] = useState<boolean>(Boolean(searchHistory.length));
  return (
    <>
      {Boolean(appTypeFilters?.length) && Boolean(!activeFilter) && (
        <ChipBox label={strings.lang.quickSearch.apps}>
          {appTypeFilters.map(({ label, Icon, filterString: filters }, index) => (
            <Chip key={index} onClick={() => setFilters({ filters })} label={label} icon={Icon} />
          ))}
        </ChipBox>
      )}
      {searchHistory.length > 0 && Boolean(!activeFilter) && (
        <Stack>
          <Box p={2} pb={0}>
            <Typography color="texts.secondary" variant="H3Regular">
              {historyTitle}
            </Typography>
          </Box>
          <Stack p={2} pt={0} pb={0} spacing={0} alignItems="stretch">
            {searchHistory.map((historyItem, index) => (
              <NavigationItem
                isColorable
                key={index}
                Icon={RecentMedium}
                onClick={e => historyItemClick(e, historyItem)}
                name={historyItem}
                hasBreadcrumbs={false}
                sx={{ ml: -1, maxWidth: '100%' }}
                nestingLevel={-1}
                itemColorable={false}
              />
            ))}
          </Stack>
        </Stack>
      )}
      {fileTypeFilters && Boolean(!activeFilter) && (
        <Stack>
          <ChipBox label={strings.lang.quickSearch.filtersTitle} maxItem={collapsed ? 6 : undefined}>
            {fileTypeFilters.map(
              ({ enabled, label, Icon, filterString: filters, entityTypes = [], newLine }, index) =>
                enabled && (
                  <Chip
                    key={newLine ? 'newLine' : index}
                    onClick={() => setFilters({ filters, entityTypes })}
                    label={label}
                    icon={Icon}
                  />
                )
            )}
          </ChipBox>

          <Button
            sx={{ maxWidth: 'none', borderTopLeftRadius: 0, borderTopRightRadius: 0, mt: 2 }}
            fullWidth
            onClick={() => setCollapsed(!collapsed)}
            variant="QuickSearchFilters"
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              {collapsed ? strings.lang.actiosNames.showMoreFilters : strings.lang.actiosNames.showLessFilters}
              {collapsed ? <ArrowDownSmall /> : <ArrowUpSmall />}
            </Stack>
          </Button>
        </Stack>
      )}
    </>
  );
};

export const QuickSearchBody: React.FC<QuickSearchBodyProps> = props => {
  const { isSearching, searchTerm, renderItems, filtersUI } = props;

  const appTypeFilters = useMemo(() => filtersUI.filter(item => item.type === 'location' && item.enabled), [filtersUI]);
  const fileTypeFilters = useMemo(() => filtersUI.filter(item => item.type === 'document'), [filtersUI]);

  if (isSearching) {
    return <LoadingState />;
  }

  if (renderItems.length && searchTerm) {
    return <RenderedItems {...props} />;
  }

  return <FiltersWithHistory appTypeFilters={appTypeFilters} fileTypeFilters={fileTypeFilters} {...props} />;
};
