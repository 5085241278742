import { ShareWithMeEmptyStateDark, ShareWithMeEmptyStateLight, SharedWithMeMedium, IconProps } from '@storybook';
import { DriveItem, SharedInsight } from '@microsoft/microsoft-graph-types';
import { GValue, RecentExpirationWindow, GraphBatchRequest } from '@services';
import { strings } from '@vendor';
import { FilterOffEmailAttachments, getSortByDate, InsightItem, InsightTypedFilter } from '../graphTypes';
import { FetchChildrenProps, ItemData, ItemDataWithPaging, MicrosoftApps } from '../itemTypes';
import { EmptyStateItem } from '../itemTypes';
import { SPFolder, SPList, SPSite } from '../sharePointTypes';
import { OfficeUserSettingManager } from '../stateManager';
import { encodeSpecialCharacters, getFolderOdataIdFromUrl, getLibraryOdataIdFromListId } from '../sharePointAPI';
import { RecentRoot } from './RecentRoot';
import { BaseRootSectionItem } from './RootSectionItem';
import { ViewFilesItem } from '../itemTypes/ViewFilesItem';

export class SharedWithMeRoot extends BaseRootSectionItem {
  static readonly SharedCountState = new OfficeUserSettingManager('SharedCountState', () => 5);
  static readonly SharedFilterState = new OfficeUserSettingManager<boolean>('SharedFilterState', () => false);

  constructor() {
    super({ type: 'sharedwithmeroot', id: 'root', name: strings.lang.nodeNames.sharedWithMe });
    this.rootPrefix = 'shaerdwithme:';
  }

  get stateCount() {
    return SharedWithMeRoot.SharedCountState;
  }
  override get supportSearch(): boolean {
    return false;
  }
  get quickSearchPlaceholder(): string {
    return strings.lang.quickSearch.sharedWithMe;
  }
  get filterState() {
    return SharedWithMeRoot.SharedFilterState;
  }
  get serverRelativeUrl(): string {
    return '';
  }
  override getNumberOfQuickActions(): number {
    return 1;
  }

  override applyEmptyState(items: ItemData[], isDrilldown?: boolean): ItemData[] {
    return new EmptyStateItem({
      name: 'sharedwithme',
      inTree: true,
      images: { light: ShareWithMeEmptyStateLight, dark: ShareWithMeEmptyStateDark },
      isDrilldown,
    }).apply(items, items.length === 0);
  }

  override async fetchChildren({ gcl, refreshStamp, isDrilldown }: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const { account, encodedUsername } = RecentRoot.getAccountAndName(gcl);
    const gb = new GraphBatchRequest(gcl.client);
    const count = isDrilldown ? 100 : 25;
    const sharedFilter = SharedWithMeRoot.SharedFilterState.value
      ? InsightTypedFilter
      : InsightTypedFilter + FilterOffEmailAttachments;
    // get documents that shared with me
    const sharedFromInsight = await gb
      .api('/me/insights/shared')
      .cache(RecentExpirationWindow, refreshStamp)
      .filter(`${sharedFilter} AND (lastShared/sharedBy/address ne '${encodeSpecialCharacters(encodedUsername)}')`)
      .top(count)
      .get();

    // get folders that shared with me
    const sharedFolders = await gb
      .api('me/drive/sharedWithMe')
      .cache(RecentExpirationWindow, refreshStamp)
      .top(count)
      .get();

    await gb.runAll();
    const { value: sharedItems }: GValue<SharedInsight> = await sharedFromInsight();
    const { value: sharedFoldersDriveApi }: GValue<DriveItem> = await sharedFolders();

    const sharedFoldersList = sharedFoldersDriveApi
      .filter(v => v.folder != null)
      .slice(0, count)
      .map(f => f.remoteItem)
      .map(f => {
        const spIds = f?.sharepointIds;
        const siteUrl = new URL(spIds?.siteUrl || '');
        const libName = decodeURIComponent(f?.webUrl?.replace(siteUrl.href, '').split('/')[1] || '');
        const folderUrl = new URL(f?.webDavUrl || '');
        const decodedFolderPath = decodeURIComponent(folderUrl.pathname);
        const folder = new SPFolder({
          metadata: {
            id: getFolderOdataIdFromUrl(siteUrl.href, decodedFolderPath),
            FileRef: decodedFolderPath,
            FileLeafRef: f?.name || '',
            UniqueId: f?.sharepointIds?.listItemUniqueId || '',
            Modified: new Date(f?.lastModifiedDateTime || ''),
          },
          list: new SPList({
            name: libName,
            id: getLibraryOdataIdFromListId(spIds?.siteUrl, spIds?.listId),
            serverRelativeUrl: `${siteUrl.pathname}/${libName}`,
            site: SPSite.fromSharepointIds(spIds, MicrosoftApps.SharePoint),
            sharepointListId: spIds?.listId || '',
            OfficeAppLocated: MicrosoftApps.SharePoint,
          }),
        });
        folder.setOwnPrefix();
        return {
          folder,
          date: f?.shared?.sharedDateTime || '',
        };
      });
    const sharedFromInsightItems = sharedItems
      .filter(item => item.resourceReference?.type !== 'microsoft.graph.entity')
      .map(item => InsightItem.fromInsight(item, account));

    const resultCount = isDrilldown ? count : SharedWithMeRoot.SharedCountState.value;
    const res = [...sharedFoldersList, ...sharedFromInsightItems].sort(getSortByDate()).slice(0, resultCount);
    const folderToShow: Array<SPFolder> = [];
    const documentsToShow: Array<InsightItem> = [];

    res.forEach(item => {
      if (item instanceof InsightItem) documentsToShow.push(item);
      else folderToShow.push(item.folder);
    });

    // Resove insight items in BG for copy address in Mac
    InsightItem.resolveSharepointIds(gcl, documentsToShow);
    const items = [...folderToShow, ...documentsToShow].map(v => {
      v.isTransientLocation = true;
      return v;
    });
    return {
      items: this.applyEmptyState(
        new ViewFilesItem({ name: strings.lang.nodeNames.viewAllFiles, parent: this }).apply(
          items,
          resultCount,
          !isDrilldown
        ),
        isDrilldown
      ),
    };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: SharedWithMeMedium, isColorable: true };
  }
}
