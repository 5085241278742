import { InsightIdentity } from '@microsoft/microsoft-graph-types';
import { IconProps, UsageType, getFileIcon } from '@storybook';
import { GraphClient } from '@services';
import { formatDateByTime, getFriendlyDateDisplay } from '@vendor';
import { formatBytes, oDirname } from '@vendor';
import { strings } from '@vendor';
import { LanguageSetting } from '~/modules/Settings/SettingContext';
import { InsightItem } from '../graphTypes';
import {
  DocumentAccessUrls,
  BaseItem,
  BaseItemProps,
  DocumentItem,
  DownloadData,
  SupportedOfficeAppTypes,
  ThumbnailData,
  treeitemFirstParent,
  ShareableItem,
} from '../itemTypes';
import { isUrlItemData } from '../misc';
import { SPFolder } from './SPFolder';
import {
  DEFAULT_FIELDS,
  SPCUser,
  SharePointClient,
  encodeSpecialCharacters,
  getDocumentOdataIdFromId,
  getDocumentOdataIdFromUrl,
  getDriveIdFromUrl,
} from '../sharePointAPI';
import { LibraryItem } from '../itemTypes/LibraryItem';
import { SharePointFieldType } from '../metadata/SharePointFieldType';
import { ListView } from '../itemTypes/ListView';
import { EmptyListView } from './SPListViewFactory';

export interface SPMetadata extends Omit<BaseItemProps, 'name' | 'id'> {
  ContentTypeId?: string;
  FileRef: string;
  FileLeafRef: string;
  UniqueId: string;
  Modified: Date;
  File_x0020_Size?: number;
  user?: SPCUser;
  [key: string]: any;
}

export class SPDocument extends BaseItem implements DocumentItem, LibraryItem, ShareableItem {
  private readonly _parent: SPFolder;
  public metadata: SPMetadata;

  //TODO use metadata for the getters
  constructor(parent: SPFolder, metadata: SPMetadata, isNew?: boolean) {
    super({
      id: getDocumentOdataIdFromUrl(parent.list.siteUrl, metadata.FileRef || ''),
      type: 'document',
      name: metadata.FileLeafRef,
    });
    this._parent = parent;
    this.metadata = metadata;
    this.isNew = isNew;
  }

  get hasPreview(): boolean {
    return true;
  }

  protected override fillJson(res: SPMetadata): void {
    super.fillJson(res);
    res.ContentTypeId = this.metadata.ContentTypeId;
    res.parent = this.parent.toJson();
    res.Modified = this.metadata.Modified;
    res.FileRef = this.serverRelativeUrl;
    res.FileLeafRef = this.metadata.FileLeafRef;
    res.user = this.metadata.Editor?.[0] || this.metadata?.Author?.[0] || this.metadata?.user;
    res.UniqueId = this.metadata.UniqueId;
    res.ID = this.metadata.ID;
  }

  get OfficeAppLocated() {
    return this.list.OfficeAppLocated;
  }
  get parent() {
    return this._parent;
  }
  get list() {
    return this.parent.list;
  }
  override get isDocument(): boolean {
    return true;
  }
  get isLibraryItem(): boolean {
    return this.itemFirstParent !== treeitemFirstParent.Favorite;
  }
  get canRemove() {
    return this.isLibraryItem;
  }
  get allowEdit(): boolean {
    return true;
  }
  get canOpenInBrowser() {
    return true;
  }
  get mediaType() {
    return getFileIcon(this.metadata.FileLeafRef).media;
  }
  get officeApp(): SupportedOfficeAppTypes | undefined {
    return InsightItem.getOfficeType(this.mediaType);
  }
  get subject() {
    return undefined;
  }

  get pathOrDescription(): string {
    let path = oDirname(this.serverRelativeUrl.replace('/sites', ''));
    if (this.OfficeAppLocated === 'Teams') path = path.replace('/Shared Documents', '');
    if (this.OfficeAppLocated === 'OneDrive') {
      return `${`${this.OfficeAppLocated}${path.replace(
        `${this.list.siteUrl.replace(`${this.rootSite}`, '')}/Documents`,
        ''
      )}`}`;
    }
    return `${`${this.OfficeAppLocated}${path}`}`;
  }

  get secondLineContent(): string | null {
    const appName = this.OfficeAppLocated === 'SP' ? 'SharePoint' : this.OfficeAppLocated;
    if (this.OfficeAppLocated === 'OneDrive') {
      return `${appName} (${
        encodeSpecialCharacters(this.parent.serverRelativeUrl)?.split('/personal')[1].split('/')[1].split('_')[0]
      }) • ${this.parent.name} • ${formatDateByTime(this.metadata.Modified, strings.lang.dateFormat)}`;
    }
    return `${appName} • ${this.parent.name} • ${formatDateByTime(this.metadata.Modified, strings.lang.dateFormat, LanguageSetting.value)}`;
  }

  async removeItem(gcl: GraphClient) {
    const spc = new SharePointClient(gcl, this.rootSite);
    await spc.api(`${this.apiId}/recycle()`).post(null);
  }

  async rename(gcl: GraphClient, newName: string): Promise<LibraryItem> {
    const addressUrl = this.parent.list.rootSite + this.serverRelativeUrl;
    const newRelative = `${oDirname(this.serverRelativeUrl)}/${newName}`;
    const newUrl = this.parent.list.rootSite + newRelative;
    await this.list.site.moveFile(gcl, addressUrl, newUrl);
    this.metadata.FileLeafRef = newName;
    this.metadata.FileRef = newRelative;
    return new SPDocument(this.parent, this.metadata);
  }

  get serverRelativeUrl(): string {
    return this.metadata.FileRef || '';
  }

  async getAccessUrls(gcl: GraphClient): Promise<DocumentAccessUrls> {
    const downloadUrl = `${getDocumentOdataIdFromId(
      this.list.siteUrl,
      this.metadata.UniqueId
    )}/$value?binaryStringResponseBody=true`;
    if (isUrlItemData(this)) {
      // Handle specifically .url files by reading them & returning the link inside them...
      const blobData = await this.download(gcl, downloadUrl);
      const text = await blobData.blob.text();
      const urlLine = text.match(/^\s*URL\s*=\s*(.*)\s*$/m);
      if (urlLine !== null) return { webUrl: urlLine[1], downloadUrl };
    }
    const canView = isUrlItemData(this) || InsightItem.hasWebViewer(this.mediaType);
    const app = this.officeApp ? 'Doc.aspx' : canView ? 'Viewer.aspx' : 'Viewer.aspx';
    const listId = await this.list.getSharePointListId(gcl);
    const account = gcl.msal.getActiveAccount() || gcl.msal.getAllAccounts()[0];
    return {
      downloadUrl,
      webUrl: `${this.parent.list.siteUrl}/_layouts/15/${app}?sourceDoc=${this.metadata.UniqueId}`,
      appUrl: InsightItem.getAppUrl(this.officeApp, this.webUrl),

      odOpenUrl: InsightItem.generateODOpenURL(
        this.metadata.UniqueId,
        this.list.site.id,
        listId,
        this.list.siteUrl,
        this.name,
        account?.homeAccountId,
        account?.username
      ),
      address: this.serverRelativeUrl.includes('aspx')
        ? `${this.parent.list.siteUrl}/_layouts/15/${app}?sourceDoc=${this.metadata.UniqueId}`
        : this.parent.list.rootSite + encodeSpecialCharacters(this.serverRelativeUrl),
    };
  }

  async getDetailsUrlByType(type: string): Promise<string> {
    const typeMapper = {
      edit: 'EditForm',
      view: 'DispForm',
    };
    return `${this.rootSite}${this.parent.list.serverRelativeUrl}/Forms/${typeMapper[type]}.aspx?ID=${this.metadata.ID}`;
  }

  async download(gcl: GraphClient, dUrl?: string): Promise<DownloadData> {
    dUrl = dUrl || (await this.getAccessUrls(gcl)).downloadUrl;
    const spc = new SharePointClient(gcl, this.rootSite);
    const fetchResult = await spc.api(dUrl).getStream();
    return { blob: await fetchResult.blob(), fileName: this.metadata.FileLeafRef };
  }

  get webUrl() {
    return this.rootSite + encodeSpecialCharacters(this.serverRelativeUrl);
  }

  get date(): string {
    return this.metadata.Modified?.toString();
  }

  get user(): InsightIdentity | undefined {
    const user = this.metadata.Editor?.[0] || this.metadata.Author?.[0] || this.metadata?.user;
    return (
      user && {
        address: user.email,
        displayName: user.title,
      }
    );
  }

  get usageType(): UsageType | undefined {
    return 'edited';
  }

  get rootSite(): string {
    return this.parent.rootSite;
  }

  async getThumbnail(gcl: GraphClient): Promise<ThumbnailData | undefined> {
    return await InsightItem.getThumbnail(gcl, getDriveIdFromUrl(this.webUrl), this.metadata.FileLeafRef);
  }

  override getIcon(_expanded: boolean): IconProps {
    return getFileIcon(this.metadata.FileLeafRef);
  }

  async resolve(gcl: GraphClient, view?: ListView): Promise<DocumentItem> {
    if (!view) view = new EmptyListView().combineWithFields(DEFAULT_FIELDS);
    return (await this.list.resolveByID(gcl, view, this.metadata.ID)) as DocumentItem;
  }

  //TODO -> Define a typed values with render method
  public getProperty(name: string, type: SharePointFieldType): any {
    if (type === SharePointFieldType.Computed && name === 'FileSizeDisplay') {
      const fileSize = Number(this.metadata.File_x0020_Size);
      return formatBytes(fileSize);
    }
    if (type === SharePointFieldType.DateTime) {
      const friendlyDisplayValue = this.metadata[`${name}.FriendlyDisplay`];
      if (friendlyDisplayValue !== undefined && friendlyDisplayValue !== '') {
        return getFriendlyDateDisplay(this.metadata[`${name}`], LanguageSetting.value);
      }
    }
    return this.metadata[name];
  }
}
