import React, { ReactNode } from 'react';
import {
  ViewItemRenderProps,
  TreeViewRenderNodeProps,
  ViewItemRenderer,
  ViewItemTableRenderer,
  RowTableRenderProps,
} from '@storybook';
import { AllowedItemType, ByItemType, ItemData } from '~/utilities';
import { byTypeItemRenderer, byTypeTreeRenderer } from './ByNodeTypeRenderer';
import { drilldownHandler } from './DrilldownHandler';
import { itemLinePainter } from './ItemLineDecorator';
import { RenderViewItem } from './RenderViewItem';
import { itemSelectionHandler } from './SelectionHandler';
import { transientNodeRenderer } from './TransientNodeRenderer';
import { treeExpandHandler } from './ExpandHandler';
import { searchItemHandler } from './SearchItemHandler';
import { renderDragAndDrop } from './DragAndDropHandler';
import { RowTableRender } from './RowTableRender';

export type RenderNodeProps = TreeViewRenderNodeProps;
export type TreeNodeRenderer = React.FC<RenderNodeProps>;
export type ItemDataRenderProps = ViewItemRenderProps<ItemData>;
export type TableRowRenderProps = RowTableRenderProps<ItemData>;
export type ItemDataRenderer = ViewItemRenderer<ItemData>;
export type RowTableRenderer = ViewItemTableRenderer<ItemData>;

export interface RenderNodeWithNextProps extends RenderNodeProps {
  nextRenderer: TreeNodeRenderer;
}
export interface RenderNodeWithNextDragAndDropProps extends ItemDataRenderProps {
  nextRenderer: ItemDataRenderer;
  type: AllowedItemType;
}

export interface RenderRowTableProps {
  nextRenderer: ItemDataRenderer;
  type: AllowedItemType;
  children: ReactNode;
}

export interface ItemDataRenderWithNextProps extends ItemDataRenderProps {
  nextRenderer: ItemDataRenderer;
}

const defaultRenderer: ItemDataRenderer = (props: ItemDataRenderProps) => <RenderViewItem {...props} />;
const tableRenderer: RowTableRenderer = (props: TableRowRenderProps) => <RowTableRender {...props} />;
const defaultSearchRenderer: ItemDataRenderer = (props: ItemDataRenderProps) => (
  <RenderViewItem only3DotsAction {...props} />
);

const viewItemRenderersByType = {} as ByItemType<ItemDataRenderer>;
const viewSearchItemRenderersByType = {} as ByItemType<ItemDataRenderer>;
const viewItemTableRenderersByType = {} as ByItemType<RowTableRenderer>;

export const getViewItemRenderer = (type: AllowedItemType) => {
  if (!viewItemRenderersByType[type]) {
    const factories = [byTypeItemRenderer, itemSelectionHandler, drilldownHandler, renderDragAndDrop];
    viewItemRenderersByType[type] = factories.reduce<ItemDataRenderer>((prev, v) => v(type, prev), defaultRenderer);
  }
  return viewItemRenderersByType[type];
};
export const getViewRowTableRenderer = (type: AllowedItemType) => {
  if (!viewItemTableRenderersByType[type]) {
    const factories = [byTypeItemRenderer, itemSelectionHandler, drilldownHandler, renderDragAndDrop];
    viewItemTableRenderersByType[type] = factories.reduce<RowTableRenderer>(
      (prev, v) => v(type, prev as ItemDataRenderer),
      tableRenderer
    );
  }
  return viewItemTableRenderersByType[type];
};

export const getSearchViewItemRenderer = (type: AllowedItemType) => {
  if (!viewSearchItemRenderersByType[type]) {
    const factories = [
      searchItemHandler,
      itemSelectionHandler,
      byTypeItemRenderer,
      drilldownHandler,
      renderDragAndDrop,
    ];
    viewSearchItemRenderersByType[type] = factories.reduce<ItemDataRenderer>(
      (prev, v) => v(type, prev),
      defaultSearchRenderer
    );
  }

  return viewSearchItemRenderersByType[type];
};

// We need to remove the hyper tree onSelect listener since it interferes with context menu selection.
const removeTreeSelectionListener = (_type: AllowedItemType, next: TreeNodeRenderer): TreeNodeRenderer => {
  return props => next({ ...props, onSelect: () => null });
};

const treeNodeRendererByType = {} as ByItemType<TreeNodeRenderer>;
export const getTreeNodeRenderer = (type: AllowedItemType) => {
  if (!treeNodeRendererByType[type]) {
    const nextRenderer = getViewItemRenderer(type);
    const factories = [
      byTypeTreeRenderer,
      treeExpandHandler,
      itemLinePainter,
      transientNodeRenderer,
      removeTreeSelectionListener,
    ];
    treeNodeRendererByType[type] = factories.reduce<TreeNodeRenderer>(
      (prev, v) => v(type, prev),
      nextRenderer as any as TreeNodeRenderer
    );
  }
  return treeNodeRendererByType[type];
};
