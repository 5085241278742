import React from 'react';
import { Stack, Typography, StackProps } from '@mui/material';

export interface WhatsNewHeaderProps extends Omit<StackProps, 'title'> {
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
}

export const WhatsNewHeader = ({ title, subTitle, ...rest }: WhatsNewHeaderProps) => {
  return (
    <Stack spacing={2} {...rest}>
      {typeof title === 'string' ? (
        <Typography variant="OnBoardingBoldTitle" color="texts.primary">
          {title}
        </Typography>
      ) : (
        title
      )}
      {typeof subTitle === 'string' ? (
        <Typography variant="H2Regular" color="texts.primary">
          {subTitle}
        </Typography>
      ) : (
        subTitle
      )}
    </Stack>
  );
};
