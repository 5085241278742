import { styled, useTheme } from '@mui/material';
import {
  Box,
  ChannelSmall,
  ErrorSmall,
  FolderSmall,
  GenericSmall,
  LibrarySmall,
  MailSmall,
  NotificationSuccesseMedium,
  PersonSmall,
  SiteSamll,
  TeamSmall,
  XMedium,
} from '@harmon.ie-ce/storybook';
import React from 'react';

export const CustomIcon = (Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>, secondary?: boolean) => {
  return styled(Icon)`
    path {
      fill: ${({ theme }) => (secondary ? theme.palette.texts.secondary : theme.palette.texts.primary)};
    }
  `;
};
export const CustomMailIcon = CustomIcon(MailSmall);
export const CustomSiteIcon = CustomIcon(SiteSamll);
export const CustomTeamIcon = CustomIcon(TeamSmall);
export const CustomFolderIcon = CustomIcon(FolderSmall);
export const CustomChannelIcon = CustomIcon(ChannelSmall);
export const CustomLibraryIcon = CustomIcon(LibrarySmall);
export const CustomPersonIcon = CustomIcon(PersonSmall);
export const CustomGenericIcon = CustomIcon(GenericSmall);
export const CustomRemoveIcon = styled(XMedium)`
  path {
    fill: ${({ theme }) => theme.palette.status.dangerPrimary};
  }
`;
export const CustomErrorIcon = styled(ErrorSmall)`
  path {
    fill: ${({ theme }) => theme.palette.status.dangerPrimary};
  }
`;

export const NotificationsToastsSuccessIcon = () => {
  return (
    <Box mt={1}>
      <NotificationSuccesseMedium />
    </Box>
  );
};

export const CustomColoredIcon = (Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>, color: string) => {
  return styled(Icon)`
    path {
      fill: ${color};
    }
  `;
};
export const CreateIconComponents = ({
  icon,
  isColorable,
}: {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined;
  isColorable?: boolean;
}) => {
  const theme = useTheme();
  if (!icon) {
    return <></>; // Handle undefined icon
  }
  const Icon = icon;
  const FinalIcon = isColorable ? CustomColoredIcon(Icon, theme.palette.texts.quaternary) : Icon;
  return FinalIcon ? <FinalIcon sx={{ mb: '2px' }} /> : <></>;
};
