import React, { useState } from 'react';
import axios from 'axios';
import { AppConfig } from '@services';
import { logError } from '@vendor';
import { useSubscribe } from '~/hooks';
import { EnableDataCollection, SidebarInitKey } from '~/utilities/analytics/tracking';
import { LicenseContext } from './LicenseContext';

const b64EncodeUnicode = (str: string) =>
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(parseInt(p1, 16))));

export const LicenseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLicenseEnabled, setIsLicenseEnabled] = useState<boolean>();

  useSubscribe(SidebarInitKey, async () => {
    if (isLicenseEnabled !== undefined || !EnableDataCollection.value) return; // Do this once!

    const auth = {
      username: 'user1@harmon.ie',
      password: 'LYKLSiADSl',
    };

    const email = Office.context?.mailbox?.userProfile?.emailAddress;
    if (!email) return;
    const productName = 'New harmon.ie';
    const buildVersion = AppConfig.getInstance().version;

    try {
      const body = {
        email,
        product_name: productName,
        build_version: buildVersion,
        free: false,
        uuid: `${b64EncodeUnicode(email)}|${productName}`,
        usage_data_delta: 0,
        links_delta: 0,
        additional_data: {
          timestamp: new Date().toISOString(),
        },
      };

      const response = await axios.post('https://status.harmon.ie/status', body, {
        auth,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });

      const licenseData = response.data;
      setIsLicenseEnabled(licenseData.status === 'enabled');
    } catch (error) {
      setIsLicenseEnabled(true);
      logError(error);
    }
  });

  return (
    <LicenseContext.Provider value={{ isLicenseEnabled: isLicenseEnabled !== false }}>
      {children}
    </LicenseContext.Provider>
  );
};
