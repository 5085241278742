import { ShowNotificationFunction } from '@harmon.ie-ce/storybook/src/contexts/NotificationsContext';
import { IndicatorRequestHandler } from '@storybook';
import React, { createContext, useContext } from 'react';
import { useSafeCallbackType, VoidPromiseFunc } from '~/hooks';
import { UploadItemProvider } from '~/utilities/uploadItem/UploadItemProvider';

export interface BeforeUploadStep {
  execute: (uploadItemProvider: UploadItemProvider[]) => Promise<UploadItemProvider[]>;
  stepUi?: React.ReactElement;
}

export interface UploadStep {
  execute: (
    uploadItemProvider: UploadItemProvider,
    index: number,
    actionsRef: any,
    isMultipleUploads: boolean,
    progressHandler?: IndicatorRequestHandler,
    showNotification?: ShowNotificationFunction
  ) => Promise<void>;
  clean: () => void;
  stepUi?: React.ReactElement;
}
export interface UploadContextProps {
  startUpload?: Parameters<useSafeCallbackType>;
  beforeUpload?: (
    items: UploadItemProvider[],
    actionRef: React.MutableRefObject<any>,
    upload: VoidPromiseFunc
  ) => Promise<void>;
}

export const UploadContext = createContext<UploadContextProps>({});

export default UploadContext;

export const useUpload = (): UploadContextProps => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error('useUpload must be used within an UploadProvider');
  }
  return context;
};
