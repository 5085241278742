import { ChannelMedium, IconProps, PrivateChannelMedium } from '@storybook';
import { ChannelMembershipType, DriveItem, SharepointIds, Channel as GChannel } from '@microsoft/microsoft-graph-types';
import { keyBy as _keyBy, values as _values } from 'lodash';
import {
  GraphClient,
  GraphWebUrlKeys,
  EternalWindow,
  GraphSharePointIds,
  GraphParentRefAndWebUrlKeys,
  TeamExpirationWindow,
  GraphChannelKeys,
  GValue,
  runBatchOnList,
} from '@services';
import { oDirname } from '@vendor';
import {
  AccessUrls,
  BaseContainer,
  BaseItemProps,
  FetchChildrenProps,
  ItemDataWithPaging,
  MicrosoftApps,
} from '../itemTypes';
import { SPFolder, SPList, SPSite } from '../sharePointTypes';
import { Team } from './Team';
import { LocalStateManager } from '../stateManager';
import { TeamsRoot } from '../virtualRoots';
import { getLibraryOdataIdFromListId } from '../sharePointAPI';
import { createItemDataFromJson } from '../misc';

export interface ChannelProps extends BaseItemProps {
  team: Team;
  membershipType: ChannelMembershipType;
}

export class Channel extends BaseContainer {
  membershipType: ChannelMembershipType;
  private team: Team;
  rootFolder?: SPFolder;
  static avialbleChannels = new LocalStateManager<ChannelProps[] | null>('avialbleChannels', () => null);

  constructor(props: ChannelProps) {
    super({ ...props, type: 'channel' });
    this.team = props.team;
    this.membershipType = props.membershipType;
  }

  protected fillJson(res: ChannelProps & { team: string }): void {
    super.fillJson(res);
    res.team = this.team.toJson();
    res.membershipType = this.membershipType;
  }

  override async getFolder(gcl: GraphClient) {
    if (!this.rootFolder) this.rootFolder = await this.getChannelFolder(gcl);
    return this.rootFolder;
  }

  override get isTeamsEntity(): boolean {
    return true;
  }

  get OfficeAppLocated() {
    return MicrosoftApps.Teams;
  }

  static async getAllChannels(gcl: GraphClient) {
    if (!Channel.avialbleChannels.value) {
      await Channel.setAllChannels(gcl);
    }
    return (Channel.avialbleChannels.value || []).map(p => createItemDataFromJson(p) as Channel);
  }

  override get parent(): Team {
    return this.team;
  }

  static async setAllChannels(gcl: GraphClient): Promise<void> {
    const allTeams = (await TeamsRoot.getAllTeams(gcl)).map(team => new Team(team));
    const fetchedChannels = await runBatchOnList<Team, GValue<GChannel>>(
      gcl,
      allTeams,
      async (gb, t) =>
        await gb
          .api(`/teams/${t.apiId}/channels`)
          .cache(TeamExpirationWindow, Date.now() - 24 * 60 * 60 * 1000)
          .select(GraphChannelKeys)
          .get()
    );
    const allAvailableChannels = allTeams.reduce(
      (allChannels, team, i) => [
        ...allChannels,
        ...fetchedChannels[i].value.map(
          channel =>
            new Channel({
              name: channel.displayName || '',
              id: channel.id || '',
              team,
              membershipType: channel.membershipType || 'standard',
            })
        ),
      ],
      [] as Channel[]
    );
    this.avialbleChannels.value = _values(
      _keyBy(
        allAvailableChannels.filter(v => v.id),
        'id'
      )
    ).map(c => c.toJson());
  }

  override async getAccessUrls(gcl: GraphClient): Promise<AccessUrls | undefined> {
    // TODO: batch
    const channelFolder = await this.getFolder(gcl);

    const acccessUrl = await channelFolder.getAccessUrls();
    const channel = await gcl
      .api(`teams/${this.team.apiId}/channels/${this.apiId}`)
      .cache(EternalWindow)
      .select(GraphWebUrlKeys)
      .get();
    return { webUrl: acccessUrl?.webUrl || '', appUrl: channel.webUrl };
  }

  override get hasFolder(): boolean {
    return true;
  }

  override get rootSite(): string {
    return this.rootFolder?.rootSite || '';
  }

  get serverRelativeUrl(): string {
    return this.rootFolder?.serverRelativeUrl || '';
  }

  private async getChannelFolder(gcl: GraphClient) {
    const channelFolder: DriveItem = await gcl
      // No cache. The channel URL doesn't seem to expire...
      .api(`/teams/${this.team.apiId}/channels/${this.apiId}/filesFolder`)
      .cache(EternalWindow)
      .select(GraphParentRefAndWebUrlKeys)
      .get();

    const spIds: SharepointIds = await gcl
      .api(`/drives/${channelFolder.parentReference?.driveId}/items/${channelFolder.id}/${GraphSharePointIds}`)
      .cache(EternalWindow)
      .get();
    const domain = new URL(channelFolder.webUrl || '');
    const decodedPathname = decodeURIComponent(domain.pathname);

    const channelList = new SPList({
      name: 'Documents',
      id: getLibraryOdataIdFromListId(spIds?.siteUrl, spIds?.listId),
      serverRelativeUrl: oDirname(decodedPathname),
      site: SPSite.fromSharepointIds(spIds, MicrosoftApps.Teams),
      sharepointListId: spIds.listId || '',
      OfficeAppLocated: MicrosoftApps.Teams,
    });
    const folder = new SPFolder({
      list: channelList,
      metadata: {
        FileRef: decodedPathname,
        UniqueId: spIds?.listItemUniqueId || '',
        Modified: new Date(),
        FileLeafRef: this.name,
      },
    });
    return folder;
  }
  get pathOrDescription(): string {
    return `${`${MicrosoftApps.Teams}/${this.team.name}`}`;
  }
  get secondLineContent(): string | null {
    return `${MicrosoftApps.Teams} • ${this.team.name}`;
  }

  override async fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const folder = await this.getFolder(props.gcl);
    return await folder.fetchChildren(props, this);
  }

  override getIcon(_expanded: boolean): IconProps {
    if (this.membershipType === 'private') return { icon: PrivateChannelMedium, isColorable: true };
    return { icon: ChannelMedium, isColorable: true };
  }
}
