export { ReactComponent as Illustration } from './Illustration OB.svg';
export { ReactComponent as SmallIllustration } from './Small Illustration OB.svg';
export { ReactComponent as HarmonieIllustration } from './harmonie.svg';

// Empty state images
export { ReactComponent as FavoriteEmptyStateLight } from './Empty state/Light/FavoriteEmptyStateLight.svg';
export { ReactComponent as RecentEmptyStateLight } from './Empty state/Light/RecentEmptyStateLight.svg';
export { ReactComponent as ShareWithMeEmptyStateLight } from './Empty state/Light/ShareWithMeEmptyStateLight.svg';
export { ReactComponent as TeamsEmptyStateLight } from './Empty state/Light/TeamsEmptyStateLight.svg';
export { ReactComponent as SharePointEmptyStateLight } from './Empty state/Light/SharePointEmptyStateLight.svg';
export { ReactComponent as OneDriveEmptyStatLight } from './Empty state/Light/OneDriveEmptyStateLight.svg';
export { ReactComponent as LocationEmptyStateLight } from './Empty state/Light/LocationEmptyStateLight.svg';
export { ReactComponent as FolderEmptyStateLight } from './Empty state/Light/FolderEmptyStateLight.svg';
export { ReactComponent as SearchEmptyStateLight } from './Empty state/Light/SearchEmptyStateLight.svg';
export { ReactComponent as QuickSearchEmptyStateLight } from './Empty state/Light/QuickSearchEmptyStateLight.svg';
export { ReactComponent as FavoriteEmptyStateDark } from './Empty state/Dark/FavoriteEmptyStateDark.svg';
export { ReactComponent as RecentEmptyStateDark } from './Empty state/Dark/RecentEmptyStateDark.svg';
export { ReactComponent as ShareWithMeEmptyStateDark } from './Empty state/Dark/ShareWithMeEmptyStateDark.svg';
export { ReactComponent as TeamsEmptyStateDark } from './Empty state/Dark/TeamsEmptyStateDark.svg';
export { ReactComponent as SharePointEmptyStateDark } from './Empty state/Dark/SharePointEmptyStateDark.svg';
export { ReactComponent as OneDriveEmptyStateDark } from './Empty state/Dark/OneDriveEmptyStateDark.svg';
export { ReactComponent as LocationEmptyStateDark } from './Empty state/Dark/LocationEmptyStateDark.svg';
export { ReactComponent as FolderEmptyStateDark } from './Empty state/Dark/FolderEmptyStateDark.svg';
export { ReactComponent as SearchEmptyStateDark } from './Empty state/Dark/SearchEmptyStateDark.svg';
export { ReactComponent as QuickSearchEmptyStateDark } from './Empty state/Dark/QuickSearchEmptyStateDark.svg';
export { ReactComponent as EmptyPageDark } from './Empty state/Dark/BrowserNotSupported.svg';
export { ReactComponent as EmptyPageLight } from './Empty state/Light/BrowserNotSupported.svg';
export { ReactComponent as MeAvatar } from './Avatares/me.svg';
export { ReactComponent as StageOne } from './Onboarding/Stages one.svg';
export { ReactComponent as StageTwo } from './Onboarding/Stages two.svg';
export { ReactComponent as StageThree } from './Onboarding/Stages three.svg';
export { ReactComponent as OnboardingSitesDark } from './Onboarding/OnboardingSitesDark.svg';
export { ReactComponent as OnboardingSitesLight } from './Onboarding/OnboardingSitesLight.svg';
export { ReactComponent as OnboardingTeamsLight } from './Onboarding/OnboardingTeamsLight.svg';
export { ReactComponent as OnboardingTeamsDark } from './Onboarding/OnboardingTeamsDark.svg';

// No preview images

export { ReactComponent as noPreviewDark } from './Preview/noPreviewDark.svg';
export { ReactComponent as noPreviewLight } from './Preview/noPreviewLight.svg';

export { ReactComponent as NewLanguageSupport } from './WhatsNew/new-language-support.svg';
export { ReactComponent as AdminProvisioning } from './WhatsNew/admin-provisioning.svg';
export { ReactComponent as ImprovedSettings } from './WhatsNew/improved-settings.svg';

export { ReactComponent as UsaFlag } from './WhatsNew/usa.svg';
export { ReactComponent as FranceFlag } from './WhatsNew/france.svg';
export { ReactComponent as GermanyFlag } from './WhatsNew/germany.svg';
