import { GraphClient } from '@services';
import { strings } from '@vendor';
import {
  LocationEmptyStateDark,
  LocationEmptyStateLight,
  QuickSearchEmptyStateDark,
  QuickSearchEmptyStateLight,
} from '@storybook';
import { BaseStateManager, OfficeUserSettingManager } from '../stateManager';
import { BaseItem, ExBaseItemProps } from './BaseItem';
import { EmptyStateViewContext, FetchChildrenProps, Folder, ItemContainer, ItemDataWithPaging } from './ItemContainer';
import { ItemData } from './ItemData';
import { EmptyStateItem } from './EmptyStateItem';
import { UploadAction } from '../actions';

export abstract class BaseContainer extends BaseItem implements ItemContainer {
  readonly location?: ItemContainer;
  newItemsCount: number;

  protected constructor(props: ExBaseItemProps) {
    super(props);
    this.newItemsCount = 0;
  }

  get fetchChildrenCount(): number {
    return 50;
  }
  get supportSearch() {
    return true;
  }
  get stateCount(): OfficeUserSettingManager<number> | undefined {
    return undefined;
  }
  get filterState(): BaseStateManager<boolean> | undefined {
    return undefined;
  }
  get supportsSortDirection() {
    return false;
  }
  get quickSearchPlaceholder() {
    return strings.lang.quickSearch.searchPlaceholder({ patch: this.type || 'location' });
  }

  abstract fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging>;
  applyEmptyState(items: ItemData[], isDrilldown?: boolean, viewCtx?: EmptyStateViewContext): ItemData[] {
    if (!viewCtx) return items;
    const { extraCond, isFilteredView, location } = viewCtx;
    const applyCondition = items.length === 0 && (extraCond !== undefined ? extraCond : true);
    if (isFilteredView)
      return new EmptyStateItem({
        name: 'drilldownWithView',
        images: { light: QuickSearchEmptyStateLight, dark: QuickSearchEmptyStateDark },
        action: undefined,
        size: 250,
        isDrilldown,
        isSaveableLocation: true,
        location: location.hasFolder ? { id: location.id, data: location } : undefined,
      }).apply(items, applyCondition);

    return new EmptyStateItem({
      name: 'drilldown',
      images: { light: LocationEmptyStateLight, dark: LocationEmptyStateDark },
      action: new UploadAction(),
      size: 220,
      isDrilldown,
      isSaveableLocation: true,
      location: location.hasFolder ? { id: location.id, data: location } : undefined,
    }).apply(items, applyCondition);
  }

  get serverRelativeUrl(): string {
    return '';
  }

  async getSearchFilter(gcl: GraphClient): Promise<string | undefined> {
    const urls = await this.getAccessUrls(gcl);
    const address = urls?.address || urls?.webUrl;
    return (address && `Path:"${address}"`) || undefined;
  }

  getFolder(_: GraphClient): Promise<Folder> {
    throw new Error('Method not implemented.');
  }

  reorderItems(): void {
    // Implement the function as needed - share point and teams section will set the items a to z!
  }

  getAddedItemsInsertionPoint(newItem: ItemData, items: ItemData[]): number {
    return (this.hasFolder && newItem.isDocument && items.findIndex(v => v.isDocument)) || 0;
  }
}
