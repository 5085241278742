import React, { useEffect, useRef, useState } from 'react';
import { CardMediaProps, CardMedia } from '@mui/material';
import { animated, useSpring, config } from '@react-spring/web';
import { LottieAnimation, LottieAnimationType } from '../../../Lottie';

const mediaSx = { display: 'inherit' };

export interface WhatsNewMediaProps extends Omit<CardMediaProps, 'children'> {
  children?: React.ReactNode;
  imageSrc?: string;
  svgComponent?: React.ReactNode;
  youtubeSrc?: string;
  lottie?: LottieAnimationType;
  maxWidth?: number;
  minWidth?: number;
  hide?: boolean;
  video?: {
    videoSrc?: string;
    videoPoster?: React.ReactNode;
    autoPlay?: boolean;
    loop?: boolean;
    muted?: boolean;
    controls?: boolean;
  };
}

export const WhatsNewMedia = ({
  imageSrc,
  youtubeSrc,
  svgComponent,
  lottie,
  maxWidth,
  minWidth,
  hide,
  sx,
  video,
  ...rest
}: WhatsNewMediaProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [videoSize, setVideoSize] = useState({
    width: 0,
    height: 0,
  });

  const { videoSrc, videoPoster, autoPlay = true, loop = true, muted = true, controls = true } = video || {};
  const prevHideRef = useRef(hide);
  const [animateHide, setAnimateHide] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    if (prevHideRef.current !== hide) {
      prevHideRef.current = hide;
      setAnimateHide(true);
    }
  }, [hide]);

  useEffect(() => {
    if (!containerRef?.current || !youtubeSrc) {
      return;
    }

    const handleResize = () => {
      const clientMaxWidth = Math.min(maxWidth || 0, Number(containerRef.current?.clientWidth || 0));
      const width = Math.max(minWidth || 0, clientMaxWidth);
      const height = (width / 16) * 9;
      setVideoSize({
        width,
        height,
      });
    };

    const observer = new ResizeObserver(handleResize);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, [containerRef, maxWidth, minWidth, youtubeSrc]);

  const springs = useSpring({
    from: { y: animateHide && hide ? 0 : -500, height: hide ? 0 : 'auto' },
    to: { y: hide ? -500 : 0, height: hide ? 0 : 'auto' },
    config: config.default,
  });

  return (
    <animated.div style={{ ...springs, width: '100%', display: 'flex', justifyContent: 'center' }} ref={containerRef}>
      {imageSrc && <CardMedia component="img" src={imageSrc} sx={{ maxWidth, minWidth, ...sx }} {...rest} />}
      {video && (
        <>
          <CardMedia
            component="video"
            muted={muted}
            loop={loop}
            autoPlay={autoPlay}
            controls={controls}
            src={videoSrc}
            sx={{ maxWidth, minWidth, display: videoLoaded ? 'inherit' : 'none', ...sx }}
            onLoadedMetadata={() => setVideoLoaded(true)}
            {...rest}
          />
          {!videoLoaded && videoPoster ? (
            <CardMedia sx={{ maxWidth, minWidth, width: '100%', ...sx, ...mediaSx }} {...rest}>
              {videoPoster}
            </CardMedia>
          ) : null}
        </>
      )}
      {youtubeSrc && (
        <CardMedia
          sx={{
            position: 'relative',
            maxWidth,
            minWidth,
            ...videoSize,
            ...sx,
          }}
          {...rest}
        >
          <iframe
            style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, border: 'none' }}
            src={youtubeSrc}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </CardMedia>
      )}
      {svgComponent && (
        <CardMedia sx={{ maxWidth, minWidth, width: '100%', ...sx, ...mediaSx }} {...rest}>
          {svgComponent}
        </CardMedia>
      )}
      {lottie && (
        <CardMedia sx={{ maxWidth, minWidth, width: '100%', ...sx }} {...rest}>
          <LottieAnimation options={lottie} width="100%" />
        </CardMedia>
      )}
    </animated.div>
  );
};
