import { GraphClient, useGraphClient } from '@services';
import { CustomErrorIcon, NotificationContext, SelectItem, SelectItemsModal } from '@storybook';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { strings } from '@vendor';
import { SelectedItemType } from '~/misc';
import { getEmailsAttachments, ItemContainer } from '~/utilities';
import { EmailUploadProvider } from '~/utilities/uploadItem/EmailUploadProvider';
import { UploadItemProvider } from '~/utilities/uploadItem/UploadItemProvider';
import { BeforeUploadStep } from '~/context/UploadContext';
import { AttacmentEmailUploadProvider } from '~/utilities/uploadItem/AttacmentEmailUploadProvider';

export const convertSelectItemToUploadProvider = (
  gcl: GraphClient,
  items: SelectItem[],
  location: ItemContainer
): UploadItemProvider[] => {
  const providersList: UploadItemProvider[] = [];
  items.forEach(item => {
    if (item.isParentItem) {
      providersList.push(
        new EmailUploadProvider(gcl, { itemId: item.id, subject: item.name } as SelectedItemType, location)
      );
    } else {
      providersList.push(new AttacmentEmailUploadProvider(gcl, item, location));
    }
  });
  return providersList;
};

export const useSaveEmailAttachments = (): BeforeUploadStep => {
  const { showNotification, hideNotification } = useContext(NotificationContext);
  const dialogPromiseRef = useRef<{
    resolve: (value: SelectItem[]) => void;
    reject: (reason?: any) => void;
  } | null>(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SelectItem[]>([]);
  const gcl = useGraphClient();

  const prepareEmailsAttachments = useCallback(
    async (
      items: UploadItemProvider[],
      openModal: (items: SelectItem[]) => Promise<SelectItem[]>,
      gcl: GraphClient
    ): Promise<UploadItemProvider[]> => {
      try {
        const emails = items.map(item => item.item);
        const emailsAttachmentsResponse = await getEmailsAttachments(gcl, emails as SelectedItemType[]);
        hideNotification();
        if (emailsAttachmentsResponse.hasAttachments) {
          const selectedItems = await openModal(emailsAttachmentsResponse.emailsItems);
          return convertSelectItemToUploadProvider(gcl, selectedItems, items[0].location);
        } else {
          return items;
        }
      } catch (e) {
        showNotification(
          strings.lang.selectItemsModal.unableToSave,
          undefined,
          undefined,
          undefined,
          <CustomErrorIcon />
        );
        return [];
      }
    },
    [hideNotification, showNotification]
  );
  const openModal = useCallback(async (items: SelectItem[]): Promise<SelectItem[]> => {
    setSelectedItems(items);
    setShowModal(true);
    return new Promise<SelectItem[]>(resolve => {
      dialogPromiseRef.current = { resolve, reject: () => {} }; // Store resolve to call when user acts
    });
  }, []);

  const onSave = useCallback((selectedItems: SelectItem[]) => {
    setShowModal(false);
    dialogPromiseRef.current?.resolve(selectedItems);
  }, []);

  const onCancel = useCallback(() => {
    setShowModal(false);
    dialogPromiseRef.current?.resolve([]); // Resolve promise on cancel with an empty array
  }, []);

  const execute = useCallback(
    async (uploadItemProvider: UploadItemProvider[]) => {
      showNotification(strings.lang.selectItemsModal.prepareEmails, 100000000, undefined, undefined);
      const preparedItems = await prepareEmailsAttachments(uploadItemProvider, openModal, gcl);
      return preparedItems;
    },
    [gcl, openModal, prepareEmailsAttachments, showNotification]
  );

  const modal = showModal ? (
    <SelectItemsModal
      items={selectedItems}
      onSave={onSave}
      onCancel={onCancel}
      showModal={true}
      isAdvancedModal={false}
      useDrawer={true}
    />
  ) : undefined;

  return {
    execute: execute,
    stepUi: modal,
  };
};
