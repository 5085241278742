import { ShowNotificationFunction } from '@harmon.ie-ce/storybook/src/contexts/NotificationsContext';
import { Recipient } from '@microsoft/microsoft-graph-types';
import { useGraphClient } from '@services';
import { CustomErrorIcon, IndicatorRequestHandler } from '@storybook';
import { strings } from '@vendor';
import React from 'react';
import { UploadStep } from '~/context/UploadContext';
import { SPDocument, verifyRecipient } from '~/utilities';
import { manageProperties } from '~/utilities/manageProperties/ManageProperties';
import { EmailUploadProvider } from '~/utilities/uploadItem/EmailUploadProvider';
import { UploadItemProvider } from '~/utilities/uploadItem/UploadItemProvider';
import { ehm_black_list } from './useCreateEmailHeaderMappingStep';

export const useEmailHeaderMapping = (): UploadStep => {
  const gcl = useGraphClient();
  const execute = async (
    uploadItemProvider: UploadItemProvider,
    _index: number,
    _actionsRef: any,
    _isMultipleUploads: boolean,
    _progressHandler?: IndicatorRequestHandler,
    showNotification?: ShowNotificationFunction
  ) => {
    const newItem = uploadItemProvider.getNewItem();
    const emailFields = uploadItemProvider.uploadItemProps?.emailFields;
    if (
      uploadItemProvider instanceof EmailUploadProvider &&
      newItem &&
      emailFields &&
      !ehm_black_list.value.includes(uploadItemProvider.location.apiId)
    ) {
      const contentTypes = await uploadItemProvider.folder.getContentTypes(gcl);
      try {
        await manageProperties(newItem as SPDocument, gcl, {
          contentType: contentTypes,
          date: emailFields?.sentDateTime || undefined,
          received: emailFields?.receivedDateTime || emailFields?.sentDateTime || undefined,
          from: verifyRecipient([emailFields?.sender] as Recipient[]),
          to: verifyRecipient(emailFields?.toRecipients as unknown as Recipient[]),
          subject: emailFields?.subject || strings.lang.emailHeaderMapping.noSubject,
          cc: verifyRecipient(emailFields?.ccRecipients as unknown as Recipient[]),
          hasAttachments: emailFields?.hasAttachments || undefined,
          categories: emailFields?.categories || undefined,
          messageId: emailFields?.internetMessageId || undefined,
          conversationIndex: emailFields?.conversationIndex || undefined,
          conversationTopic:
            emailFields?.singleValueExtendedProperties?.[0].value || strings.lang.emailHeaderMapping.noSubject,
        });
      } catch (e: any) {
        showNotification &&
          showNotification(
            strings.lang.emailHeaderMapping.errorTitle,
            undefined,
            undefined,
            'indicator-still-uploading',
            <CustomErrorIcon />
          );
      }
    }
  };
  const clean = () => {};
  return { clean: clean, execute: execute };
};
