import './index.css';
import { AccountInfo } from '@azure/msal-browser';
import { GraphClient } from '@services';
import { OnOfficeReady } from '@vendor';
import { AppInit } from '~/initializers/AppInit';
import { OfficeInit } from '~/initializers/OfficeInit';
import { asyncPublish } from '~/hooks';

export interface ReactAppInitData {
  account: AccountInfo;
  gcl: GraphClient;
}

// Invoke all initializers from all modules

OfficeInit();
AppInit();

Office.onReady(async () => {
  await asyncPublish(OnOfficeReady, undefined);
});
