import { makeStyles } from '@mui/styles';

// TODO (DN) - web kit isn't standard. This is probably why we have scrollbar issues on Mac
const useGlobalStyles = makeStyles({
  '@global': {
    '.hidden-scrollbar': {
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        display: 'none',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        display: 'none',
      },
    },
    '[data-testid="survey-root"] > div': {
      backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
    },
  },
});

export default useGlobalStyles;
