import React, { forwardRef, useEffect, useRef } from 'react';
import { animated, useSpringRef, useTransition, config } from '@react-spring/web';
import { Stack, StackProps } from '@mui/material';
import { Property } from 'csstype';

export interface WhatsNewStepsProps extends StackProps {
  steps: React.CElement<any, any>[];
  activeStep: number;
  height?: Property.Height | undefined;
  onSprintChange?: (props: any, spring: any) => void;
}

export const WhatsNewSteps = forwardRef<any, WhatsNewStepsProps>(
  ({ activeStep, steps, onSprintChange, height, ...rest }: WhatsNewStepsProps, ref) => {
    const transRef = useSpringRef();
    const prevStepRef = useRef(activeStep);

    useEffect(() => {
      if (prevStepRef.current !== activeStep) {
        prevStepRef.current = activeStep;
        transRef.start();
      }
    }, [activeStep, transRef]);

    const movingNext = activeStep > prevStepRef.current;
    const transitions = useTransition(activeStep, {
      keys: null,
      config: config.default,
      onChange: onSprintChange,
      from: { opacity: 0, transform: `translate3d(${movingNext ? '100%' : '-50%'},0,0)`, progress: 0 },
      enter: { opacity: 1, transform: 'translate3d(0%,0,0)', progress: 1 },
      leave: { opacity: 0, transform: `translate3d(${movingNext ? '-50%' : '100%'},0,0)`, progress: 0 },
    });

    return (
      <Stack style={{ position: 'relative', width: '100%', height: height || '100%' }} {...rest}>
        {transitions((style, i) => (
          <animated.div style={{ ...style, position: 'absolute', width: '100%', height: '100%' }}>
            {React.cloneElement(steps?.[i], { ref })}
          </animated.div>
        ))}
      </Stack>
    );
  }
);

WhatsNewSteps.displayName = 'WhatsNewSteps';
