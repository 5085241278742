import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OperatedList } from '@storybook';
import { useGraphClient } from '@services';
import { Onboarding, Stack, useTheme } from '@storybook';
import { EnableTeamsState, OfficeUserSettingManager, SPSiteProps, SharePointSitesRoot, TeamsRoot } from '~/utilities';
import { useSettingsState } from '~/hooks';

export const onBoardingState = new OfficeUserSettingManager<number>('onBoardingState', () => 1);
export const onBoardingDone = new OfficeUserSettingManager<boolean>('onBoardingDone', () => false);
const OnboardingFlow = () => {
  const navigate = useNavigate();
  const gcl = useGraphClient();
  const [currentSlide, setCurrentSlide] = useState(onBoardingState.value || 1);
  const [enableTeams] = useSettingsState(EnableTeamsState);
  const theme = useTheme();
  const onComplete = (sitesItems?: OperatedList[], teamsItems?: OperatedList[]) => {
    SharePointSitesRoot.save((sitesItems as SPSiteProps[]) || [], false);
    TeamsRoot.save(teamsItems || [], false);
    onBoardingDone.value = true;
    navigate('/home');
  };

  const getOnboardingTeams = useCallback(async () => {
    return await TeamsRoot.getOnBoardingTeams(gcl);
  }, [gcl]);
  const getOnboardingSites = useCallback(async () => {
    return (await SharePointSitesRoot.getOnBoardingItems(gcl, Date.now())).map(s => s.toJson());
  }, [gcl]);

  const saveSlideState = useCallback((slide: number) => {
    if (slide > 0 && slide < 7) {
      onBoardingState.value = slide;
      setCurrentSlide(slide);
    } else {
      onBoardingState.value = 0;
      setCurrentSlide(0);
    }
  }, []);

  return (
    <Stack
      sx={{ height: '100%', width: '100%' }}
      className="hidden-scrollbar"
      background={theme.palette.backgrounds.primary}
    >
      <Onboarding
        currentSlide={currentSlide !== undefined ? currentSlide : 1}
        setCurrentSlide={saveSlideState}
        onComplete={onComplete}
        getOnboardingSites={getOnboardingSites}
        getOnboardingTeams={enableTeams ? getOnboardingTeams : undefined}
      />
    </Stack>
  );
};

export default OnboardingFlow;
