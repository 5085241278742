import React, { MouseEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Stack,
  Button,
  Toolbar,
  Typography,
  useTheme,
  ContextMenuContext,
  MouseEventsContext,
  MouseEventsInterface,
  MenuActions,
  toolbarQuickActions,
  CollapseSmall,
  Tooltip,
} from '@storybook';
import { strings } from '@vendor';
import { debounce } from 'lodash';
import { RenderActionByHook } from '~/utilities';
import { AllLocations } from '~/utilities/virtualRoots/AllLocations';
import { GoBackAction } from '~/modules/Drilldown/DrilldownToolbar/Actions/GoBack';
import {
  getAllLocationsActions,
  getAllLocationsQuickActions,
  getSttingsQuickActions,
} from './Actions/AllLocationActionManager';
import { getHarmonieTree, getHarmonieTreeHandlers } from '../Tree/Tree';
import { handleNodeClosing, resetTreeNodeState } from '../Tree/Renderers/ExpandHandler';
import { getEmptyHandlers } from '../Tree/Renderers/QuickSearchHandler';

const TopBar: React.FC<{
  isSettingsPage: boolean;
}> = ({ isSettingsPage }) => {
  const [showCollapseAll, setShowCollapseAll] = useState<boolean>(false);
  const emptyHandlers = useMemo(() => getEmptyHandlers(AllLocations.instance), []);
  const theme = useTheme();
  const { handleContextMenu } = useContext(ContextMenuContext);
  const { waitForAction, actionDone } = useContext<MouseEventsInterface>(MouseEventsContext);

  const handleSelection = useCallback<MouseEventHandler<HTMLDivElement>>(
    event => {
      const isContextMenu = event.type === 'contextmenu';
      if (isContextMenu) {
        event.preventDefault();
        const actions = getAllLocationsActions().map((a, ind) => ({
          render: (
            <RenderActionByHook
              key={`action-${a.constructor.name}-${ind}`}
              action={a}
              actionVariant={MenuActions}
              trigger="Context Menu"
              nodes={[]}
              handlers={emptyHandlers}
            />
          ),
        }));
        if (actions.length) {
          waitForAction?.();
          handleContextMenu?.(event, actions, actionDone);
        }
      }
    },
    [actionDone, emptyHandlers, handleContextMenu, waitForAction]
  );

  const handleClick = useMemo(
    () =>
      debounce(() => {
        let doCollapse = false;
        getHarmonieTree().instance.enhancedData.forEach(node => {
          doCollapse ||= node.isLoading() || node.isOpened();
        });
        setShowCollapseAll(doCollapse);
      }, 10),
    []
  );

  const collapseTree = useCallback(() => {
    const act = getHarmonieTreeHandlers();
    getHarmonieTree()
      .instance.getOpenedNodes()
      .forEach(n => {
        handleNodeClosing(n);
        if (n.isOpened()) act.setOpen(n);
      });
    resetTreeNodeState();
    handleClick();
  }, [handleClick]);

  useEffect(() => {
    document?.querySelector('.ht_hyperTreeView')?.addEventListener('click', handleClick);
    handleClick();
    return () => {
      document?.querySelector('.ht_hyperTreeView')?.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <AppBar
      position="static"
      sx={{
        position: 'relative',
        width: '100%',
        maxHeight: theme.spacing(7),
        paddingTop: theme.spacing(1),
        background: theme.palette.backgrounds.primary,
      }}
    >
      <Toolbar
        onContextMenu={handleSelection}
        className="all-locations-toolbar"
        sx={{
          display: 'flex',
          alignItems: 'center',
          ...(isSettingsPage && { justifyContent: 'space-between', padding: 0 }),
        }}
      >
        {isSettingsPage ? (
          <>
            <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ flexGrow: 1 }}>
              <RenderActionByHook
                nodes={[]}
                handlers={emptyHandlers}
                action={new GoBackAction()}
                actionVariant={toolbarQuickActions}
                trigger="Quick Action"
              />
              <Typography variant="CapsLockSemiBold" color={theme.palette.texts.quaternary} noWrap>
                {strings.lang.allLocatiosToolbar.gotoSettings}
              </Typography>
            </Stack>
            <Stack direction="row" flexGrow={0}>
              <>
                {getSttingsQuickActions().map((action, index) => (
                  <React.Fragment key={`action-${index}`}>
                    <RenderActionByHook
                      trigger={index < 1 ? 'Quick Action' : '3Dots'}
                      action={action}
                      nodes={[]}
                      actionVariant={toolbarQuickActions}
                      handlers={emptyHandlers}
                    />
                  </React.Fragment>
                ))}
              </>
            </Stack>
          </>
        ) : (
          <Stack direction="row" width={'100%'} justifyContent="space-between" mt={1} paddingY={1}>
            <Stack alignItems="center" direction="row">
              <Tooltip title={strings.lang.collapseAll}>
                <Button
                  disabled={!showCollapseAll}
                  sx={{ ml: -1.5, mr: 1, visibility: showCollapseAll ? 'visible' : 'hidden' }}
                  variant="IconButtons"
                  onClick={collapseTree}
                >
                  <CollapseSmall />
                </Button>
              </Tooltip>
              <Typography variant="CapsLockSemiBold" color={theme.palette.texts.quaternary} noWrap>
                {strings.lang.allLocations}
              </Typography>
            </Stack>
            <Stack direction="row" flexGrow={0}>
              <>
                {getAllLocationsQuickActions().map((action, index) => (
                  <React.Fragment key={`action-${index}`}>
                    <RenderActionByHook
                      trigger={index < 1 ? 'Quick Action' : '3Dots'}
                      action={action}
                      nodes={[]}
                      actionVariant={toolbarQuickActions}
                      handlers={emptyHandlers}
                    />
                  </React.Fragment>
                ))}
              </>
            </Stack>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
