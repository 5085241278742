import React, { ReactEventHandler, useState } from 'react';
import { Box, Stack, StackProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { strings } from '@vendor';
import noPreviewDark from '../../../assets/images/Preview/noPreviewDark.svg';
import noPreviewLight from '../../../assets/images/Preview/noPreviewLight.svg';
import LottieAnimation from '../../Lottie/LottieAnimation';
import { Root } from './styled';
import Loader from '../../Lottie/Animations/Loader/Loader.json';
export type UsageType =
  | 'shared'
  | 'you-used'
  | 'edited'
  | 'you-edited'
  | 'you-shared'
  | 'sent'
  | 'you-sent'
  | 'you-shared-in-team-chat'
  | 'shared-with-you-in-team-chat'
  | 'email-attachment';

export interface FilePreviewProps extends StackProps {
  fileName: string;
  previewImage?: string | undefined;
  byUser?: string;
  atDate?: string;
  type?: UsageType;
  subject?: string;
  hasPreview?: boolean;
}
interface ByUserProps {
  byUser?: string;
  subject?: string;
}

const ShareByUser = ({ byUser }: ByUserProps) => {
  return (
    <Box>
      <Typography color="texts.onAccentPrimary" variant="H3SemiBold">
        {byUser}&nbsp;
      </Typography>
      <Typography color="texts.onAccentPrimary" variant="H3Regular">
        {strings.lang.filePreview.shared}
      </Typography>
    </Box>
  );
};

const YouShared = () => {
  return (
    <Box>
      <Typography color="texts.onAccentPrimary" variant="H3Regular">
        {strings.lang.filePreview.youShared}
      </Typography>
    </Box>
  );
};

const ShareByChat = () => {
  return (
    <Box>
      <Typography color="texts.onAccentPrimary" variant="H3Regular">
        {strings.lang.filePreview.youSharedInChat}
      </Typography>
    </Box>
  );
};

const YouSent = () => {
  return (
    <Box>
      <Typography color="texts.onAccentPrimary" variant="H3Regular">
        {strings.lang.filePreview.youSent}
      </Typography>
    </Box>
  );
};
const SharedWithYouInTeamChat = ({ byUser }: ByUserProps) => {
  return (
    <Box>
      <Typography color="texts.onAccentPrimary" variant="H3SemiBold">
        {byUser}&nbsp;
      </Typography>
      <Typography color="texts.onAccentPrimary" variant="H3Regular">
        {strings.lang.filePreview.sharedInChat}
      </Typography>
    </Box>
  );
};

const SharedWithYouAsAttachment = ({ byUser, subject }: ByUserProps) => {
  return (
    <Stack direction={'column'}>
      <Typography color="texts.onAccentPrimary" variant="H3SemiBold">
        {`${strings.lang.emailProps.from} ${byUser}`}&nbsp;
      </Typography>
      <Typography color="texts.onAccentPrimary" variant="H3SemiBold">
        {subject}
      </Typography>
    </Stack>
  );
};

const SentByUser = ({ byUser }: ByUserProps) => {
  return (
    <Box>
      <Typography color="texts.onAccentPrimary" variant="H3SemiBold">
        {byUser}&nbsp;
      </Typography>
      <Typography color="texts.onAccentPrimary" variant="H3Regular">
        {strings.lang.filePreview.sent}
      </Typography>
    </Box>
  );
};

const UsedByYou = () => {
  return (
    <Box>
      <Typography color="texts.onAccentPrimary" variant="H3Regular">
        {strings.lang.filePreview.recentlyOpened}
      </Typography>
    </Box>
  );
};

const EditedByYou = () => {
  return (
    <Box>
      <Typography color="texts.onAccentPrimary" variant="H3Regular">
        {strings.lang.filePreview.recentlyEdited}
      </Typography>
    </Box>
  );
};

const EditedByUser = ({ byUser }: ByUserProps) => {
  return (
    <Box>
      <Typography color="texts.onAccentPrimary" variant="H3SemiBold">
        {byUser}&nbsp;
      </Typography>
      <Typography color="texts.onAccentPrimary" variant="H3Regular">
        {strings.lang.filePreview.youEdited}
      </Typography>
    </Box>
  );
};

const FilePreviewPopover = ({
  previewImage,
  fileName,
  byUser,
  atDate,
  subject,
  type,
  hasPreview = true,
}: FilePreviewProps) => {
  let itemRelevance;
  switch (type || '') {
    case 'shared':
      itemRelevance = <ShareByUser byUser={byUser} />;
      break;
    case 'you-shared':
      itemRelevance = <YouShared />;
      break;
    case 'edited':
      itemRelevance = <EditedByUser byUser={byUser} />;
      break;
    case 'you-edited':
      itemRelevance = <EditedByYou />;
      break;
    case 'you-used':
      itemRelevance = <UsedByYou />;
      break;
    case 'sent':
      itemRelevance = <SentByUser byUser={byUser} />;
      break;
    case 'you-sent':
      itemRelevance = <YouSent />;
      break;
    case 'you-shared-in-team-chat':
      itemRelevance = <ShareByChat />;
      break;
    case 'shared-with-you-in-team-chat':
      itemRelevance = <SharedWithYouInTeamChat byUser={byUser} />;
      break;
    case 'email-attachment':
      itemRelevance = <SharedWithYouAsAttachment byUser={byUser} subject={subject} />;
      break;
  }

  const [imageLoaded, setImageLoaded] = useState(false);
  const theme = useTheme();
  const onLoad = () => {
    setImageLoaded(true);
  };

  const onError: ReactEventHandler<HTMLImageElement> = e => {
    e.currentTarget.src = theme.palette.mode === 'light' ? noPreviewLight : noPreviewDark;
  };

  const options = {
    loop: true,
    autoplay: true,
    animationData: Loader,
  };

  return (
    <Root sx={{ width: 230 }}>
      <Stack direction="column">
        {hasPreview && (
          <Box sx={{ background: theme.palette.backgrounds.primary, width: '100%', borderRadius: '6px' }}>
            {previewImage === undefined ? (
              <LottieAnimation options={options} height={140} width={100} />
            ) : (
              <Stack
                sx={{
                  opacity: imageLoaded ? 1 : 0,
                  transition: 'opacity 0.3s ease-in-out',
                }}
              >
                <img
                  src={previewImage}
                  onError={onError}
                  onLoad={onLoad}
                  style={{ width: '100%', height: 140, borderRadius: 6 }}
                />
              </Stack>
            )}
          </Box>
        )}
        <Stack direction="column" sx={{ padding: 2, paddingLeft: 1, paddingRight: 1 }}>
          <Stack direction={'column'}>
            <Typography color="texts.onAccentPrimary" variant="H1SemiBold">
              {fileName}
            </Typography>
            {type === 'email-attachment' && (
              <Typography color="texts.onAccentPrimary" variant="H3Regular">
                {strings.lang.filePreview.emailAttachment}
              </Typography>
            )}
          </Stack>
        </Stack>
        {atDate && (
          <Stack direction="column" sx={{ paddingLeft: 1, paddingRight: 1, paddingBottom: 2 }}>
            {itemRelevance && itemRelevance}
            <Typography color="texts.onAccentSecondary" variant="H3Regular">
              {atDate}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Root>
  );
};

export default FilePreviewPopover;
