import React, { useCallback, useState } from 'react';
import { NewItemDialog, ViewItem, URLLinkMedium } from '@storybook';
import { GraphClient } from '@services';
import { strings } from '@vendor';
import { useNewItemDialog } from './NewOfficeDocument';
import { ItemContainer, ItemData } from '../itemTypes';
import { UploadDocument } from '../sharePointTypes';
import { isValidUrl } from './ActionsUtils';
import { ActionItemType, ActionRendererHookType, BaseAddActionClass, TrackedEventType } from './BaseAction';
import { publishItemListChanged } from './UploadAction';

const useNewLinkDocumentAction: ActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const location = nodes[0].data as ItemContainer;
  const [urlPath, setUrlPath] = useState('');
  const [urlErrorMessage, setUrlErroMessage] = useState<string>();
  const createLink = useCallback(
    async (gcl: GraphClient, itemName: string) => {
      const fileName = `${itemName}.url`;
      const folder = await (location as ItemContainer).getFolder(gcl);
      const data = new Blob([`URL = ${urlPath}`], { type: 'text/plain' });
      const newItem = await folder.upload({ data, fileName, gcl, override: false } as UploadDocument);
      publishItemListChanged({ added: { [newItem.apiIdKey]: newItem }, location });
    },
    [location, urlPath]
  );
  const urlValidate = useCallback(() => {
    if (!isValidUrl(urlPath)) {
      setUrlErroMessage(strings.lang.itemDialogTexts.urlNotValid);
      return false;
    }
    return true;
  }, [urlPath]);
  const resetUrlFields = useCallback(async () => {
    setUrlPath('');
    setUrlErroMessage(undefined);
  }, []);
  const { setNewItemDialogVisible, ...props } = useNewItemDialog({
    location,
    texts: strings.lang.newItemDialogTexts.document,
    actionCallback: createLink,
    secondFieldValidate: urlValidate,
    secondFieldReset: resetUrlFields,
    useSafeCallback,
  });
  const handleUrlPathChange = useCallback((value: string) => {
    const defaultUrl = strings.lang.itemDialogTexts.defaultUrl;
    setUrlPath(value);
    setUrlErroMessage(
      value !== defaultUrl && !isValidUrl(value) ? strings.lang.itemDialogTexts.urlNotValid : undefined
    );
  }, []);

  return {
    title: strings.lang.actionToolTip.newLinkDocument,
    icon: URLLinkMedium,
    onClick: () => setNewItemDialogVisible(true),
    SideUI: (
      <NewItemDialog
        {...props}
        urlPath={urlPath}
        defaultUrl={strings.lang.itemDialogTexts.defaultUrl}
        title={strings.lang.itemDialogTexts.linkDocumentTitle}
        maxWidth="xs"
        extensions={'.url'}
        secondFieldSubTitle={strings.lang.itemDialogTexts.urlPath}
        handleUrlPathChange={handleUrlPathChange}
        secondFieldTitle={strings.lang.newItemDialogTexts.document.subTitle}
        twoFields={true}
        urlErrorMessage={urlErrorMessage}
      ></NewItemDialog>
    ),
  };
};

export class NewLinkDocumentAction extends BaseAddActionClass {
  readonly useRenderer = useNewLinkDocumentAction;
  transformEvent({ data, eventName }: TrackedEventType) {
    return {
      eventName,
      data: {
        ...data,
        itemType: 'Document' as ActionItemType,
        documentType: 'Link',
      },
    };
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return nodes.length === 1 && data.hasFolder && !data.isSearchItem;
  }
}
