import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { ProvisionedSettingKeys, SettingsStrings, strings } from '@vendor';
import {
  Button,
  Divider,
  NotificationsToastsSuccessIcon,
  Stack,
  TextVariant,
  Typography,
  useTheme,
  useYesNoDialog,
} from '@storybook';
import { FormControlLabel, Switch } from '@mui/material';
import { asType, useGraphClient } from '@services';
import _ from 'lodash';
import { ProvisionedValue, getInitialSettings, getProvisionedSettings } from '~/provisioning/Provisioned';
import { ProvisionedDetails, updateProvisioning, ValidationError } from '~/provisioning';
import { createItemDataFromJson, ItemData, RenderActionByHook, SPList, TrackedEventType } from '~/utilities';
import { useSafeCallback, useSettingsState } from '~/hooks';
import { trackEvent } from '~/utilities/analytics/tracking';
import { getEmptyHandlers } from '~/components/Tree/Renderers/QuickSearchHandler';
import { SettingsKeyControl } from '../SettingsControls';
import { ViewConfiguration } from './ViewConfiguration';
import { ImportConfigurationAction } from './ImportConfigurations';

interface AdminCenterProps {
  setAdminStateChange: React.Dispatch<React.SetStateAction<boolean>>;
  adminStateChange: boolean;
  list?: SPList;
}

const useAdminActions = (
  provDoc: ItemData | undefined,
  handleJsonUpload: (data: Record<string, any>, actionRef: MutableRefObject<any>) => void,
  adminStateChange: boolean
) => {
  const adminActions = useMemo(
    () => [
      ...(provDoc ? [new ViewConfiguration(adminStateChange)] : []),
      new ImportConfigurationAction(handleJsonUpload),
    ],
    [adminStateChange, handleJsonUpload, provDoc]
  );
  const nodes = useMemo(() => (provDoc ? [{ id: provDoc.id, data: provDoc }] : []), [provDoc]);
  const handlers = useMemo(() => getEmptyHandlers(provDoc), [provDoc]);
  return adminActions.map((action, ind) => (
    <RenderActionByHook
      key={`action-${action.constructor.name}-${ind}`}
      action={action}
      trigger="Admin Center"
      nodes={nodes}
      actionVariant={TextVariant}
      handlers={handlers}
      disabled={!action.isHandled(nodes)}
    />
  ));
};

const AdminCenter: React.FC<AdminCenterProps> = ({ setAdminStateChange, adminStateChange, list }) => {
  const provisionedSettings = getProvisionedSettings();
  const initialSettings = getInitialSettings();
  const [settings, setSettings] = useState(getInitialSettings());
  const theme = useTheme();
  const gcl = useGraphClient();
  const [provDetails] = useSettingsState(ProvisionedDetails);
  const provDoc = useMemo(
    () => (provDetails?.fileDetails ? createItemDataFromJson(provDetails?.fileDetails) : undefined),
    [provDetails]
  );
  const updateAdminSettingsChange = useCallback(
    (value: boolean) => {
      setAdminStateChange(value);
      PubSub.publish('AdminSettingsChanged', value);
    },
    [setAdminStateChange]
  );
  const applySettings = useSafeCallback(
    async () => {
      // Apply updated settings to the original ProvisionedSettings object
      Object.entries(settings).forEach(([key, updatedSetting]) => {
        if (key in provisionedSettings) {
          const originalSetting = provisionedSettings[key];
          // Update the original object
          provisionedSettings[key] = {
            ...originalSetting,
            value: updatedSetting.value,
            isReadOnly: updatedSetting.isReadOnly,
          };
        }
      });
      // Reset admin state change and close the dialog
      updateAdminSettingsChange(false);

      const folder = await list?.getFolder();
      folder && (await updateProvisioning(gcl, folder));
    },
    [settings, updateAdminSettingsChange, list, gcl, provisionedSettings],
    false,
    {
      onStartTitle: strings.lang.adminSetting.adminCenterApplyNotificationsStart,
      onSuccessTitle: strings.lang.adminSetting.adminCenterApplyNotificationsSuccess,
      errorTitle: strings.lang.adminSetting.adminCenterApplyNotificationsFailedTitle,
      errorMessage: strings.lang.adminSetting.adminCenterImportNotificationsFailedMessage,
    },
    undefined,
    undefined,
    undefined,
    <NotificationsToastsSuccessIcon />
  );

  const discardChanges = () => {
    setSettings(initialSettings);
    updateAdminSettingsChange(false);
  };

  const { YesNoDialog, setIsDialogVisible } = useYesNoDialog({
    texts: {
      dialogTitle: strings.lang.adminSetting.adminCenterConfirmationDialogTitle,
      dialogSubTitle: strings.lang.adminSetting.adminCenterConfirmationDialogDescription,
      primaryActionText: strings.lang.adminSetting.adminCenterApply,
      secondaryActionText: strings.lang.deleteDialog.secondaryActionText,
    },
    blockOutsideClick: false,
    primaryActionCallback: applySettings,
    useTextVariant: true,
  });

  const haveSameState = (newState: typeof initialSettings, prevState: typeof initialSettings): boolean => {
    // Check if values for all keys in the initial state match the current state
    const allPrevKeysMatch = Object.entries(prevState).every(([key, byPrev]) => {
      const byNewState = newState[key];
      // Compare both value and isReadOnly properties
      return byNewState?.value === byPrev.value && byNewState?.isReadOnly === byPrev.isReadOnly;
    });
    // Verify that the new state has no extra keys
    return allPrevKeysMatch && Object.keys(newState).length === Object.keys(prevState).length;
  };
  const simplifyValue = (value: any) => (typeof value === 'object' ? 'Complex Value' : value?.toString() || 'deleted');

  const handleJsonUpload = (data: Record<string, any>, actionRef: MutableRefObject<any>) => {
    const updatedSettings: Partial<typeof provisionedSettings> = {};
    actionRef.current = {};
    Object.entries(data).forEach(([key, value]) => {
      if (key in provisionedSettings) {
        const isReadOnly = data[`${key}.ReadOnly`];
        const setting: ProvisionedValue<any> = provisionedSettings[key];
        setting.validator?.(key, value); // Validate value & provide a readbale error message
        if (setting.isHidden && setting.defaultValue && _.isEqual(value, setting.defaultValue)) value = undefined;
        updatedSettings[key] = {
          ...setting,
          isReadOnly: isReadOnly !== undefined ? isReadOnly : setting.isReadOnly,
          value,
        };
        actionRef.current[key] = simplifyValue(value);
      } else if (!key.endsWith('.ReadOnly')) {
        throw new ValidationError(strings.lang.adminSetting.validation.unspportedKey({ key }));
      }
    });

    // Merge updated settings into the existing state
    setSettings(prevState => {
      const newState = { ...prevState };
      Object.entries(updatedSettings).forEach(([key, value]) => {
        newState[key] = {
          ...prevState[key],
          ...value,
        };
      });
      // Use the utility function to check initial state
      updateAdminSettingsChange(!haveSameState(newState, initialSettings));
      return newState;
    });
  };
  const adminActions = useAdminActions(provDoc, handleJsonUpload, adminStateChange);

  const handleToggleSetting = (key: ProvisionedSettingKeys) => {
    setSettings(prevState => {
      const newState = {
        ...prevState,
        [key]: {
          ...prevState[key],
          value: !prevState[key].value,
        },
      };
      trackEvent(
        'ProvisionedSettings!',
        asType<TrackedEventType['data']>({
          actionType: 'Update',
          trigger: 'Admin Center',
          itemType: 'Setting',
          [key]: newState[key].value,
          targetView: 'Settings',
        })
      );
      // Use the utility function to check initial state
      updateAdminSettingsChange(!haveSameState(newState, initialSettings));
      return newState;
    });
  };

  return (
    <Stack minHeight="100%" spacing={7}>
      {YesNoDialog}
      <Stack spacing={2}>
        <Stack direction="row" alignSelf="flex-end" spacing={1}>
          {...adminActions}
        </Stack>
        {/* Dynamically Rendered Settings */}
        <Stack spacing={3}>
          <SettingsKeyControl
            title={strings.lang.adminSetting.manageApplicationsTitle}
            description={strings.lang.adminSetting.manageApplicationsDescription}
          />
          {Object.entries(settings)
            .filter(([_key, { value }]) => value !== undefined)
            .map(([key, { value }], ind, ar) => {
              const { title, description, body, hasDivider } = (strings.lang.adminSetting.settingsTexts[key] ||
                {}) as SettingsStrings;
              return (
                <Stack key={key} spacing={2} paddingBottom={hasDivider && ind < ar.length - 1 ? 3 : 0}>
                  {title && (
                    <SettingsKeyControl
                      spacing={2}
                      title={title}
                      description={description}
                      descriptionColor={theme.palette.texts.primary}
                    />
                  )}
                  {/* Render the toggle switch */}
                  {body && (
                    <FormControlLabel
                      value={true}
                      slotProps={{
                        typography: {
                          sx: { flex: 1 },
                        },
                      }}
                      sx={{ flexDirection: 'row-reverse', margin: 0 }}
                      control={
                        <Switch checked={value} onChange={() => handleToggleSetting(key as ProvisionedSettingKeys)} />
                      }
                      label={
                        <SettingsKeyControl
                          spacing={1}
                          direction="row"
                          display="flow"
                          title={body}
                          titleVariant="H2Medium"
                          descriptionVariant="H2Regular"
                        />
                      }
                    />
                  )}
                  {hasDivider && ind < ar.length - 1 && (
                    <Divider sx={{ width: '100%', color: theme.palette.texts.secondary, paddingBottom: 4 }} />
                  )}
                </Stack>
              );
            })}
        </Stack>
      </Stack>
      <Stack spacing={2} direction={'row'}>
        <Button
          variant="secondary"
          disabled={!adminStateChange}
          sx={{
            width: '100%',
            paddingY: `${theme.spacing(1)} !important`,
            paddingX: `${theme.spacing(3)} !important`,
          }}
          onClick={discardChanges}
        >
          <Typography variant="H2Medium" ml={theme.spacing(1)}>
            {strings.lang.adminSetting.adminCenterDiscard}
          </Typography>
        </Button>
        <Button
          variant="primary"
          disabled={!adminStateChange}
          sx={{
            width: '100%',
            paddingY: `${theme.spacing(1)} !important`,
            paddingX: `${theme.spacing(3)} !important`,
          }}
          onClick={() => setIsDialogVisible(true)}
        >
          <Typography variant="H2Medium" ml={theme.spacing(1)}>
            {strings.lang.adminSetting.adminCenterApply}
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export default AdminCenter;
