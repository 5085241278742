import React from 'react';
import { styled } from '@mui/material';
import { IconButton, IconButtonProps } from 'material-ui-core';
import { XSmall } from '../../../../assets/icons';

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  border-radius: 50%;
  padding: 6px;
  border: none;
  background-color: ${({ theme }) => theme.palette.backgrounds.overlaidComponentsPrimary} !important;

  svg:not([data-nofill]),
  svg:not([data-nofill]) path {
    fill: ${({ theme }) => theme.palette.texts.quaternary};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.backgrounds.overlaidComponentsPrimary};

    svg:not([data-nofill]),
    svg:not([data-nofill]) path {
      fill: ${({ theme }) => theme.palette.texts.quaternary};
    }
  }
`;

export const CloseButton = (props: IconButtonProps) => {
  return (
    <StyledCloseButton {...props}>
      <XSmall fontSize={16} />
    </StyledCloseButton>
  );
};
