import React, { PropsWithChildren } from 'react';
import { Paper, Stack, useTheme } from '@mui/material';
import { VerticalScrollbars } from '../../Scollbars';
import { useComponentHeight } from '../hooks';

export interface WhatsNewProps extends PropsWithChildren<any> {
  elevation?: number | undefined;
  borderRadius?: number | undefined;
  padding?: number | undefined;
  spacing?: number | undefined;
}

export const WhatsNew = ({ children, elevation = 0, padding, spacing = 4, borderRadius }: WhatsNewProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { state } = useComponentHeight({ ref: containerRef });
  const theme = useTheme();

  return (
    <Paper
      elevation={elevation}
      className="hidden-scrollbar"
      sx={{ height: '100%', width: '100%', borderRadius, background: theme.palette.backgrounds.primary }}
      ref={containerRef}
    >
      <VerticalScrollbars autoHide>
        <Stack spacing={spacing} sx={{ padding, height: '100%', width: '100%' }} alignContent="center">
          {typeof children == 'object' ? children : React.cloneElement(children(state))}
        </Stack>
      </VerticalScrollbars>
    </Paper>
  );
};
