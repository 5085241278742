import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGraphClient } from '@services';
import { getRootSectionItem, ItemDataWithPaging, RefreshAction } from '~/utilities';
import { DrilldownState } from '~/components/Tree/Renderers/DrilldownHandler';
import { useSubscribe } from '~/hooks';
import Drilldown from './Drilldown';

const SimpleDrilldown = () => {
  const {
    state: { location, refreshStamp, newItems, itemCount },
  } = useLocation() as DrilldownState;
  const gcl = useGraphClient();
  const get = useCallback(
    async (token?: string): Promise<ItemDataWithPaging> => {
      const nrefreshStamp = await RefreshAction.markRefreshStamp(location, refreshStamp);
      return await location.fetchChildren({
        gcl,
        refreshStamp: nrefreshStamp,
        itemCount,
        isDrilldown: true,
        sortDir: true,
        pageToken: token,
      });
    },
    [location, refreshStamp, gcl, itemCount]
  );
  const [coldRefreshstamp, setColdRefreshStamp] = useState(0);
  const settings = getRootSectionItem(location)?.Settings;
  useSubscribe(settings?.sKey, () => settings?.refreshRequired && setColdRefreshStamp(coldRefreshstamp + 1));
  return (
    <Drilldown
      key={`${location.apiIdKey}-${location.isAdvancedSearchContainer}-${coldRefreshstamp}`}
      enableToolbarActions={true}
      newItems={newItems}
      location={location}
      getItems={get}
    />
  );
};

export default SimpleDrilldown;
