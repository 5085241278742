import { FavoriteEmptyStateDark, FavoriteEmptyStateLight, FavoriteMedium, IconProps } from '@storybook';
import { strings } from '@vendor';
import {
  ItemData,
  EmptyStateItem,
  BaseItem,
  treeitemFirstParent,
  ItemDataWithPaging,
  FetchChildrenProps,
} from '../itemTypes';
import { createItemDataFromJson, cloneItemData } from '../misc';
import { ItemListChangesType, ItemsListChangedEvent } from '../actions';
import { BaseRootSectionItem } from './RootSectionItem';
import { BaseRootSectionManager } from './RootSectionManager';

class FavoritesStateManager extends BaseRootSectionManager<Map<string, ItemData>> {
  constructor() {
    super('AllFavorites', () => new Map<string, ItemData>(), 'AllFacorites');
  }

  protected stringify(val) {
    return JSON.stringify([...val.values()].map(f => f.toJson()));
  }

  protected transformValue(val: any) {
    const vals: BaseItem[] = val.map(f => createItemDataFromJson(f));
    vals.forEach(v => {
      v.setOwnPrefix();
      v.isTransientLocation = !v.isDocument;
    });
    return new Map<string, ItemData>(vals.map(f => [f.apiIdKey, f]));
  }

  isFavorite(fav: ItemData) {
    return this.value?.has(fav.apiIdKey);
  }

  protected override internalRemove(favs: string[]) {
    const clonedFavs = new Map(this.value);
    favs.forEach(fav => clonedFavs.delete(fav));
    this.value = clonedFavs;
  }

  private cloneAndPrepare(fav: ItemData, timestamp?: number) {
    const cloned = cloneItemData(fav);
    (cloned as BaseItem).setOwnPrefix();
    cloned.isTransientLocation = !cloned.isDocument;
    cloned.timeStamp = timestamp;
    return cloned;
  }

  update(key: string, fav: ItemData) {
    const prev = this.value.get(key);
    if (prev === undefined) return false;
    this.value.delete(key);
    this.value.set(fav.apiIdKey, this.cloneAndPrepare(fav, prev.timeStamp));
    return true;
  }

  add(fav: ItemData, timeStamp?: number) {
    const cloned = this.cloneAndPrepare(fav, timeStamp);
    const favArray = Array.from(this.value);
    favArray.unshift([fav.apiIdKey, cloned]);
    this.refreshRequired = false;
    this.value = favArray.reduce(function (map, [key, value]) {
      map.set(key, value);
      return map;
    }, new Map());
    return cloned;
  }
}

export class FavoritesRoot extends BaseRootSectionItem {
  private static _instance: FavoritesRoot;
  private static _allFavorites?: FavoritesStateManager;

  static get Settings(): FavoritesStateManager {
    if (this._allFavorites === undefined) this._allFavorites = new FavoritesStateManager();
    return this._allFavorites;
  }
  static get instance() {
    if (this._instance === undefined) this._instance = new FavoritesRoot();
    return this._instance;
  }

  private constructor() {
    super({ type: 'favoritesroot', id: 'root', name: strings.lang.nodeNames.favorites });
    this.rootPrefix = 'favorites:';
    this.timeStamp = Date.now();
    PubSub.subscribe(ItemsListChangedEvent, (_nad, { updated, deleted, location }: ItemListChangesType) => {
      if (location.rootPrefix == this.rootPrefix) return;

      // Follow up to see if a favorite item was modified or deleted
      const favSettings = FavoritesRoot.Settings;
      let isUpdated = false;
      if (updated) Object.keys(updated).forEach(k => (isUpdated = favSettings.update(k, updated[k] || isUpdated)));
      if (deleted) favSettings.remove(Object.keys(deleted));
      if (isUpdated) favSettings.save();
    });
  }

  override get name() {
    return strings.lang.nodeNames.favorites;
  }
  override get Settings() {
    return FavoritesRoot.Settings;
  }
  override get supportSearch(): boolean {
    return false;
  }
  get quickSearchPlaceholder(): string {
    return strings.lang.quickSearch.favorites;
  }
  override get canRefresh(): boolean {
    return false;
  }
  override get showNewIndicator(): boolean {
    for (const val of FavoritesRoot.Settings.value.values()) {
      if (this.isNewItem(val)) return true; // No 'some' method for map iterators in current tsc version
    }
    return false;
  }
  private isNewItem(val: ItemData) {
    return (val.timeStamp && val.timeStamp > this.timeStamp!) || undefined;
  }

  override markAsUpdated(): void {
    this.timeStamp = Date.now();
  }

  override applyEmptyState(items: ItemData[], isDrilldown?: boolean): ItemData[] {
    return new EmptyStateItem({
      inTree: true,
      name: 'favorites',
      images: { light: FavoriteEmptyStateLight, dark: FavoriteEmptyStateDark },
      isDrilldown,
    }).apply(items, items.length === 0);
  }

  override async fetchChildren({ isDrilldown }: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const allFavs = [...FavoritesRoot.Settings.value.values()];
    allFavs.forEach(val => {
      val.isNew = this.isNewItem(val);
      val.itemFirstParent = treeitemFirstParent.Favorite;
    });

    this.markAsUpdated();

    return {
      items: this.applyEmptyState(
        allFavs.sort((u, v) => (v.timeStamp || 0) - (u.timeStamp || 0)),
        isDrilldown
      ),
    };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: FavoriteMedium, isColorable: true };
  }
}
