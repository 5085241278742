import { useCallback } from 'react';
import { WhatsNewNotificationSeen } from '~/modules/Settings/SettingContext';

export const useWhatsNewNotifications = () => {
  const getSeen = useCallback((): string[] => {
    return WhatsNewNotificationSeen.value || [];
  }, []);

  const setSeen = useCallback((notifications: string[]) => {
    WhatsNewNotificationSeen.value = notifications;
  }, []);

  const markAsSeen = useCallback(
    (notification: string) => {
      const seen = getSeen();
      setSeen([...seen, notification]);
    },
    [getSeen, setSeen]
  );

  const isSeen = useCallback(
    (notification: string) => {
      return getSeen().includes(notification);
    },
    [getSeen]
  );

  const clearAll = useCallback(() => {
    setSeen([]);
  }, [setSeen]);

  return {
    isSeen,
    markAsSeen,
    clearAll,
  };
};
