/* eslint-disable react/display-name */
import React, { useCallback, forwardRef, useEffect, useMemo } from 'react';
import { styled } from '@mui/system';
import { InputAdornment, TextField, Chip } from '@mui/material';
import { useTheme } from '@harmon.ie-ce/storybook';
import { strings } from '@vendor';
import { SearchMedium, XSmall } from '../../assets/icons';
import { Button } from '../Button';
import { Tooltip } from '../Tooltip';
import { SearchProps } from '../QuickSearch/QuickSearchBody';

const zIndex = 100;

const SearchInputComponent = styled(TextField, {
  shouldForwardProp: prop => prop !== 'isWrapped',
})<{ isWrapped?: boolean }>`
  ${({ theme, isWrapped }) => `
  .MuiInputBase-root {
    padding: ${theme.spacing(2)};
    background: ${isWrapped ? 'transparent' : theme.palette.backgrounds.tertiary};
    border-radius: ${isWrapped ? 0 : 10}px;
    width: 100%;
    cursor: default;
    z-index: ${zIndex + 1};
    box-shadow: none !important;

    & .MuiOutlinedInput-notchedOutline {
      border-width: 0;
      display: none;
    }

    & input {
        font-size: 14px;
        line-height: 20px;
        cursor: default;
        font-weight: 440;
        padding: 0px 1px;
        color: ${theme.palette.texts.primary};

        /* Ensuring ellipsis for long placeholder */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        min-width: 0;

        :focus {
          outline: none;
        }

        &::placeholder {
            color: ${theme.palette.texts.placeHolderSecondary};
            opacity: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            min-width: 0;
        }
      };

      & svg {
          fill: ${theme.palette.texts.placeHolderSecondary};
      };

      &:hover {
        & input::placeholder {
          color: ${theme.palette.texts.quaternary};
        }
        & svg {
          fill: ${theme.palette.texts.quaternary};
        }
      }
    }
  `}
`;

type SearchInputInterface = {
  searchState: [SearchProps, React.Dispatch<React.SetStateAction<SearchProps>>];
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  handleSearchFocus?: () => void;
  placeholder?: string;
  onLeave?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  clearTooltipText?: string;
  clearFilterText?: string;
  withChip?: React.ReactElement;
  withFilter?: React.ReactElement;
  isWrapped?: boolean;
  onEnter: any;
  disabled?: boolean;
};

const SearchInput = forwardRef<HTMLDivElement, SearchInputInterface>(
  (
    {
      searchState,
      isWrapped,
      placeholder = strings.lang.quickSearch.generalSearch,
      clearTooltipText = strings.lang.quickSearch.clearTooltipText,
      clearFilterText = 'Click to remove',
      onEnter,
      withChip,
      withFilter,
      handleSearchFocus,
      onLeave,
      inputRef,
      disabled,
    },
    searchRef
  ) => {
    const [searchProps, setSearchProps] = searchState;

    const clearFilter = useCallback(() => {
      setSearchProps(data => ({ ...data, filters: '', entityTypes: [] }));
    }, [setSearchProps]);

    const setSearchString = useCallback(
      searchTerm => {
        setSearchProps(data => ({ ...data, searchTerm }));
      },
      [setSearchProps]
    );

    const clearInput = useCallback(() => {
      searchState[1]({ searchTerm: '', filters: '', entityTypes: [] });
      inputRef.current?.focus();
    }, [inputRef, searchState]);

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event?.key === 'Escape' && onLeave) {
        clearInput();
        onLeave(event);
      } else if (event?.key === 'Enter') {
        onEnter();
        inputRef.current?.blur();
      } else if (
        (event?.key === 'Backspace' || event?.key === 'Delete') &&
        Boolean(withFilter) &&
        searchProps.searchTerm?.length === 0
      ) {
        clearFilter();
      }
      return null;
    };

    useEffect(() => {
      const handleKeyDown = e => {
        if (e.key === 'f' && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          e.stopPropagation();
        }

        if (e.key === 'k' && (e.ctrlKey || e.metaKey) && !disabled) {
          e.preventDefault();
          inputRef.current?.focus();
          handleSearchFocus?.();
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [disabled, handleSearchFocus, inputRef]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchString(event.target.value);
    };

    const theme = useTheme();

    const StartWithFilter = useMemo(
      () => (
        <InputAdornment position="start">
          <Tooltip title={clearFilterText}>
            <Chip size="small" onClick={clearFilter} disabled={disabled} icon={withFilter} />
          </Tooltip>
        </InputAdornment>
      ),
      [clearFilterText, clearFilter, disabled, withFilter]
    );

    const StartWithSearch = useMemo(
      () => (
        <InputAdornment position="start">
          {Boolean(withFilter) ? (
            <Tooltip title={clearFilterText}>
              <Chip size="small" onClick={clearFilter} disabled={disabled} icon={withFilter} />
            </Tooltip>
          ) : (
            <SearchMedium />
          )}
        </InputAdornment>
      ),
      [withFilter, clearFilterText, clearFilter, disabled]
    );

    const EndWithClearButton = useMemo(
      () =>
        disabled ? null : (
          <InputAdornment position="end">
            <Tooltip title={clearTooltipText}>
              <Button variant="IconButtons" onClick={clearInput} sx={{ color: theme.palette.texts.quaternary }}>
                <XSmall />
              </Button>
            </Tooltip>
          </InputAdornment>
        ),
      [clearTooltipText, clearInput, theme.palette.texts.quaternary, disabled]
    );

    const EndWithChip = useMemo(() => <InputAdornment position="end">{withChip}</InputAdornment>, [withChip]);

    const startAdornment = useMemo(
      () =>
        searchProps?.searchTerm
          ? Boolean(withFilter)
            ? StartWithFilter
            : null
          : isWrapped
            ? Boolean(withFilter)
              ? StartWithFilter
              : null
            : StartWithSearch,
      [searchProps, withFilter, isWrapped, StartWithFilter, StartWithSearch]
    );

    const endAdornment = useMemo(
      () =>
        searchProps?.searchTerm
          ? EndWithClearButton
          : Boolean(withFilter)
            ? EndWithClearButton
            : Boolean(withChip) && !isWrapped
              ? EndWithChip
              : null,
      [searchProps?.searchTerm, EndWithClearButton, withFilter, withChip, isWrapped, EndWithChip]
    );

    return (
      <SearchInputComponent
        isWrapped={isWrapped}
        ref={searchRef}
        disabled={disabled}
        inputRef={inputRef}
        variant="outlined"
        placeholder={placeholder}
        value={searchProps.searchTerm}
        onKeyDown={onKeyDown}
        onChange={handleSearchChange}
        onClick={handleSearchFocus}
        fullWidth
        inputProps={{
          autoCapitalize: 'off',
          autoCorrect: 'off',
        }}
        InputProps={{
          startAdornment,
          endAdornment,
        }}
      />
    );
  }
);

export default SearchInput;
