import React, { useState } from 'react';
import { MenuAction, SortMedium, ViewItem } from '@storybook';
import { strings } from '@vendor';
import { ActionRendererHookType, BaseReadActionClass, ItemData } from '~/utilities';
import { SortMenuAction } from '~/components/SortMenu/SortMenuAction';

export const generateSubMenuSortAction = (): MenuAction => {
  return {
    title: strings.lang.toolbarActions.sort,
    Icon: <SortMedium />,
    type: 'item',
    children: [
      {
        title: strings.lang.toolbarActions.sort,
        type: 'item',
        keepOpenOnSelection: true,
        render: <SortMenuAction headless={true} />,
      },
    ],
  };
};

const useLocationSort: ActionRendererHookType = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return {
    title: strings.lang.toolbarActions.sort,
    icon: SortMedium,
    onClick: (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    opened: Boolean(anchorEl),
    SideUI: <SortMenuAction anchorEl={anchorEl} setAnchorEl={setAnchorEl} />,
  };
};

export class LocationSortAction extends BaseReadActionClass {
  readonly trackedName = 'Sort';
  readonly useRenderer = useLocationSort;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes[0].data.hasFolder;
  }
}
