import React from 'react';
import { Skeleton, Stack, Box, useTheme } from '@mui/material';

const HomeSkeleton = () => {
  const theme = useTheme();
  return (
    <Stack spacing={4} padding={4} sx={{ background: theme.palette.backgrounds.primary }} height={'100%'}>
      {/* Search bar skeleton */}
      <Box display="flex" alignItems="center" gap={1}>
        <Skeleton variant="rectangular" width={300} height={40} sx={{ borderRadius: 2 }} />
      </Box>
      <Stack spacing={4}>
        <Stack spacing={2}>
          {/* Toolbar */}
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Skeleton variant="text" width={120} height={30} />
            <Stack flex={1} direction="row" justifyContent="flex-end" spacing={1} alignItems="center">
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton variant="text" width={5} height={30} />
            </Stack>
          </Stack>

          {/* Favorites */}
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width={100} height={20} />
          </Box>

          {/* Recent */}
          <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
            <Box display="flex" alignItems="center" gap={1}>
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width={100} height={20} />
            </Box>
          </Box>

          {/* Shared with me */}
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width={120} height={20} />
          </Box>
        </Stack>
        <Stack spacing={2}>
          {/* Teams */}
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width={100} height={20} />
          </Box>
          {/* SharePoint */}
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width={100} height={20} />
          </Box>

          {/* OneDrive */}
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width={100} height={20} />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HomeSkeleton;
