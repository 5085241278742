import { EntityType } from '@microsoft/microsoft-graph-types';
import { GraphClient } from '@services';
import { oDirname } from '@vendor';
import { OneDriveRoot } from '../virtualRoots';

export const FilesTypesFilters = {
  Document: { name: 'OnlyFiles', filter: 'isDocument:true' },
  DOC: {
    name: 'Doc',
    filter:
      '(fileExtension:docx OR fileExtension:dot OR fileExtension:dotm OR fileExtension:dotx OR fileExtension:docb OR fileExtension:docm OR fileExtension:doc) AND isDocument:true',
  },
  PPT: {
    name: 'Ppt',
    filter:
      '(fileExtension:pptx OR fileExtension:ppt OR fileExtension:pot OR fileExtension:potm OR fileExtension:potx OR fileExtension:ppa OR fileExtension:ppam OR fileExtension:pps OR fileExtension:ppsm OR fileExtension:ppsx OR fileExtension:pptm) AND isDocument:true',
  },
  EML: {
    name: 'Eml',
    filter:
      '(fileExtension:eml OR fileExtension:msg OR fileExtension:oft OR fileExtension:ost OR fileExtension:pst) AND isDocument:true',
  },
  PDF: { name: 'Pdf', filter: 'fileExtension:pdf' },
  XLSX: {
    name: 'Xlsx',
    filter:
      '(fileExtension:xlsx OR fileExtension:xlsm OR fileExtension:xltx OR fileExtension:xltm OR fileExtension:xlsb OR fileExtension:xlam OR fileExtension:xls OR fileExtension:xlt OR fileExtension:xlc OR fileExtension:xlw) AND isDocument:true',
  },
};
export enum PersonFilters {
  PERSON = 'contentclass:STS_Person',
}
export const getOneDriveBaseUrl = async (gcl: GraphClient) => {
  try {
    const oneDriveLibrary = await OneDriveRoot.getRootLibrary(gcl);
    return oDirname(oneDriveLibrary.siteUrl);
  } catch {
    return '';
  }
};
// TODO:Share point filter does not work for now
const isTeamCondition = `(SiteTemplate:GROUP OR SiteTemplate:TEAMCHANNEL)`;
const notTeamCondition = `-SiteTemplate:GROUP AND -SiteTemplate:TEAMCHANNEL`;
export const AppsFilters = {
  TEAMS: { name: 'TeamsApp', filter: isTeamCondition },
  SHARE_POINT: async (gcl: GraphClient, withTeams = false) => {
    return {
      name: 'SharePoint',
      filter: `-Path:${await getOneDriveBaseUrl(gcl)}${withTeams ? '' : ` AND ${notTeamCondition}`}`,
    };
  },
  ONE_DRIVE: async (gcl: GraphClient) => {
    return { name: 'OneDrive', filter: `Path:${await getOneDriveBaseUrl(gcl)}` };
  },
};

export const ContainerFilters = {
  SITE: { name: 'Site', entityTypes: ['site'] as EntityType[] },
  Library: { name: 'Library', entityTypes: ['list'] as EntityType[], aggregationFilters: notTeamCondition },
  Folder: {
    name: 'Folder',
    entityTypes: ['listItem'] as EntityType[],
    aggregationFilters: `isDocument:false AND isContainer:true`,
  },
  Team: { name: 'Team' },
  Channel: { name: 'Channel' },
};
