import MuiTooltip from './MuiTooltip';
import MuiToolbar from './MuiToolbar';
import MuiTypography from './MuiTypography';
import MuiAlert from './MuiAlert';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiListItemIcon from './MuiListItemIcon';
import MuiList from './MuiList';
import MuiButton from './MuiButton';
import MuiAppBar from './MuiAppBar';
import MuiPopover from './MuiPopover';
import MuiSlider from './MuiSlider';
import MuiDialog from './MuiDialog';
import MuiDialogTitle from './MuiDialogTitle';
import MuiDialogContent from './MuiDialogContent';
import MuiCheckbox from './MuiCheckbox';
import MuiChip from './MuiChip';
import MuiBackdrop from './MuiBackdrop';
import MuiDialogActions from './MuiDialogActions';
import MuiSnackbar from './MuiSnackbar';
import MuiTextField from './MuiTextField';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiBreadcrumbs from './MuiBreadcrumbs';
import MuiAccordionSummary from './MuiAccordionSummary';
import MuiAccordion from './MuiAccordion';
import MuiAccordionDetails from './MuiAccordionDetails';
import MuiAvatar from './MuiAvatar';
import MuiRadio from './MuiRadio';
import MuiSwitch from './MuiSwitch';
import MuiTab from './MuiTab';
import MuiSelect from './MuiSelect';

export default theme => {
  return {
    MuiTooltip: MuiTooltip(theme),
    MuiToolbar: MuiToolbar(theme),
    MuiTypography: MuiTypography(theme),
    MuiAlert: MuiAlert(theme),
    MuiMenu: MuiMenu(theme),
    MuiBackdrop: MuiBackdrop(theme),
    MuiMenuItem: MuiMenuItem(theme),
    MuiListItemIcon: MuiListItemIcon(theme),
    MuiList: MuiList(theme),
    MuiButton: MuiButton(theme),
    MuiAppBar: MuiAppBar(theme),
    MuiPopover: MuiPopover(theme),
    MuiSlider: MuiSlider(theme),
    MuiDialog: MuiDialog(theme),
    MuiDialogTitle: MuiDialogTitle(theme),
    MuiDialogActions: MuiDialogActions(theme),
    MuiDialogContent: MuiDialogContent(theme),
    MuiCheckbox: MuiCheckbox(theme),
    MuiTextField: MuiTextField(theme),
    MuiOutlinedInput: MuiOutlinedInput(theme),
    MuiSnackbar: MuiSnackbar(theme),
    MuiBreadcrumbs: MuiBreadcrumbs(theme),
    MuiAccordionSummary: MuiAccordionSummary(theme),
    MuiAccordion: MuiAccordion(theme),
    MuiAccordionDetails: MuiAccordionDetails(theme),
    MuiChip: MuiChip(theme),
    MuiAvatar: MuiAvatar(theme),
    MuiRadio: MuiRadio(theme),
    MuiSwitch: MuiSwitch(theme),
    MuiTab: MuiTab(theme),
    MuiSelect: MuiSelect(theme),
  };
};
