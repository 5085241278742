import { GraphClient, GValue, runBatchOnList } from '@services';
import { formatBytes, logError } from '@vendor';
import { CustomIcon, getFileIcon, IndicatorRequestHandler, MailMedium, SelectItem } from '@storybook';
import MsgReader from '@kenjiuno/msgreader';
import { strings } from '@vendor';
import { Attachment, Message, Recipient } from '@microsoft/microsoft-graph-types';
import { SelectedItemType, convertToEwsId } from '~/misc';
import { UseUniqueNames } from '~/modules/Settings/SettingContext';
import { DocumentItem, Folder, ItemContainer } from '../itemTypes';
import { RefreshAction } from '../actions/RefreshAction';
import { formatDateTime } from '../actions/ActionsUtils';

export type MSGraphEmailProps = Message;

export interface UploadItemProps {
  name: string;
  data: Blob;
  itemId?: string;
  emailFields?: MSGraphEmailProps;
}
export interface EmailProps {
  subject?: string;
  from?: string;
  isFromExist?: boolean;
}
export interface EmailsAttachmentsResponse {
  emailsItems: SelectItem[];
  hasAttachments: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MSGRaphEmailPropsSelect = [
  'id',
  'subject',
  'sender',
  'hasAttachments',
  'toRecipients',
  'ccRecipients',
  'sentDateTime',
  'conversationIndex',
  'singleValueExtendedProperties ',
];
const extractMessageId = (emlContent: string): string | undefined => {
  // Regular expression to match the Message-ID pattern
  const messageIdRegex = /^Message-ID:\s*<?([^>\r\n]+)>?/im;

  // Search the .eml content for the Message-ID header using regex
  const match = emlContent.match(messageIdRegex);

  // If a match is found, return the Message-ID inside angle brackets; otherwise, return undefined
  return match ? `<${match[1]}>` : undefined;
};

// Function to read the .eml file, extract, and convert the Message-ID
const extractAndConvertMessageIdFromEml = async (file: File): Promise<string | undefined> => {
  try {
    // Read the file content as text
    const fileContent = await file.text();

    // Extract the Message-ID using the extractMessageId function
    const messageId = extractMessageId(fileContent);

    if (messageId) {
      // Convert the Message-ID to Internet Message ID format
      const internetMessageId = convertToEwsId(messageId);
      return internetMessageId;
    } else {
      return undefined;
    }
  } catch (error) {
    logError('Error reading .eml file');
    return undefined;
  }
};

export const readableStreamToBlob = async readableStream => {
  const chunks: Uint8Array[] = [];
  const reader = readableStream.getReader();
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    chunks.push(value as Uint8Array);
  }
  return new Blob(chunks);
};

export const extractSubjectAndFrom = (input: string): EmailProps => {
  // Split the string by newline to get each line
  const lines = input.split('\n');
  const isFromExist = lines[0].split('\t')[0] === 'From';
  // Check if there is more than one line (to avoid header line)
  if (lines.length > 1) {
    // Split the second line (first data line) by tab
    const data = lines[1].split('\t');

    // Extract the 'From' and 'Subject' fields
    const from = data[0];
    const subject = data[1];

    return { from, subject, isFromExist: isFromExist };
  }

  // Return undefined if the fields cannot be extracted
  return { from: undefined, subject: undefined, isFromExist: false };
};

export const uploadDocumentToSharePoint = async (
  gcl: GraphClient,
  folder: Folder,
  fileName: string,
  fileIndex: number,
  data: Blob,
  override?: boolean,
  progressRequestHandler?: IndicatorRequestHandler
): Promise<DocumentItem> => {
  try {
    const newItem = await folder.upload({
      data,
      fileName,
      fileIndex,
      gcl,
      override,
      progressRequestHandler,
    });
    RefreshAction.markRefreshStamp(folder, Date.now());
    return newItem;
  } catch (e) {
    throw e;
  }
};

export const getOfficeItem = (selectedItem?: SelectedItemType) => {
  const item = Office.context.mailbox.item;
  return selectedItem || item;
};

export const responseToFile = async (attachment: any): Promise<File> => {
  try {
    const byteCharacters = atob(attachment.contentBytes);
    const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: attachment.contentType });
    const file = new File([blob], attachment.name, {
      type: attachment.contentType,
      lastModified: Date.now(),
    });
    return file;
  } catch (e) {
    logError(e);
    throw Error("Couldn't read attachment content");
  }
};

const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
      if (loadEvent.target?.result instanceof ArrayBuffer) {
        resolve(loadEvent.target.result);
      } else {
        reject(new Error('Array buffer error'));
      }
    };
    reader.onerror = () => reject(new Error('File reader error'));
    reader.readAsArrayBuffer(file);
  });
};
const prepareUploadItemProps = (emailData: Blob, emailProps: MSGraphEmailProps, itemId: string): UploadItemProps => {
  // Prepare the file name
  let fileName = `${emailProps.subject || 'Untitled'}`;
  if (UseUniqueNames.value && emailProps.sender?.emailAddress?.name && emailProps.sentDateTime) {
    fileName = `${fileName}_${formatDateTime(emailProps.sentDateTime)}_${emailProps.sender.emailAddress.name}`;
  }

  // Return the complete upload item props with email data and properties
  return {
    data: emailData,
    name: `${fileName}.eml`,
    itemId: itemId,
    emailFields: { ...emailProps },
  };
};

// Helper function to prepare email properties and fetch the content
const prepareEmailProps = async (
  itemID: string,
  gcl?: GraphClient,
  existingProps?: MSGraphEmailProps
): Promise<{ emailData: Blob; emailProps: any }> => {
  // Fetch the email content as a stream
  const emailDataStream = gcl && (await gcl.api(`/me/messages/${itemID}/$value`).getStream());
  const emailData = await readableStreamToBlob(emailDataStream);

  // Use existing props if available, otherwise fetch them
  const emailProps =
    existingProps ||
    (gcl &&
      (await gcl
        .api(`/me/messages/${itemID}`)
        .expand(`singleValueExtendedProperties($filter=id eq 'string 0x0070')`)
        .get()));
  return { emailData, emailProps };
};

// Function to fetch email ID using message ID and fetch properties if available
const getEmailIdAndProps = async (
  gcl: GraphClient,
  itemMessageId: string,
  isEmlFile?: boolean
): Promise<{ id: string; props?: any }> => {
  const emailProps = await gcl
    .api(`/me/messages`)
    .filter(`internetMessageId eq '${itemMessageId}'`)
    .expand(`singleValueExtendedProperties($filter=id eq 'string 0x0070')`)
    .get();
  // If exactly one email is found, return its ID and props directly
  if (emailProps.value.length === 1) {
    return { id: emailProps.value[0].id, props: emailProps.value[0] };
  }
  if (isEmlFile) {
    itemMessageId = itemMessageId.replaceAll('_', '+');
  }
  // Handle emails from external sources like Gmail with differing IDs
  let filter = '';
  const imid: string[] = itemMessageId.split('+');
  for (let index = 0; index < imid.length; index++) {
    filter +=
      index === 0
        ? `startsWith(internetMessageId, '${imid[index]}')`
        : ` and contains(internetMessageId, '${imid[index]}')`;
  }

  const res = await gcl
    .api(`/me/messages`)
    .filter(filter)
    .expand(`singleValueExtendedProperties($filter=id eq 'string 0x0070')`)
    .get();
  if (res.value.length === 0) throw new Error('Email not found.');

  // Return the found email ID and props
  return { id: res.value[0].id, props: res.value[0] };
};

// Main function to handle MSG file reading and fetching corresponding email data and properties
export const readMsgEmailAndGetBlob = async (file: File, gcl: GraphClient): Promise<UploadItemProps | undefined> => {
  const arrayBuffer = await readFileAsArrayBuffer(file);
  let messageId: string | undefined;
  let isEmlFile = false;
  if (file.name.endsWith('.msg')) {
    const msg = new MsgReader(arrayBuffer);
    const msgInfo = msg.getFileData();
    messageId = msgInfo.messageId;
  } else if (file.name.endsWith('.eml')) {
    messageId = await extractAndConvertMessageIdFromEml(file);
    isEmlFile = true;
  }
  if (messageId) {
    // Fetch email ID and properties in a single step if possible
    const { id: itemId, props: existingProps } = await getEmailIdAndProps(gcl, messageId, isEmlFile);

    // Fetch the email content and reuse the existing properties
    const { emailData, emailProps } = await prepareEmailProps(itemId, gcl, existingProps);

    return prepareUploadItemProps(emailData, emailProps, itemId);
  }

  return undefined;
};

// Main function to fetch email data directly when the email ID is already known
export const getEmailData = async (
  selectedItem?: SelectedItemType,
  gcl?: GraphClient
): Promise<UploadItemProps | undefined> => {
  try {
    const emailItem = getOfficeItem(selectedItem);
    const itemID = convertToEwsId(emailItem?.itemId);

    // Use the prepareEmailProps function to fetch content and properties
    const { emailData, emailProps } = await prepareEmailProps(itemID, gcl);

    return prepareUploadItemProps(emailData, emailProps, itemID);
  } catch (e: any) {
    if (e.statusCode === 404) {
      throw new Error('Email not found.');
    } else throw e;
  }
};

export const readFile = (item: File, _gcl: GraphClient): Promise<UploadItemProps> | UploadItemProps => {
  const data = new Blob([item], { type: item.type });
  return { data: data, name: item.name };
};

export const addCategoryToMessage = async (gcl: GraphClient, messageId: string, folder: ItemContainer) => {
  const itemPath =
    folder.OfficeAppLocated === 'SP'
      ? `${folder.pathOrDescription?.replace('SP/', 'SharePoint/')}/`
      : folder.pathOrDescription;
  const normalizedItemPath = `${strings.lang.labels.category} ${itemPath?.endsWith('/')}`
    ? itemPath?.slice(0, -1)
    : itemPath;
  const category = `${strings.lang.labels.category} ${normalizedItemPath}`;
  try {
    const res = await gcl.api(`/me/messages/${messageId}`).select('categories').get();
    if (res.categories.includes(category)) return;
    await gcl.api(`/me/messages/${messageId}`).patch({ categories: [category, ...res.categories] });
  } catch (e) {
    logError(e);
  }
};
export const verifyRecipient = (recipients?: Recipient[]) => {
  const max_length = 255;
  let recipient_string = '';
  for (const recipient of recipients ?? []) {
    let formattedEmail = '';

    if (recipient.emailAddress) {
      formattedEmail =
        recipient.emailAddress.name && recipient.emailAddress.name !== recipient.emailAddress.address
          ? `${recipient.emailAddress.name} <${recipient.emailAddress.address}>`
          : `${recipient.emailAddress.address}`;
    }
    if (recipient_string.length + formattedEmail.length + 2 > max_length) {
      // +2 for ", "
      break;
    }
    if (recipient_string) {
      recipient_string += ', ';
    }
    recipient_string += formattedEmail;
  }

  return recipient_string;
};
// Function to extract the Message-ID from .eml content

export const getEmailsAttachments = async (
  gcl: GraphClient,
  items: SelectedItemType[]
): Promise<EmailsAttachmentsResponse> => {
  try {
    const allResults: SelectItem[] = [];
    let hasAttachments = false;
    const allAttachments = await runBatchOnList<SelectedItemType, GValue<Attachment>>(
      gcl,
      items,
      async (gb, item) =>
        await gb
          .api(`/me/messages/${convertToEwsId(item.itemId)}/attachments`)
          .select(['id', 'name', 'size', 'isInline', 'contentType'])
          .get()
    );
    const emailAttachmentMap: { [key: string]: SelectItem[] } = {};
    // Process the fetched attachments
    for (let i = 0; i < items.length; i++) {
      const { value: attachments } = allAttachments[i];
      const emailItem = items[i];
      const finalEmailAttachments = attachments.filter((attachment: Attachment) => !attachment.isInline);
      hasAttachments ||= finalEmailAttachments.length > 0;

      // Convert attachments into SelectItems and map them to their parent email
      emailAttachmentMap[emailItem.itemId] = finalEmailAttachments.map(attachment => {
        const iconProps = getFileIcon(attachment.name!);
        return {
          id: attachment.id!,
          name: attachment.name || 'Unknown Attachment',
          parentId: convertToEwsId(emailItem.itemId),
          checked: false,
          isParentItem: false, // Mark as an attachment, not an email
          Icon: iconProps.isColorable ? CustomIcon(iconProps.icon) : iconProps.icon,
          description:
            (attachment.size && `${strings.lang.selectItemsModal.itemSize}: ${formatBytes(attachment.size)} `) || '',
          contentType: attachment.contentType,
        };
      });
    }
    for (const item of items) {
      const emailSelectItem: SelectItem = {
        id: item.itemId,
        name: item.subject || 'Email',
        checked: true,
        parentId: convertToEwsId(item.itemId),
        isParentItem: true,
        Icon: CustomIcon(MailMedium),
        description: item.subject || 'No subject',
      };
      allResults.push(emailSelectItem);
      if (emailAttachmentMap[item.itemId]) {
        allResults.push(...emailAttachmentMap[item.itemId]);
      }
    }
    return { emailsItems: allResults, hasAttachments: hasAttachments };
  } catch (error) {
    logError(`Error fetching email attachments: ${error}`);
    throw error;
  }
};

export const getEmailAttachmentContent = async (item: SelectItem, gcl: GraphClient): Promise<Blob> => {
  const response = await gcl.api(`/me/messages/${item.parentId}/attachments/${item.id}/$value`).getStream();
  const attachmentsData = await readableStreamToBlob(response);
  return attachmentsData;
};
