import { AttachmentMedium, IndicatorRequestHandler, MenuAction, Primary, ViewItem } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor';
import { useContext } from 'react';
import React from 'react';
import { OfficeMode, officeMode } from '~/misc';
import IndicatorContext from '~/context/IndicatorContext';
import { getEmptyHandlers } from '~/components/Tree/Renderers/QuickSearchHandler';
import { ItemData } from '../itemTypes';
import {
  BaseAction,
  BaseReadActionClass,
  KeyModifiers,
  SelectedItemHandler,
  ShortcutAction,
  ShortcutActionRendererHookType,
} from './BaseAction';
import { emailWithAttachment } from './ActionsUtils';
import { isMacOsNative } from '../misc';
import { RenderActionByHook } from './ContextMenuAction';
import { EmailWithLink } from './EmailWithLink';
const publishEmailAttachmentsActions = (
  progressRequestHandler: IndicatorRequestHandler | undefined,
  nodes: ViewItem<ItemData>[]
) => {
  const getActionsRender = () => {
    const action = new EmailWithLink();
    return [
      {
        render: (
          <RenderActionByHook
            key={`action-${action.constructor.name}`}
            action={action}
            actionVariant={Primary}
            trigger="Indicator"
            nodes={nodes}
            handlers={getEmptyHandlers(nodes[0].data)}
          />
        ),
      },
    ];
  };
  const indicatorActions = getActionsRender() as MenuAction[];
  progressRequestHandler?.setIndicatorActions(indicatorActions);
};
const useEmailWithAttachment: ShortcutActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const gcl = useGraphClient();
  const {
    errorfulProcessIndication,
    oneItemCompletedIndication,
    oneItemStartIndication,
    progressRequestHandler,
    startProgressIndication,
    successfulProcessIndication,
    applyClassName,
  } = useContext(IndicatorContext);
  const onClick = useSafeCallback(
    async () => {
      if (nodes.length > 1 && applyClassName) applyClassName('footer-indications');
      startProgressIndication?.(
        nodes.length,
        strings.lang.addAttachmentsIndicatorTexts,
        nodes.map(node => node.data.name)
      );
      try {
        for (let i = 0; i < nodes.length; i++) {
          if (progressRequestHandler?.needToCancel()) {
            return;
          }
          if (progressRequestHandler?.getCurrentProgressIndex())
            progressRequestHandler.getCurrentProgressIndex().current = i;
          oneItemStartIndication?.(i);
          await emailWithAttachment(gcl, nodes[i].data as ItemData);
          oneItemCompletedIndication?.(i);
        }
      } catch (e) {
        publishEmailAttachmentsActions(progressRequestHandler, nodes);
        throw e;
      }
      successfulProcessIndication?.();
    },
    [
      applyClassName,
      gcl,
      nodes,
      oneItemCompletedIndication,
      oneItemStartIndication,
      progressRequestHandler,
      startProgressIndication,
      successfulProcessIndication,
    ],
    false,
    {},
    undefined,
    errorfulProcessIndication,
    nodes.length > 1 ? 'footer-indications' : ''
  );
  return {
    title: nodes.length == 1 ? strings.lang.actionToolTip.addAttachment : strings.lang.actionToolTip.addAttachments,
    icon: AttachmentMedium,
    onClick,
    chipLabel: isMacOsNative() ? strings.lang.shortcuts.addAttachmentMac : strings.lang.shortcuts.addAttachment,
  };
};

export class EmailWithAttachment extends BaseReadActionClass implements SelectedItemHandler, ShortcutAction {
  readonly trackedName = 'EmailWithAttachment';
  readonly useRenderer = useEmailWithAttachment;
  isHandled = BaseAction.isMultiHandled(this);

  isSingleHandled = (d: ItemData) => officeMode !== OfficeMode.Read && d.canOpenInBrowser && d.isDocument;

  shortcut: [KeyModifiers, string] = [KeyModifiers.Alt, isMacOsNative() ? 'å' : 'a'];
}
