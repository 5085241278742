import React from 'react';
import { TreeHandlers } from '@storybook';
import { TreeNode } from 'react-hyper-tree/dist/helpers/node';
import { AllowedItemType, ItemData, isItemContainer } from '~/utilities';
import { DragAndDrop } from '~/modules/DragAndDrop';
import { ItemDataRenderProps, ItemDataRenderer, RenderNodeWithNextDragAndDropProps } from './GetNodeRenderer';
import { handleNodeClosing, saveNodeState } from './ExpandHandler';
import { getHarmonieTree } from '../Tree';

export const DragAndDropRenderer = (props: RenderNodeWithNextDragAndDropProps) => {
  const { nextRenderer, handlers, type, node } = props;
  const treeHandlers = handlers as unknown as TreeHandlers;
  const treeNode = node as unknown as TreeNode;
  const isTreeItem = typeof treeHandlers.setOpen === 'function';
  const expand = isTreeItem
    ? () => {
        if (isItemContainer(type)) {
          if (node.data.isRootSection) {
            getHarmonieTree()
              .instance.getOpenedNodes()
              .forEach(n => {
                if (n !== treeNode && (n.data as ItemData).isRootSection) {
                  handleNodeClosing(n, true);
                }
              });
          }
          treeHandlers.setOpen(treeNode as TreeNode, true);
          saveNodeState(treeNode, true);
        }
      }
    : undefined;
  return (
    <DragAndDrop
      node={props.node}
      isTreeItem={isTreeItem}
      expand={expand}
      isDrilldownWrapper={false}
      isClose={!handlers.isExpanded(node)}
      handlers={handlers}
    >
      {nextRenderer(props)}
    </DragAndDrop>
  );
};

export const renderDragAndDrop = (type: AllowedItemType, nextRenderer: ItemDataRenderer): ItemDataRenderer => {
  return (props: ItemDataRenderProps) => DragAndDropRenderer({ ...props, nextRenderer, type });
};
