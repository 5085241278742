import React from 'react';
import { Skeleton, Stack, Divider, Typography } from '@mui/material';

const SettingsSkeleton = () => {
  return (
    <Stack height="100%" spacing={7} mb={7} ml={2} mr={2}>
      {/* Account Details Section */}
      {/* Toolbar */}
      <Stack spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingLeft={1}
          paddingRight={3}
          pt={2}
        >
          <Skeleton variant="text" width={100} height={30} />
          <Stack flex={1} direction="row" justifyContent="flex-end" spacing={1} alignItems="center">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="text" width={5} height={35} />
          </Stack>
        </Stack>
        <Stack>
          <Skeleton width="100%" height={32} />
          <Skeleton width="30%" height={32} />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="flex-start">
          {/* Avatar and User Info */}
          <Stack spacing={1} sx={{ minWidth: 0, flexShrink: 1, width: '50%' }}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={130} height={20} />
            <Skeleton variant="text" width={130} height={20} />
          </Stack>
          <Divider orientation="vertical" flexItem sx={{ height: '85px', color: 'grey.300' }} />
          {/* Company Info */}
          <Stack paddingLeft={1} sx={{ minWidth: 0, flexGrow: 1 }} spacing={1}>
            <Skeleton width={40} height={40} variant="rectangular" sx={{ borderRadius: 1 }} />
            <Skeleton variant="text" width={120} height={20} />
            <Skeleton variant="text" width={120} height={20} />
          </Stack>
        </Stack>
      </Stack>

      {/* Buttons Section */}
      <Stack alignItems="flex-start">
        <Skeleton width={150} height={32} />
        <Skeleton width={150} height={32} />
        <Skeleton width={150} height={32} />
      </Stack>

      {/* Theme Selector Section */}
      <Stack spacing={3}>
        <Divider />
        <Skeleton width="30%" height={32} />
        <Stack direction="row" spacing={3}>
          <Skeleton variant="rectangular" width={60} height={60} sx={{ borderRadius: 1 }} />
          <Skeleton variant="rectangular" width={60} height={60} sx={{ borderRadius: 1 }} />
          <Skeleton variant="rectangular" width={60} height={60} sx={{ borderRadius: 1 }} />
        </Stack>
      </Stack>

      {/* Document Opening Section */}
      <Stack>
        <Typography>
          <Skeleton width="40%" height={32} />
        </Typography>
        <Typography>
          <Skeleton width="80%" height={24} />
        </Typography>
        <Stack spacing={2}>
          <Skeleton variant="text" width="100%" height={40} />
          <Skeleton variant="text" width="100%" height={40} />
          <Skeleton variant="text" width="100%" height={40} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SettingsSkeleton;
