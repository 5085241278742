import { ItemContainer } from '../itemTypes';
import { UploadItemProvider } from './UploadItemProvider';

export class FileUploadProvider extends UploadItemProvider {
  constructor(gcl: any, file: File, location: ItemContainer) {
    super(gcl, file, location);
  }

  // Implementing the method to get the item name for files
  getItemName(): string {
    this.name = (this.item as File).name;
    return this.name;
  }

  async getItemBlob(): Promise<void> {
    const file = this.item as File;
    const data = new Blob([file], { type: file.type });
    this.uploadItemProps = {
      name: file.name,
      data: data,
    };
  }
}
