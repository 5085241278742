import React from 'react';
import { Stack, Button, useTheme } from '@mui/material';
import { strings } from '@vendor';
import { StageOne, StageTwo, StageThree } from '../../assets/images';
import SignInButton from './SignInButton';
import { OperatedList } from '../ManageLists/ManageItem';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';
import { Checkbox } from '../Checkbox';

interface SlideFooterProps {
  slideType: 'welcome' | 'upload' | 'share' | 'search' | 'teams' | 'sites';
  skipOrBackSlide?: () => void;
  nextSlide?: () => void;
  loginOnClick?: () => void;
  selectedItems?: number;
  setSelectedItems?: React.Dispatch<React.SetStateAction<OperatedList[] | undefined>>;
  items?: OperatedList[];
  isLastSlide?: boolean;
}
interface SelectionTexts {
  selectAll: string;
  unSelectAll: string;
  selectedItems: string;
}

export const SelectionInfo: React.FC<{
  selectedItems: number;
  totalItems: number;
  selectAll: () => void;
  unselectAll: () => void;
  texts: SelectionTexts;
}> = ({ selectedItems, totalItems, selectAll, unselectAll, texts }) => {
  const theme = useTheme();

  const tooltipTitle = () => {
    if (selectedItems === 0 || (selectedItems > 0 && selectedItems < totalItems)) {
      return texts.selectAll;
    }
    return texts.unSelectAll;
  };
  const getTitle = () => {
    if (selectedItems === 0) {
      return texts.selectAll;
    }
    if (selectedItems > 0 && selectedItems < totalItems) {
      return texts.selectedItems;
    }
    return texts.unSelectAll;
  };

  const onChange = () => {
    if (selectedItems === 0) selectAll();
    if (selectedItems < totalItems) selectAll();
    else unselectAll();
  };

  return (
    <Stack direction={'row'} alignItems={'center'} alignContent={'center'} width={'100%'}>
      <Tooltip title={tooltipTitle()}>
        <span>
          <Checkbox
            onChange={onChange}
            alignItems="center"
            indeterminate={selectedItems !== 0 && selectedItems < totalItems}
            className="default-checkbox"
            checked={selectedItems !== 0}
            disableRipple
          />
        </span>
      </Tooltip>
      <Stack
        width={'100%'}
        direction={'row'}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          marginTop: '1px',
          alignItems: 'center',
        }}
      >
        {selectedItems > 0 && selectedItems < totalItems && (
          <Typography variant="CapsLockSemiBold" color={theme.palette.texts.quaternary} width={10}>
            {selectedItems}
          </Typography>
        )}
        <Typography
          variant="CapsLockSemiBold"
          color={theme.palette.texts.quaternary}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginTop: '1px',
          }}
        >
          {getTitle()}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const SlideFooter: React.FC<SlideFooterProps> = ({
  slideType,
  skipOrBackSlide,
  nextSlide,
  loginOnClick,
  selectedItems,
  setSelectedItems,
  items,
  isLastSlide,
}) => {
  const selectAll = () => setSelectedItems?.(items);
  const unselectAll = () => setSelectedItems?.(undefined);

  if (slideType === 'welcome') {
    return (
      <Stack marginTop={4} width={'100%'}>
        <SignInButton onClick={loginOnClick} />
      </Stack>
    );
  }

  const renderStageIcon = () => {
    switch (slideType) {
      case 'upload':
        return <StageOne />;
      case 'share':
        return <StageTwo />;
      case 'search':
        return <StageThree />;
      default:
        return null;
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      sx={{ overflow: 'hidden' }}
      paddingBottom={2}
      paddingTop={2}
    >
      <Stack alignItems="center" width="100%" sx={{ flexShrink: 1, minWidth: 0, overflow: 'hidden' }}>
        <Stack alignSelf="start">{renderStageIcon()}</Stack>
        {(slideType === 'teams' || slideType === 'sites') && selectedItems !== undefined && (
          <SelectionInfo
            selectedItems={selectedItems}
            totalItems={items?.length || 0}
            selectAll={selectAll}
            unselectAll={unselectAll}
            texts={{
              selectAll: strings.lang.onBoarding.selectAll,
              selectedItems: strings.lang.onBoarding.selectedItems,
              unSelectAll: strings.lang.onBoarding.unselectAll,
            }}
          />
        )}
      </Stack>

      <Stack direction="row" spacing={2} sx={{ flexShrink: 0 }}>
        {slideType !== 'upload' && (
          <Button variant="text" onClick={skipOrBackSlide}>
            {slideType === 'teams' || slideType === 'sites'
              ? strings.lang.onBoarding.skip
              : strings.lang.onBoarding.back}
          </Button>
        )}
        <Button variant="primary" onClick={nextSlide}>
          {Boolean(isLastSlide) ? strings.lang.onBoarding.done : strings.lang.onBoarding.next}
        </Button>
      </Stack>
    </Stack>
  );
};
