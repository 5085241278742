import React, { useState, useMemo, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { isEqual } from 'lodash';
import { useGraphClient } from '@services';
import { logError } from '@vendor';
import { strings } from '@vendor';
import {
  QuickSearchEmptyStateDark,
  QuickSearchEmptyStateLight,
  DocxSamll,
  PptxSamll,
  XlsxSamll,
  PdfSamll,
  TeamsSamll,
  SharePointSamll,
  OneDriveSamll,
  CustomMailIcon,
  CustomTeamIcon,
  CustomChannelIcon,
  CustomSiteIcon,
  CustomLibraryIcon,
  CustomFolderIcon,
  CustomGenericIcon,
  ContainerHandlers,
  Filter,
  ViewItem,
} from '@storybook';
import { useMUIDebouncedEffect, useSettingsState } from '~/hooks';
import { ItemData, ItemContainer, EmptyStateItem, EnableTeamsState, EnableOneDriveState } from '~/utilities';
import { AppsFilters, FilesTypesFilters, ContainerFilters, SearchProps, Search } from '~/utilities/search';
import { SearchAllLocations } from '~/utilities/actions/SearchAllLocations';
import { useNavigateAction } from '~/utilities/actions/NavigateAction';
import { SearchHistoryState } from '~/modules/Settings/SettingContext';
import { SelectionState } from './DrilldownHandler';

const HISTORY_ITEMS = 4;

export const getEmptyHandlers = (location?: ItemData): ContainerHandlers<ItemData> => {
  return {
    hasNewItems: () => false,
    isExpanded: () => false,
    isSelected: () => false,
    selectAll: () => undefined,
    setSelected: () => undefined,
    unselectAll: () => false,
    getSelectedNodes: () => [],
    getParent: node => (location && node?.id !== location.id ? { id: location.id, data: location } : undefined),
  };
};
export const getQuickSearchHandlers = (
  location: ItemData,
  selection: SelectionState,
  setSelection?: Dispatch<SetStateAction<SelectionState>>
): ContainerHandlers<ItemData> => {
  return {
    hasNewItems: () => false, // Quick search doesn't involve new items
    isExpanded: () => false, // Quick search assumes no expanded state
    isSelected: item => {
      return selection.has(item.id); // Check if the item is currently selected
    },
    setSelected: (item: ViewItem<ItemData>, selected) => {
      // Ensure only one item is selected at a time
      if (selected) {
        selection.clear(); // Clear any previous selections
        selection.set(item.id, item); // Set the new selection
      } else {
        selection.delete(item.id); // Deselect the item if `selected` is false
      }
      setSelection?.(new Map(selection)); // Update the state with the new selection
    },
    selectAll: () => undefined, // Not applicable for single selection
    unselectAll: () => {
      // Clear the current selection
      selection.clear();
      setSelection?.(new Map(selection));
    },
    getSelectedNodes: () => {
      return [...selection.values()]; // Return the currently selected item, if any
    },
    getParent: node => (node?.id !== location.id ? { id: location.id, data: location } : undefined),
  };
};

export const useQuickSearch = (
  initSearchProps: SearchProps = { searchTerm: '', filters: '', entityTypes: [] },
  location: ItemContainer
) => {
  const [resolvedAppTypeFilters, setResolvedAppTypeFilters] = useState<{
    teams: string;
    oneDrive: string;
    sharepoint: string;
  }>({
    teams: '',
    oneDrive: '',
    sharepoint: '',
  });

  const gcl = useGraphClient();
  const [isLoaading, setIsLoading] = useState<boolean>(false);
  const searchState = useState(initSearchProps);
  const [searchProps, setSearchProps] = searchState;
  const [findItems, setFindItems] = useState<ItemData[]>();
  const [selection, setSelection] = useState<SelectionState>(new Map());
  const [enableTeams] = useSettingsState(EnableTeamsState);
  const [enableOneDrive] = useSettingsState(EnableOneDriveState);

  const quickHandlers = useMemo(() => getQuickSearchHandlers(location, selection, setSelection), [location, selection]);
  const isSearchInit = useMemo(() => isEqual(initSearchProps, searchProps), [initSearchProps, searchProps]);

  const getAppTypesFilters = useCallback(async () => {
    const locs = await Promise.all([AppsFilters.ONE_DRIVE(gcl), AppsFilters.SHARE_POINT(gcl)]);
    setResolvedAppTypeFilters({
      teams: AppsFilters.TEAMS.filter,
      oneDrive: locs[0].filter,
      sharepoint: locs[1].filter,
    });
  }, [gcl]);

  const handleSearchResultClick = useCallback(
    (_e?: React.MouseEvent<Element, MouseEvent>, text?: string) => {
      const { searchTerm } = searchProps;
      if (!searchTerm && !text) return;
      const searchInput = text || searchTerm;
      if (searchTerm && SearchHistoryState.value.includes(searchTerm)) {
        SearchHistoryState.value = SearchHistoryState.value.filter(str => str !== searchInput);
      }
      if (searchInput) {
        const newHistory = [searchInput, ...SearchHistoryState.value.filter(str => str !== searchInput)].slice(
          0,
          HISTORY_ITEMS
        );
        SearchHistoryState.value = newHistory;
      }
    },
    [searchProps]
  );
  const orgLocation = location.isAdvancedSearchContainer
    ? (location as Search).location
    : (location.type !== 'home' && location) || undefined;
  const filtersUI = useMemo((): Filter[] => {
    const isHomeLocation = orgLocation === undefined;
    const enableLibraryAndSite =
      isHomeLocation || (!orgLocation.hasFolder && (!orgLocation.isVirtual || orgLocation.type === 'sharepointroot'));
    const enableTeamsAndChannels = enableTeams && (orgLocation === undefined || orgLocation.type === 'teamsroot');
    return [
      {
        label: strings.lang.QuickSearchFilters.word,
        filterString: FilesTypesFilters.DOC.filter,
        Icon: <DocxSamll />,
        type: 'document',
        enabled: true,
      },
      {
        label: strings.lang.QuickSearchFilters.powerPoint,
        filterString: FilesTypesFilters.PPT.filter,
        Icon: <PptxSamll />,
        type: 'document',
        enabled: true,
      },
      {
        label: strings.lang.QuickSearchFilters.excel,
        filterString: FilesTypesFilters.XLSX.filter,
        Icon: <XlsxSamll />,
        type: 'document',
        enabled: true,
      },
      {
        label: strings.lang.QuickSearchFilters.pdf,
        filterString: FilesTypesFilters.PDF.filter,
        Icon: <PdfSamll />,
        type: 'document',
        enabled: true,
      },
      {
        label: strings.lang.QuickSearchFilters.email,
        filterString: FilesTypesFilters.EML.filter,
        Icon: <CustomMailIcon />,
        type: 'document',
        enabled: true,
      },
      {
        label: strings.lang.QuickSearchFilters.allFiles,
        filterString: FilesTypesFilters.Document.filter,
        Icon: <CustomGenericIcon />,
        type: 'document',
        enabled: true,
        newLine: true,
      },
      {
        label: strings.lang.QuickSearchFilters.team,
        Icon: <CustomTeamIcon />,
        filterString: ContainerFilters.Team.name,
        enabled: enableTeamsAndChannels,
        type: 'document',
      },
      {
        label: strings.lang.QuickSearchFilters.channel,
        Icon: <CustomChannelIcon />,
        filterString: ContainerFilters.Channel.name,
        enabled: enableTeamsAndChannels,
        type: 'document',
      },
      {
        label: strings.lang.QuickSearchFilters.site,
        entityTypes: ContainerFilters.SITE.entityTypes,
        Icon: <CustomSiteIcon />,
        enabled: enableLibraryAndSite,
        type: 'document',
      },
      {
        label: strings.lang.QuickSearchFilters.library,
        filterString: ContainerFilters.Library.aggregationFilters,
        entityTypes: ContainerFilters.Library.entityTypes,
        Icon: <CustomLibraryIcon />,
        enabled: enableLibraryAndSite,
        type: 'document',
      },
      {
        label: strings.lang.QuickSearchFilters.folder,
        filterString: ContainerFilters.Folder.aggregationFilters,
        entityTypes: ContainerFilters.Folder.entityTypes,
        Icon: <CustomFolderIcon />,
        enabled: !orgLocation?.isVirtual || orgLocation?.isAppContainer,
        type: 'document',
      },
      {
        label: strings.lang.QuickSearchFilters.teams,
        filterString: resolvedAppTypeFilters.teams,
        Icon: <TeamsSamll />,
        type: 'location',
        enabled: enableTeams && isHomeLocation,
      },
      {
        label: strings.lang.QuickSearchFilters.sharePoint,
        filterString: resolvedAppTypeFilters.sharepoint,
        Icon: <SharePointSamll />,
        type: 'location',
        enabled: isHomeLocation && (enableTeams || enableOneDrive),
      },
      {
        label: strings.lang.QuickSearchFilters.oneDrive,
        filterString: resolvedAppTypeFilters.oneDrive,
        Icon: <OneDriveSamll />,
        type: 'location',
        enabled: isHomeLocation && enableOneDrive,
      },
    ];
  }, [
    enableOneDrive,
    enableTeams,
    orgLocation,
    resolvedAppTypeFilters.oneDrive,
    resolvedAppTypeFilters.sharepoint,
    resolvedAppTypeFilters.teams,
  ]);

  const getItems = useCallback(async () => {
    if (!searchProps?.searchTerm) {
      setIsLoading(false);
      return;
    }
    try {
      setIsLoading(true);
      const { items } = await Search.searchItems(gcl, searchProps, orgLocation, false, undefined, undefined);
      const data = items.map((data, index) => ({
        id: `${data.id}-${index}`,
        data,
      }));
      const isGlobalSearch = Search.isGlobalSearch(orgLocation, searchProps.filters);
      setFindItems(
        new EmptyStateItem({
          name: isGlobalSearch
            ? 'globalNoSearchResult'
            : data.length === 0
              ? 'noSearchResultInLocation'
              : 'quickSearchWithResults',
          noBackground: data.length === 0,
          isSearchItem: true,
          images:
            data.length === 0 ? { light: QuickSearchEmptyStateLight, dark: QuickSearchEmptyStateDark } : undefined,
          action: (!isGlobalSearch && new SearchAllLocations()) || undefined,
          location: orgLocation && {
            id: orgLocation.id,
            data: new Search(searchProps.searchTerm, true, undefined, '', searchProps.filters, searchProps.entityTypes),
          },
        }).apply(
          data.map(i => i.data),
          (!isGlobalSearch && data.length < 5) || data.length === 0
        )
      );
    } catch (e: any) {
      logError(e);
    } finally {
      setIsLoading(false);
    }
  }, [searchProps, gcl, orgLocation]);

  useEffect(() => {
    getAppTypesFilters();
  }, [getAppTypesFilters]);

  useEffect(() => {
    (searchProps.searchTerm || searchProps.filters) && !isSearchInit && setIsLoading(true);
  }, [searchProps, isSearchInit]);

  useMUIDebouncedEffect(getItems, 500);

  const searchItem = useMemo(
    () =>
      new Search(
        searchProps.searchTerm || '',
        true,
        orgLocation as ItemContainer,
        orgLocation?.name || '',
        searchProps.filters,
        searchProps.entityTypes
      ),
    [orgLocation, searchProps.entityTypes, searchProps.filters, searchProps.searchTerm]
  );
  const onSearchApply = useNavigateAction({ data: searchItem, id: searchItem.id }, 'Other', quickHandlers);

  return {
    handleSearchResultClick,
    filtersUI,
    findItems,
    isLoaading,
    listHandlers: quickHandlers,
    searchProps,
    isSearchInit,
    onSearchApply,
    searchState,
    getItems,
    historyState: SearchHistoryState,
    resetState: () => setSearchProps(initSearchProps),
  };
};
