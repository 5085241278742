import {
  ManageLists,
  SettingsMedium,
  SettingsSmall,
  TeamMedium,
  TeamsEmptyStateDark,
  TeamsEmptyStateLight,
  ViewItem,
  nameMatchesFilter,
} from '@storybook';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGraphClient } from '@services';
import { strings } from '@vendor';
import { BaseItemProps, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';
import { TeamsRoot } from '../virtualRoots';
import { TeamProps } from '../graphTypes';

const ManageTeamsComponent: React.FC<{
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  container: React.RefObject<HTMLDivElement>;
}> = ({ isOpen, setIsOpen, container }) => {
  const gcl = useGraphClient();
  const [listLoading, setListLoading] = useState(true);
  const [registeredTeams, setRegisteredTeams] = useState<TeamProps[]>();
  const [availableTeams, setAvailableTeams] = useState<TeamProps[]>([]);
  const [filter, setFilter] = useState<string | undefined>();
  const [error, setError] = useState(false);
  const onSave = useCallback((newState: BaseItemProps[], hasNew: boolean) => {
    TeamsRoot.save(newState, hasNew);
  }, []);
  const teamStrings = useMemo(() => strings.lang.userManage.teams, []);
  const filterName = useMemo(() => nameMatchesFilter(filter), [filter]);
  const filteredItems = useMemo(() => availableTeams.filter(t => filterName(t.name)), [filterName, availableTeams]);

  const openAndLoading = listLoading && isOpen;
  useEffect(() => {
    if (openAndLoading) {
      const fetchTeams = async () => {
        try {
          setRegisteredTeams(await TeamsRoot.getRegisteredTeams(gcl));
          setAvailableTeams(await TeamsRoot.getAllTeams(gcl, Date.now()));
        } catch (error) {
          setError(true);
        } finally {
          setListLoading(false);
        }
      };
      fetchTeams();
    }
  }, [gcl, openAndLoading]);

  return (
    <ManageLists
      icon={<TeamMedium />}
      container={container}
      strings={teamStrings}
      operatedListItems={registeredTeams}
      listLoading={listLoading}
      listMoreResults={{ kind: 'none' }}
      isOpen={isOpen}
      setOpen={setIsOpen}
      items={filteredItems}
      setFilterValue={setFilter}
      filterValue={filter}
      error={error}
      onSave={onSave}
      filterFunction={filterName}
      images={{ light: TeamsEmptyStateLight, dark: TeamsEmptyStateDark }}
    />
  );
};

const useManageTeamsAction: ActionRendererHookType = ({ useCallback }) => {
  const container = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const onClick = useCallback(async () => setIsOpen(true), []);

  return {
    SideUI: (
      <div onClick={e => e.stopPropagation()}>
        <ManageTeamsComponent key={`Teams-${isOpen}`} isOpen={isOpen} setIsOpen={setIsOpen} container={container} />
      </div>
    ),
    title: strings.lang.actionToolTip.manage,
    icon: [SettingsSmall, SettingsMedium],
    onClick,
  };
};

export class ManageTeamsAction extends BaseReadActionClass {
  readonly trackedName = 'ManageTeams';
  readonly useRenderer = useManageTeamsAction;
  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes[0].data.type === 'teamsroot';
  }
  isQuickAction(_data: ItemData, isTopBarItem: boolean): string | undefined {
    return isTopBarItem ? 'only' : undefined;
  }
}
