import { ViewItem, NewItemMedium } from '@storybook';
import { strings } from '@vendor';
import { ItemData } from '../itemTypes';
import { BaseMenuActionClass } from './BaseMenuAction';

export class NewItemAction extends BaseMenuActionClass {
  title = strings.lang.actionToolTip.newItem;
  icon = NewItemMedium;

  isQuickAction() {
    return 'only';
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes[0].data.hasFolder && nodes.length === 1;
  }
}
