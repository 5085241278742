import { emptyStateButton, ViewItem } from '@storybook';
import { strings } from '@vendor';
import { useDrilldownNavigation } from '~/components/Tree/Renderers/DrilldownHandler';
import { EmptyStateItem, ItemContainer, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';

const useSearchAllLocations: ActionRendererHookType = ({ nodes, useCallback }) => {
  const node = nodes[0].data.type == 'empty' ? (nodes[0].data as EmptyStateItem).location : nodes[0];
  const data = node?.data as ItemContainer;
  const onClick = useDrilldownNavigation({ data, useCallback });
  return {
    title: strings.lang.actionToolTip.searchAllLocations,
    onClick,
    node,
    actionVariant: emptyStateButton,
  };
};

export class SearchAllLocations extends BaseReadActionClass {
  readonly trackedName = 'SearchAllLocations';
  readonly useRenderer = useSearchAllLocations;

  isHandled(_nodes: ViewItem<ItemData>[]): boolean {
    return true;
  }
}
