import { LottieAnimation, CelebrationAnimation } from '@storybook';
import React, { useCallback, useState } from 'react';

const Celebration = () => {
  const [showCelebration, setShowCelebration] = useState<boolean>(true);
  const [playCount, setPlayCount] = useState<number>(0);

  const handleAnimationComplete = useCallback(() => {
    if (playCount < 1) {
      setPlayCount(prev => prev + 1);
    } else {
      setShowCelebration(false);
    }
  }, [playCount]);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100',
        zIndex: '9999',
        pointerEvents: 'none',
      }}
    >
      {showCelebration && (
        <LottieAnimation
          key={playCount}
          handleAnimationComplete={handleAnimationComplete}
          options={{
            loop: false,
            autoplay: true,
            animationData: CelebrationAnimation,
          }}
        />
      )}
    </div>
  );
};

export default Celebration;
