import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback as useCallbackOrig,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { keyBy as _keyBy } from 'lodash';
import { multiSelectionsEventPublish } from '@storybook';
import { logError } from '@vendor';
import { strings } from '@vendor';
import { ViewItem, ContainerHandlers } from '@storybook';
import { useGraphClient } from '@services';
import {
  AllowedItemType,
  ItemContainer,
  ItemData,
  ItemDataWithPaging,
  ItemListChangesType,
  ItemsListChangedEvent,
  createUseCallbackWrap,
  getRootSectionItem,
  resolveAndPublishItem,
  useCombinedHandlers,
} from '~/utilities';
import { ListView, OrderByInfo, LibraryViewScope } from '~/utilities/itemTypes/ListView';
import { SharePointField } from '~/utilities/metadata/SharePointFieldType';
import { ViewFilesItem } from '~/utilities/itemTypes/ViewFilesItem';
import { useSafeCallback, useSubscribe } from '~/hooks';
import { SeparatorItem } from '~/utilities/itemTypes/SeparatorItem';
import { SearchProps } from '~/utilities/search/Search';
import { NavigateAction } from '~/utilities/actions/NavigateAction';
import { createActionEvent, trackErrorEvent } from '~/utilities/analytics/tracking';
import { EmptyListView } from '~/utilities/sharePointTypes/SPListViewFactory';
import { ItemDataRenderProps, ItemDataRenderWithNextProps, ItemDataRenderer } from './GetNodeRenderer';

export interface DrilldownState {
  state: {
    location: ItemContainer;
    view: ListView;
    refreshStamp?: number;
    newItems?: ItemData[];
    itemCount?: number;
    orderBy?: OrderByInfo;
  };
}
export interface DrilldownViewState {
  stateView: ListView;
  isSimpleView: boolean;
}

const isFilteredView = (view?: ListView) => {
  return (
    view !== undefined &&
    (view?.whereQuery !== '' ||
      (view.scope !== LibraryViewScope.RecursiveAll && view.scope !== LibraryViewScope.Default))
  );
};

export const navigateDrilldown = (
  navigate: NavigateFunction,
  inData: ItemContainer | ViewFilesItem,
  replace = false,
  refreshStamp?: number,
  newItems?: ItemData[],
  view?: ListView,
  orderBy?: OrderByInfo
) => {
  const data = inData?.type == 'viewfiles' ? (inData as ViewFilesItem).data : (inData as ItemContainer);
  // Reset so the target locations will include all action - we need to re-work this part!
  data.isSearchItem = false;
  navigate(data?.hasFolder ? `/drilldown/folderDrilldown` : '/drilldown/simpleDrilldown', {
    replace: replace,
    state: {
      location: data,
      view,
      refreshStamp,
      newItems,
      itemCount: data.fetchChildrenCount,
      orderBy,
    },
  } as DrilldownState);
  PubSub.publish('resetNewItems');
};

export type SelectionState = Map<string | number, ViewItem<ItemData>>;

const getListHandlers = (
  location: ItemContainer,
  selection: SelectionState,
  setSelection?: Dispatch<SetStateAction<SelectionState>>,
  itemsRef?: { items: ItemData[]; newItems: ItemData[] }
): ContainerHandlers<ItemData> => {
  const handlers: ContainerHandlers<ItemData> = {
    hasNewItems: () => Boolean(itemsRef?.newItems.length),
    isExpanded: () => false,
    isSelected: item => {
      return selection.get(item.id) !== undefined;
    },
    selectAll: () => undefined,
    setSelected: (item: ViewItem<ItemData>, selected, shift?: boolean) => {
      if (shift && itemsRef) {
        const allItems = [...itemsRef.newItems, ...itemsRef.items];
        const first = allItems.find(d => selection.has(d.id));

        // Select the range [firstSel..current node]. Note that we don't know who comes first in the iteration
        let doSelection = false;
        allItems.forEach(d => {
          if (d.id === first?.id) doSelection = !doSelection;
          else if (d.id === item.id) doSelection = !doSelection && Boolean(first);
          else if (d.supportsSelection && doSelection) selection.set(d.id, { id: d.id, data: d });
          else selection.delete(d.id);
        });
      } else if (selected == (selection.get(item.id) !== undefined)) return;

      if (selected) selection.set(item.id, item);
      else selection.delete(item.id);
      setSelection?.(new Map(selection));

      multiSelectionsEventPublish({ handlers: handlers });
    },
    unselectAll: () => {
      multiSelectionsEventPublish({ handlers: handlers });
      selection.clear();
      setSelection?.(new Map(selection));
    },
    getSelectedNodes: () => {
      return [...selection.values()];
    },
    getParent: data => (data?.id === location.id ? undefined : { data: location, id: location.id }),
  };
  return handlers;
};

export const useDrilldown = (
  getItems: (token: string | undefined) => Promise<ItemDataWithPaging>,
  location: ItemContainer,
  inpNewItems?: ItemData[]
) => {
  const useCallback = useCallbackOrig;
  const gcl = useGraphClient();
  const [resultCount, setResultCount] = useState<number>(0);
  const [items, setItemsInternal] = useState<ItemData[]>([]);
  const [nextToken, setNextToken] = useState<string | undefined>();
  const [pagginationText, setPagginationText] = useState<string | undefined>();
  const [newItems, setNewItemsInternal] = useState<ItemData[]>(inpNewItems || []);
  const itemsRef = useRef({ items: [] as ItemData[], newItems: inpNewItems || [] });
  const setNewItems = useCallback((newItems: ItemData[]) => {
    setNewItemsInternal(newItems);
    itemsRef.current.newItems = newItems;
  }, []);
  const setItems = useCallback((items: ItemData[]) => {
    setItemsInternal(items);
    itemsRef.current.items = items;
  }, []);
  const selectionRef = useRef(new Map<string | number, ViewItem<ItemData>>());
  const [selection, setSelection] = useState(new Map(selectionRef.current));
  const [selectedView, setSelectedView] = useState<DrilldownViewState | undefined>({
    stateView: new EmptyListView(),
    isSimpleView: true,
  });
  const [schema, setSchema] = useState<SharePointField[] | undefined>(undefined);

  const loadingRef = useRef(false);
  const counter = useRef(0);

  const setItemsWithEmptyState = useCallback(
    (items: ItemData[], extraCond, isFilteredView: boolean, fromUpdate: boolean) => {
      if (items.length === 0 || fromUpdate) {
        items = location.applyEmptyState(items, true, { extraCond, isFilteredView, location });
      }
      setItems(items);
    },
    [location, setItems]
  );

  const listHandlers = useMemo(
    () => getListHandlers(location, selectionRef.current, setSelection, itemsRef.current),
    [location, setSelection]
  );

  const resolveItem = useCallback(
    async (item: ItemData) => {
      await resolveAndPublishItem(gcl, location, item, selectedView?.stateView);
    },
    [gcl, selectedView?.stateView, location]
  );
  const mappedItems = useMemo(() => {
    const newItemsSet = _keyBy(newItems, 'apiIdKey');
    return (
      newItems.length > 0
        ? [...newItems, new SeparatorItem(), ...items.filter(i => i.type != 'empty' && !newItemsSet[i.apiIdKey])]
        : items
    ).map(data => ({ data, id: data.id }));
  }, [items, newItems]);
  // TODO: Rework the new items to go over the list once to replace exisiting items
  useSubscribe(
    ItemsListChangedEvent,
    useCallback(
      ({ added, toResolve, updated, deleted, location: targetLocation }: ItemListChangesType) => {
        counter.current = counter.current + 1;
        if (location.isAdvancedSearchItem || location.apiIdKey !== targetLocation?.apiIdKey) return;
        let itemsAfterUpdate = itemsRef.current.items.filter(i => i.type != 'empty');
        let newItemsAfterUpdate = itemsRef.current.newItems;
        if (added) {
          getRootSectionItem(location)?.markAsUpdated(); // Mark root section as uptodate
          // Resolving can only be done when the current location has folders.
          if (location.hasFolder) Object.values(added).forEach(item => resolveItem(item));
          newItemsAfterUpdate = [...Object.values(added), ...newItemsAfterUpdate];
        }
        if (toResolve) {
          // Resolving can only be done when the current location has folders.
          if (location.hasFolder) Object.values(toResolve).forEach(item => resolveItem(item));
        }

        if (updated) {
          itemsAfterUpdate = itemsAfterUpdate.map(v => updated[v.apiIdKey] || v);
          newItemsAfterUpdate = newItemsAfterUpdate.map(v => updated[v.apiIdKey] || v);
        }
        if (deleted) {
          itemsAfterUpdate = itemsAfterUpdate.filter(v => !deleted[v.apiIdKey]);
          newItemsAfterUpdate = newItemsAfterUpdate.filter(v => !deleted[v.apiIdKey]);

          // Unselect deleted items!
          Object.values(deleted).forEach(data => listHandlers.setSelected({ id: data.id, data }, false));
        }
        setNewItems(newItemsAfterUpdate);
        setItemsWithEmptyState(
          itemsAfterUpdate,
          newItemsAfterUpdate.length == 0,
          isFilteredView(selectedView?.stateView),
          true
        );
      },
      [location, setNewItems, setItemsWithEmptyState, selectedView?.stateView, resolveItem, listHandlers]
    )
  );

  useSubscribe(
    'resetNewItems',
    useCallback(() => setNewItems(inpNewItems || []), [inpNewItems, setNewItems])
  );

  const fetchPage = useSafeCallback(
    async (token, inp: ItemData[], isCanceled?: () => boolean) => {
      if (loadingRef.current) return;
      loadingRef.current = true;
      try {
        const data = await getItems(token);
        setResultCount(data.resultCount || 0);
        if (isCanceled?.()) return;
        let nextItems = [...inp, ...data.items];
        const nextNewItems = [...itemsRef.current.newItems, ...nextItems.filter(i => i.isNew)];
        setNewItems(nextNewItems);
        nextItems = nextItems.filter(i => !i.isNew);
        setItemsWithEmptyState(nextItems, nextNewItems.length === 0, isFilteredView(data.view), false);
        setNextToken(data.pageToken);
        data.view &&
          setSelectedView({
            stateView: data.view,
            isSimpleView: false,
          });
        setSchema(data?.schema);
        setPagginationText(data.pagginationString);
        return true;
      } catch (error: any) {
        logError(error, 'Drilldown navigation failed.');
        const trackedEvent = createActionEvent(
          new NavigateAction(),
          'Other',
          [{ data: location, id: location.id }],
          // We don't want to depend on the current selection as this will fetch the items every time the selection changes.
          getListHandlers(location, new Map<string | number, ViewItem<ItemData>>())
        );
        trackErrorEvent(trackedEvent, error);
        setItems([]);
        throw error;
      } finally {
        loadingRef.current = false;
      }
    },
    [getItems, location, setItems, setItemsWithEmptyState, setNewItems],
    true,
    {
      errorTitle: strings.lang.errorPage.genericTitle,
      errorMessage: strings.lang.notificationsError.drilldownFailedMessage,
    }
  );

  useEffect(() => {
    setItems([]);
    setNewItems(inpNewItems || []);
    setNextToken(undefined);
    counter.current = 0;
    selectionRef.current.clear();
    setSelection(new Map(selectionRef.current));
    multiSelectionsEventPublish({ handlers: listHandlers });
    setSelectedView({
      stateView: new EmptyListView(),
      isSimpleView: true,
    });

    setSchema(undefined);
    let canceled = false;
    fetchPage(undefined, [], () => canceled);
    return () => {
      canceled = true;
    };
  }, [fetchPage, inpNewItems, listHandlers, location, setItems, setNewItems]);

  return {
    newItems,
    listHandlers,
    resultCount,
    nextToken,
    mappedItems,
    selection,
    fetchPage,
    counter,
    loadingRef,
    items,
    pagginationText,
    selectedView,
    schema,
  };
};

export const generateQuickSearchKey = (location: ItemContainer, searchProps?: SearchProps) =>
  `${location.apiIdKey}-${searchProps?.searchTerm}-${searchProps?.filters}-${searchProps?.entityTypes}`;

export const generateVirtualListKey = (
  location: ItemContainer,
  tempViewCount: number,
  isEmptyState: boolean,
  orderBy?: OrderByInfo[],
  searchProps?: SearchProps
) =>
  `${location.id}-${searchProps?.searchTerm}-${orderBy?.[0]?.field}-${orderBy?.[0]?.dir}-${isEmptyState}-${tempViewCount}`;

export const useDrilldownNavigation = ({
  data: inData,
  replace = false,
  refreshStamp,
  newItems,
  useCallback = useCallbackOrig,
}: {
  data?: ItemContainer | ViewFilesItem;
  replace?: boolean;
  refreshStamp?: number;
  newItems?: ItemData[];
  useCallback?: typeof useCallbackOrig;
}) => {
  const navigate = useNavigate();
  const state = useLocation()?.state as DrilldownState['state'];
  const data = inData?.type == 'viewfiles' ? (inData as ViewFilesItem).data : (inData as ItemContainer);

  return useCallback<MouseEventHandler>(async () => {
    navigateDrilldown(navigate, data, replace, refreshStamp, newItems, state?.view);
  }, [navigate, data, replace, refreshStamp, newItems, state?.view]);
};

const DrilldownComponent = (props: ItemDataRenderWithNextProps) => {
  const { node, onDoubleClick, nextRenderer, handlers } = props;
  const useCallbackWrap = createUseCallbackWrap(
    new NavigateAction(),
    'Double Click',
    [props.node],
    useCallbackOrig,
    handlers
  );
  const { data } = node;
  const onDoubleClickOpenFolder = useDrilldownNavigation({
    data: data as ItemContainer,
    useCallback: useCallbackWrap,
  });
  const combinedDoubleClickHandler = useCombinedHandlers(onDoubleClickOpenFolder, onDoubleClick);
  return nextRenderer({ ...props, onDoubleClick: combinedDoubleClickHandler });
};

export const drilldownHandler = (type: AllowedItemType, nextRenderer: ItemDataRenderer): ItemDataRenderer => {
  const res = (props: ItemDataRenderProps) => <DrilldownComponent {...{ ...props, nextRenderer, type }} />;
  return res; // Strange that res is needed here...
};

export type UseDrilldownResult = ReturnType<typeof useDrilldown>;
