import { GraphClient } from '@services';
import { IODataContentType } from '@microsoft/sp-odata-types';
import { SharePointField } from '~/utilities/metadata/SharePointFieldType';
import { UploadDocument } from '../sharePointTypes';
import { BaseStateManager, OfficeUserSettingManager } from '../stateManager';
import { DocumentItem } from './DocumentItem';
import { AllowedItemType, ItemData } from './ItemData';
import { ListView } from './ListView';
import { LibraryItem } from './LibraryItem';
import { SharePointClient } from '../sharePointAPI';

export interface FetchChildrenProps {
  gcl: GraphClient;
  sortDir: boolean; // true - ascending
  refreshStamp?: number;
  itemCount?: number;
  isDrilldown?: boolean;
  pageToken?: string;
}

export interface ItemDataWithPaging {
  items: ItemData[];
  pageToken?: string;
  resultCount?: number;
  pagginationString?: string;
  view?: ListView;
  schema?: SharePointField[];
}

export interface EmptyStateViewContext {
  extraCond?: boolean;
  isFilteredView?: boolean;
  location: ItemContainer;
}

export interface ItemContainer extends ItemData {
  get quickSearchPlaceholder(): string;
  get supportSearch(): boolean;
  get supportsSortDirection();
  get stateCount(): OfficeUserSettingManager<number> | undefined;
  get filterState(): BaseStateManager<boolean> | undefined;
  newItemsCount: number;
  fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging>;
  applyEmptyState(items: ItemData[], isDrilldown?: boolean, viewCtx?: EmptyStateViewContext): ItemData[];
  getFolder(gcl: GraphClient): Promise<Folder>;
  getSearchFilter(gcl: GraphClient): Promise<string | undefined>;
  get fetchChildrenCount(): number;
  reorderItems(): void;
  getAddedItemsInsertionPoint(newItem: ItemData, items: ItemData[]): number;
}

export const isItemContainer = (type: AllowedItemType) => {
  switch (type) {
    case 'insight':
    case 'document':
    case 'empty':
    case 'error':
    case 'viewfiles':
      return false;
  }
  return true;
};

export const getItemContainer = (item?: ItemData) => {
  return item && isItemContainer(item.type) ? (item as ItemContainer) : undefined;
};

export interface Folder extends LibraryItem, ItemContainer {
  get isDocumentSet(): boolean;
  get isOneNote();
  get list(): ItemContainer;
  upload(uploadProps: UploadDocument): Promise<DocumentItem>;
  getItemsFromView(
    gcl: GraphClient,
    view: ListView,
    count: number,
    refreshStamp?: number,
    next?: string
  ): Promise<ItemDataWithPaging>;
  createNewFolder(gcl: GraphClient, folderName: string): Promise<Folder>;
  getViews(gcl: GraphClient, includeHidden?: boolean, refreshStamp?: number): Promise<ListView[]>;
  resolve(gcl: GraphClient, view?: ListView): Promise<Folder>;
  onRefresh(gcl: GraphClient, refreshStamp?: number): Promise<void>;
  onLoading(gcl: GraphClient, refreshStamp?: number): Promise<void>;
  getSchema(spc: SharePointClient, refetch?: boolean): Promise<SharePointField[] | undefined>;
  getContentTypes(gcl: GraphClient, timestamp?: number): Promise<IODataContentType[]>;
  addOrCreateEmailContentType(gcl: GraphClient): Promise<IODataContentType>;
}

export const typeHasFolder = (type: AllowedItemType) => {
  switch (type) {
    case 'channel':
    case 'folder':
    case 'list':
    case 'onedriveroot':
      return true;
  }
};
