import React, { useCallback } from 'react';
import { FormControl, SelectChangeEvent, useTheme } from '@mui/material';
import { setLanguage, strings } from '@vendor';
import { Select, Typography, SelectOption } from '@storybook';
import { LanguageSetting } from './SettingContext';

export const setDefaultLanguage = (lang: string) => {
  lang = lang.split('-')[0];
  if (['en', 'de', 'fr'].includes(lang)) {
    if (LanguageSetting.value !== lang) LanguageSetting.value = lang;
    setLanguage(lang);
  } else {
    LanguageSetting.value = 'en';
    setLanguage('en');
  }
};
interface LanguageSelectorProps {
  changeLanguage?: (lang: string) => void;
  selectedLanguage?: string;
}
const languages: SelectOption[] = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'Deutsch' },
  { value: 'fr', label: 'Français' },
];

const LanguageSelector = ({ changeLanguage, selectedLanguage }: LanguageSelectorProps) => {
  const theme = useTheme();

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedValue = event.target.value;
      changeLanguage?.(selectedValue);
    },
    [changeLanguage]
  );
  return (
    <FormControl fullWidth variant="outlined" sx={{ minWidth: 250, mt: 2 }}>
      <Typography variant="H1SemiBold" color={theme.palette.texts.primary} mb={2}>
        {strings.lang.userSetting.languageSettingTitle}
      </Typography>
      <Select
        value={selectedLanguage}
        onChange={handleChange}
        options={languages}
        currentSelected={languages.find(lang => lang.value === selectedLanguage) || languages[0]}
      ></Select>
    </FormControl>
  );
};

export default LanguageSelector;
