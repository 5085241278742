import { useCallback, useState } from 'react';
import { asType } from '@services';
import { BaseStateManager, TrackedEventType } from '~/utilities';
import { trackEvent } from '~/utilities/analytics/tracking';
import { useSubscribe } from './useSubscribe';

export function useSettingsState<T>(state: BaseStateManager<T> | undefined, track?: boolean): [T, (x: T) => void] {
  const [val, setVal] = useState<T>(state ? state.value : (undefined as any));
  useSubscribe(state?.sKey, () => {
    state && setVal(state.value);
  });
  const setValReturned = useCallback(
    (v: T) => {
      if (state === undefined || state.value === v) return;
      state.value = v;
      track &&
        trackEvent(
          'SettingsChange',
          asType<TrackedEventType['data']>({
            [state.key]: v,
            trigger: 'Other',
            actionType: 'Update',
            itemType: 'Setting',
            targetView: 'Settings',
          })
        );
    },
    [state, track]
  );
  const empTySetter = useCallback(() => {}, []);
  return (state && [val, setValReturned]) || [undefined as any, empTySetter];
}
