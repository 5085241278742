import PubSub from 'pubsub-js';
import { ProvisionedSettingKeys } from '@vendor';
import { getProvisionedSettings, InternalStateKeys } from '~/provisioning/Provisioned';
import { BaseStateManager } from './localstate';

export class OfficeUserSettingManager<T> extends BaseStateManager<T> {
  private static ProvisionedCopy: ReturnType<typeof getProvisionedSettings>;

  static readonly version = '1.0';
  static ResetRequired = false;

  get sKey() {
    return `stateOf.${this.keyOverride || this.key}.v${OfficeUserSettingManager.version}`;
  }

  private setSettingValue(val?: string, save = true) {
    const roamingSettings = Office.context.roamingSettings;
    if (val === undefined) {
      roamingSettings?.remove(this.sKey);
      delete BaseStateManager.userLocalSettings[this.key];
    } else {
      BaseStateManager.userLocalSettings[this.key] = val;
      roamingSettings?.set(this.sKey, val);
    }

    if (save) {
      BaseStateManager.saveLocalUserSettings(true, roamingSettings);
      roamingSettings?.saveAsync(res => console.warn(`Saving ${this.key} ${res.status}`));
    }
  }

  public save() {
    this.setSettingValue(this.stringify(this._value));
    PubSub.publish(this.sKey, this._value);
  }

  protected reset(resetSetting = false) {
    // Allow use when Office isn't present. Note that we need to convince TypeScript that roaming can be undefined...
    if (resetSetting) this.setSettingValue(undefined, false);
    const lVal = BaseStateManager.getSettingValue(this.key) || Office.context.roamingSettings?.get(this.sKey);
    if (OfficeUserSettingManager.ResetRequired && lVal === this.initValBeforeProvisioning())
      this.setSettingValue(undefined, false);
    const prevValue = this._value;
    this._value = (lVal && this.transformValue(this.parse(lVal))) || this.initialGetter();
    if (prevValue !== this._value) PubSub.publish(this.sKey, this._value);
  }

  static resetFromProvisioning(prov: typeof this.ProvisionedCopy) {
    const roaming = Office.context?.roamingSettings || {}; // Ensure an object event if not in Office ctx
    OfficeUserSettingManager.ProvisionedCopy = prov;
    this.ResetRequired = !roaming['ReesetNotRequired'];
    PubSub.publishSync('resetFromProvisioning');
    roaming['ReesetNotRequired'] = true;
  }

  constructor(
    key: ProvisionedSettingKeys | InternalStateKeys,
    readonly initValBeforeProvisioning: () => T,
    readonly keyOverride?: string
  ) {
    super(key, () => {
      const provVal = OfficeUserSettingManager.ProvisionedCopy?.[key]?.value;
      return provVal !== undefined ? this.transformValue(provVal) : initValBeforeProvisioning();
    });
    PubSub.subscribe('resetFromProvisioning', () => this.reset());
  }
}

// Fix cases where we used 'string' instead of 'boolean'.
export class BooleanFixOfficeUserSettingManager extends OfficeUserSettingManager<boolean> {
  protected transformValue(res): boolean {
    return typeof res === 'string' ? JSON.parse(res) : res;
  }
}

PubSub.subscribe('onAfterResetSetting', () => {
  Office.context.roamingSettings?.saveAsync();
  BaseStateManager.saveLocalUserSettings();
});
