import { IndicatorRequestHandler, useYesNoDialog } from '@storybook';
import { strings } from '@vendor';
import { useCallback, useRef } from 'react';
import { UploadStep } from '~/context/UploadContext';
import { UploadItemProvider } from '~/utilities/uploadItem/UploadItemProvider';

export const useCheckIfExistStep = (): UploadStep => {
  const dialogPromiseRef = useRef<{ resolve: (value: boolean) => void; reject: (reason?: any) => void } | null>(null);
  const progressHandlerRef = useRef<IndicatorRequestHandler>();
  const applyToAll = useRef<boolean>(false);
  const overrideAll = useRef<boolean | undefined>(undefined); // null indicates no choice made yet
  const currentName = useRef('');

  const hasCheckbox = useRef<boolean>(false);
  const handleReplace = useCallback(async () => {
    if (applyToAll.current) {
      overrideAll.current = true;
    }
    dialogPromiseRef.current?.resolve(true);
  }, []);

  const handleKeepBoth = useCallback(async () => {
    if (applyToAll.current) {
      overrideAll.current = false;
    }
    dialogPromiseRef.current?.resolve(false);
  }, []);

  const onChecked = () => {
    applyToAll.current = !applyToAll.current;
  };

  const { YesNoDialog, setIsDialogVisible } = useYesNoDialog({
    texts: {
      dialogTitle: strings.lang.replaceDialog.title,
      dialogSubTitle: strings.lang.replaceDialog.message({ name: currentName.current }),
      primaryActionText: strings.lang.replaceDialog.replace,
      secondaryActionText: strings.lang.replaceDialog.keepBoth,
      checkboxLabel: strings.lang.replaceDialog.checkbox,
    },
    primaryActionCallback: handleReplace,
    secondryActionCallback: handleKeepBoth,
    onChecked: onChecked,
    hasCheckbox: hasCheckbox.current,
    onCloseAction: handleKeepBoth,
    blockOutsideClick: true,
  });

  const execute = async (
    uploadItemProvider: UploadItemProvider,
    index: number,
    actionsRef: any,
    isMultipleUploads: boolean,
    progressHandler?: IndicatorRequestHandler
  ) => {
    hasCheckbox.current = isMultipleUploads;
    currentName.current = uploadItemProvider.name;
    progressHandlerRef.current = progressHandler;
    if (uploadItemProvider.isExist) {
      if (applyToAll.current) {
        const newItem = await uploadItemProvider.uploadItem(progressHandler, overrideAll.current);
        actionsRef.current?.push(newItem?.name);
      } else {
        progressHandler?.updateProgress(index, index);
        setIsDialogVisible(true);
        const userResponse = await new Promise<boolean>((resolve, reject) => {
          dialogPromiseRef.current = { resolve, reject };
        });
        await uploadItemProvider.uploadItem(progressHandler, userResponse);
        actionsRef.current?.push(uploadItemProvider.newItem?.name);
      }
    }
  };
  const clean = () => {
    applyToAll.current = false;
    overrideAll.current = undefined;
  };

  return { stepUi: YesNoDialog, execute: execute, clean: clean };
};
