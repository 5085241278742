import { Guid } from 'guid-typescript';
import { FolderClosedMedium, FolderOpenedMedium, OneMedium, IndicatorRequestHandler, IconProps } from '@storybook';
import { GraphClient } from '@services';
import { oDirname } from '@vendor';
import { strings } from '@vendor';
import { getFriendlyDateDisplay } from '@vendor';
import { IODataContentType } from '@microsoft/sp-odata-types';
import { LanguageSetting, SPFilesCountState } from '~/modules/Settings/SettingContext';
import { SharePointField, SharePointFieldType } from '~/utilities/metadata/SharePointFieldType';
import {
  DEFAULT_FIELDS,
  DEFAULT_VIEW,
  encodeSpecialCharacters,
  getFolderOdataIdFromUrl,
  replaceSpecialCharsWithUnderscore,
  SharePointClient,
} from '../sharePointAPI';
import {
  AccessUrls,
  BaseContainer,
  DocumentItem,
  FetchChildrenProps,
  Folder,
  ItemContainer,
  ItemDataWithPaging,
  ShareableItem,
  treeitemFirstParent,
} from '../itemTypes';
import { SPList } from './SPList';
import { ViewFilesItem } from '../itemTypes/ViewFilesItem';
import { EmptyListView, SPListView } from './SPListViewFactory';
import { ListView } from '../itemTypes/ListView';
import { SPDocument, SPMetadata } from './SPDocument';
import { RefreshAction } from '../actions';
import { LibraryItem } from '../itemTypes/LibraryItem';
import { getUploadParams } from '../misc/utils';
export const FORTY_MB = 40000000;

interface SPOUser {
  Email: string;
  Title: string;
}
interface SPOUploadResult {
  'odata.id': string;
  Author: SPOUser;
  ListItemAllFields: {
    ContentTypeId: string;
    Id: number;
  };
  Name: string;
  ServerRelativeUrl: string;
  TiemCreated: string;
  TimeLastModified: string;
  UniqueId: string;
}

interface fetchDocumentProps extends Pick<FetchChildrenProps, 'gcl' | 'refreshStamp'> {
  count?: number;
}

interface SPFolderProps {
  metadata: SPMetadata;
  list: SPList;
  filters?: string[];
}

export interface IProgressBar {
  readonly appCtx: any;
  startProgress(): void;
  progeressStarted(): boolean;
  setProgress(n: number): void;
  setText(str: string): void;
  isCanceled(): boolean;
  cancel(): void;
  finish(): void;
  setEnableCancel(state: boolean): void;
}

export interface UploadDocument {
  data: Blob;
  fileName: string;
  gcl: GraphClient;
  fileIndex?: number;
  override?: boolean;
  progressRequestHandler?: IndicatorRequestHandler;
}

export class SPFolder extends BaseContainer implements Folder, ShareableItem {
  private readonly filters?: Set<string>;
  private _parent?: SPList | SPFolder;
  metadata: SPMetadata;
  readonly list: SPList;

  constructor({ metadata, list, filters }: SPFolderProps) {
    super({
      id: metadata?.id ?? getFolderOdataIdFromUrl(list.siteUrl, metadata.FileRef || ''),
      type: 'folder',
      name: metadata.FileLeafRef,
    });
    this.list = list;
    this.metadata = metadata;
    this.filters = filters && new Set(filters);
    this.isNew = metadata.isNew;
    if (this.id.endsWith('/rootFolder')) this._parent = this.list;
  }

  protected fillJson(res: SPMetadata): void {
    super.fillJson(res);
    const metadata = this.metadata;
    res.ContentTypeId = metadata.ContentTypeId;
    res.Modified = metadata.Modified;
    res.FileRef = metadata.FileRef;
    res.FileLeafRef = metadata.FileLeafRef;
    res.user = metadata.Editor?.[0] || metadata?.Author?.[0] || metadata?.user;
    res.ID = metadata.ID;
    if (this.filters) res.filters = [...this.filters];
    res.list = this.list.toJson();
  }

  get serverRelativeUrl(): string {
    return this.metadata.FileRef;
  }
  get parent() {
    if (this._parent === undefined) {
      const parentFileRef = oDirname(this.serverRelativeUrl);
      if (parentFileRef == oDirname(this.list.rootFolder.serverRelativeUrl)) {
        this._parent = this.list;
      } else {
        this._parent = new SPFolder({
          metadata: {
            ...this.metadata,
            FileRef: parentFileRef,
            id: getFolderOdataIdFromUrl(this.list.siteUrl, parentFileRef),
            FileLeafRef: parentFileRef.substring(parentFileRef.lastIndexOf('/') + 1),
          },
          list: this.list,
        });
      }
    }
    return this._parent;
  }
  get OfficeAppLocated() {
    return this.list.OfficeAppLocated;
  }
  override get hasFolder(): boolean {
    return true;
  }
  get isLibraryItem(): boolean {
    return this.isTransientLocation !== true && this.itemFirstParent !== treeitemFirstParent.Favorite;
  }
  override get rootSite(): string {
    return this.list.rootSite;
  }
  get isOneNote() {
    return this.metadata.ProgId === 'OneNote.Notebook';
  }
  get canRemove(): boolean {
    return this.isLibraryItem;
  }
  get isDocumentSet() {
    return (
      this.metadata.ProgId === 'Sharepoint.DocumentSet' ||
      this.metadata.ContentTypeId?.startsWith('0x0120D520') ||
      false
    );
  }
  get allowEdit(): boolean {
    return true;
  }

  async getContentTypes(gcl: GraphClient, timestamp?: number) {
    timestamp = await RefreshAction.markRefreshStamp(this, timestamp);
    return await this.list.getContentTypes(gcl, timestamp);
  }

  async addOrCreateEmailContentType(gcl: GraphClient): Promise<IODataContentType> {
    return await this.list.addOrCreateEmailContentType(gcl);
  }

  override async getFolder() {
    return this;
  }

  async getSchema(spc: SharePointClient, refetch?: boolean): Promise<SharePointField[]> {
    return await this.list.getSchema(spc, refetch);
  }

  async onRefresh(gcl: GraphClient, refreshStamp?: number): Promise<void> {
    await this.list.onRefresh(gcl, refreshStamp);
  }

  // Initialize evrything required when loading folder items.
  // Currently get views which will also ensure the scheme is read.
  async onLoading(gcl: GraphClient, refreshStamp?: number): Promise<void> {
    await this.getViews(gcl, false, refreshStamp);
  }

  async getViews(gcl: GraphClient, refresh?: boolean, refreshStamp?: number): Promise<ListView[]> {
    //Group By Views are not supported yet
    refreshStamp = await RefreshAction.markRefreshStamp(this, refreshStamp);
    const rawViews = await this.list.getViews(gcl, refresh, refreshStamp);

    //If from some reason the default view was left out, set the first view as default
    if (Array.isArray(rawViews) && rawViews.length > 0 && !rawViews.find(view => view.DefaultView)) {
      rawViews[0].DefaultView = true;
    }

    const listViews = rawViews.map(rawView => new SPListView(rawView));
    if (!listViews.length) return [DEFAULT_VIEW];
    listViews.sort((a, b) => a.title.localeCompare(b.title));
    return listViews?.filter(view => !view.hidden);
  }

  override async getAccessUrls(): Promise<AccessUrls> {
    return {
      webUrl: this.rootSite + encodeSpecialCharacters(this.serverRelativeUrl),
    };
  }

  get pathOrDescription(): string {
    let path = oDirname(this?.serverRelativeUrl?.replace('/sites', ''));
    if (this.OfficeAppLocated === 'Teams') path = path.replace('/Shared Documents', '');
    if (this.OfficeAppLocated === 'OneDrive') {
      return `${`${this.OfficeAppLocated}${path.replace(
        `${this.list.siteUrl.replace(`${this.rootSite}`, '')}/Documents`,
        ''
      )}`}`;
    }
    return `${`${this.OfficeAppLocated}${path}`}`;
  }
  get secondLineContent(): string | null {
    const appName = this.OfficeAppLocated === 'SP' ? 'SharePoint' : this.OfficeAppLocated;
    let parent = this.parent.name;
    if (parent === 'Shared Documents') {
      parent = this.parent.parent.name;
    }
    if (this.OfficeAppLocated === 'OneDrive') {
      return `${appName} (${
        encodeSpecialCharacters(this?.serverRelativeUrl)?.split('/personal')[1].split('/')[1].split('_')[0]
      }) • ${parent}`;
    }
    return `${appName} • ${parent}`;
  }

  async resolve(gcl: GraphClient, view?: ListView): Promise<Folder> {
    if (!view) view = new EmptyListView().combineWithFields(DEFAULT_FIELDS);
    return (await this.list.resolveByID(gcl, view, this.metadata.ID)) as Folder;
  }

  async rename(gcl: GraphClient, newName: string): Promise<LibraryItem> {
    const webUrl = this.rootSite + this.serverRelativeUrl;
    const newRelative = `${oDirname(this.serverRelativeUrl)}/${newName}`;
    const newUrl = this.list.rootSite + newRelative;
    await this.list.site.moveFolder(gcl, webUrl, newUrl);
    const props: SPFolderProps = { ...this.toJson(), name: newName, list: this.list };
    return new SPFolder({
      ...props,
      metadata: { ...this.metadata, FileRef: newRelative, FileLeafRef: newName },
    });
  }

  async createNewFolder(gcl: GraphClient, folderName: string): Promise<Folder> {
    const spc = new SharePointClient(gcl, this.rootSite);
    const res: SPOUploadResult = await spc
      .api(`${this.apiId}/folders/AddUsingPath(DecodedUrl=@a1,overwrite=@a2)?@a1=%27${folderName}%27&@a2=false`)
      .expand(['Author', 'ListItemAllFields'])
      .post(null);
    const listItem = res.ListItemAllFields;
    return new SPFolder({
      metadata: {
        ...this.metadata,
        FileRef: res.ServerRelativeUrl,
        FileLeafRef: res.Name,
        ContentTypeId: listItem.ContentTypeId,
        ID: listItem.Id,
        isNew: true,
      },
      list: this.list,
    });
  }
  encodeFileName4Upload = (filename: string) => {
    const newFileName = replaceSpecialCharsWithUnderscore(filename.trim());
    return encodeSpecialCharacters(newFileName, true).replaceAll('&', '%26').replaceAll('+', '%2B');
  };

  async upload({
    gcl,
    data,
    fileName,
    fileIndex,
    override,
    progressRequestHandler,
  }: UploadDocument): Promise<DocumentItem> {
    const spc = new SharePointClient(gcl, this.rootSite);
    const controller = new AbortController();
    const fileNameEncoded = `'${this.encodeFileName4Upload(fileName)}'`;
    const isLargeFile = data.size >= FORTY_MB;
    let res = {} as SPOUploadResult;
    let listId;
    try {
      const id = Guid.create().toString();
      const guid = encodeURIComponent(`guid'${id}'`);
      const uploadParams = getUploadParams(fileNameEncoded, isLargeFile, guid, override);
      if (!isLargeFile) {
        const stream = await data?.arrayBuffer();
        res = await spc
          .directapi(`${this.apiId}${uploadParams}`)
          .expand(['Author', 'ListItemAllFields'])
          .upload(controller, stream, 0, data.size, progressRequestHandler, fileIndex);
        await spc.directapi(`${this.apiId}`).cancelRequest(controller, false, progressRequestHandler);
        listId = res.ListItemAllFields.Id;
      } // large file
      else {
        res = await spc.directapi(`${this.apiId}${uploadParams}`).expand(['Author', 'ListItemAllFields']).post(null);
        listId = res.ListItemAllFields.Id;
        let bufferRead = FORTY_MB;
        let offset = 0;
        let stream = await data.slice(0, 0).arrayBuffer();
        let done = false;
        while (!done) {
          if (bufferRead + offset >= data.size) {
            bufferRead = data.size - offset;
            done = true;
          }
          stream = await data.slice(offset, offset + bufferRead).arrayBuffer();
          await spc
            .directapi(`${res['odata.id']}/ContinueUpload(uploadId=@a2,fileOffset=@a3)?@a2=${guid}&@a3=${offset}`)
            .upload(controller, stream, offset, data.size, progressRequestHandler, fileIndex);
          offset += bufferRead;
        }
        await spc
          .directapi(`${res['odata.id']}/FinishUpload(uploadId=@a2,fileOffset=@a3)?@a2=${guid}&@a3=${data.size}`)
          .post(null);
      }
      const docItem = res.ListItemAllFields;
      const doc = new SPDocument(
        this,
        {
          ContentTypeId: docItem.ContentTypeId,
          UniqueId: `{${res.UniqueId}}`,
          FileLeafRef: res.Name,
          Modified: new Date(res.TimeLastModified),
          id: res['odata.id'],
          user: {
            email: res.Author.Email,
            title: res.Author.Title,
          },
          FileRef: res.ServerRelativeUrl,
          ID: listId,
          File_x0020_Size: data.size,
        },
        true
      );
      return doc;
    } catch (error: any) {
      throw error;
    }
  }

  async getItemsFromView(
    gcl: GraphClient,
    view: ListView,
    count: number,
    refreshStamp?: number,
    next?: string
  ): Promise<ItemDataWithPaging> {
    // Handle cases where root folders (of libraries & channels) are marked for refresh on deleting items.
    refreshStamp = await RefreshAction.markRefreshStamp(this, refreshStamp);
    const spc = new SharePointClient(gcl, this.rootSite);
    return await this.list.renderAsStream(spc, this, view, count, refreshStamp, next);
  }

  override async fetchChildren(
    { gcl, refreshStamp }: FetchChildrenProps,
    parent?: ItemContainer
  ): Promise<ItemDataWithPaging> {
    if (hrmProvisioning.virtualFoldersCount > 0) return { items: await this.fetchVirtualFolders() };
    const foldersView = new EmptyListView()
      .combineWithFields(DEFAULT_FIELDS)
      .combineWithOrderBy(EmptyListView.OrderByName)
      .combineWithQuery(EmptyListView.FolderOnlyQuery);
    const pres = this.getItemsFromView(gcl, foldersView, hrmProvisioning.foldersCount, refreshStamp);
    // Use promise so we can execute in parallel.
    const pDocs = this.fetchDocuments({ gcl, refreshStamp });
    const [fRes, dRes] = await Promise.all([pres, pDocs]);

    let value = fRes.items;
    if (this.filters) value = value.filter(n => !this.filters?.has(n.name));
    return {
      items: new ViewFilesItem({ name: strings.lang.nodeNames.viewAllFiles, parent: parent || this }).apply(
        [...value, ...dRes.items],
        0,
        Boolean(fRes.pageToken || dRes.pageToken)
      ),
    };
  }

  private async fetchVirtualFolders(): Promise<Folder[]> {
    return new Array(hrmProvisioning.virtualFoldersCount).fill(0).map(
      (_e, i) =>
        new SPFolder({
          metadata: {
            ...this.metadata,
            FileLeafRef: `${this.metadata.FileLeafRef}-${i}`,
          },
          list: this.list,
        })
    );
  }

  private async fetchDocuments({ gcl, refreshStamp, count }: fetchDocumentProps): Promise<ItemDataWithPaging> {
    count = count || SPFilesCountState.value;
    const view = new EmptyListView()
      .combineWithFields(DEFAULT_FIELDS)
      .combineWithOrderBy(EmptyListView.OrderByLastModified)
      .combineWithQuery(EmptyListView.DocumentOnlyQuery);
    const res = await this.getItemsFromView(gcl, view, count, refreshStamp);
    return { items: res.items.slice(0, count), pageToken: res.pageToken };
  }

  async removeItem(gcl: GraphClient): Promise<void> {
    const spc = new SharePointClient(gcl, this.rootSite);
    await spc.api(`${this.apiId}/recycle()`).post(null);
  }

  override getIcon(expanded: boolean): IconProps {
    if (this.isDocumentSet)
      if (expanded) return { icon: FolderOpenedMedium, isColorable: true };
      else return { icon: FolderClosedMedium, isColorable: true };
    if (this.isOneNote) return { icon: OneMedium, isColorable: false };
    if (expanded) return { icon: FolderOpenedMedium, isColorable: true };
    return { icon: FolderClosedMedium, isColorable: true };
  }

  async getDetailsUrlByType(type: string): Promise<string> {
    const typeMapper = {
      edit: 'EditForm',
      view: 'DispForm',
    };
    return `${this.rootSite}${this.list.serverRelativeUrl}/Forms/${typeMapper[type]}.aspx?ID=${this.metadata.ID}`;
  }

  getProperty(name: string, type: SharePointFieldType): any {
    if (type === SharePointFieldType.Computed && name === 'FileSizeDisplay') {
      return this.metadata.ItemChildCount && `${this.metadata.ItemChildCount} items`;
    }
    if (type === SharePointFieldType.DateTime) {
      const friendlyDisplayValue = this.metadata[`${name}.FriendlyDisplay`];
      if (friendlyDisplayValue !== undefined && friendlyDisplayValue !== '') {
        return getFriendlyDateDisplay(this.metadata[`${name}`], LanguageSetting.value);
      }
    }
    return this.metadata[name];
  }
}
