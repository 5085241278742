import { getSortByName } from '@vendor';
import { AllowedItemType, BaseItemProps, ItemData } from '../itemTypes';
import { OfficeUserSettingManager } from '../stateManager';

export abstract class BaseRootSectionManager<T> extends OfficeUserSettingManager<T> {
  refreshRequired = false;

  remove(ids: string[]) {
    if (this.value) {
      const oldValue = this.value;
      this.refreshRequired = false;
      this.internalRemove(ids);
      return {
        run: () => {
          this.refreshRequired = true;
          this.value = oldValue;
        },
      };
    }
  }

  protected abstract internalRemove(ids: string[]): void;
}

interface RootSectionContent<T extends BaseItemProps> {
  registeredItems: T[];
  sortDir: boolean;
}

export class RootSectionManager<T extends BaseItemProps> extends BaseRootSectionManager<RootSectionContent<T> | null> {
  protected timeStamp = Date.now();
  private itemType?: AllowedItemType;

  setItemType(type: AllowedItemType) {
    this.itemType = type;
    return this;
  }

  canRemove(item: ItemData) {
    return item.type === this.itemType && item.isUserManaged;
  }

  protected override transformValue(res: any): RootSectionContent<T> | null {
    // Bad code (typo) saved partially the RootSectionContent. Fix it.
    if (res) res.registeredItems = res.registeredItems || [];
    return res;
  }

  protected override internalRemove(ids: string[]): void {
    this.value = {
      ...this.value!,
      registeredItems: this.value?.registeredItems.filter(item => !ids.includes(item.id)) || [],
    };
  }

  markAsUpdated() {
    this.timeStamp = Date.now();
  }
  get hasNewItems() {
    return this.value?.registeredItems.some(item => this.isNewItem(item)) || false;
  }

  isNewItem(item: T) {
    return (item.timeStamp && item.timeStamp > this.timeStamp) || undefined;
  }

  update(items: T[], hasNew: boolean) {
    const prevVal = this._value;
    if (this.value) {
      this.value.registeredItems = this.value.registeredItems?.map(item => {
        item.isNew = this.isNewItem(item);
        return item;
      });
    }

    this.refreshRequired = false;
    this.value = {
      registeredItems: items,
      sortDir: hasNew ? false : this._value ? this._value.sortDir : false,
    };
    return {
      added: items.filter(item => item.isNew),
      deleted: prevVal?.registeredItems.filter(prevItem => !items.some(nItem => nItem.id === prevItem.id)),
    };
  }

  get registeredItems() {
    return this.value?.registeredItems || [];
  }

  reorderItems() {
    if (this.value) {
      const sortDir = !this.value.sortDir;
      this.markAsUpdated();
      this.refreshRequired = true;
      this.value = {
        registeredItems: this.value.registeredItems.sort(getSortByName(sortDir)),
        sortDir,
      };
    }
  }
}
