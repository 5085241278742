import React, { useCallback, useEffect } from 'react';
import { ContextMenu2, Stack, useTheme, Scrollbar } from '@storybook';
import { useGraphClient } from '@services';
import QuickSearch from '~/components/QuickSearch/QuickSearch';
import { Channel } from '~/utilities';
import { AllLocations } from '~/utilities/virtualRoots/AllLocations';
import { SidebarInitKey } from '~/utilities/analytics/tracking';
import MultiSelectionModalManager from '~/modules/MultiSelectionModal/MultiSelectionModalManager';
import LicenseManager from '~/licenseManager/LicenseManager';
import { TopBar, Tree, WhatsNewNotification } from '../../components';

const style = { height: '100%', width: '100%' };

const Home = () => {
  const theme = useTheme();
  const gcl = useGraphClient();
  const allChannels = useCallback(async () => {
    await Channel.setAllChannels(gcl);
  }, [gcl]);

  useEffect(() => {
    allChannels();
    PubSub.publish(SidebarInitKey);
  }, [allChannels]);

  return (
    <Stack sx={style} className="hidden-scrollbar" background={theme.palette.backgrounds.primary}>
      <LicenseManager />
      <MultiSelectionModalManager />
      <WhatsNewNotification />
      <ContextMenu2>
        <QuickSearch location={AllLocations.instance} />
        <TopBar isSettingsPage={false} />
        <Scrollbar showDebugger={Boolean(hrmProvisioning?.scrollDebugger)}>
          <Tree />
        </Scrollbar>
      </ContextMenu2>
    </Stack>
  );
};

export default Home;
