import React, { useState, useRef, useCallback, useEffect, ReactNode } from 'react';
import Popover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';
import { Box, Stack, styled } from '@mui/system';
import { useTheme, Tooltip } from '@harmon.ie-ce/storybook';
import { strings } from '@vendor';
import { Scrollbar } from '../Scollbar';

const zIndex = 100;
const DropdownBodyStack = styled(Stack)`
  ${({ theme }) => ({
    marginTop: theme.spacing(8),
    overflowY: 'auto',
    borderTop: `1px solid ${theme.palette.outlinesAndStrokes.primary}`,
  })}
`;

interface DropdownComponentProps {
  withBackDrop?: boolean;
  disabled?: boolean;
  renderBody: (props: any) => ReactNode;
  renderInput: (props: any) => ReactNode;
  fixedHeight?: number;
  resetState?: () => void;
}

const DropdownWithInput: React.FC<DropdownComponentProps> = ({
  disabled,
  renderBody,
  renderInput,
  withBackDrop,
  fixedHeight,
  resetState,
}) => {
  const theme = useTheme();
  const componentRef = useRef<HTMLDivElement | null>(null);
  const inputComponentRef = useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleMouseEnter = () => {
    setShowTooltip(Boolean(disabled));
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handlePopoverClose = useCallback(
    e => {
      if (e['no-open']) return;
      setAnchorEl(null);
      resetState?.();
      // Using setTimeout to bypass changes on focus.
      setTimeout(() => {
        (document.activeElement as HTMLElement).blur();
      }, 0);
    },
    [resetState]
  );

  useEffect(() => {
    window.addEventListener('resize', handlePopoverClose);
    return () => {
      window.removeEventListener('resize', handlePopoverClose);
    };
  }, [anchorEl, disabled, handlePopoverClose]);

  const isDropOpen = Boolean(anchorEl);
  const openDrop = useCallback(() => !disabled && setAnchorEl(componentRef.current), [disabled]);
  const closeDrop = useCallback(() => setAnchorEl(null), []);

  return (
    <Tooltip title={strings.lang.tooltip.chatAndFilesSearch} open={showTooltip} className="default-tooltip">
      <Box flex={1} ref={componentRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {renderInput({ handlePopoverClose, openDrop, isDropOpen, closeDrop, inputComponentRef })}
        <Popover
          classes={{ paper: 'search-popover' }}
          open={isDropOpen}
          anchorEl={anchorEl}
          disableScrollLock={true}
          className="AnimationfadeInScaleUp"
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          sx={{ zIndex }}
          PaperProps={{
            sx: { width: inputComponentRef.current?.clientWidth },
          }}
        >
          <DropdownBodyStack sx={{ height: fixedHeight || '100%' }} spacing={1}>
            <Scrollbar noHorizontal>{renderBody({ closeDrop })}</Scrollbar>
          </DropdownBodyStack>
        </Popover>
        {withBackDrop && (
          <Backdrop
            onClick={handlePopoverClose}
            sx={{ zIndex: zIndex - 1, backgroundColor: theme.palette.backgrounds.overlay }}
            open={isDropOpen}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default DropdownWithInput;
