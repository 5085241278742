import React, { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { createMsalInstance } from '@services';
import { MsalProvider } from '@azure/msal-react';
import { createRoot } from 'react-dom/client';
import { Provider } from '@storybook';
import { IPublicClientApplication } from '@azure/msal-browser';
import { OnOfficeReady, ReactAppInit } from '@vendor';
import { ReactAppInitData } from '~/taskpane';
import { asyncSubscribe, asyncSubscribeOnce, useSettingsState } from '~/hooks';
import { lightOrDark, setConfig } from '~/utilities';
import { SelectedTheme } from '~/modules/Settings/SettingContext';
import LoadingScreen from '~/modules/Login/LoadingScreen';
import { IndicatorProvider } from '~/context/IndicatorProvider';
import { LicenseProvider } from '~/licenseManager/LicenseProvider';
import { onTrackerInit } from './TrackerInit';

const DragAndDropGlobal = React.lazy(() => import('./DragAndDropGlobal'));
const App = React.lazy(() => import('~/taskpane/App'));

const AppWithProviders: FC = () => {
  const [theme] = useSettingsState(SelectedTheme);
  const [msalInstance, setMsalInstance] = useState<IPublicClientApplication>();
  const initMsal = useCallback(async () => setMsalInstance(await createMsalInstance()), []);
  const isDark =
    theme === 'dark' || (theme === 'system' && lightOrDark(Office.context?.officeTheme?.bodyBackgroundColor));

  useEffect(() => {
    initMsal();
  }, [initMsal]);
  return (
    <LicenseProvider>
      <Provider themeMode={isDark ? 'dark' : 'light'}>
        <IndicatorProvider>
          <DragAndDropGlobal>
            {msalInstance && (
              <MsalProvider instance={msalInstance}>
                <Suspense fallback={<LoadingScreen></LoadingScreen>}>
                  <App />
                </Suspense>
                <div id="poppoverContainer" />
              </MsalProvider>
            )}
          </DragAndDropGlobal>
        </IndicatorProvider>
      </Provider>
    </LicenseProvider>
  );
};

const onOfficeInit = async () => {
  setConfig();
  const container = createRoot(document.getElementById('container') as HTMLElement);

  container.render(<AppWithProviders />);
};

const RefreshStamp = Date.now(); // Ensure that getting all teams is refreshed exactly once!

const initIndexDB = async () => {
  const { initializeDB } = await import('@services');

  await initializeDB();
};

const onProvisioningInit = async ({ account, gcl }: ReactAppInitData) => {
  const { TeamsRoot } = await import('~/utilities');
  const { initProvisioing } = await import('~/provisioning/ProvisioningReader');
  const { BaseStateManager } = await import('~/utilities/stateManager/localstate');

  if (account) {
    await initProvisioing(gcl, account);
    BaseStateManager.resetLocalUserSettings(account?.username || '');

    // Start collecting team urls in BG...
    TeamsRoot.collectAllTeamsLocations(gcl, RefreshStamp);
  }
};

export const AppInit = () => {
  asyncSubscribeOnce(OnOfficeReady, onOfficeInit);
  asyncSubscribe(OnOfficeReady, initIndexDB);
  asyncSubscribe(ReactAppInit, onProvisioningInit);
  asyncSubscribeOnce(OnOfficeReady, onTrackerInit);
};
