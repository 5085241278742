import React, { SyntheticEvent } from 'react';
import { Drawer, DrawerProps, styled } from '@mui/material';
import { CloseButton } from './common';

const StyledDrawer = styled(Drawer)`
  position: relative;

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export type WhatsNewDrawerProps = Omit<DrawerProps, 'onClose'> & {
  onClose?: (e: SyntheticEvent) => void;
  borderRadius?: number | undefined;
};

export const WhatsNewDrawer = ({ children, onClose, borderRadius = 4, ...rest }: WhatsNewDrawerProps) => {
  return (
    <StyledDrawer
      {...rest}
      anchor="bottom"
      PaperProps={{
        sx: { borderRadius, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
      }}
    >
      <CloseButton onClick={onClose} />
      {children}
    </StyledDrawer>
  );
};
