import React from 'react';
import { ContextMenu2, Counter, Stack, useTheme } from '@storybook';
import { strings } from '@vendor';
import { ItemContainer, ItemData, ItemDataWithPaging, locationPlaceholderQuickSearch } from '~/utilities';
import QuickSearch from '~/components/QuickSearch/QuickSearch';
import { Search } from '~/utilities/search';
import { generateQuickSearchKey, useDrilldown } from '~/components/Tree/Renderers/DrilldownHandler';
import DrilldownToolbar from './DrilldownToolbar/DrilldownToolbar';
import KeyPressSubscribe from '../KeyPress/KeyPressSubscribe';
import { DragAndDrop } from '../DragAndDrop';
import TableManager from './Table/TableManager';
import VirtualListManager from './VirtualListManager';

const moreThanAThousand = '1000+ ';

const Drilldown: React.FC<{
  getItems: (token: string | undefined) => Promise<ItemDataWithPaging>;
  location: ItemContainer;
  enableToolbarActions: boolean;
  newItems?: ItemData[];
}> = ({ getItems, location, enableToolbarActions, newItems: inpNewItems }) => {
  const theme = useTheme();
  const {
    listHandlers,
    resultCount,
    pagginationText,
    selection,
    newItems,
    items,
    mappedItems,
    counter,
    loadingRef,
    nextToken,
    fetchPage,
    selectedView,
    schema,
  } = useDrilldown(getItems, location, inpNewItems);
  const searchProps =
    location && (location as ItemContainer).isAdvancedSearchContainer
      ? {
          searchTerm: (location as Search).searchTerm,
          filters: (location as Search).filters,
          entityTypes: (location as Search).entityTypes,
        }
      : { searchTerm: '', filters: '', entityTypes: [] };

  return (
    <ContextMenu2>
      <KeyPressSubscribe handlers={listHandlers} />
      <Stack sx={{ height: '100%' }} background={theme.palette.backgrounds.primary}>
        <QuickSearch
          key={generateQuickSearchKey(location, searchProps)}
          placeholder={locationPlaceholderQuickSearch(
            location.isAdvancedSearchContainer ? (location as Search).location : location
          )}
          location={location}
          searchProps={searchProps || { searchTerm: '', filters: '', entityTypes: [] }}
          disabled={location.isAdvancedSearchContainer}
        />
        <DrilldownToolbar
          location={location}
          enableActions={enableToolbarActions}
          handlers={listHandlers}
        ></DrilldownToolbar>
        <DragAndDrop
          node={{ data: location, id: location.id }}
          handlers={listHandlers}
          isTreeItem={false}
          isDrilldownWrapper={true}
        >
          <Stack sx={{ height: '100%' }} pb={2} onClick={() => listHandlers.unselectAll()}>
            {selectedView?.isSimpleView ? (
              <VirtualListManager
                location={location}
                listHandlers={listHandlers}
                selection={selection}
                searchProps={searchProps}
                newItems={newItems}
                items={items}
                mappedItems={mappedItems}
                nextToken={nextToken}
                loadingRef={loadingRef}
                counter={counter}
                fetchPage={fetchPage}
                orderBy={selectedView.stateView.orderBy}
              />
            ) : (
              <TableManager
                listHandlers={listHandlers}
                selectedView={selectedView}
                items={items}
                newItems={newItems}
                mappedItems={mappedItems}
                selection={selection}
                nextToken={nextToken}
                fetchPage={fetchPage}
                schema={schema}
              />
            )}
          </Stack>
        </DragAndDrop>
        {location.isAdvancedSearchContainer && (
          <Counter
            text={`${pagginationText ? pagginationText : resultCount > 1000 ? moreThanAThousand : resultCount} ${
              strings.lang.results.countResults
            }`}
          ></Counter>
        )}
      </Stack>
    </ContextMenu2>
  );
};

export default Drilldown;
