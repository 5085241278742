import React from 'react';
import { ViewItem, TeamsMedium } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor';
import { ItemData } from '../itemTypes';
import { BaseReadActionClass, KeyModifiers, ShortcutAction, ShortcutActionRendererHookType } from './BaseAction';
import { shareToTeams } from './ActionsUtils';
import { InsightItem } from '../graphTypes';
import { EnableTeamsState } from '../virtualRoots';

const useShareToTeams: ShortcutActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const gcl = useGraphClient();
  const onClick = useSafeCallback(
    async () => {
      await shareToTeams(gcl, nodes);
    },
    [gcl, nodes],
    false
  );
  const fill = { 'data-nofill': true };
  return {
    title: strings.lang.actionToolTip.shareToTeams,
    icon: <TeamsMedium {...fill} />,
    onClick,
    chipLabel: strings.lang.shortcuts.shareToTeams,
  };
};

export class ShareToTeams extends BaseReadActionClass implements ShortcutAction {
  readonly trackedName = 'ShareToTeams';
  supportsTopLocation = true;
  readonly useRenderer = useShareToTeams;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return (
      EnableTeamsState.value &&
      nodes.length === 1 &&
      data &&
      data.canOpenInBrowser &&
      !data.isTeamsEntity &&
      nodes[0].id !== 'oneDrive:OneDrive:' &&
      !(data instanceof InsightItem && data.isEmailAttachment)
    );
  }

  shortcut: [KeyModifiers, string] = [KeyModifiers.Ctrl, 't'];
}
