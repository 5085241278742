import { BackMedium, ViewItem } from '@storybook';
import { useNavigate } from 'react-router-dom';
import { strings } from '@vendor';
import { useState } from 'react';
import { ActionRendererHookType, BaseReadActionClass, ItemData, TrackedEventType, isItemContainer } from '~/utilities';
export const useGoHome: ActionRendererHookType = ({}) => {
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  PubSub.subscribe('AdminSettingsChanged', (_msg: string, changed: boolean) => setDisable(changed));
  const onClick = () => {
    navigate(-1), [navigate];
  };
  return {
    title: strings.lang.toolbarActions.back,
    icon: BackMedium,
    onClick,
    disabled: disable,
  };
};

export class GoBackAction extends BaseReadActionClass {
  readonly trackedName = 'NavigationHistory';
  useRenderer = useGoHome;
  transformEvent({ data, eventName }: TrackedEventType, _ctx?: any): TrackedEventType {
    return {
      eventName,
      data: {
        ...data,
        itemType: 'History Item',
        direction: 'Back',
      },
    } as TrackedEventType;
  }

  isQuickAction() {
    return 'only';
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return isItemContainer(nodes[0].data.type);
  }
}
