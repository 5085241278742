import { useCallback, useMemo } from 'react';
import { ViewItem, LocationMedium, LocationSmall, ContainerHandlers } from '@storybook';
import { strings } from '@vendor';
import { useDrilldownNavigation } from '~/components/Tree/Renderers/DrilldownHandler';
import { BaseItem, ItemData } from '../itemTypes';
import { ActionRendererHookType, ActionTrigger, BaseReadActionClass } from './BaseAction';
import { isSameLocationForParent } from '../misc';
import { createUseCallbackWrap } from './ContextMenuAction';

const useOpenContaingLocationRenderer: ActionRendererHookType = ({ nodes, useCallback }) => {
  // For hover specific node get if it's favorite or not!
  const node = nodes[0].data;
  const newItem = useMemo(() => (node as BaseItem).objectify({ ...node, isSearchItem: false }), [node]);
  const onClick = useDrilldownNavigation({ data: node.parent, newItems: [newItem], useCallback });

  return {
    title: strings.lang.actionToolTip.openContainingLocation,
    icon: [LocationSmall, LocationMedium],
    onClick,
  };
};

export class OpenContaingLocationAction extends BaseReadActionClass {
  readonly trackedName = 'OpenContainingLocation';
  readonly useRenderer = useOpenContaingLocationRenderer;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return (
      Boolean(nodes.length == 1 && nodes[0].data.isSearchItem && nodes[0].data.parent) &&
      !isSameLocationForParent(nodes[0].data.parent, nodes[0].data.searchedLocationId)
    );
  }
}

export const useOpenContaingLocation = (
  node: ViewItem<ItemData>,
  trigger: ActionTrigger,
  handlers: ContainerHandlers<ItemData>
) => {
  const useCallbackWrap = createUseCallbackWrap(
    new OpenContaingLocationAction(),
    trigger,
    [node],
    useCallback,
    handlers
  );
  const newItem = useMemo(() => node.data.objectify({ ...node.data, isSearchItem: false }), [node]);
  return useDrilldownNavigation({ data: node.data.parent, newItems: [newItem], useCallback: useCallbackWrap });
};
