import { strings } from '@vendor';
import { ItemContainer } from '../itemTypes';

export const locationPlaceholderQuickSearch = (location?: ItemContainer) => {
  return location?.quickSearchPlaceholder ?? strings.lang.quickSearch.generalSearch;
};

export const isSameLocationForParent = (parent?: ItemContainer, searchedLocationId?: string) => {
  return parent?.id === searchedLocationId;
};
