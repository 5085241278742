import { IndicatorRequestHandler, SelectItem } from '@storybook';
import { GraphClient } from '@services';
import { DocumentItem, Folder, ItemContainer } from '../itemTypes';
import { uploadDocumentToSharePoint, UploadItemProps } from './UploadUtils';

export abstract class UploadItemProvider {
  name: string = '';
  newItem: DocumentItem | undefined;
  uploadItemProps: UploadItemProps | undefined;
  isOverride?: boolean = undefined;
  isExist?: boolean = undefined;

  // Replace readonly folder with a private field
  private _folder: Folder;

  constructor(
    protected readonly gcl: GraphClient,
    readonly item: File | Office.SelectedItemDetails | SelectItem,
    readonly location: ItemContainer,
    readonly isDragAndDrop = false
  ) {}

  // Getter for folder
  get folder(): Folder {
    return this._folder;
  }

  // Setter for folder
  set folder(value: Folder) {
    this._folder = value;
  }

  // Abstract method to get the item name, to be overridden by subclasses
  abstract getItemName(): string;

  getNewItem(): DocumentItem | undefined {
    return this.newItem;
  }

  setNewItem(newItem: DocumentItem): DocumentItem | undefined {
    return (this.newItem = newItem);
  }

  // Abstract method to get the item blob/data, to be overridden by subclasses
  abstract getItemBlob(): Promise<void>;

  // Upload process that uses the folder getter
  async uploadItem(
    progressRequestHandler: IndicatorRequestHandler | undefined,
    override?: boolean
  ): Promise<DocumentItem | undefined> {
    await this.getItemBlob();
    if (!this.uploadItemProps) return;
    this.newItem = await uploadDocumentToSharePoint(
      this.gcl,
      this.folder, // Uses the getter here
      this.uploadItemProps.name,
      progressRequestHandler?.getCurrentProgressIndex().current || 0,
      this.uploadItemProps.data,
      override,
      progressRequestHandler
    );
  }
}
