import { ContainerHandlers, MenuActions, NotificationContext, ViewItem } from '@storybook';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useGraphClient } from '@services';
import { useSubscribe } from '~/hooks';
import { ItemData, ShortcutAction as ShortcutAction, useRenderActionByHook, wrapActionCallback } from '~/utilities';
import { ShortcurPressedkey } from './KeyPressPublish';

interface KeyPressSubscribeProps {
  handlers: ContainerHandlers<ItemData>;
}

const SelectedKeyAction: React.FC<{
  handlers: ContainerHandlers<ItemData>;
  nodes: ViewItem<ItemData>[];
  action: ShortcutAction;
}> = ({ handlers, nodes, action }) => {
  const [appliedAction, setAppliedAction] = useState<ShortcutAction>();
  const { onClick, SideUI } = useRenderActionByHook({
    trigger: 'Shortcut',
    actionVariant: MenuActions,
    nodes,
    action,
    handlers,
  })!;
  useEffect(() => {
    appliedAction !== action && (onClick as () => any)();
    setAppliedAction(action);
  }, [action, appliedAction, onClick]);
  return <div>{SideUI}</div>;
};

const KeyPressSubscribe = ({ handlers }: KeyPressSubscribeProps) => {
  const [selectedItems, setSelectedItems] = useState([] as ViewItem<ItemData>[]);
  const [selectedAction, setSelectedAction] = useState<ShortcutAction>();
  const [actionKey, setActionKey] = useState(0);

  // For direct action call
  const gcl = useGraphClient();
  const actionRef = useRef<any>();
  const { showNotification } = useContext(NotificationContext);

  useSubscribe(ShortcurPressedkey, async (action: ShortcutAction) => {
    const parent = handlers.getParent();
    let selItems = handlers.getSelectedNodes();
    selItems = selItems.length ? selItems : (parent && action.supportsTopLocation && [parent]) || [];
    if (action.isHandled(selItems))
      if (action.directAction) {
        const callBack = wrapActionCallback(action.directAction, action, 'Shortcut', selItems, handlers, actionRef);
        const notify = await callBack(gcl, selItems);
        notify && showNotification(...notify);
      } else {
        setSelectedAction(action);
        setSelectedItems(selItems);
        setActionKey(actionKey + 1);
      }
  });

  return (
    <React.Fragment>
      {selectedAction && (
        <SelectedKeyAction key={actionKey} action={selectedAction} nodes={selectedItems} handlers={handlers} />
      )}
    </React.Fragment>
  );
};

export default KeyPressSubscribe;
