import { BaseAction, ContextMenuAction } from '~/utilities';
import { GoToSetting as GoToSetting } from './GoToSetting';
import { GetSupportLink } from './GetSupport';
import { WhatsNew } from './WhatsNew';
import { TrainingVideos } from './TrainingVideos';

export const getAllLocationsActions = (): BaseAction[] => [new GetSupportLink(), new WhatsNew(), new TrainingVideos()];
export const getAllLocationsQuickActions = (): BaseAction[] => [
  new GoToSetting(),
  new ContextMenuAction(getAllLocationsActions()),
];
export const getSttingsQuickActions = (): BaseAction[] => [new ContextMenuAction(getAllLocationsActions())];
