import { SharePointEmptyStateDark, SharePointEmptyStateLight, SharepointMedium, IconProps } from '@storybook';
import _ from 'lodash';
import { Site } from '@microsoft/microsoft-graph-types';
import { SiteExpirationWindow, GraphSiteKeys, GValue, GraphClient, EternalWindow } from '@services';
import { getSortByName } from '@vendor';
import { strings } from '@vendor';
import { SPSite, SPSiteProps } from '../sharePointTypes';
import { FetchChildrenProps, EmptyStateItem, ItemDataWithPaging, MicrosoftApps, ItemData } from '../itemTypes';
import { createItemDataFromJson } from '../misc';
import { ManageSitesAction, publishItemListChanged } from '../actions';
import { RootSectionManager } from './RootSectionManager';
import { SharePointClient } from '../sharePointAPI';
import { OneDriveRoot } from './OneDriveRoot';
import { TeamsRoot } from './TeamsRoot';
import { BaseRootSectionItem } from './RootSectionItem';
import { AppsFilters } from '../search';

export class SharePointSitesRoot extends BaseRootSectionItem {
  private static _instance: SharePointSitesRoot;

  static Settings = new RootSectionManager<SPSiteProps>('SharePointSettings', () => null).setItemType('site');

  static get instance() {
    if (this._instance === undefined) this._instance = new SharePointSitesRoot();
    return this._instance;
  }
  private constructor() {
    super({ type: 'sharepointroot', id: 'root', name: strings.lang.nodeNames.sharePoint });
    this.rootPrefix = 'sharePoint:';
  }

  get canRefresh(): boolean {
    return false;
  }
  get supportsSortDirection() {
    return true;
  }
  override get canReorder(): boolean {
    return true;
  }
  get quickSearchPlaceholder(): string {
    return strings.lang.quickSearch.sharePoint;
  }
  override get showNewIndicator(): boolean {
    return SharePointSitesRoot.Settings.hasNewItems;
  }
  override get isAppContainer(): boolean {
    return true;
  }
  override get Settings() {
    return SharePointSitesRoot.Settings;
  }

  override getNumberOfQuickActions() {
    return 0;
  }

  override markAsUpdated() {
    SharePointSitesRoot.Settings.markAsUpdated();
  }

  static save(newState: SPSiteProps[], hasNew: boolean) {
    const changes = SharePointSitesRoot.Settings.update(newState, hasNew);
    publishItemListChanged({
      location: this._instance,
      added: changes?.added.reduce((val, site) => {
        val[site.id] = new SPSite(site);
        val[site.id].isNew = this.Settings.isNewItem(site);
        return val;
      }, {}),
      deleted: changes.deleted?.reduce((val, site) => {
        val[site.id] = new SPSite(site);
        return val;
      }, {}),
    });
  }

  override reorderItems(): void {
    SharePointSitesRoot.Settings.reorderItems();
  }

  override get sortDir(): boolean {
    return SharePointSitesRoot.Settings.value?.sortDir || false;
  }

  static async getFollowedSites(gcl: GraphClient, refreshStamp?: number, top?: number): Promise<SPSite[]> {
    const followedSites: GValue<Site> = await gcl
      .api('/me/followedSites')
      .cache(SiteExpirationWindow, refreshStamp)
      .select(GraphSiteKeys)
      .top(top || hrmProvisioning.followedSitesCount)
      .get();
    return followedSites.value.map(
      s =>
        new SPSite({
          graphRoot: '/sites',
          id: s.id || '',
          site: s.webUrl || '',
          name: s.displayName || '',
          _isUserManaged: true,
          siteDescription: s.description,
          OfficeAppLocated: MicrosoftApps.SharePoint,
        })
    );
  }

  static async getFrequentSites(spc: SharePointClient, refreshStamp?: number, top?: number): Promise<SPSite[]> {
    const frequentSites = await spc
      .api(`${spc.rootSite}/_api/v2.1/insights/frequentSites`)
      .cache(SiteExpirationWindow, refreshStamp)
      .top(top || hrmProvisioning.frequentsSitesCount)
      .get();
    return frequentSites.value.map(
      (s: { id: string; sharepointIds: { siteUrl: string }; title: string }) =>
        new SPSite({
          id: s.id,
          site: s.sharepointIds.siteUrl,
          name: s.title,
          _isUserManaged: true,
          graphRoot: '/sites',
          OfficeAppLocated: MicrosoftApps.SharePoint,
          // The feed doesn't provide the site's description.
        })
    );
  }

  static async getRootSPSite(gcl: GraphClient): Promise<SPSite> {
    const rootItem: Site = await gcl
      .api('/sites/root')
      // Home site URL shouldn't change
      .cache(EternalWindow)
      .select(GraphSiteKeys)
      .get();
    return new SPSite({
      graphRoot: '/sites',
      id: rootItem.id || '',
      site: rootItem.webUrl || '',
      name: rootItem.displayName || '',
      _isUserManaged: true,
      siteDescription: rootItem.description,
      OfficeAppLocated: MicrosoftApps.SharePoint,
    });
  }

  static async getOnBoardingItems(gcl: GraphClient, refreshStamp?: number): Promise<SPSite[]> {
    // Prepare for frequent sites call.
    let frequentRoot: string | undefined;
    let rootSite: SPSite | undefined;
    try {
      // try SharePoint root site
      const root = await this.getRootSPSite(gcl);
      frequentRoot = root.rootSite;
      rootSite = root;
    } catch {}
    if (!frequentRoot) {
      try {
        // try OneDrive instead
        const oneDriveRoot = await OneDriveRoot.getRootLibrary(gcl);
        frequentRoot = oneDriveRoot.rootSite;
      } catch {}
    }

    // We run in parallel - followed sites, frequent sites & fetching all teams' urls.
    const getFollowedF = this.getFollowedSites(gcl, refreshStamp, 25);
    let getFrequentF = Promise.resolve<SPSite[]>([]);
    if (frequentRoot) {
      const spc = new SharePointClient(gcl, frequentRoot);
      getFrequentF = this.getFrequentSites(spc, refreshStamp, 25);
    }
    const [knownTeams, followed, frequent] = await Promise.all([
      TeamsRoot.collectAllTeamsLocations(gcl, Date.now()),
      getFollowedF,
      getFrequentF,
    ]);
    const result = [...followed, ...frequent].filter(s => !knownTeams.bySiteUrl.has(s.site));
    if (result.length === 0 && rootSite) result.push(rootSite);
    return _.uniqBy(result, 'id');
  }

  static async getRegisteredSites(gcl: GraphClient, refreshStamp?: number) {
    if (this.Settings.value === null) {
      const initialValue = await this.getOnBoardingItems(gcl, refreshStamp);
      SharePointSitesRoot.Settings.value = {
        registeredItems: initialValue.sort(getSortByName(true)).map(s => s.toJson()),
        sortDir: true,
      };
    }
    return this.Settings.registeredItems.map(s => {
      const item = createItemDataFromJson(s);
      item.isNew = this.Settings.isNewItem(s);
      return item;
    });
  }

  override applyEmptyState(items: ItemData[], isDrilldown?: boolean): ItemData[] {
    return new EmptyStateItem({
      name: 'sharePoint',
      inTree: true,
      images: { light: SharePointEmptyStateLight, dark: SharePointEmptyStateDark },
      action: new ManageSitesAction(),
      location: { id: this.id, data: this },
      isDrilldown,
    }).apply(items, items.length === 0);
  }

  override async fetchChildren({ gcl, isDrilldown, refreshStamp }: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const registeredSites = await SharePointSitesRoot.getRegisteredSites(gcl, refreshStamp);

    this.markAsUpdated();

    return {
      items: this.applyEmptyState(registeredSites, isDrilldown),
    };
  }

  override async getSearchFilter(gcl: GraphClient, withTeams = false) {
    return (await AppsFilters.SHARE_POINT(gcl, withTeams)).filter;
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: SharepointMedium, isColorable: false };
  }
}
