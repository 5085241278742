import React, { MutableRefObject, useCallback, useRef } from 'react';
import { strings } from '@vendor';
import { logError } from '@vendor';
import { AddMedium, NotificationsToastsSuccessIcon } from '@storybook';
import { ValidationError } from '~/provisioning';
import { ActionRendererHookType, BaseUpdateActionClass, TrackedEventType } from '~/utilities';

const useImportConfigurations: ActionRendererHookType = ({
  action,
  useSafeCallback,
  actionRef,
}): ReturnType<ActionRendererHookType> => {
  const onUpload = (action as ImportConfigurationAction).onUpload;
  const fileUpload = useRef<HTMLInputElement | null>(null);
  const uploadKey = useRef<number>(Date.now());

  const handleEvent = useSafeCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const filesList = target?.files;
      if (filesList !== null) {
        try {
          const jsonFile = filesList[0];
          const fileContent = await jsonFile.text();
          const parsedJson = JSON.parse(fileContent);
          onUpload(parsedJson, actionRef); // Pass the uploaded JSON data to the parent
        } catch (error) {
          logError(error, 'Failed to upload JSON file');
          if (error instanceof ValidationError) throw error;
          throw new Error(strings.lang.adminSetting.adminCenterImportNotificationsFailedMessage);
        }
      }
    },
    [actionRef, onUpload],
    false,
    {
      onStartTitle: strings.lang.adminSetting.adminCenterImportNotificationsStart,
      onSuccessTitle: strings.lang.adminSetting.adminCenterImportNotificationsDone,
      errorTitle: strings.lang.adminSetting.adminCenterImportNotificationsFailed,
      errorMessage: strings.lang.adminSetting.adminCenterImportNotificationsFailedMessage,
    },
    undefined,
    undefined,
    undefined,
    <NotificationsToastsSuccessIcon />
  );

  const handleUpload = useCallback(() => {
    fileUpload.current?.click();
  }, []);

  return {
    title: strings.lang.adminSetting.adminCenterImport,
    icon: AddMedium,
    onClick: handleUpload,
    SideUI: (
      <input
        key={uploadKey.current}
        type="file"
        accept=".json"
        ref={fileUpload}
        hidden={true}
        onChange={handleEvent}
        value="" // Otherwise selecting the same file twice won't trigger the change event
        aria-label="json-file-upload"
      />
    ),
  };
};

export class ImportConfigurationAction extends BaseUpdateActionClass {
  readonly trackedName = 'ProvisionedSettings!';
  useRenderer = useImportConfigurations;

  override transformEvent({ data, eventName }: TrackedEventType, ctx?: any): TrackedEventType {
    return {
      eventName,
      data: {
        ...data,
        ...ctx,
      },
    };
  }
  constructor(public readonly onUpload: (data: Record<string, any>, actionRef: MutableRefObject<any>) => void) {
    super();
  }

  isHandled(): boolean {
    return true;
  }
}
