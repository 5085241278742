import { TooltipProps as MuiTooltipProps, Tooltip as MuiTooltip } from '@mui/material';
import React from 'react';
import { Typography, TypographyProps } from '../Typography';
import { Stack } from '../Stack';
export interface TooltipProps extends Omit<MuiTooltipProps, 'title' | 'arrow'> {
  disabled?: boolean;
  title?: React.ReactNode;
  tooltip?: boolean;
  className?: string;
}

export const NativeTooltip = ({ children, title = '', ...rest }: TooltipProps) => {
  const container = document.getElementById('poppoverContainer') || document.body;

  return (
    <MuiTooltip
      disableTouchListener
      disableInteractive
      arrow={false}
      PopperProps={{ container, className: 'preview-tooltip' }}
      title={title}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

const Tooltip = ({ children, className, title = '', ...rest }: TooltipProps) => {
  return (
    <MuiTooltip
      disableTouchListener
      disableInteractive
      arrow={false}
      PopperProps={{ className: className || 'default-tooltip' }}
      title={!className ? <Typography variant="H3Regular">{title}</Typography> : title}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export const LocationLabelTooltip = ({ children, className, title = '', ...rest }: TooltipProps) => {
  return (
    <MuiTooltip
      disableInteractive
      arrow={false}
      PopperProps={{ className: className || 'location-label-tooltip' }}
      {...rest}
      title={title}
    >
      {children}
    </MuiTooltip>
  );
};
export default Tooltip;

interface TruncatedTextWithTooltipProps {
  text?: string;
  color: string;
  variant: TypographyProps['variant'];
  maxWidth: string;
  charLimit: number; // Number of characters to trigger tooltip
}

export const TruncatedTextWithTooltip: React.FC<TruncatedTextWithTooltipProps> = ({
  text,
  color,
  variant,
  maxWidth,
  charLimit,
}) => {
  const shouldShowTooltip = text && text.length > charLimit; // Check if text exceeds the character limit

  return (
    <Tooltip title={shouldShowTooltip ? text : ''} disableHoverListener={!shouldShowTooltip}>
      <Stack
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'default',
        }}
      >
        <Typography
          color={color}
          variant={variant}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: maxWidth,
          }}
        >
          {text}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
