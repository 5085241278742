import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ManageLists,
  SettingsMedium,
  SettingsSmall,
  SharePointEmptyStateDark,
  SharePointEmptyStateLight,
  SiteMedium,
  ViewItem,
  nameMatchesFilter,
} from '@storybook';
import { TeamInfo } from '@microsoft/microsoft-graph-types';
import { useGraphClient } from '@services';
import { logError } from '@vendor';
import { strings } from '@vendor';
import { useDebouncedEffect } from '~/hooks';
import { ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';
import { SharePointSitesRoot, TeamsRoot } from '../virtualRoots';
import { SPSiteProps } from '../sharePointTypes';
import { Search } from '../search';

interface sitesInfo extends TeamInfo {
  isNew?: boolean;
  timeStamp?: number;
}

const ManageSitesComponent: React.FC<{
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  container: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
}> = ({ setIsOpen, container, isOpen }) => {
  const latestSeach = useRef<string>('');
  const gcl = useGraphClient();
  const [error, setError] = useState(false);
  const [filter, setFilter] = useState<string>('');
  const [listLoading, setListLoading] = useState<boolean>(true);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [knownTeamUrls, setKnownTeamUrls] = useState<Map<string, TeamInfo>>();

  const [availableSites, setAvailableSites] = useState<SPSiteProps[]>([]);
  const [registeredSites, setRegisteredSites] = useState<SPSiteProps[]>();

  const onSave = useCallback((newState: sitesInfo[], hasNew) => {
    SharePointSitesRoot.save(newState as SPSiteProps[], hasNew);
  }, []);

  const filterName = useMemo(() => nameMatchesFilter(filter), [filter]);

  const siteStrings = useMemo(() => strings.lang.userManage.sites, []);

  const initializeTeamsAndSites = useCallback(async () => {
    try {
      setRegisteredSites((await SharePointSitesRoot.getRegisteredSites(gcl)).map(s => s.toJson()));
      // We can now enable the managed list...
      setKnownTeamUrls(TeamsRoot.allTeamLocations.bySiteUrl);

      setKnownTeamUrls((await TeamsRoot.collectAllTeamsLocations(gcl, Date.now())).bySiteUrl);
    } catch (error: any) {
      logError(error, 'Failed to initialize sites');
      setError(true);
    }
  }, [gcl]);

  const internalFetchSites = useCallback(
    async (term: string) => {
      const fromItem = 0;
      const top = 100 + hrmProvisioning.sitesSearchCount;
      try {
        setListLoading(true);
        setAvailableSites([]);
        latestSeach.current = term;
        const sitesFilter = await SharePointSitesRoot.instance.getSearchFilter(gcl, true);
        const res = await Search.searchItems(
          gcl,
          { searchTerm: `SiteTitle:${term}*`, entityTypes: ['site'], filters: sitesFilter },
          undefined,
          false,
          top,
          fromItem
        );
        if (term === latestSeach.current) {
          setAvailableSites([...res.items.map(s => s.toJson())]);
          setHasMoreResults(res.itemsCount > res.items.length);
        }
      } catch (error: any) {
        logError(error, 'Failed to search sites');
        setError(true);
      } finally {
        setListLoading(false);
      }
    },
    [gcl]
  );

  useEffect(() => {
    // Init Effect
    if (isOpen) {
      if (knownTeamUrls !== undefined) {
        internalFetchSites('');
      } else {
        initializeTeamsAndSites();
      }
    }
  }, [internalFetchSites, initializeTeamsAndSites, isOpen, knownTeamUrls]);

  const fetchSites = useCallback(() => internalFetchSites(filter), [filter, internalFetchSites]);
  useDebouncedEffect(fetchSites, [fetchSites], 500);

  return (
    <ManageLists
      icon={<SiteMedium />}
      container={container}
      strings={siteStrings}
      operatedListItems={registeredSites}
      listLoading={listLoading}
      listMoreResults={{ kind: 'hint', hasMore: hasMoreResults, hintCount: hrmProvisioning.sitesSearchCount }}
      isOpen={isOpen}
      setOpen={setIsOpen}
      items={availableSites}
      setFilterValue={setFilter}
      filterValue={filter}
      error={error}
      onSave={onSave}
      filterFunction={filterName}
      images={{ light: SharePointEmptyStateLight, dark: SharePointEmptyStateDark }}
    />
  );
};

const useManageSitesAction: ActionRendererHookType = ({ useCallback }) => {
  const container = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const onClick = useCallback(() => setIsOpen(true), []);

  return {
    SideUI: (
      <div onClick={e => e.stopPropagation()}>
        <ManageSitesComponent key={`Sites-${isOpen}`} isOpen={isOpen} setIsOpen={setIsOpen} container={container} />
      </div>
    ),
    title: strings.lang.actionToolTip.manage,
    icon: [SettingsSmall, SettingsMedium],
    onClick: onClick,
  };
};

export class ManageSitesAction extends BaseReadActionClass {
  readonly trackedName = 'ManageSites';
  readonly useRenderer = useManageSitesAction;
  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes[0].data.type === 'sharepointroot';
  }
  isQuickAction(_data: ItemData, isTopBarItem: boolean): string | undefined {
    return isTopBarItem ? 'only' : undefined;
  }
}
