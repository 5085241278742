import React, { ReactElement } from 'react';
import {
  ExcelAppMedium,
  PowerPointAppMedium,
  ProjectAppMedium,
  VisioAppMedium,
  WordAppMedium,
  ViewItem,
  MediaKeysType,
  OutlookMedium,
  PdfMedium,
} from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor';
import { DocumentItem, isOfficeAppDocument, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass, TrackedEventType } from './BaseAction';
import { InsightItem } from '../graphTypes';

const useOpenInOfficeApp: ActionRendererHookType = ({ nodes, action, useSafeCallback, actionRef }) => {
  const that = action as OpenInOfficeAppAction;
  const gcl = useGraphClient();
  const node = nodes[0];
  const data = node.data;
  const onClick = useSafeCallback(
    async () => {
      actionRef.current = data.isDocument ? (data as DocumentItem).mediaType : undefined;
      const urls = await data.getAccessUrls(gcl);
      const defaultUrl = urls?.appUrl;
      const openUrl = urls?.odOpenUrl ?? defaultUrl;
      const linkToOpen = isOfficeAppDocument(nodes[0].data) ? defaultUrl : openUrl;
      if (linkToOpen) {
        const newWindow = window.open(linkToOpen);
        // Ensure TypeScript ignores the possibility of undefined
        if (newWindow) {
          (newWindow.document as Document).title = 'New harmon.ie';
        }
      }
    },
    [actionRef, data, gcl, nodes],
    false,
    {
      onSuccessTitle: data.isDocument
        ? strings.lang.notifications.openDocumentNotifications((data as DocumentItem).mediaType.toLowerCase())
        : '',
    }
  );

  return {
    title: that.title,
    icon: that.getIcon(data!),
    onClick,
  };
};

export class OpenInOfficeAppAction extends BaseReadActionClass {
  readonly trackedName = 'OpenDocument';
  readonly title = strings.lang.actionToolTip.openInApp;
  readonly useRenderer = useOpenInOfficeApp;

  private static iconMap = {
    Word: WordAppMedium,
    Excel: ExcelAppMedium,
    Csv: ExcelAppMedium,
    PowerPoint: PowerPointAppMedium,
    Project: ProjectAppMedium,
    Default: VisioAppMedium,
    Mail: OutlookMedium,
    Pdf: PdfMedium,
  };

  getIcon(data: ItemData): ReactElement {
    const app = (data as DocumentItem).officeApp ?? data.isDocument ? (data as DocumentItem).mediaType : '';
    const Icon = OpenInOfficeAppAction.iconMap[app] || OpenInOfficeAppAction.iconMap.Default;

    const fill = { 'data-nofill': true };
    return <Icon {...fill} key="OpenInApp" />;
  }

  transformEvent({ data, eventName }: TrackedEventType, mediaType?: MediaKeysType): TrackedEventType {
    return {
      eventName,
      data: {
        ...data,
        mode: 'Desktop',
        mediaType,
      },
    } as TrackedEventType;
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.length === 1 && (isOfficeAppDocument(nodes[0].data) || this.canOpen(nodes[0].data));
  }

  canOpen(item: ItemData): boolean {
    const mediaType = item.isDocument ? (item as DocumentItem).mediaType : '';
    const isAttachment = item instanceof InsightItem && item.isEmailAttachment;
    return ['Pdf', 'Mail'].includes(mediaType) && !isAttachment;
  }
}
