import React from 'react';
import { GraphClient } from '@services';
import { strings } from '@vendor';
import {
  NotificationsToastsSuccessIcon,
  SVGComponent,
  Typography,
  ViewItem,
  ViewsActionMedium,
  VMedium,
} from '@storybook';
import { useLocation, useNavigate } from 'react-router-dom';
import { DrilldownState, navigateDrilldown } from '~/components/Tree/Renderers/DrilldownHandler';
import { ActionRendererHookType, BaseAction, BaseReadActionClass, TrackedEventName } from './BaseAction';
import { ItemContainer, ItemData } from '../itemTypes';
import { ListView } from '../itemTypes/ListView';
import { DynamicMenuActionClass } from './DynamicMenuAction';

// Helper function for views names
const truncateViewName = (viewName: string) => {
  if (viewName.length > 25) {
    return `${viewName.slice(0, 25)}...`; // Truncate and append '...'
  }
  return viewName; // Return as is if it's within the limit
};
class ChangeViewAction extends BaseReadActionClass {
  constructor(
    private view: ListView,
    private location: ItemContainer
  ) {
    super();
  }
  get trackedName(): TrackedEventName {
    return 'ChangeView';
  }
  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes?.[0]?.data?.hasFolder;
  }
  useNavigateToView: ActionRendererHookType = ({ handlers, useSafeCallback }) => {
    const navigate = useNavigate();
    const {
      state: { view: selectedView },
    } = useLocation();

    const isSelectedView = selectedView ? this.view.id === selectedView.id : this.view.isDefault;
    const title = handlers.hasNewItems() && isSelectedView ? `${this.view.title}*` : this.view.title;
    const tooltip = title.length > 30 ? <Typography variant="H3Regular">{title}</Typography> : undefined;

    return {
      title,
      onClick: useSafeCallback(
        async () => navigateDrilldown(navigate, this.location, true, undefined, undefined, this.view),
        [navigate],
        undefined,
        {
          onSuccessTitle: strings.lang.viewsAction.changeViewSuccessMessage({
            viewname: truncateViewName(this.view.title),
          }),
        },
        () => true,
        undefined,
        undefined,
        <NotificationsToastsSuccessIcon />
      ),
      EndIcon: isSelectedView ? <VMedium /> : undefined,
      tooltipTitle: tooltip,
    };
  };
  readonly useRenderer = this.useNavigateToView;
}
export class ViewsAction extends DynamicMenuActionClass {
  private static currentSubscriptionToken: string | null = null;
  constructor(gcl: GraphClient, location: ItemContainer) {
    super(gcl, location);

    // Unsubscribe the previous instance if it exists
    if (ViewsAction.currentSubscriptionToken) {
      PubSub.unsubscribe(ViewsAction.currentSubscriptionToken);
    }

    // Subscribe the current instance to the 'refreshViews' event
    ViewsAction.currentSubscriptionToken = PubSub.subscribe('refreshViews', () => {
      this.init(true);
    });
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes?.[0]?.data?.hasFolder;
  }

  isDirty(): boolean {
    //it's hacky, need to figure out how to do it better
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation() as DrilldownState;

    return location.state.view?.isDefault === false;
  }

  async getDynamicActions(refresh: boolean): Promise<BaseAction[]> {
    if (this?.location?.hasFolder) {
      const folder = await this.location.getFolder(this.gcl);
      if (folder) {
        const views = await folder.getViews(this.gcl, refresh, refresh ? Date.now() : undefined);
        return views.map(view => new ChangeViewAction(view, this.location));
      }
    }
    return [];
  }
  get title(): string {
    return strings.lang.viewsAction.title;
  }
  get icon(): SVGComponent | SVGComponent[] | React.JSX.Element {
    return <ViewsActionMedium />;
  }
  // Cleanup the subscription when this instance is destroyed
  destroy() {
    if (ViewsAction.currentSubscriptionToken) {
      PubSub.unsubscribe(ViewsAction.currentSubscriptionToken);
      ViewsAction.currentSubscriptionToken = null;
    }
  }
}
