import React, { forwardRef } from 'react';
import { Stack, StackProps } from '@mui/material';
import { Scrollbar } from '../../../Scollbar';

export type WhatsNewBulletItemListProps = StackProps;

export const WhatsNewBulletItemList = forwardRef<any, WhatsNewBulletItemListProps>(
  ({ children, ...rest }: WhatsNewBulletItemListProps, ref) => {
    return (
      <Scrollbar>
        <Stack ref={ref} spacing={2} sx={{ pl: 3, pr: 3 }} {...rest}>
          {children}
        </Stack>
      </Scrollbar>
    );
  }
);

WhatsNewBulletItemList.displayName = 'WhatsNewBulletItemList';
