import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { applicationScopes, useGraphClient } from '@services';
import { logError, ReactAppInit } from '@vendor';
import { asyncPublish } from '~/hooks';
import { onBoardingDone } from '~/modules/Onboarding/OnboardingFlow';
import HomeSkeleton from '~/modules/Home/HomeSkeleton';

let CurrentlySignedIn = false;

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const gcl = useGraphClient();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const initialize = useCallback(async () => {
    try {
      const instance = gcl.msal;
      const accounts = instance.getAllAccounts();
      const account = instance.getActiveAccount() || (accounts && accounts[0]);
      if (account) {
        setLoading(!onBoardingDone.value || !CurrentlySignedIn);
        CurrentlySignedIn = true;
        await instance.acquireTokenSilent({ account, ...applicationScopes });

        // Do this serially so different modules can initialize in order
        await asyncPublish(ReactAppInit, { account, gcl }, true);

        if (onBoardingDone.value) {
          navigate('/home');
        } else {
          navigate('/onboarding');
        }
      } else navigate('/login');
    } catch (error: any) {
      logError(error);
      navigate('/login');
    } finally {
      setLoading(false);
    }
  }, [gcl, navigate]);

  useEffect(() => {
    const curPath = window.location.pathname;
    if (curPath !== '/') navigate(curPath);
    else initialize();
  }, [initialize, navigate]);

  return loading ? <HomeSkeleton /> : <>{children}</>;
};

export default ProtectedRoute;
